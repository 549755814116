import deepEqual from "deep-equal";

import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { RepeatOrderCommentDocument } from "../comment.model";
import { UnorderedList } from "../../components/unordered-list/UnorderedList";

interface RepeatOrderCommentProps {
    comment: RepeatOrderCommentDocument;
}

export const RepeatOrderComment = (props: RepeatOrderCommentProps) => {
    const { comment } = props;

    const before = extractRelevantData(comment.before);
    const after = extractRelevantData(comment.after);

    const anyChanges = !deepEqual(before, after, { strict: true });

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Genbestilling modtaget">
            <CommentBody>
                Kunden har afgivet bestilling igen.<br />
                <br />

                {anyChanges ? (
                    <>
                        Kundens oplysninger blev ændret:
                        <UnorderedList>
                            {before.name !== after.name ? <li>Navn er ændret fra “{before.name}” til “{after.name}”</li> : null}
                            {before.email !== after.email ? <li>Email er ændret fra “{before.email}” til “{after.email}”</li> : null}
                            {before.phoneNumber !== after.phoneNumber ? <li>Telefon er ændret fra “{before.phoneNumber}” til “{after.phoneNumber}”</li> : null}
                            {before.address !== after.address ? <li>Adresse er ændret fra “{before.address}” til “{after.address}”</li> : null}
                        </UnorderedList>
                    </>
                ) : "Kundens oplysninger blev ikke ændret."}

            </CommentBody>
        </Comment>
    );
};

function extractRelevantData(data: RepeatOrderCommentDocument["before"] | RepeatOrderCommentDocument["after"]) {
    return {
        name: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber,
        address: `${data.address.street}, ${data.address.zipCode}`,
    };
}
