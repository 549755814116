import { useEffect, useState } from "react";

import firebase from "firebase/compat/app";

import { RegistrationDocument } from "./registration.model";

export const useRegistration = (registrationId: string | null | undefined) => {
    const [state, setState] = useState<RegistrationDocument | null>();

    useEffect(() => {
        if ( registrationId === undefined ) return setState(undefined);
        if ( registrationId === null ) return setState(null);

        setState(undefined);

        return firebase.firestore().collection("registrations").doc(registrationId).onSnapshot(document => {
            if ( !document.exists ) return setState(null);

            setState({ id: document.id, ...document.data() } as RegistrationDocument);
        });
    }, [registrationId]);

    return state;
};
