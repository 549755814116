import { TablePagination } from "@material-ui/core";
import { useCallback } from "react";

import { InvoiceTablePaginationActions } from "./InvoiceTablePaginationActions";

interface InvoiceTablePaginationProps {
    page: number;
    onPageChange: (page: number) => void;
    pageSize: number;
    totalCount: number;
}

const Pagination = TablePagination as any;
const rowsPerPageOptions: any[] = [];

export const InvoiceTablePagination = (props: InvoiceTablePaginationProps) => {
    const { page, onPageChange, pageSize, totalCount } = props;

    const changePage = useCallback((_event, newPage) => {
        onPageChange(newPage);
    }, [onPageChange]);

    const labelDisplayedRows = useCallback((options: { from: number, to: number, count: number }) => {
        const { from, to, count } = options;

        return `${from}–${to} ud af ${count}`;
    }, []);

    return (
        <Pagination
            page={page}
            onChangePage={changePage}
            rowsPerPage={pageSize}
            rowsPerPageOptions={rowsPerPageOptions}
            labelDisplayedRows={labelDisplayedRows}
            count={totalCount}
            ActionsComponent={InvoiceTablePaginationActions}
        />
    );
};
