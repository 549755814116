import { useCallback, useMemo, useRef, useState } from "react";

import { Collapse, makeStyles } from "@material-ui/core";
import firebase from "firebase/compat/app";

import { NeutralAction } from "../../components/card/NeutralAction";
import { PositiveAction } from "../../components/card/PositiveAction";
import { Dialog, DialogActions, DialogContent, DialogHeader, useDialog } from "../../components/dialog";
import { Label } from "../../components/form/Label";
import { Group } from "../../components/form/Group";
import { AlternativeHint } from "../../components/form/Hint";
import { useAnimation } from "../../components/validation/animation.hook";
import { TwoOptionSwitch } from "../../components/switch/TwoOptionSwitch";
import { PersonSection } from "./PersonSection";
import { CompanySection } from "./CompanySection";
import { Validation, validateAddress, validateCpr, validateCvr, validateEmail, validateMandatory, validateMandatoryValidation, validateStrictPhoneNumber } from "../../components/validation/validation";
import { EmailField, TextField } from "../../components/form/InputField";
import { AddressField, AddressFieldAddress, useAddressPreloadingHack } from "../../components/form/AddressField";

const useStyles = makeStyles({
    text: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        marginTop: 0,
    },
});

export interface UpdateGardenerDialogProps {
    initialViewModel: UpdateGardenerDialogViewModel;
    onConfirm: (viewModel: UpdateGardenerDialogViewModel, idempotencyKey: string) => void;
    onCancel: () => void;
}

export interface UpdateGardenerDialogViewModel {
    name: string;
    nameDirty: boolean;
    email: string;
    emailDirty: boolean;
    phoneNumber: string;
    phoneNumberDirty: boolean;
    addressSearch: {
        searchTerm: string;
        address: AddressFieldAddress | undefined;
    },
    addressSearchDirty: boolean;
    type: "person" | "company";
    typeDirty: boolean;
    hasIdInitial: boolean;
    person: {
        cpr: string;
        cprDirty: boolean;
    },
    company: {
        cvr: string;
        cvrDirty: boolean;
    },
}

export interface UpdateGardenerDialogErrorModel {
    any: boolean;
    name: string | undefined;
    email: string | undefined;
    phoneNumber: string | undefined;
    addressSearch: string | undefined;
    type: string | undefined;
    cpr: Validation;
    cvr: string | undefined;
}

export const UpdateGardenerDialog = (props: UpdateGardenerDialogProps) => {
    const idempotencyKey = useRef(firebase.firestore().collection("x").doc().id);
    const classes = useStyles();

    const { initialViewModel, onConfirm, onCancel } = props;
    const [viewModel, setViewModel] = useState(initialViewModel);

    const errorModel = useMemo<UpdateGardenerDialogErrorModel>(() => {
        const nameError = [validateMandatory(viewModel.name)].find(Boolean);
        const emailError = [validateMandatory(viewModel.email), validateEmail(viewModel.email)].find(Boolean);
        const phoneNumberError = [validateMandatory(viewModel.phoneNumber), validateStrictPhoneNumber(viewModel.phoneNumber)].find(Boolean);
        const addressSearchError = [validateMandatory(viewModel.addressSearch.searchTerm), validateAddress(viewModel.addressSearch.address)].find(Boolean);
        const typeError = [].find(Boolean);

        const cprValidation = viewModel.type === "person" ? [viewModel.hasIdInitial ? validateMandatoryValidation(viewModel.person.cpr) : undefined, validateCpr(viewModel.person.cpr)] : [];
        const cprValidationError = cprValidation.find(x => x?.severity === "error");
        const cprValidationWarning = cprValidation.find(x => x?.severity === "warning");
        const cprError = cprValidationError ?? cprValidationWarning;

        const cvrError = viewModel.type === "company" ? [validateMandatory(viewModel.company.cvr), validateCvr(viewModel.company.cvr)].find(Boolean) : undefined;

        return {
            any: Boolean(nameError || emailError || phoneNumberError || addressSearchError || typeError || cprError?.severity === "error" || cvrError),
            name: nameError,
            email: emailError,
            phoneNumber: phoneNumberError,
            addressSearch: addressSearchError,
            type: typeError,
            cpr: cprError,
            cvr: cvrError,
        };
    }, [viewModel]);

    const changeName = useCallback((value: string) => {
        setViewModel(viewModel => ({ ...viewModel, name: value }));
    }, [setViewModel])

    const markNameDirty = useCallback(() => {
        setViewModel(viewModel => ({ ...viewModel, nameDirty: true }));
    }, [setViewModel]);

    const changeEmail = useCallback((value: string) => {
        setViewModel(viewModel => ({ ...viewModel, email: value }));
    }, [setViewModel])

    const markEmailDirty = useCallback(() => {
        setViewModel(viewModel => ({ ...viewModel, emailDirty: true }));
    }, [setViewModel]);

    const changePhoneNumber = useCallback((value: string) => {
        setViewModel(viewModel => ({ ...viewModel, phoneNumber: value }));
    }, [setViewModel])

    const markPhoneNumberDirty = useCallback(() => {
        setViewModel(viewModel => ({ ...viewModel, phoneNumberDirty: true }));
    }, [setViewModel]);

    const changeAddressSearch = useCallback((value: UpdateGardenerDialogViewModel["addressSearch"]) => {
        setViewModel(viewModel => ({ ...viewModel, addressSearch: value }));
    }, [setViewModel])

    const markAddressSearchDirty = useCallback(() => {
        setViewModel(viewModel => ({ ...viewModel, addressSearchDirty: true }));
    }, [setViewModel]);

    const onExactAddress = useCallback((address: AddressFieldAddress) => {
        if ( viewModel.addressSearch.searchTerm !== initialViewModel.addressSearch.searchTerm ) return;

        changeAddressSearch({
            searchTerm: initialViewModel.addressSearch.searchTerm,
            address,
        });
    }, [changeAddressSearch, initialViewModel, viewModel]);

    useAddressPreloadingHack(initialViewModel.addressSearch.searchTerm, initialViewModel.addressSearch.address, onExactAddress, markAddressSearchDirty);

    const typeOptions = useMemo(() => [
        { label: "Privatperson", value: "person" as UpdateGardenerDialogViewModel["type"] },
        { label: "Virksomhed", value: "company" as UpdateGardenerDialogViewModel["type"] },
    ], []);
    const changeType = useCallback((type: "person" | "company") => {
        setViewModel(viewModel => ({ ...viewModel, type, typeDirty: true }));
    }, []);

    const [validationAnimationDuration, skipValidationAnimation] = useAnimation();

    const scrollToFirstError = useCallback(() => {
        const sectionId = (() => {
            if ( errorModel.name ) return "name-section";
            if ( errorModel.email ) return "email-section";
            if ( errorModel.phoneNumber ) return "phone-number-section";
            if ( errorModel.addressSearch ) return "address-search-section";
            if ( errorModel.type ) return "type-section";
            if ( errorModel.cpr ) return "cpr-section";
            if ( errorModel.cvr ) return "cvr-section";

            return undefined;
        })();

        if ( !sectionId ) return;

        const section = document.getElementById(sectionId);
        if ( !section ) return;

        section.scrollIntoView({ behavior: "smooth" });
    }, [errorModel]);

    const confirm = useCallback(() => {
        if ( document.activeElement ) {
            (document.activeElement as HTMLInputElement).blur();
        }

        if ( errorModel.any ) {
            skipValidationAnimation();
            setViewModel(viewModel => ({
                ...viewModel,
                nameDirty: true,
                emailDirty: true,
                phoneNumberDirty: true,
                addressSearchDirty: true,
                typeDirty: true,
                person: {
                    ...viewModel.person,
                    cprDirty: true,
                },
                company: {
                    ...viewModel.company,
                    cvrDirty: true,
                },
            }));

            return setTimeout(scrollToFirstError, 100);
        }

        onConfirm(viewModel, idempotencyKey.current);
    }, [skipValidationAnimation, scrollToFirstError, onConfirm, errorModel.any, viewModel, idempotencyKey]);

    return (
        <Dialog>
            <DialogHeader>Ændr oplysninger</DialogHeader>

            <DialogContent>

                <p className={classes.text}>Eventuelle ændringer af havemandens honorarsatser træder i kraft fremadrettet.</p>

                <div id="name-section">
                    <Label htmlFor="name" style={{ marginBottom: "8px" }}>Fulde navn</Label>
                    <Group error={Boolean(viewModel.nameDirty && errorModel.name)}>
                        <TextField id="name" value={viewModel.name} onChange={changeName} onBlur={markNameDirty} />

                        <Collapse in={Boolean(viewModel.nameDirty && errorModel.name)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.name} />
                        </Collapse>
                    </Group>
                </div>

                <div id="email-section">
                    <Label htmlFor="email" style={{ marginBottom: "8px" }}>Email</Label>
                    <Group error={Boolean(viewModel.emailDirty && errorModel.email)}>
                        <EmailField id="email" value={viewModel.email} onChange={changeEmail} onBlur={markEmailDirty} />

                        <Collapse in={Boolean(viewModel.emailDirty && errorModel.email)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.email} />
                        </Collapse>
                    </Group>
                </div>

                <div id="phone-number-section">
                    <Label htmlFor="phone-number" style={{ marginBottom: "8px" }}>Telefon</Label>
                    <Group error={Boolean(viewModel.phoneNumberDirty && errorModel.phoneNumber)}>
                        <TextField id="phone-number" value={viewModel.phoneNumber} onChange={changePhoneNumber} onBlur={markPhoneNumberDirty} />

                        <Collapse in={Boolean(viewModel.phoneNumberDirty && errorModel.phoneNumber)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.phoneNumber} />
                        </Collapse>
                    </Group>
                </div>

                <div id="address-search-section">
                    <Label htmlFor="address-search" style={{ marginBottom: "8px" }}>Adresse</Label>
                    <Group error={Boolean(viewModel.addressSearchDirty && errorModel.addressSearch)}>
                        <AddressField id="address-search" searchTerm={viewModel.addressSearch.searchTerm} value={viewModel.addressSearch.address} onChange={changeAddressSearch} onBlur={markAddressSearchDirty} />

                        <Collapse in={Boolean(viewModel.addressSearchDirty && errorModel.addressSearch)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.addressSearch} />
                        </Collapse>
                    </Group>
                </div>

                <div id="type-section">
                    <Label style={{ marginBottom: "8px" }}>Arbejder havemanden som privatperson eller virksomhed?</Label>
                    <Group error={Boolean(viewModel.typeDirty && errorModel.type)}>

                        <TwoOptionSwitch options={typeOptions} value={viewModel.type} onChange={changeType} />

                        <Collapse in={Boolean(viewModel.typeDirty && errorModel.type)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.type} />
                        </Collapse>
                    </Group>
                </div>

                {viewModel.type === "person" ? (
                    <PersonSection
                        viewModel={viewModel}
                        setViewModel={setViewModel}
                        errorModel={errorModel}
                        validationAnimationDuration={validationAnimationDuration}
                    />
                ) : null}

                {viewModel.type === "company" ? (
                    <CompanySection
                        viewModel={viewModel}
                        setViewModel={setViewModel}
                        errorModel={errorModel}
                        validationAnimationDuration={validationAnimationDuration}
                    />
                ) : null}

            </DialogContent>

            <DialogActions>
                <NeutralAction onClick={onCancel}>Annuller</NeutralAction>
                <PositiveAction onClick={confirm}>Bekræft</PositiveAction>
            </DialogActions>

        </Dialog>
    );
};

export const useUpdateGardenerDialog = (initialViewModel: UpdateGardenerDialogViewModel | undefined, onConfirm: UpdateGardenerDialogProps["onConfirm"]) => {
    const { openDialog, closeDialog } = useDialog();

    const confirm: UpdateGardenerDialogProps["onConfirm"] = useCallback((data, idempotencyKey) => {
        closeDialog();

        onConfirm(data, idempotencyKey);
    }, [closeDialog, onConfirm]);

    return useCallback(() => {
        if ( !initialViewModel ) return;

        openDialog(<UpdateGardenerDialog initialViewModel={initialViewModel} onConfirm={confirm} onCancel={closeDialog} />);
    }, [openDialog, closeDialog, confirm, initialViewModel]);
};
