import { useCallback, useMemo, useRef, useState } from "react";

import { Collapse } from "@material-ui/core";
import firebase from "firebase/compat/app";

import { NeutralAction } from "../../components/card/NeutralAction";
import { PositiveAction } from "../../components/card/PositiveAction";
import { Dialog, DialogActions, DialogContent, DialogHeader, useDialog } from "../../components/dialog";
import { Label } from "../../components/form/Label";
import { Group } from "../../components/form/Group";
import { AlternativeHint } from "../../components/form/Hint";
import { useAnimation } from "../../components/validation/animation.hook";
import { validateAddress, validateEmail, validateMandatory, validateRelaxedPhoneNumber } from "../../components/validation/validation";
import { EmailField, TextField } from "../../components/form/InputField";
import { AddressField, AddressFieldAddress, useAddressPreloadingHack } from "../../components/form/AddressField";
import { NoticeFormItemComponent } from "../../components/form-item/client/components";

export interface CreateCustomerDialogProps {
    initialViewModel: CreateCustomerDialogViewModel;
    onConfirm: (viewModel: CreateCustomerDialogViewModel, idempotencyKey: string) => void;
    onCancel: () => void;
}

export interface CreateCustomerDialogViewModel {
    name: string;
    nameDirty: boolean;
    email: string;
    emailDirty: boolean;
    phoneNumber: string;
    phoneNumberDirty: boolean;
    addressSearch: {
        searchTerm: string;
        address: AddressFieldAddress | undefined;
    },
    addressSearchDirty: boolean;
}

export interface CreateCustomerDialogErrorModel {
    any: string | undefined;
    name: string | undefined;
    email: string | undefined;
    phoneNumber: string | undefined;
    addressSearch: string | undefined;
}

export const CreateCustomerDialog = (props: CreateCustomerDialogProps) => {
    const idempotencyKey = useRef(firebase.firestore().collection("x").doc().id);

    const { initialViewModel, onConfirm, onCancel } = props;
    const [viewModel, setViewModel] = useState(initialViewModel);

    const errorModel = useMemo<CreateCustomerDialogErrorModel>(() => {
        const nameError = [validateMandatory(viewModel.name)].find(Boolean);
        const emailError = [validateEmail(viewModel.email)].find(Boolean);
        const phoneNumberError = [validateMandatory(viewModel.phoneNumber), validateRelaxedPhoneNumber(viewModel.phoneNumber)].find(Boolean);
        const addressSearchError = [validateMandatory(viewModel.addressSearch.searchTerm), validateAddress(viewModel.addressSearch.address)].find(Boolean);

        return {
            any: nameError || emailError || phoneNumberError || addressSearchError,
            name: nameError,
            email: emailError,
            phoneNumber: phoneNumberError,
            addressSearch: addressSearchError,
        };
    }, [viewModel]);

    const changeName = useCallback((value: string) => {
        setViewModel(viewModel => ({ ...viewModel, name: value }));
    }, [setViewModel])

    const markNameDirty = useCallback(() => {
        setViewModel(viewModel => ({ ...viewModel, nameDirty: true }));
    }, [setViewModel]);

    const changeEmail = useCallback((value: string) => {
        setViewModel(viewModel => ({ ...viewModel, email: value }));
    }, [setViewModel])

    const markEmailDirty = useCallback(() => {
        setViewModel(viewModel => ({ ...viewModel, emailDirty: true }));
    }, [setViewModel]);

    const changePhoneNumber = useCallback((value: string) => {
        setViewModel(viewModel => ({ ...viewModel, phoneNumber: value }));
    }, [setViewModel])

    const markPhoneNumberDirty = useCallback(() => {
        setViewModel(viewModel => ({ ...viewModel, phoneNumberDirty: true }));
    }, [setViewModel]);

    const changeAddressSearch = useCallback((value: CreateCustomerDialogViewModel["addressSearch"]) => {
        setViewModel(viewModel => ({ ...viewModel, addressSearch: value }));
    }, [setViewModel])

    const markAddressSearchDirty = useCallback(() => {
        setViewModel(viewModel => ({ ...viewModel, addressSearchDirty: true }));
    }, [setViewModel]);

    const onExactAddress = useCallback((address: AddressFieldAddress) => {
        if ( viewModel.addressSearch.searchTerm !== initialViewModel.addressSearch.searchTerm ) return;

        changeAddressSearch({
            searchTerm: initialViewModel.addressSearch.searchTerm,
            address,
        });
    }, [changeAddressSearch, initialViewModel, viewModel]);

    useAddressPreloadingHack(initialViewModel.addressSearch.searchTerm, initialViewModel.addressSearch.address, onExactAddress, markAddressSearchDirty);

    const [validationAnimationDuration, skipValidationAnimation] = useAnimation();

    const scrollToFirstError = useCallback(() => {
        const sectionId = (() => {
            if ( errorModel.name ) return "name-section";
            if ( errorModel.email ) return "email-section";
            if ( errorModel.phoneNumber ) return "phone-number-section";
            if ( errorModel.addressSearch ) return "address-search-section";

            return undefined;
        })();

        if ( !sectionId ) return;

        const section = document.getElementById(sectionId);
        if ( !section ) return;

        section.scrollIntoView({ behavior: "smooth" });
    }, [errorModel]);

    const confirm = useCallback(() => {
        if ( document.activeElement ) {
            (document.activeElement as HTMLInputElement).blur();
        }

        if ( errorModel.any ) {
            skipValidationAnimation();
            setViewModel(viewModel => ({
                ...viewModel,
                nameDirty: true,
                emailDirty: true,
                phoneNumberDirty: true,
                addressSearchDirty: true,
            }));

            return setTimeout(scrollToFirstError, 100);
        }

        onConfirm(viewModel, idempotencyKey.current);
    }, [skipValidationAnimation, scrollToFirstError, onConfirm, errorModel.any, viewModel, idempotencyKey]);

    return (
        <Dialog>
            <DialogHeader>Opret kunde</DialogHeader>

            <DialogContent>

                <NoticeFormItemComponent item={{
                    type: "notice",
                    render: "warning",
                    html: `
                        Kunden oprettes med de samme faktureringsoplysninger og der bliver <strong>ikke</strong> sat gang i at lede efter en havemand.<br />
                        <br />
                        Husk derfor at:<br />
                        <ul>
                            <li>ændre fakturerings-oplysninger (hvis nødvendigt)</li>
                            <li>oprette en ny opgave for kunden</li>
                        </ul>
                    `,
                }} />

                <div id="name-section">
                    <Label htmlFor="name" style={{ marginBottom: "8px" }}>Navn</Label>
                    <Group error={Boolean(viewModel.nameDirty && errorModel.name)}>
                        <TextField id="name" value={viewModel.name} onChange={changeName} onBlur={markNameDirty} />

                        <Collapse in={Boolean(viewModel.nameDirty && errorModel.name)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.name} />
                        </Collapse>
                    </Group>
                </div>

                <div id="email-section">
                    <Label htmlFor="email" style={{ marginBottom: "8px" }}>Email</Label>
                    <Group error={Boolean(viewModel.emailDirty && errorModel.email)}>
                        <EmailField id="email" value={viewModel.email} onChange={changeEmail} onBlur={markEmailDirty} />

                        <Collapse in={Boolean(viewModel.emailDirty && errorModel.email)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.email} />
                        </Collapse>
                    </Group>
                </div>

                <div id="phone-number-section">
                    <Label htmlFor="phone-number" style={{ marginBottom: "8px" }}>Telefon</Label>
                    <Group error={Boolean(viewModel.phoneNumberDirty && errorModel.phoneNumber)}>
                        <TextField id="phone-number" value={viewModel.phoneNumber} onChange={changePhoneNumber} onBlur={markPhoneNumberDirty} />

                        <Collapse in={Boolean(viewModel.phoneNumberDirty && errorModel.phoneNumber)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.phoneNumber} />
                        </Collapse>
                    </Group>
                </div>

                <div id="address-search-section">
                    <Label htmlFor="address-search" style={{ marginBottom: "8px" }}>Adresse</Label>
                    <Group error={Boolean(viewModel.addressSearchDirty && errorModel.addressSearch)}>
                        <AddressField id="address-search" searchTerm={viewModel.addressSearch.searchTerm} value={viewModel.addressSearch.address} onChange={changeAddressSearch} onBlur={markAddressSearchDirty} />

                        <Collapse in={Boolean(viewModel.addressSearchDirty && errorModel.addressSearch)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.addressSearch} />
                        </Collapse>
                    </Group>
                </div>

            </DialogContent>

            <DialogActions>
                <NeutralAction onClick={onCancel}>Annuller</NeutralAction>
                <PositiveAction onClick={confirm}>Bekræft</PositiveAction>
            </DialogActions>

        </Dialog>
    );
};

export const useCreateCustomerDialog = (initialViewModel: CreateCustomerDialogViewModel | undefined, onConfirm: CreateCustomerDialogProps["onConfirm"]) => {
    const { openDialog, closeDialog } = useDialog();

    const confirm: CreateCustomerDialogProps["onConfirm"] = useCallback((data, idempotencyKey) => {
        closeDialog();

        onConfirm(data, idempotencyKey);
    }, [closeDialog, onConfirm]);

    return useCallback(() => {
        if ( !initialViewModel ) return;

        openDialog(<CreateCustomerDialog initialViewModel={initialViewModel} onConfirm={confirm} onCancel={closeDialog} />);
    }, [openDialog, closeDialog, confirm, initialViewModel]);
};
