import { Comment } from "../components/comment/Comment";
import { CommentBody } from "../components/comment/CommentBody";
import { GardenerUnpausedCommentDocument } from "./comment.model";

interface GardenerUnpausedCommentProps {
    comment: GardenerUnpausedCommentDocument;
}

export const GardenerUnpausedComment = (props: GardenerUnpausedCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Havemand er ikke længere på pause">
            <CommentBody>
                Havemanden har gjort sig selv aktiv igen.
            </CommentBody>
        </Comment>
    );
};
