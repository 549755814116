import { Cluster, Renderer } from "@googlemaps/markerclusterer";

/*
    Based on DefaultRenderer from @googlemaps/markerclusterer
*/
export class ClusterRenderer implements Renderer {
    
    public render(
        { count, position }: Pick<Cluster, "count"> & Pick<google.maps.MarkerOptions, "position">,
    ): google.maps.Marker {
        const color = "#A259FF";

        const svg = window.btoa(`
            <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
                <circle cx="120" cy="120" opacity=".6" r="70" />
                <circle cx="120" cy="120" opacity=".3" r="90" />
                <circle cx="120" cy="120" opacity=".2" r="110" />
            </svg>`
        );
  
        return new google.maps.Marker({
            position,
            icon: {
                url: `data:image/svg+xml;base64,${svg}`,
                scaledSize: new google.maps.Size(45, 45),
            },
            label: {
                text: String(count),
                color: "rgba(255,255,255,0.9)",
                fontSize: "12px",
            },
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        });
    }
}
