import { useCallback } from "react";

import { makeStyles } from "@material-ui/core";

import { Dialog, DialogContent, DialogHeader, useDialog } from "../../components/dialog";
import { DashedBorderSeparator } from "../../finance/billing/invoices/DashedBorderSeparator";
import { formatDate } from "../../date-utility";
import { formatPrice } from "../../finance/billing/invoices/invoice-detail/utility";
import { PaymentTransaction } from "./check-invoice-payment.function";

const useStyles = makeStyles({
    heading: {
        fontSize: "16px",
        fontWeight: 600,
        color: "#4A4A4A",
    },
    item: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "8px",

        "&:last-child": {
            marginBottom: 0,
        },
    },
    grid: {
        display: "grid",
        
        gridTemplateColumns: "160px 1fr 100px",
        gridColumnGap: "40px",
        gridRowGap: "8px",

        "& > :nth-child(3n + 3)": {
            textAlign: "right",
        },
    },
});

export interface TransactionsDialogViewModel {
    invoiceNumber: string;
    principal: number;
    fees: number;
    transactions: PaymentTransaction[];
    balance: number;
    externalDebtCollectionDate: string | null;
}

export interface TransactionsDialogProps {
    viewModel: TransactionsDialogViewModel;
}

export const TransactionsDialog = (props: TransactionsDialogProps) => {
    const classes = useStyles();
    const { viewModel } = props;

    return (
        <Dialog>
            <DialogHeader>Transaktioner for faktura {viewModel.invoiceNumber}</DialogHeader>

            <DialogContent>

                <div>

                    <div>
                        <div className={classes.item}>
                            <div className={classes.heading}>Faktureret</div>
                        </div>
                        <div className={classes.item}>
                            <div>Hovedstol</div>
                            <div>{formatPrice(viewModel.principal)}</div>
                        </div>
                        <div className={classes.item}>
                            <div>Gebyrer</div>
                            <div>{formatPrice(viewModel.fees)}</div>
                        </div>
                    </div>

                    <DashedBorderSeparator />

                    <div>
                        <div className={classes.item}>
                            <div className={classes.heading}>Transaktioner</div>
                        </div>

                        {viewModel.transactions.length === 0 ? (
                            <div className={classes.item}>Der er ikke registreret nogen transaktioner</div>
                        ) : null}

                        {viewModel.transactions.length > 0 ? (
                            <div className={classes.grid}>
                                {viewModel.transactions.map(transaction => (
                                    <>
                                        <div>{formatDate(transaction.date)}</div>
                                        <div>{formatTransactionType(transaction.type, transaction.amount)}</div>
                                        <div>{formatPrice(transaction.amount)}</div>
                                    </>
                                ))}
                            </div>
                        ) : null}
                    </div>

                    <DashedBorderSeparator />
                    
                    <div>
                        <div className={classes.item}>
                            <div className={classes.heading}>Udestående</div>
                            <div>{formatPrice(-viewModel.balance)}</div>
                        </div>

                        {viewModel.balance > 0 ? <div className={classes.item}>Fakturaen er overbetalt</div> : null}
                        {viewModel.balance === 0 ? <div className={classes.item}>Fakturaen er betalt</div> : null}    
                        {viewModel.balance < 0 ? <div className={classes.item}>Fakturaen mangler stadig betaling</div> : null}    

                        {viewModel.balance < 0 && viewModel.balance >= -5 ? <div className={classes.item}>Det resterende beløb vil blive afskrevet, da det er under vores bagatel-grænse.</div> : null}

                        {viewModel.externalDebtCollectionDate ? <div className={classes.item}>Fakturaen er sendt til inkasso {formatDate(viewModel.externalDebtCollectionDate)}.</div> : null}
                    </div>

                </div>

            </DialogContent>

        </Dialog>
    );
};

function formatTransactionType(type: PaymentTransaction["type"], amount: number): string {
    switch ( type ) {
        case "payment": return amount >= 0 ? "Betaling" : "Refusion";
        case "credit-note": return "Kreditnota";
        case "write off": return "Afskrivning";
        case "voucher": return "Bilag";
    }
}

export const useTransactionsDialog = () => {
    const { openDialog } = useDialog();

    return useCallback((viewModel: TransactionsDialogViewModel) => {
        openDialog(<TransactionsDialog viewModel={viewModel} />);
    }, [openDialog]);
};
