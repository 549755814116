import { useCallback, useMemo } from "react";

import { Container } from "@material-ui/core";
import moment from "moment";

import { ContentSection } from "../../../../layout/ContentSection";
import { SectionHeader } from "../../../../components/section/SectionHeader";
import { Section } from "../../../../components/section";
import { FollowUpList } from "../../FollowUpList";
import { AdhocGardenerTaskDocument } from "../../../../task/task.model";
import { useAdhocGardenerTasks } from "../../appointment/TaskProvider";

export const GardenersTab = () => {
    const adhocTasks = useAdhocGardenerTasks();

    const now = moment().toISOString(true);

    const currentTasks = useMemo(() => {
        return adhocTasks
            .filter(task => task.performAfterDate <= now)
            .sort((a, b) => {
                if ( a.performAfterDate < b.performAfterDate ) return -1;
                if ( a.performAfterDate > b.performAfterDate ) return 1;
                return 0;
            });
    }, [adhocTasks, now]);

    const futureTasks = useMemo(() => {
        return adhocTasks
            .filter(task => task.performAfterDate > now)
            .sort((a, b) => {
                if ( a.performAfterDate < b.performAfterDate ) return -1;
                if ( a.performAfterDate > b.performAfterDate ) return 1;
                return 0;
            });
    }, [adhocTasks, now]);

    const formatPrimaryText = useCallback((task: AdhocGardenerTaskDocument) => {
        return task.gardenerName;
    }, []);

    return (
        <ContentSection>
            <Container maxWidth="sm" disableGutters>

                <Section>
                    <SectionHeader>Aktuelle opgaver</SectionHeader>

                    <FollowUpList
                        tasks={currentTasks}
                        formatPrimaryText={formatPrimaryText}
                        placeholder="Der er ingen aktuelle opgaver"
                    />
                </Section>

                <Section>
                    <SectionHeader>Fremtidige opgaver</SectionHeader>

                    <FollowUpList
                        tasks={futureTasks}
                        formatPrimaryText={formatPrimaryText}
                        placeholder="Der er ingen fremtidige opgaver"
                    />
                </Section>

            </Container>
        </ContentSection>
    );
};
