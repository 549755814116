import { useEffect, useRef, useState } from "react";

import algoliasearch, { SearchClient } from "algoliasearch/lite";
import firebase from "firebase/compat/app";

const getSearchApiKey = firebase.app().functions("europe-west3").httpsCallable("Algolia-getSearchApiKey");

export const useSearchClient = () => {
    const [searchClient, setSearchClient] = useState<SearchClient>();

    const mounted = useRef(true);

    useEffect(() => {
        getSearchApiKey().then(getSearchApiKeyResponse => {
            if ( !mounted.current ) return;

            const searchApiKey = getSearchApiKeyResponse.data as string;
            const searchClient = algoliasearch("HUZ4QCBO1A", searchApiKey);

            setSearchClient(searchClient);
        });

        return () => {
            mounted.current = false;
        };
    }, []);

    return searchClient;
};
