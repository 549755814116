import { Link } from "react-router-dom";

import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { MessageSentCommentDocument } from "../comment.model";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    comment: {
        fontStyle: "italic",
    },
})

interface MessageSentGardenerCommentProps {
    comment: MessageSentCommentDocument;
}

export const MessageSentGardenerComment = (props: MessageSentGardenerCommentProps) => {
    const classes = useStyles();
    
    const { comment } = props;

    
    if ( comment.customerId && comment.customerName ) {
        const customerLink = <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link>;

        return (
            <Comment date={comment.creationDate.toDate().toISOString()} title="Besked til Go Go Garden">
                <CommentBody>
                    Havemanden har sendt en besked angående {customerLink}.<br />
                    <br />
                    Besked fra havemanden:<br />
                    <span className={classes.comment}>“{comment.message}”</span>
                </CommentBody>
            </Comment>
        );
    }

    const gardenerLink = <Link to={`/gardeners/context/${comment.gardenerId}`}>{comment.gardenerName}</Link>;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Havemanden bad om hjælp">
            <CommentBody>
                {gardenerLink} har bedt om hjælp.<br />
                <br />
                Spørgsmål fra havemanden:<br />
                <span className={classes.comment}>“{comment.message}”</span>
            </CommentBody>
        </Comment>
    );
};
