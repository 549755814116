import { ChangeEvent, PropsWithChildren, useCallback } from "react";

import { Checkbox as MaterialCheckbox, FormControlLabel, makeStyles } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        margin: 0,

        "& > .Mui-checked": {
            color: "#2FA52B",
        },
    },
    label: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",

        [theme.breakpoints.down(500)]: {
            fontSize: "14px",
        },
    },
    icon: {
        color: "#818181",
    },
}));

interface CheckboxProps {
    value: boolean;
    onChange: (checked: boolean) => void;
}

export const Checkbox = (props: PropsWithChildren<CheckboxProps>) => {
    const classes = useStyles();

    const { children, value, onChange } = props;

    const toggle = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked);
    }, [onChange]);

    const icon = <CheckBoxOutlineBlankIcon className={classes.icon} />;
    const control = <MaterialCheckbox color="primary" checked={value} icon={icon} onChange={toggle} />;

    return (
        <FormControlLabel
            control={control}
            label={children}
            classes={{ root: classes.root, label: classes.label }}
        />
    );
};
