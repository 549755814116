import Big from "big.js";
import moment from "moment";

import { CreditNoteDocument } from "../credit-note.model";
import { InvoiceDocument, Service } from "../invoice.model";

export const formatDate = (date: string): string => {
    return moment(date).format("D MMM YYYY");
}

export const formatServiceDuration = (hours: number): string => {
    const hourComponent = Math.floor(hours);
    const minuteComponent = (hours - hourComponent) * 60;

    return `${hourComponent}t ${minuteComponent.toString().padStart(2, "0")}m`;
}

export const formatServiceTools = (tools: Service["tools"]): string => {
    switch ( tools ) {
        case "customer-tools": return "Kundens redskaber";
        case "gardener-tools": return "Havemandens redskaber";
        case "gardener-large-tools": return "Havemandens store redskaber";
    }
}

export const calculateGardenerCommissionIncludingVat = (services: (InvoiceDocument | CreditNoteDocument)["services"]): number => {
    return services.reduce((total, service) => {
        const gardenerPrice = parseFloat(service.hourlyGardenerPrice.toFixed(2)) * service.hours;

        const gardenerCommission = parseFloat((gardenerPrice).toFixed(2));

        return Big(total).plus(gardenerCommission).round(2).toNumber();
    }, 0);
}

export const calculateServiceCommissionIncludingVat = (services: (InvoiceDocument | CreditNoteDocument)["services"], discount: number | null, gardenerDiscount: number | null): number => {
    const invoiceTotal = calculateInvoiceTotal(services, discount, gardenerDiscount);
    const gardenerCommission = calculateGardenerCommissionIncludingVat(services);

    return Big(invoiceTotal).plus(discount ?? 0).plus(gardenerDiscount ?? 0).minus(gardenerCommission).round(2).toNumber();
}

export const calculateVat = (serviceCommission: number, gardenerCommission: number, paysVat: boolean): number => {
    const serviceCommissionVat = serviceCommission * 0.25;
    const gardenerCommissionVat = paysVat ? gardenerCommission * 0.25 : 0;

    return parseFloat((serviceCommissionVat + gardenerCommissionVat).toFixed(2));
}

export const calculateInvoiceTotal = (services: (InvoiceDocument | CreditNoteDocument)["services"], discount: number | null, gardenerDiscount: number | null): number => {
    const totalPriceBeforeDiscount = services.reduce((total, service) => {
        const customerPrice = Big(service.hours).times(service.hourlyCustomerPrice).round(2);

        return total.plus(customerPrice);
    }, Big(0));

    return totalPriceBeforeDiscount.minus(discount ?? 0).round(2).minus(gardenerDiscount ?? 0).round(2).toNumber();
}

export const formatPrice = (price: number): string => {
    const value = price.toFixed(2)
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return `${value} kr.`;
}

export const formatNumber = (value: number): string => {
    return value.toFixed(2)
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const formatInteger = (value: number): string => {
    return value.toFixed(0)
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
