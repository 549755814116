import { useCallback } from "react";

import firebase from "firebase/compat/app";

import { useBlockingProgress } from "../../components/blocking-progress";
import { useDialog } from "../../components/dialog";
import { useSnackbar } from "../../components/snackbar";
import { PauseGardenerDialog, PauseGardenerFormContext } from "./PauseGardenerDialog";

const pauseGardenerFunction = firebase.app().functions("europe-west3").httpsCallable("ManualAdministratorProtection-activate2");

export const usePauseGardener = (gardenerId: string | null | undefined) => {
    const { openDialog, closeDialog } = useDialog();
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    const pauseGardener = useCallback((form: PauseGardenerFormContext) => {
        if ( !gardenerId ) return;

        closeDialog();

        block();
        pauseGardenerFunction({ gardenerId, reason: form.reasonInput.reason })
            .then(() => {
                openSnackbar("success", "Havemanden er sat på pause");
                unblock();
            })
            .catch(() => {
                openSnackbar("error", "Der skete en fejl. Havemanden er ikke blevet sat på pause.");
                unblock();
            });

    }, [closeDialog, block, unblock, openSnackbar, gardenerId]);

    return useCallback(() => {
        openDialog(<PauseGardenerDialog onConfirm={pauseGardener} />);
    }, [openDialog, pauseGardener]);
};
