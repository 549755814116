import { useMemo } from "react";
import { Redirect, useParams } from "react-router-dom";

import moment from "moment";

import { TitleHeader } from "../../components/TitleHeader";
import { TabBar } from "../../components/tabs/TabBar";
import { TabBarItem } from "../../components/tabs/TabBarItem";
import { useEvaluateGardenerTasks } from "../../workflow/follow-up/appointment/TaskProvider";
import { IntegratedGardeners } from "./IntegratedGardenersTab";

export const IntegratedEvaluateGardenerOverviewPage = () => {
    const { tab } = useParams<{ tab: "gardeners" }>();

    const tabIndex = useMemo(() => {
        switch ( tab ) {
            case "gardeners": return 0;
        }
    }, [tab]);

    const now = moment().toISOString(true);

    const evaluateGardenerTasks = useEvaluateGardenerTasks();
    const currentEvaluateGardenerTasks = useMemo(() => evaluateGardenerTasks.filter(task => task.performAfterDate <= now), [evaluateGardenerTasks, now]);

    if ( tab !== "gardeners" ) return <Redirect to="/evaluate-gardener/gardeners" />;

    return (
        <>
            <TitleHeader>Evaluer samarbejde</TitleHeader>

            <TabBar index={tabIndex}>
                <TabBarItem href="/evaluate-gardener/gardeners">{formatTitle("Havemænd", currentEvaluateGardenerTasks.length)}</TabBarItem>
            </TabBar>

            {(() => {
                if ( tab === "gardeners" ) return <IntegratedGardeners />;

                return null;
            })()}
        </>
    );
};

function formatTitle(text: string, count: number): string {
    if ( count === 0 ) return text;

    return `${text} (${count})`;
};
