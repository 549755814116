import { useParams } from "react-router";

import { Container } from "@material-ui/core";

import { ContentSection } from "../../../../layout/ContentSection";
import { TitleHeader } from "../../../../components/TitleHeader";
import { LinearProgress } from "../../../../components/LinearProgress";
import { BackButton } from "../../../../components/BackButton";
import { useCustomerComments } from "../../../../comment/comments.hook";
import { ActivitySection } from "../../../../comment/ActivitySection";
import { CustomerCommentList } from "../../../../comment/CustomerCommentList";
import { useAddCustomerComment } from "../../../../comment/add-comment.hook";
import { useTask } from "../../../../task/tasks.hook";
import { NotFoundPage } from "../../../../NotFoundPage";
import { ErrorPage } from "../../../../ErrorPage";
import { CallBeforeInvoiceReminderFee1TaskContext } from "./CallBeforeInvoiceReminderFee1TaskContext";
import { TaskDocument } from "../../../../task/task.model";
import { useAdministratorAction } from "../../../../workflow/administrator-action.hook";
import { useDineroContact } from "../../pending-approval/dinero-contacts.hook";

interface CallBeforeInvoiceReminderFee1DetailPageProps {
    goBack: () => void;
}

export const CallBeforeInvoiceReminderFee1DetailPage = (props: CallBeforeInvoiceReminderFee1DetailPageProps) => {
    const { goBack } = props;

    const { taskId } = useParams<{ taskId: string }>();
    const task = useTask(taskId) as TaskDocument | null | undefined;

    const customerId = (() => {
        if ( !task ) return undefined;
        if ( !("customerId" in task) ) return undefined;

        return task.customerId;
    })();

    const contact = useDineroContact(customerId);

    const comments = useCustomerComments(customerId);
    const addComment = useAddCustomerComment(customerId);

    const administratorAction = useAdministratorAction(goBack);

    if ( task === null ) return <NotFoundPage />;
    if ( task === undefined || contact === undefined ) return <LinearProgress />;
    if ( task.type !== "payment-collection" ) return <ErrorPage message="Opgavens type genkendes ikke" />;
    if ( contact === null ) return <ErrorPage message="Kundens oplysninger kunne ikke findes" />
    if ( task.status !== "call before invoice reminder fee 1" ) return <ErrorPage message="Opgavens status er uforventet" />;

    if ( comments === undefined ) return <LinearProgress />;

    const backButton = <BackButton onClick={goBack} />;

    return (
        <>
            <TitleHeader button={backButton}>Rykker-advarsel</TitleHeader>
            
            <ContentSection>
                <Container maxWidth="sm" disableGutters>

                    <CallBeforeInvoiceReminderFee1TaskContext
                        task={task}
                        contact={contact}
                        administratorAction={administratorAction}
                    />

                    <ActivitySection onSave={addComment}>
                        <CustomerCommentList comments={comments} />
                    </ActivitySection>

                </Container>
            </ContentSection>
        </>
    );
};
