import { useCallback, useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router";

import { Container } from "@material-ui/core";
import moment from "moment";

import { ContentSection } from "../../../layout/ContentSection";
import { SectionHeader } from "../../../components/section/SectionHeader";
import { Section } from "../../../components/section";
import { Message } from "../../../Message";
import { List } from "../../../components/list/List";
import { ListItem } from "../../../components/list/ListItem";
import { useManualPaymentCollectionTasks } from "../BillingTaskProvider";
import { skipAccordingToHoursAndMinutesSkippingWeekendAsWell } from "../../../date-utility";
import { sortByCustomerThenDueDate } from "../../../shared/utility";
import { PaymentCollectionTaskDocument } from "../../../task/task.model";

type RelevantTask = PaymentCollectionTaskDocument;

export const DebtCollectionWarningTab = () => {
    const tasks = useRelevantTasks();

    const currentTasks = useMemo(() => {
        const now = moment().toISOString(true);

        return tasks.filter(task => skipAccordingToHoursAndMinutesSkippingWeekendAsWell(task.performAfterDate, { hour: 8, minute: 0 }, { hour: 20, minute: 0 }) <= now);
    }, [tasks]);

    const formatPrimaryText = useCallback((task: RelevantTask) => {
        return `${task.customerName}, faktura ${task.invoiceNumber}`;
    }, []);

    const formatSecondaryText = useCallback((task: RelevantTask) => {
        const date = skipAccordingToHoursAndMinutesSkippingWeekendAsWell(task.performAfterDate, { hour: 8, minute: 0 }, { hour: 20, minute: 0 });
        const formattedDate = moment(date).format("D MMMM HH:mm");

        return `Skal udføres ${formattedDate}`;
    }, []);

    const history = useHistory();
    const { path } = useRouteMatch();

    const goToTaskPage = useCallback((task: RelevantTask) => {
        history.push(`${path}/${task.id}`);
    }, [history, path]);

    return (
        <ContentSection>
            <Container maxWidth="sm" disableGutters>

                <Section>
                    <SectionHeader>Aktuelle opgaver</SectionHeader>

                    {currentTasks.length > 0 ? (
                        <List>
                            {currentTasks.map(task => (
                                <ListItem
                                    key={task.id}
                                    item={task}
                                    formatPrimaryText={formatPrimaryText}
                                    formatSecondaryText={formatSecondaryText}
                                    onClick={goToTaskPage}
                                />
                            ))}
                        </List>
                    ) : (
                        <Message>Der er ingen aktuelle opgaver</Message>
                    )}
                </Section>

            </Container>
        </ContentSection>
    );
};

function useRelevantTasks() {
    const tasks = useManualPaymentCollectionTasks();

    return useMemo(() => {
        return tasks
            .filter(task => task.status === "call before debt collection")
            .sort(sortByCustomerThenDueDate) as RelevantTask[];
    }, [tasks]);
};
