import { useMemo } from "react";

import { PageFormItem, GroupFormItem, VerticalButtonsSingleChoiceFormItem, InfoFormItem, NoticeFormItem, TextAreaFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { GardenerPickerFormItem } from "../../components/form-item/client/form-item/GardenerPickerFormItemComponent";

export interface SpecifyFindGardenerStrategyFormIds {
    SingleChoiceFindGardenerStrategy: string;
    GardenerPickerGardener: string;
    TextAreaReason: string;
}

export type SpecifyFindGardenerStrategyForm<NextPage extends FormItem> = SpecifyFindGardenerStrategyPage<NextPage>;

type SpecifyFindGardenerStrategyPage<NextPage extends FormItem> = PageFormItem<
    GroupFormItem<[
        VerticalButtonsSingleChoiceFormItem<[
            NextPage,
            SpecificGardenerPage<ConfirmationPage>,
        ]>,
    ]>
>;

type SpecificGardenerPage<NextPage extends FormItem> = PageFormItem<
    GroupFormItem<[
        NoticeFormItem,
        GardenerPickerFormItem,
        TextAreaFormItem,
        NextPage,
    ]>
>

type ConfirmationPage = PageFormItem<InfoFormItem>

export const useSpecifyFindGardenerStrategyForm = <NextPage extends FormItem>(nextPage: NextPage, formIds: SpecifyFindGardenerStrategyFormIds): SpecifyFindGardenerStrategyForm<NextPage> => {
    return useMemo(() => {

        const specificGardenerPage: SpecificGardenerPage<ConfirmationPage> = {
            type: "page",
            title: "Tilknyt havemand",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "notice",
                        render: "info",
                        html: "Nedenstående årsag sendes ikke til havemanden.",
                    },
                    {
                        id: formIds.GardenerPickerGardener,
                        type: "gardener-picker",
                        dirty: false,
                        value: "",
                        gardener: null,
                        lookedUpGardenerId: "",
                    },
                    {
                        id: formIds.TextAreaReason,
                        type: "text-area",
                        render: "text-area",
                        label: "Årsag",
                        placeholder: "Angiv årsagen til at du tilknytter en specifik havemand til opgaven",
                        value: "",
                        dirty: false,
                        validation: "mandatory",
                    },
                    {
                        type: "page",
                        title: "Opret opgave",
                        item: {
                            type: "info",
                            render: "default",
                            text: `
                                Opgaven bliver oprettet.<br />
                                <br />
                                Havemanden bliver ikke tilbudt opgaven, men tilknyttes opgaven med det samme.<br />
                                <br />
                                Kunden informeres om at opgaven er blevet oprettet.
                            `,
                        },
                    },
                ],
            },
        };

        const page: SpecifyFindGardenerStrategyPage<NextPage> = {
            type: "page",
            title: "Find eller tilknyt havemand",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        id: formIds.SingleChoiceFindGardenerStrategy,
                        type: "single-choice",
                        mode: "switch page",
                        render: "vertical-buttons",
                        questionText: "Hvad skal der ske med opgaven?",
                        answerTexts: [
                            "Der skal findes en ny havemand",
                            "Der skal tilknyttes en specifik havemand",
                        ],
                        answerItems: [nextPage, specificGardenerPage],
                        selectedChoiceIndex: null,
                        dirty: false,
                    },
                ],
            },
        };
        
        return page;
    }, [nextPage, formIds]);
}
