import { ListItem, makeStyles } from "@material-ui/core";
import { GardeningTaskDocument } from "../gardening-task.model";
import { useCallback, useMemo } from "react";
import { determineColorForGardeningTaskStatus, formatGardeningTaskCreationDate, formatGardeningTaskStatus } from "../utility";
import { CustomerDocument } from "../../customer/customer.model";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.25)",

        backgroundColor: "#FFFFFF",
        padding: "24px",

        "&:hover": {
            backgroundColor: "#EBF3EA",
        },

        "& > .MuiTouchRipple-root": {
            color: "#1C5B13",
        },

        [theme.breakpoints.down(650)]: {
            flexDirection: "column",
        },
    },
    leftPanel: {
        [theme.breakpoints.down(650)]: {
            marginBottom: "8px",
            textAlign: "center",
        },
    },
    heading: {
        fontSize: "16px",
        fontWeight: 600,
        color: "#4A4A4A",
        marginBottom: "4px",
    },
    gardener: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
    },
    status: {
        padding: "8px",
        fontSize: "14px",
        fontWeight: 400,
        fontVariant: "small-caps",
        borderRadius: "4px",
        whiteSpace: "nowrap",
    },
    statusDefault: {
        backgroundColor: "#818181",
        color: "#FFFFFF",
    },
    statusInfo: {
        backgroundColor: "#0076EB",
        color: "#FFFFFF",
    },
    statusDanger: {
        backgroundColor: "#F44336",
        color: "#FFFFFF",
    },
    statusWarning: {
        backgroundColor: "#E37F09",
        color: "#FFFFFF",
    },
    statusSuccess: {
        backgroundColor: "#2FA52B",
        color: "#FFFFFF",
    },
}));

interface GardeningTaskGardenerListItemProps {
    gardeningTask: GardeningTaskDocument;
    customers: CustomerDocument[];
    onSelect: (gardeningTask: GardeningTaskDocument) => void;
}

export const GardeningTaskGardenerListItem = (props: GardeningTaskGardenerListItemProps) => {
    const classes = useStyles();
    const { gardeningTask, customers, onSelect } = props;

    const heading = formatGardeningTaskCreationDate(gardeningTask);
    const gardener = formatCustomer(gardeningTask, customers);
    const status = formatGardeningTaskStatus(gardeningTask, "gardener");
    const statusColor = determineColorForGardeningTaskStatus(gardeningTask);

    const statusClass = useMemo(() => {
        switch ( statusColor ) {
            case "default": return classes.statusDefault;
            case "info": return classes.statusInfo;
            case "success": return classes.statusSuccess;
            case "warning": return classes.statusWarning;
            case "danger": return classes.statusDanger;
        }
    }, [classes, statusColor]);

    const onClick = useCallback(() => {
        onSelect(gardeningTask);
    }, [onSelect, gardeningTask]);

    return (
        <ListItem button className={classes.root} onClick={onClick}>
            <div className={classes.leftPanel}>
                <div className={classes.heading}>{heading}</div>
                <div className={classes.gardener}>{gardener}</div>
            </div>
            <div className={`${classes.status} ${statusClass}`}>{status}</div>
        </ListItem>
    );
};

function formatCustomer(gardeningTask: GardeningTaskDocument, customers: CustomerDocument[]): string {
    const gardener = customers.find(customer => customer.id === gardeningTask.customerId);
    if ( !gardener ) return "Ukendt kunde";

    return gardener.name;
}
