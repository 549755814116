import { Redirect, useParams } from "react-router-dom";

import { LinearProgress } from "../components/LinearProgress";
import { useGardener } from "../gardener/gardeners.hook";

export const RedirectToGardenerWithAppropriateContext = () => {
    const { gardenerId } = useParams<{ gardenerId: string }>();

    const gardener = useGardener(gardenerId);

    const context: "all" | "terminated" | undefined = (() => {
        if ( gardener === undefined ) return undefined;
        if ( gardener === null ) return "terminated";

        return gardener.status !== "terminated" ? "all" : "terminated";
    })();

    if ( context === undefined ) return <LinearProgress />;

    return <Redirect to={`/gardeners/${context}/${gardenerId}`} />;
};
