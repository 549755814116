import { useCallback } from "react";

import firebase from "firebase/compat/app";

import { useBlockingProgress } from "../../components/blocking-progress";
import { useSnackbar } from "../../components/snackbar";
import { UpdateGardenerDialogProps } from "./UpdateGardenerDialog";

export interface UpdateGardenerData {
    idempotencyKey: string;
    gardenerId: string;
    update: {
        type: "person",
        name: string;
        email: string;
        phoneNumber: string;
        externalAddressId: string;
        cpr: string | null,
    } | {
        type: "company",
        name: string;
        email: string;
        phoneNumber: string;
        externalAddressId: string;
        cvr: string,
    },
}

const updateGardenerFunction: (data: UpdateGardenerData) => Promise<firebase.functions.HttpsCallableResult>
    = firebase.app().functions("europe-west3").httpsCallable("Gardener-update3");

export const useUpdateGardenerAction = (gardenerId: string) => {
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    const action: UpdateGardenerDialogProps["onConfirm"] = useCallback((viewModel, idempotencyKey) => {
        block();

        const phoneNumberWithoutWhitespace = viewModel.phoneNumber.replace(/\s/g, "");
        const formattedPhoneNumber = phoneNumberWithoutWhitespace.substring(0, 2) + " " + phoneNumberWithoutWhitespace.substring(2, 4) + " " + phoneNumberWithoutWhitespace.substring(4, 6) + " " + phoneNumberWithoutWhitespace.substring(6, 8);

        const data: UpdateGardenerData = {
            idempotencyKey,
            gardenerId,
            update: viewModel.type === "person" ? {
                type: "person",
                name: viewModel.name,
                email: viewModel.email,
                phoneNumber: formattedPhoneNumber,
                externalAddressId: viewModel.addressSearch.address!.externalId,
                cpr: viewModel.person.cpr || null,
            } : {
                type: "company",
                name: viewModel.name,
                email: viewModel.email,
                phoneNumber: formattedPhoneNumber,
                externalAddressId: viewModel.addressSearch.address!.externalId,
                cvr: viewModel.company.cvr,
            }
        };

        updateGardenerFunction(data)
            .then(() => {
                openSnackbar("success", "Din handling er registreret");
                unblock();
            })
            .catch(() => {
                openSnackbar("error", "Der skete en fejl. Din handling er ikke blevet registreret.");
                unblock();
            });

    }, [block, unblock, openSnackbar, gardenerId]);

    return action;
};
