import { useAddCustomerComment } from "../../../comment/add-comment.hook";
import { useCustomerComments } from "../../../comment/comments.hook";
import { ComplaintTaskDocument } from "../../../task/task.model";
import { LinearProgress } from "../../../components/LinearProgress";
import { ContactGardenerDetailPage } from "./ContactGardenerDetailPage";
import { useAdministratorAction } from "../../../workflow/administrator-action.hook";
import { useInvoices } from "../../../finance/billing/invoices/invoices.hook";
import { ErrorPage } from "../../../ErrorPage";
import { useGardener } from "../../../gardener/gardeners.hook";

interface IntegratedContactGardenerDetailPageProps {
    task: ComplaintTaskDocument;
    goBack: () => void;
}

export const IntegratedContactGardenerDetailPage = (props: IntegratedContactGardenerDetailPageProps) => {
    const { task, goBack } = props;

    const gardener = useGardener(task.gardenerId);
    const invoices = useInvoices(task.complaint.invoiceIds);

    const administratorAction = useAdministratorAction(goBack);

    const comments = useCustomerComments(task?.customerId);
    const addComment = useAddCustomerComment(task?.customerId);

    if ( gardener === null ) return <ErrorPage message="Kunne ikke finde havemanden" />;
    if ( invoices === null ) return <ErrorPage message="Kunne ikke finde fakturaer" />
    if ( gardener === undefined || invoices === undefined || comments === undefined ) return <LinearProgress />;

    return (
        <ContactGardenerDetailPage
            task={task}
            gardener={gardener}
            invoices={invoices}
            administratorAction={administratorAction}
            comments={comments}
            addComment={addComment}
            goBack={goBack}
        />
    );
};
