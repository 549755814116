import { Section } from "../../../../components/section";
import { CallBeforeInvoiceDebtCollectionDetailCard } from "./CallBeforeInvoiceDebtCollectionDetailCard";
import { VerticalButtons, WhiteButton } from "../../../../components/new-button";
import { useDidNotReachCustomerDialog } from "../DidNotReachCustomerDialog";
import { useDidReachCustomerDialog } from "../DidReachCustomerDialog";
import { Message } from "../../../../Message";
import { PaymentCollectionTaskDocument } from "../../../../task/task.model";
import { AdministratorActionData } from "../../../../workflow/administrator-action.hook";
import { useCallback } from "react";
import { DineroContactDocument } from "../../pending-approval/dinero-contact.model";

interface CallBeforeInvoiceDebtCollectionTaskContextProps {
    task: PaymentCollectionTaskDocument;
    contact: DineroContactDocument;
    administratorAction: (data: AdministratorActionData | string) => void;
}

export const CallBeforeInvoiceDebtCollectionTaskContext = (props: CallBeforeInvoiceDebtCollectionTaskContextProps) => {
    const { task, contact, administratorAction } = props;

    const registerDidNotReachCustomerAdapter = useCallback(() => {
        administratorAction({
            action: "register did not reach customer for call before debt collection",
            taskId: task.id,
        });
    }, [administratorAction, task.id]);
    const openDidNotReachCustomerDialog = useDidNotReachCustomerDialog(registerDidNotReachCustomerAdapter);

    const registerDidReachCustomerAdapter = useCallback((summary: string) => {
        administratorAction({
            action: "register did reach customer for call before debt collection",
            taskId: task.id,
            summary,
        });
    }, [administratorAction, task.id]);
    const openDidReachCustomerDialog = useDidReachCustomerDialog(registerDidReachCustomerAdapter);

    return (
        <Section>
            {task.hasBeenPerformed ? <Message>Opgaven er allerede blevet udført</Message> : null}

            <CallBeforeInvoiceDebtCollectionDetailCard task={task} contact={contact} />

            {!task.hasBeenPerformed ? (
                <VerticalButtons>
                    <WhiteButton onClick={openDidNotReachCustomerDialog}>Jeg fik ikke fat i kunden</WhiteButton>
                    <WhiteButton onClick={openDidReachCustomerDialog}>Jeg fik fat i kunden</WhiteButton>
                </VerticalButtons>
            ) : null}

        </Section>
    );
};
