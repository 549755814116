import { makeStyles } from "@material-ui/core";

import { Card } from "../../../../components/card/Card";
import { LegacyCardContent } from "../../../../components/card/CardContent";
import { CardHeader } from "../../../../components/card/CardHeader";
import { Link } from "react-router-dom";
import { formatDateAccordingToHoursSkippingWeekend } from "../../../../date-utility";
import { UnorderedList } from "../../../../components/unordered-list/UnorderedList";
import { PaymentCollectionTaskDocument } from "../../../../task/task.model";
import { DineroContactDocument } from "../../pending-approval/dinero-contact.model";

const useStyles = makeStyles({
    group: {
        display: "flex",
        flexDirection: "column",
        lineHeight: "125%",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },

        "& > span": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
            whiteSpace: "pre-wrap",

            "& > a": {
                fontSize: "16px",
                fontWeight: 500,
                color: "#297A1D",
                textDecoration: "none",
        
                "&:hover": {
                    color: "#1C5B13",
                    textDecoration: "underline",
                },
            },
        },
    },
    order: {
        "& > *": {
            marginBottom: "16px",
        },

        "& > *:last-child": {
            marginBottom: 0,
        }
    },
});

interface CallBeforeInvoiceReminderFee1DetailCardProps {
    task: PaymentCollectionTaskDocument;
    contact: DineroContactDocument;
}

export const CallBeforeInvoiceReminderFee1DetailCard = (props: CallBeforeInvoiceReminderFee1DetailCardProps) => {
    const classes = useStyles();

    const { task, contact } = props;

    return (
        <Card>
            <CardHeader>{task.customerName}, faktura {task.invoiceNumber}</CardHeader>

            <LegacyCardContent className={classes.order}>

                <div className={classes.group}>
                    <label>Hvad er der hændt?</label>
                    <span>
                        <Link to={`/customers/context/${task.customerId}`}>{task.customerName}</Link> har ikke betalt <Link to={`/invoices/all/invoice/${task.invoiceId}`}>faktura {task.invoiceNumber}</Link> efter at have fået tilsendt 2 betalingspåmindelser.
                    </span>
                </div>

                <div className={classes.group}>
                    <label>Hvad skal der gøres?</label>
                    <span>
                        Ring kunden op på <Link to={`tel:${contact.phoneNumber}`}>{contact.phoneNumber}</Link>. Får du ikke fat i kunden, læg da en besked.<br />
                        <br />
                        Stikord til samtalen/beskeden:
                    </span>
                    <UnorderedList>
                        <li>Du har en faktura, der er gået over forfald</li>
                        <li>Systemet afsender automatisk en rykker i dag</li>
                        <li>Men inden det sker, ville jeg lige give dig et sidste opkald, for at se, om ikke vi kunne få det klaret</li>
                        <li>Hvis du betaler hurtigst muligt, kan jeg sørge for, at du ikke skal betale rykkergebyret</li>
                        <li>Send os en email, når du har betalt</li>
                    </UnorderedList>
                </div>

                <div className={classes.group}>
                    <label>Hvornår skal det udføres?</label>
                    <span>{formatDateAccordingToHoursSkippingWeekend(task.performAfterDate, 8, 20)}</span>
                </div>

            </LegacyCardContent>

        </Card>
    );
};
