import { PropsWithChildren } from "react";

import { Container, makeStyles, Paper } from "@material-ui/core";

const useStyles = makeStyles({
    paper: {
        overflow: "hidden",
    },
});

interface CardProps {
    className?: string;
}

export const Card = (props: PropsWithChildren<CardProps>) => {
    const classes = useStyles();

    const { className, children } = props;

    return (
        <Container maxWidth="sm" disableGutters className={className}>
            <Paper className={classes.paper}>

                {children}

            </Paper>
        </Container>
    );
};
