import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { AllTab } from "./AllTab";

export const IntegratedAllTab = () => {
    const history = useHistory();
    const goToInvoice = useCallback((invoiceId: string) => history.push(`/invoices/all/invoice/${invoiceId}`), [history]);
    const goToCreditNote = useCallback((creditNoteId: string) => history.push(`/invoices/all/credit-note/${creditNoteId}`), [history]);

    return (
        <AllTab
            goToInvoice={goToInvoice}
            goToCreditNote={goToCreditNote}
        />
    );
};
