import { Link } from "react-router-dom";

import { Comment } from "../components/comment/Comment";
import { OfferAcceptedCommentDocument } from "./comment.model";
import { CommentBody } from "../components/comment/CommentBody";

interface OfferAcceptedGardenerCommentProps {
    comment: OfferAcceptedCommentDocument;
}

export const OfferAcceptedGardenerComment = (props: OfferAcceptedGardenerCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Havemand tilknyttet">
            <CommentBody>
                Havemanden har sagt ja tak til <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link>.
            </CommentBody>
        </Comment>
    );
};
