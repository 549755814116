import { makeStyles } from "@material-ui/core";

import { Card } from "../components/card/Card";
import { CardContent } from "../components/card/CardContent";
import { GardenerApplicationDocument } from "./gardener-application.model";
import { CardHeader } from "../components/card/CardHeader";

import { GardenerDocument } from "../gardener/gardener.model";
import { MenuButton } from "../components/menu/MenuButton";
import { MenuItem } from "../components/menu/MenuItem";
import { ManageAccountsIcon } from "../components/icon/ManageAccountsIcon";
import { PersonalInformationDocument } from "../gardener/personal-information/personal-information.model";
import { useMemo } from "react";
import { UpdateGardenerDialogViewModel, useUpdateGardenerDialog } from "../gardener/update-gardener/UpdateGardenerDialog";
import { useUpdateGardenerAction } from "../gardener/update-gardener/update-gardener-action.hook";

const useStyles = makeStyles({
    group: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },
    },
    text: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",

        "& > a": {
            fontSize: "16px",
            fontWeight: 500,
            color: "#297A1D",
            textDecoration: "none",
    
            "&:hover": {
                color: "#1C5B13",
                textDecoration: "underline",
            },
        },
    },
    multiline: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        whiteSpace: "pre-wrap",
    },
});

interface ApplicationDetailCardProps {
    gardener: GardenerDocument;
    personalInformation: PersonalInformationDocument;
    application: GardenerApplicationDocument;
}

export const ApplicationDetailCard = (props: ApplicationDetailCardProps) => {
    const classes = useStyles();

    const { gardener, personalInformation, application } = props;

    const initialUpdateGardenerDialogViewModel = useMemo<UpdateGardenerDialogViewModel | undefined>(() => {
        return {
            name: gardener.name,
            nameDirty: false,
            email: gardener.email,
            emailDirty: false,
            phoneNumber: gardener.phoneNumber,
            phoneNumberDirty: false,
            addressSearch: {
                searchTerm: `${gardener.address.street}, ${gardener.address.zipCode}`,
                address: undefined,
            },
            addressSearchDirty: false,
            type: gardener.cvr ? "company" : "person",
            typeDirty: false,
            hasIdInitial: Boolean(personalInformation.cpr || gardener.cvr),
            person: {
                cpr: personalInformation.cpr ?? "",
                cprDirty: true,
            },
            company: {
                cvr: gardener.cvr ?? "",
                cvrDirty: true,
            },
        };
    }, [gardener, personalInformation]);
    const updateGardener = useUpdateGardenerAction(gardener.id);
    const openUpdateGardenerDialog = useUpdateGardenerDialog(initialUpdateGardenerDialogViewModel, updateGardener);

    const menuButton = (
        <MenuButton>
            <MenuItem icon={<ManageAccountsIcon />} onClick={openUpdateGardenerDialog}>Ændr oplysninger</MenuItem>
        </MenuButton>
    );

    return (
        <Card>

            <CardHeader button={menuButton}>{gardener.name}</CardHeader>

            <CardContent>

                <div className={classes.group}>
                    <label>Id</label>
                    <span className={classes.text}>{gardener.id}</span>
                </div>

                {gardener.cvr ? (
                    <div className={classes.group}>
                        <label>Virksomhed</label>
                        <span className={classes.text}>
                            <a href={`https://datacvr.virk.dk/enhed/virksomhed/${gardener.cvr}`} target="_blank" rel="noreferrer">
                                {gardener.companyName} (CVR: {gardener.cvr})
                            </a>
                        </span>
                    </div>
                ) : null}

                <div className={classes.group}>
                    <label>Erfaring med havearbejde</label>
                    <span className={classes.multiline}>{application.experience}</span>
                </div>

            </CardContent>

        </Card>
    );
};
