import moment from "moment";

import { TextField } from "../../../../../components/form/InputField";
import { useInvoiceForm } from "./InvoiceForm";

export const InvoiceDateInput = () => {
    const { form } = useInvoiceForm();
    const { invoiceDateInput } = form;

    return <TextField value={invoiceDateInput.invoiceDate} disabled />;
}

export interface InvoiceDateInputContext {
    invoiceDate: string;
}

export const newInvoiceDateInputContext = (today: string): InvoiceDateInputContext => ({
    invoiceDate: moment(today).format("DD-MM-YYYY"),
});
