import { useEffect, useRef, useState } from "react";

import firebase from "firebase/compat/app";

import { CustomerDocument, DeletedCustomerDocument } from "./customer.model";

export const useDeletedCustomers = () => {
    const [state, setState] = useState<DeletedCustomerDocument[]>();

    useEffect(() => {
        setState(undefined);

        return firebase.firestore()
            .collection("customers")
                .where("status", "==", "deleted")
            .onSnapshot(collection => {
                const documents = collection.docs.map(document => ({ id: document.id, ...document.data() } as DeletedCustomerDocument));
                
                setState(documents);
            });
    }, []);

    return state;
};

export const useCustomer = (customerId: string | null | undefined) => {
    const [state, setState] = useState<CustomerDocument | null>();

    useEffect(() => {
        if ( customerId === null ) return setState(null);
        if ( customerId === undefined ) return setState(undefined);

        setState(undefined);

        return firebase.firestore().collection("customers").doc(customerId).onSnapshot(document => {
            if ( !document.exists ) return setState(null);

            setState({ id: document.id, ...document.data() } as CustomerDocument);
        });
    }, [customerId]);

    return state;
};

export const useCustomers = () => {
    const [state, setState] = useState<CustomerDocument[]>();

    useEffect(() => {
        setState(undefined);

        return firebase.firestore()
            .collection("customers")
            .onSnapshot(collection => {
                const documents = collection.docs.map(document => ({ id: document.id, ...document.data() } as CustomerDocument));
                
                setState(documents);
            });
    }, []);

    return state;
};

export const useCustomersById = (customerIds: string[] | undefined) => {
    const [state, setState] = useState<CustomerDocument[]>();

    const hasTerminated = useRef(false);
    useEffect(() => {
        hasTerminated.current = false;

        if ( !customerIds ) return setState(undefined);

        setState(undefined);

        Promise.all(customerIds.map(async customerId => {
            const document = await firebase.firestore().collection("customers").doc(customerId).get();
            if ( !document ) throw new Error(`Expected customers/${customerId} to exist`);

            return { id: document.id, ...document.data() } as CustomerDocument;
        })).then(customers => {
            if ( !hasTerminated.current ) {
                setState(customers);
            }
        });

        return () => {
            hasTerminated.current = true;
        };
    }, [customerIds]);

    return state;
};

export const useOptionalCustomersById = (customerIds: string[] | undefined) => {
    const [state, setState] = useState<CustomerDocument[]>();

    const hasTerminated = useRef(false);
    useEffect(() => {
        if ( customerIds === undefined ) return setState(undefined);

        hasTerminated.current = false;

        setState(undefined);

        Promise.all(customerIds.map(async customerId => {
            const document = await firebase.firestore().collection("customers").doc(customerId).get();
            if ( !document.exists ) return null;

            return { id: document.id, ...document.data() } as CustomerDocument;
        })).then(optionalCustomers => {
            const gardeners = optionalCustomers.filter(Boolean) as CustomerDocument[];

            if ( !hasTerminated.current ) setState(gardeners);
        });

        return () => {
            hasTerminated.current = true;
        };
    }, [customerIds]);

    return state;
}
