import { useMemo } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";

import moment from "moment";

import { TitleHeader } from "../../../components/TitleHeader";
import { TabBar } from "../../../components/tabs/TabBar";
import { TabBarItem } from "../../../components/tabs/TabBarItem";
import { AutomaticTab } from "./AutomaticTab";
import { ReminderFeeWarningTab } from "./ReminderFeeWarningTab";
import { DebtCollectionWarningTab } from "./DebtCollectionWarningTab";
import { useManualPaymentCollectionTasks } from "../BillingTaskProvider";
import { skipAccordingToHoursAndMinutesSkippingWeekendAsWell } from "../../../date-utility";

export const PaymentCollectionOverviewPage = () => {
    const { tab } = useParams<{ tab: string }>();

    const tabIndex = useMemo(() => {
        switch ( tab ) {
            case "reminder-fee-warning": return 0;
            case "debt-collection-warning": return 1;
            case "automatic": return 2;

            default: return 0;
        }
    }, [tab]);

    const now = moment().toISOString(true);
    const paymentCollectionTasks = useManualPaymentCollectionTasks();

    const currentReminderFeeWarningTasks = paymentCollectionTasks
        .filter(task => task.status === "call before invoice reminder fee 1")
        .filter(task => skipAccordingToHoursAndMinutesSkippingWeekendAsWell(task.performAfterDate, { hour: 8, minute: 0 }, { hour: 20, minute: 0 }) <= now);

    const currentDebtCollectionWarningTasks = paymentCollectionTasks
        .filter(task => task.status === "call before debt collection")
        .filter(task => skipAccordingToHoursAndMinutesSkippingWeekendAsWell(task.performAfterDate, { hour: 8, minute: 0 }, { hour: 20, minute: 0 }) <= now);

    return (
        <>
            <TitleHeader>Opkrævning</TitleHeader>

            <TabBar index={tabIndex}>

                <TabBarItem href="/finance/payment-collection/reminder-fee-warning">
                    {appendTaskCount("Rykker-advarsel", currentReminderFeeWarningTasks.length)}
                </TabBarItem>

                <TabBarItem href="/finance/payment-collection/debt-collection-warning">
                    {appendTaskCount("Inkasso-advarsel", currentDebtCollectionWarningTasks.length)}
                </TabBarItem>

                <TabBarItem href="/finance/payment-collection/automatic">Automatisk håndtering</TabBarItem>

            </TabBar>

            <Switch>

                <Route exact path="/finance/payment-collection/reminder-fee-warning">
                    <ReminderFeeWarningTab />
                </Route>

                <Route exact path="/finance/payment-collection/debt-collection-warning">
                    <DebtCollectionWarningTab />
                </Route>

                <Route exact path="/finance/payment-collection/automatic">
                    <AutomaticTab />
                </Route>

                <Redirect to="/finance/payment-collection/reminder-fee-warning" />

            </Switch>
        </>
    );
};

function appendTaskCount(text: string, count: number): string {
    if ( count > 0 ) return `${text} (${count})`;

    return text;
}
