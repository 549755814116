import { useCallback } from "react";

import DeleteIcon from "@material-ui/icons/DeleteOutlined";

import { InvoiceFormContext, useInvoiceForm } from "../InvoiceForm";
import { GreenIconButton } from "../../../../../../components/icon-button/GreenIconButton";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 0,
        marginBottom: "24px",

        "& > label": {
            fontSize: "16px",
            fontWeight: 600,
            color: "#4A4A4A",
        },
    },
    button: {
        margin: "-16px",
        marginRight: "-8px",
    },
});

interface ServiceHeaderProps {
    index: number;
}

export const ServiceHeader = (props: ServiceHeaderProps) => {
    const classes = useStyles();
    const { index } = props;

    const { setForm } = useInvoiceForm();

    const deleteTask = useCallback(() => {
        setForm((previousState): InvoiceFormContext => {
            if ( previousState.serviceInputs.length === 1 ) {
                return {
                    ...previousState,
                    serviceInputs: [{
                        descriptionInput: {
                            description: "",
                            dirty: false,
                        },
                        durationInput: {
                            hours: undefined,
                            dirty: false,
                        },
                        toolsInput: {
                            tools: undefined,
                            dirty: false,
                        },
                    }],
                };
            }

            const serviceInputs = [...previousState.serviceInputs];
            serviceInputs.splice(index, 1);

            return {
                ...previousState,
                serviceInputs,
            };
        });
    }, [setForm, index]);

    return (
        <div className={classes.root}>
            <label>Opgave {index + 1}</label>

            <GreenIconButton className={classes.button} icon={<DeleteIcon />} onClick={deleteTask} />
        </div>
    );
}
