import { Dispatch, SetStateAction, useCallback, useEffect, useRef } from "react";

import { Collapse, makeStyles } from "@material-ui/core";
import { da } from "date-fns/locale";
import moment from "moment";

import { Label } from "../../components/form/Label";
import { Group } from "../../components/form/Group";
import { AlternativeHint } from "../../components/form/Hint";
import { DateField, TextArea, TimeField } from "../../components/form/InputField";
import { useAnimation } from "../../components/validation/animation.hook";
import { usePrevious } from "../../previous.hook";
import { useMandatory } from "../../components/validation/mandatory.hook";
import { useTime, useTimeAfterCutoff } from "../../components/validation/time.hook";
import { CreateTaskDialogViewModel } from "./CreateTaskDialog";
import { useDate, useDateAfterCutoff } from "../../components/validation/date.hook";

const useStyles = makeStyles(theme => ({
    doubleGroup: {
        display: "flex",

        "& > *": {
            flexBasis: "50%",
            marginRight: "24px",
        },

        "& > *:last-child": {
            marginRight: 0,
        },

        [theme.breakpoints.down(675)]: {
            flexDirection: "column",

            "& > *": {
                flexBasis: "100%",
                marginRight: 0,
                marginBottom: "24px",
            },
    
            "& > *:last-child": {
                marginBottom: 0,
            },
        },
    },
}));

export interface AdhocTaskFormSectionViewModel {
    type: "adhoc";
    whatHasHappened: string;
    whatHasHappenedDirty: boolean;
    whatShouldBeDone: string;
    whatShouldBeDoneDirty: boolean;
    performAfterDateDate: string;
    performAfterDateDateDirty: boolean;
    performAfterDateTime: string;
    performAfterDateTimeDirty: boolean;
    error: boolean;
}

interface AdhocTaskFormSectionProps {
    viewModel: AdhocTaskFormSectionViewModel;
    setViewModel: Dispatch<SetStateAction<CreateTaskDialogViewModel>>;
    hasSubmittedWithError: boolean;
    shouldScrollToFirstError: boolean;
}

export const AdhocTaskFormSection = (props: AdhocTaskFormSectionProps) => {
    const classes = useStyles();

    const { viewModel, setViewModel, hasSubmittedWithError, shouldScrollToFirstError } = props;

    const { whatHasHappened, whatShouldBeDone, performAfterDateDate, performAfterDateTime } = viewModel;
    const { whatHasHappenedDirty, whatShouldBeDoneDirty, performAfterDateDateDirty, performAfterDateTimeDirty } = viewModel;

    const setWhatHasHappened = useCallback((whatHasHappened: string) => {
        setViewModel(previousState => ({ ...previousState, whatHasHappened }));
    }, [setViewModel]);

    const setWhatShouldBeDone = useCallback((whatShouldBeDone: string) => {
        setViewModel(previousState => ({ ...previousState, whatShouldBeDone }));
    }, [setViewModel]);

    const setPerformAfterDateDate = useCallback((performAfterDateDate: string) => {
        setViewModel(previousState => ({ ...previousState, performAfterDateDate }));
    }, [setViewModel]);

    const setPerformAfterDateTime = useCallback((performAfterDateTime: string) => {
        setViewModel(previousState => ({ ...previousState, performAfterDateTime }));
    }, [setViewModel]);

    const markWhatHasHappenedDirty = useCallback(() => setViewModel(previousState => ({ ...previousState, whatHasHappenedDirty: true })), [setViewModel]);
    const markWhatShouldBeDoneDirty = useCallback(() => setViewModel(previousState => ({ ...previousState, whatShouldBeDoneDirty: true })), [setViewModel]);
    const markPerformAfterDateDateDirty = useCallback(() => setViewModel(previousState => ({ ...previousState, performAfterDateDateDirty: true })), [setViewModel]);
    const markPerformAfterDateTimeDirty = useCallback(() => setViewModel(previousState => ({ ...previousState, performAfterDateTimeDirty: true })), [setViewModel]);

    const [validationAnimationDuration, skipValidationAnimation] = useAnimation();
    const now = useRef(moment().toISOString(true)).current;

    const whatHasHappenedError = useMandatory(whatHasHappened);
    const whatShouldBeDoneError = useMandatory(whatShouldBeDone);
    const performAfterDateDateError = [useMandatory(performAfterDateDate), useDate(performAfterDateDate), useDateAfterCutoff(performAfterDateDate, now)].find(Boolean);
    const performAfterDateTimeError = [useMandatory(performAfterDateTime), useTime(performAfterDateTime), useTimeAfterCutoff(performAfterDateDate, performAfterDateTime, now)].find(Boolean);

    const error = Boolean(whatHasHappenedError || whatShouldBeDoneError || performAfterDateDateError || performAfterDateTimeError);
    const previousError = usePrevious(error);

    useEffect(() => {
        if ( error !== previousError ) {
            setViewModel(previousState => ({ ...previousState, error }));
        }
    }, [setViewModel, error, previousError]);

    const scrollToFirstError = useCallback(() => {
        const sectionId = (() => {
            if ( whatHasHappenedError ) return "what-has-happened-section";
            if ( whatShouldBeDoneError ) return "what-should-be-done-section";
            if ( performAfterDateDateError ) return "perform-after-date-date-section";
            if ( performAfterDateTimeError ) return "perform-after-date-time-section";

            return undefined;
        })();

        if ( !sectionId ) return;

        const section = document.getElementById(sectionId);
        if ( !section ) return;

        section.scrollIntoView({ behavior: "smooth" });
    }, [whatHasHappenedError, whatShouldBeDoneError, performAfterDateDateError, performAfterDateTimeError]);

    const previousHasSubmittedWithError = usePrevious(hasSubmittedWithError);

    useEffect(() => {
        if ( hasSubmittedWithError && !previousHasSubmittedWithError ) {
            skipValidationAnimation();
            setViewModel(previousState => ({ ...previousState, whatHasHappenedDirty: true, whatShouldBeDoneDirty: true, performAfterDateDateDirty: true, performAfterDateTimeDirty: true }));
        }
    }, [setViewModel, hasSubmittedWithError, previousHasSubmittedWithError, skipValidationAnimation, scrollToFirstError]);

    useEffect(() => {
        if ( !shouldScrollToFirstError ) return;

        setTimeout(scrollToFirstError, 100);
    }, [scrollToFirstError, shouldScrollToFirstError])

    return (
        <>
            <div id="what-has-happened-section">
                <Label htmlFor="what-has-happened" style={{ marginBottom: "8px" }}>Hvad er der hændt?</Label>
                <Group error={Boolean(whatHasHappenedDirty && whatHasHappenedError)}>
                    <TextArea id="what-has-happened" value={whatHasHappened} onChange={setWhatHasHappened} onBlur={markWhatHasHappenedDirty} placeholder="Beskriv hvorfor der skal oprettes en opgave" />

                    <Collapse in={Boolean(whatHasHappenedDirty && whatHasHappenedError)} timeout={validationAnimationDuration}>
                        <AlternativeHint message={whatHasHappenedError} />
                    </Collapse>
                </Group>
            </div>

            <div id="what-should-be-done-section">
                <Label htmlFor="what-should-be-done" style={{ marginBottom: "8px" }}>Hvad skal der gøres?</Label>
                <Group error={Boolean(whatShouldBeDoneDirty && whatShouldBeDoneError)}>
                    <TextArea id="what-should-be-done" value={whatShouldBeDone} onChange={setWhatShouldBeDone} onBlur={markWhatShouldBeDoneDirty} placeholder="Beskriv hvad der skal gøres" />

                    <Collapse in={Boolean(whatShouldBeDoneDirty && whatShouldBeDoneError)} timeout={validationAnimationDuration}>
                        <AlternativeHint message={whatShouldBeDoneError} />
                    </Collapse>
                </Group>
            </div>

            <div className={classes.doubleGroup}>

                <div id="perform-after-date-date-section">
                    <Label htmlFor="perform-after-date-date" style={{ marginBottom: "8px" }}>Dato for udførsel</Label>
                    <Group error={Boolean(performAfterDateDateDirty && performAfterDateDateError)}>
                        <DateField id="perform-after-date-date" value={performAfterDateDate} onChange={setPerformAfterDateDate} onBlur={markPerformAfterDateDateDirty} placeholder="DD-MM-YYYY" minimumDate={new Date(now)} locale={da} />

                        <Collapse in={Boolean(performAfterDateDateDirty && performAfterDateDateError)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={performAfterDateDateError} />
                        </Collapse>
                    </Group>
                </div>

                <div id="perform-after-date-time-section">
                    <Label htmlFor="perform-after-date-time" style={{ marginBottom: "8px" }}>Klokkeslæt</Label>
                    <Group error={Boolean(performAfterDateTimeDirty && performAfterDateTimeError)}>
                        <TimeField id="perform-after-date-time" value={performAfterDateTime} onChange={setPerformAfterDateTime} onBlur={markPerformAfterDateTimeDirty} placeholder="HH:mm" />

                        <Collapse in={Boolean(performAfterDateTimeDirty && performAfterDateTimeError)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={performAfterDateTimeError} />
                        </Collapse>
                    </Group>
                </div>

            </div>
        </>
    );
};
