import { useCallback, useMemo, useRef, useState } from "react";

import { Collapse, MenuItem, makeStyles } from "@material-ui/core";

import { NeutralAction } from "../components/card/NeutralAction";
import { PositiveAction } from "../components/card/PositiveAction";
import { Dialog, DialogActions, DialogContent, DialogHeader, useDialog } from "../components/dialog";
import { Label } from "../components/form/Label";
import { Group } from "../components/form/Group";
import { AlternativeHint } from "../components/form/Hint";
import { TextArea } from "../components/form/InputField";
import { useAnimation } from "../components/validation/animation.hook";
import { validateMandatory, validateString } from "../components/validation/validation";
import { Dropdown } from "../components/form/Dropdown";

export interface TerminateGardenerDialogViewModel {
    gardenerName: string;
    reason: string;
    reasonDirty: boolean;
    explanation: string;
    explanationDirty: boolean;
    confirmation: string;
    confirmationDirty: boolean;
}

export interface TerminateGardenerDialogErrorModel {
    any: boolean;
    reason: string | undefined;
    explanation: string | undefined;
    confirmation: string | undefined;
}

const useStyles = makeStyles({
    text: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        marginTop: 0,
    },
});

export interface TerminateGardenerDialogProps {
    initialViewModel: TerminateGardenerDialogViewModel;
    onConfirm: (viewModel: TerminateGardenerDialogViewModel) => void;
    onCancel: () => void;
}

export const TerminateGardenerDialog = (props: TerminateGardenerDialogProps) => {
    const classes = useStyles();

    const { initialViewModel, onConfirm, onCancel } = props;
    const [viewModel, setViewModel] = useState(initialViewModel);

    const errorModel = useMemo<TerminateGardenerDialogErrorModel>(() => {
        const reasonError = [validateMandatory(viewModel.reason)].find(Boolean);
        const explanationError = viewModel.reason !== "gardener-wants-to-stop" ? [validateMandatory(viewModel.explanation)].find(Boolean) : [].find(Boolean);
        const confirmationError = [validateMandatory(viewModel.confirmation), validateString(viewModel.confirmation, viewModel.gardenerName)].find(Boolean);

        return {
            any: Boolean(reasonError || explanationError || confirmationError),
            reason: reasonError,
            explanation: explanationError,
            confirmation: confirmationError,
        };
    }, [viewModel]);

    const changeReason = useCallback((value: string) => {
        setViewModel(viewModel => ({
            ...viewModel,
            reason: value,
        }));
    }, []);

    const markReasonDirty = useCallback(() => setViewModel(viewModel => ({ ...viewModel, reasonDirty: true })), []);
    const renderReasonPlaceholder = useCallback(() => <>Vælg årsag</>, []);

    const changeExplanation = useCallback((value: string) => {
        setViewModel(viewModel => ({ ...viewModel, explanation: value }));
    }, [])

    const markExplanationDirty = useCallback(() => setViewModel(viewModel => ({ ...viewModel, explanationDirty: true })), []);

    const changeConfirmation = useCallback((value: string) => {
        setViewModel(viewModel => ({ ...viewModel, confirmation: value }));
    }, [])

    const markConfirmationDirty = useCallback(() => setViewModel(viewModel => ({ ...viewModel, confirmationDirty: true })), []);

    const [validationAnimationDuration, skipValidationAnimation] = useAnimation();

    const scrollToFirstError = useCallback(() => {
        const sectionId = (() => {
            if ( errorModel.reason ) return "reason-section";
            if ( errorModel.explanation ) return "explanation-section";
            if ( errorModel.confirmation ) return "confirmation-section";

            return undefined;
        })();

        if ( !sectionId ) return;

        const section = document.getElementById(sectionId);
        if ( !section ) return;

        section.scrollIntoView({ behavior: "smooth" });
    }, [errorModel]);

    const confirm = useCallback(() => {
        if ( document.activeElement ) {
            (document.activeElement as HTMLInputElement).blur();
        }

        if ( errorModel.any ) {
            skipValidationAnimation();
            setViewModel(viewModel => ({
                ...viewModel,
                reasonDirty: true,
                explanationDirty: true,
                confirmationDirty: true,
            }));
            return setTimeout(scrollToFirstError, 100);
        }

        onConfirm(viewModel);
    }, [skipValidationAnimation, scrollToFirstError, onConfirm, errorModel, viewModel]);

    const fieldsVisible = useMemo(() => {
        const fields: Array<keyof Pick<TerminateGardenerDialogViewModel, "reason" | "explanation" | "confirmation">> = [];
        fields.push("reason");
        if ( viewModel.reason === "bad-partnership" || viewModel.reason === "gardener-behavior" ) fields.push("explanation");
        if ( viewModel.reason === "gardener-wants-to-stop" || viewModel.explanation ) fields.push("confirmation");

        return fields;
    }, [viewModel]);

    return (
        <Dialog>
            <DialogHeader>Ophør samarbejde</DialogHeader>

            <DialogContent>

                <p className={classes.text}>Alle opgaver tages fra havemanden og havemanden slettes næste år d. 1. marts.</p>

                {fieldsVisible.includes("reason") ? (
                    <div id="reason-section">
                        <Label style={{ marginBottom: "8px" }}>Årsag til ophør</Label>
                        <Group error={Boolean(viewModel.reasonDirty && errorModel.reason)}>
                            <Dropdown variant="outlined" renderValue={viewModel.reason ? undefined : renderReasonPlaceholder} value={viewModel.reason} onChange={changeReason} onBlur={markReasonDirty} displayEmpty>
                                <MenuItem value="bad-partnership">Samarbejdet fungerer ikke</MenuItem>
                                <MenuItem value="gardener-behavior">Havemandens opførsel</MenuItem>
                                <MenuItem value="gardener-wants-to-stop">Havemanden ønsker at stoppe samarbejdet</MenuItem>
                            </Dropdown>

                            <Collapse in={Boolean(viewModel.reasonDirty && errorModel.reason)} timeout={validationAnimationDuration}>
                                <AlternativeHint message={errorModel.reason} />
                            </Collapse>
                        </Group>
                    </div>
                ) : null}

                {fieldsVisible.includes("explanation") ? (
                    <div id="explanation-section">
                        <Label htmlFor="explanation" style={{ marginBottom: "8px" }}>Uddybende forklaring</Label>
                        <Group error={Boolean(viewModel.explanationDirty && errorModel.explanation)}>
                            <TextArea id="explanation" value={viewModel.explanation} onChange={changeExplanation} onBlur={markExplanationDirty} placeholder="Uddyb hvorfor du vil ophøre samarbejdet med havemanden. Sendes ikke til havemanden." />

                            <Collapse in={Boolean(viewModel.explanationDirty && errorModel.explanation)} timeout={validationAnimationDuration}>
                                <AlternativeHint message={errorModel.explanation} />
                            </Collapse>
                        </Group>
                    </div>
                ) : null}

                {fieldsVisible.includes("confirmation") ? (
                    <div id="confirmation-section">
                        <Label htmlFor="confirmation" style={{ marginBottom: "8px" }}>Bekræft handling ved at skrive havemandens fulde navn</Label>
                        <Group error={Boolean(viewModel.confirmationDirty && errorModel.confirmation)}>
                            <TextArea id="confirmation" value={viewModel.confirmation} onChange={changeConfirmation} onBlur={markConfirmationDirty} placeholder={viewModel.gardenerName} />

                            <Collapse in={Boolean(viewModel.confirmationDirty && errorModel.confirmation)} timeout={validationAnimationDuration}>
                                <AlternativeHint message={errorModel.confirmation} />
                            </Collapse>
                        </Group>
                    </div>
                ) : null}

            </DialogContent>

            <DialogActions>
                <NeutralAction onClick={onCancel}>Annuller</NeutralAction>
                <PositiveAction onClick={confirm}>Ja, ophør samarbejde</PositiveAction>
            </DialogActions>

        </Dialog>
    );
};

export const useTerminateGardenerDialog = (onConfirm: TerminateGardenerDialogProps["onConfirm"], initialViewModel: TerminateGardenerDialogViewModel | undefined) => {
    const { openDialog, closeDialog } = useDialog();

    const hasConfirmed = useRef(false);
    const confirm: TerminateGardenerDialogProps["onConfirm"] = useCallback(data => {
        if ( hasConfirmed.current ) return;
        hasConfirmed.current = true;

        closeDialog();

        onConfirm(data);
    }, [closeDialog, onConfirm, hasConfirmed]);

    return useCallback(() => {
        if ( !initialViewModel ) return;

        hasConfirmed.current = false;

        openDialog(<TerminateGardenerDialog initialViewModel={initialViewModel} onConfirm={confirm} onCancel={closeDialog} />);
    }, [openDialog, closeDialog, confirm, initialViewModel, hasConfirmed]);
};
