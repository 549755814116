import { useMemo } from "react";

import moment from "moment";

import { TitleHeader } from "../../../components/TitleHeader";
import { TabBarItem } from "../../../components/tabs/TabBarItem";
import { TabBar } from "../../../components/tabs/TabBar";
import { CustomersTab } from "./customer/CustomersTab";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { GardenersTab } from "./gardener/GardenersTab";
import { useAdhocCustomerTasks, useAdhocGardenerTasks } from "../appointment/TaskProvider";

export const AdhocFollowUpOverviewPage = () => {
    const { tab } = useParams<{ tab: string }>();

    const tabIndex = useMemo(() => {
        switch ( tab ) {
            case "customers": return 0;
            case "gardeners": return 1;

            default: return 0;
        }
    }, [tab]);

    const now = moment().toISOString(true);

    const customerAdhocTasks = useAdhocCustomerTasks();
    const currentCustomerAdhocTasks = useMemo(() => customerAdhocTasks.filter(task => task.performAfterDate <= now), [customerAdhocTasks, now]);

    const gardenerAdhocTasks = useAdhocGardenerTasks();
    const currentGardenerAdhocTasks = useMemo(() => gardenerAdhocTasks.filter(task => task.performAfterDate <= now), [gardenerAdhocTasks, now]);

    return (
        <>
            <TitleHeader>Diverse</TitleHeader>

            <TabBar index={tabIndex}>

                <TabBarItem href="/workflow/adhoc/customers">
                    {currentCustomerAdhocTasks.length > 0 ? `Kunder (${currentCustomerAdhocTasks.length})` : "Kunder"}
                </TabBarItem>

                <TabBarItem href="/workflow/adhoc/gardeners">
                    {currentGardenerAdhocTasks.length > 0 ? `Havemænd (${currentGardenerAdhocTasks.length})` : "Havemænd"}
                </TabBarItem>

            </TabBar>

            <Switch>

                <Route exact path="/workflow/adhoc/customers">
                    <CustomersTab />
                </Route>

                <Route exact path="/workflow/adhoc/gardeners">
                    <GardenersTab />
                </Route>

                <Redirect to="/workflow/adhoc/customers" />

            </Switch>
        </>
    );
};
