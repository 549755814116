import { useMemo } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";

import { TitleHeader } from "../components/TitleHeader";
import { TabBarItem } from "../components/tabs/TabBarItem";
import { TabBar } from "../components/tabs/TabBar";
import { DashboardPageOverviewTab } from "./DashboardPageOverviewTab";
import { DashboardPageDeadZonesTab } from "./DashboardPageDeadZonesTab";
import { DashboardPageGardenersTab } from "./DashboardPageGardenersTab";
import { DashboardPageVisitsTab } from "./DashboardPageVisitsTab";

export const DashboardPage = () => {
    const { tab } = useParams<{ tab: string }>();

    const tabIndex = useMemo(() => {
        switch ( tab ) {
            case "overview": return 0;
            case "dead-zones": return 1;
            case "gardeners": return 2;
            case "visits": return 3;

            default: return 0;
        }
    }, [tab]);

    return (
        <>
            <TitleHeader>Dashboard</TitleHeader>

            <TabBar index={tabIndex}>
                <TabBarItem href="/dashboard/overview">Overblik</TabBarItem>
                <TabBarItem href="/dashboard/dead-zones">Dead zones</TabBarItem>
                <TabBarItem href="/dashboard/gardeners">Havemænd</TabBarItem>
                <TabBarItem href="/dashboard/visits">Besøg</TabBarItem>
            </TabBar>

            <Switch>

                <Route exact path="/dashboard/overview">
                    <DashboardPageOverviewTab />
                </Route>

                <Route exact path="/dashboard/dead-zones">
                    <DashboardPageDeadZonesTab />
                </Route>

                <Route exact path="/dashboard/gardeners">
                    <DashboardPageGardenersTab />
                </Route>

                <Route exact path="/dashboard/visits">
                    <DashboardPageVisitsTab />
                </Route>

                <Redirect to="/dashboard/overview" />

            </Switch>
        </>
    );
};
