import { Comment } from "../components/comment/Comment";
import { CommentBody } from "../components/comment/CommentBody";
import { GardenerPausedCommentDocument } from "./comment.model";

interface GardenerPausedCommentProps {
    comment: GardenerPausedCommentDocument;
}

export const GardenerPausedComment = (props: GardenerPausedCommentProps) => {
    const { comment } = props;

    const message = (() => {
        switch ( comment.data.reason ) {
            case "manual-gardener": return "Havemanden har sat sig selv på pause.";
            case "picky-gardener": return "Havemanden er automatisk blevet sat på pause efter at have sagt nej tak til for mange opgaver i træk.";
        }
    })();

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Havemand er på pause">
            <CommentBody>
                {message}<br />
                <br />
                Havemanden kan gøre sig selv aktiv igen.
            </CommentBody>
        </Comment>
    );
};
