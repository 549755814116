import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: "12px",
        borderBottom: "1px solid #8181814D",
        marginBottom: "12px",

        "&:last-child": {
            paddingBottom: 0,
            borderBottom: "none",
            marginBottom: 0,
        },
    },
    name: {
        fontSize: "16px",
        color: "#4A4A4A",
        margin: 0,
    },
    regular: {
        fontWeight: 400,
    },
    medium: {
        fontWeight: 500,
    },
    value: {
        flexShrink: 0,
        fontSize: "16px",
        fontWeight: 500,

        borderRadius: "4px",
        padding: "4px 16px",
        margin: 0,
    },
    blue: {
        backgroundColor: "#1976D226",
        color: "#1976D2",
    },
    green: {
        backgroundColor: "#F0F6E7",
        color: "#2FA52B",
    },
    yellow: {
        backgroundColor: "#FDE0AF80",
        color: "#E37F09",
    },
    red: {
        backgroundColor: "#F443361C",
        color: "#F44336",
    },
});

interface MetricListItemProps {
    name: string;
    value: string | number;
    color: "blue" | "green" | "orange" | "red";
    bold?: boolean;
}

export const MetricListItem = (props: MetricListItemProps) => {
    const classes = useStyles();

    const { name, value, color, bold } = props;

    const colorClass = (() => {
        switch ( color ) {
            case "blue": return classes.blue;
            case "green": return classes.green;
            case "orange": return classes.yellow;
            case "red": return classes.red;
        }
    })();

    const fontWeight = bold ? classes.medium : classes.regular;

    return (
        <div className={classes.root}>
            <h5 className={`${classes.name} ${fontWeight}`}>{name}</h5>
            <p className={`${classes.value} ${colorClass}`}>{value}</p>
        </div>
    );
};
