import { useCallback, useEffect } from "react";

import { PositiveAction } from "../../../../../components/card/PositiveAction";
import { UpdateInvoiceDialogFormContext, updateInvoiceDialogFormHasChanged, scrollToFirstError, useUpdateInvoiceDialogForm, validateUpdateInvoiceDialogForm } from "./UpdateInvoiceDialog";
import { UpdateInvoiceData } from "./update-invoice.hook";

interface SubmitButtonProps {
    data: Omit<UpdateInvoiceData, "reason">;
    onSubmit: (form: UpdateInvoiceDialogFormContext, data: Omit<UpdateInvoiceData, "reason">) => void;
}

export const SubmitButton = (props: SubmitButtonProps) => {
    const { data, onSubmit } = props;

    const { form, setForm } = useUpdateInvoiceDialogForm();    

    const hasChanged = updateInvoiceDialogFormHasChanged(form);

    const { validationAnimationDuration } = form;
    useEffect(() => {
        if ( validationAnimationDuration === 200 ) return;

        setForm((previousState): UpdateInvoiceDialogFormContext => ({
            ...previousState,
            validationAnimationDuration: 200,
        }))
    }, [setForm, validationAnimationDuration]);

    const skipValidationAnimation = useCallback(() => {
        setForm((previousState): UpdateInvoiceDialogFormContext => ({
            ...previousState,
            validationAnimationDuration: 0,
        }));
    }, [setForm]);

    const submit = useCallback(() => {
        const anyError = validateUpdateInvoiceDialogForm(form);
        if ( anyError ) {
            skipValidationAnimation();
            setForm((previousState): UpdateInvoiceDialogFormContext => ({
                ...previousState,
                reasonInput: {
                    ...previousState.reasonInput,
                    dirty: true,
                },
            }));
            return scrollToFirstError(form);
        }

        onSubmit(form, data);
    }, [setForm, skipValidationAnimation, onSubmit, form, data]);

    return (
        <PositiveAction onClick={submit} disabled={!hasChanged}>Ja, udsted ny faktura</PositiveAction>
    );
};
