import { useCallback, useMemo, useRef } from "react";

import { Collapse } from "@material-ui/core";

import { AddressField, AddressFieldAddress, AlternativeHint, Group, Label, useAddressPreloadingHack } from "../../../form";
import { Validation, validateAddressValidation, validateMandatoryValidation } from "../../../validation/validation";
import { useForm } from "../../framework/react/FormProvider";
import { FormItemSupport } from "../../framework/core/form-item.support";

export interface AddressPickerFormItem {
    id: string;
    type: "address-picker";
    label: string | null;
    searchTerm: string;
    value: AddressFieldAddress | null;
    dirty: boolean;
    validation: "optional" | "mandatory";
}

interface AddressPickerFormItemComponentProps {
    item: AddressPickerFormItem;
}

export const AddressPickerFormItemComponent = (props: AddressPickerFormItemComponentProps) => {
    const { controller, validationAnimationDuration, updateItem } = useForm();
    const { item } = props;

    const updateValue = useCallback((data: { searchTerm: string, address: AddressFieldAddress | undefined }) => {
        updateItem(item.id, (state: AddressPickerFormItem) => ({
            ...state,
            searchTerm: data.searchTerm,
            value: data.address ?? null,
        }));
    }, [updateItem, item.id]);

    const markDirty = useCallback(() => {
        updateItem(item.id, state => controller.markDirty(state));
    }, [controller, updateItem, item.id]);

    const validation = useMemo(() => validate(item), [item]);

    const initialSearchTerm = useRef(item.searchTerm).current;
    const onExactAddress = useCallback((address: AddressFieldAddress) => {
        if ( item.searchTerm !== initialSearchTerm ) return;

        updateValue({
            searchTerm: initialSearchTerm,
            address,
        });
    }, [updateValue, initialSearchTerm, item]);

    useAddressPreloadingHack(item.searchTerm, item.value ?? undefined, onExactAddress, markDirty);

    return (
        <div id={`${item.id}-section`}>
            {item.label ? <Label htmlFor={item.id} style={{ marginBottom: "8px" }}>{item.label}</Label> : null}
            <Group error={Boolean(item.dirty && validation?.severity === "error")}>

                <AddressField id={item.id} searchTerm={item.searchTerm} value={item.value ?? undefined} onChange={updateValue} onBlur={markDirty} />

                <Collapse in={Boolean(item.dirty && validation)} timeout={validationAnimationDuration}>
                    <AlternativeHint severity={validation?.severity} message={validation?.message} />
                </Collapse>
            </Group>
        </div>
    );
};

export class AddressPickerFormItemSupport implements FormItemSupport<AddressPickerFormItem> {
    supportedType = "address-picker" as const;
    updateChildren = undefined;

    markDirty(item: AddressPickerFormItem): AddressPickerFormItem {
        return { ...item, dirty: true };
    }

    getAllChildren() {
        return [];
    }

    getActiveChildren() {
        return [];
    }

    blocksParent(item: AddressPickerFormItem): boolean {
        return validate(item)?.severity === "error";
    }
}

const validate = (item: AddressPickerFormItem): Validation => {
    const validation = item.validation ?? "mandatory";

    const validators: Validation[] = (() => {
        switch ( validation ) {
            case "optional": return item.searchTerm ? [validateAddressValidation(item.value ?? undefined)] : [];
            case "mandatory": return [validateMandatoryValidation(item.searchTerm), validateAddressValidation(item.value ?? undefined)];
        }
    })();

    const error = validators.find(x => x?.severity === "error");
    if ( error ) return error;

    const warning = validators.find(x => x?.severity === "warning");
    if ( warning ) return warning;

    return undefined;
}
