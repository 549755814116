import { useCallback } from "react";

import firebase from "firebase/compat/app";

import { useBlockingProgress } from "../../components/blocking-progress";
import { useSnackbar } from "../../components/snackbar";
import { ChangeTimeDialogProps } from "./ChangeTimeDialog";

export interface ChangeTimeData {
    taskId: string;
    dueDate: string;
    reason: string;
}

const changeTimeFunction: (data: ChangeTimeData) => Promise<firebase.functions.HttpsCallableResult>
    = firebase.app().functions("europe-west3").httpsCallable("Task-changeTime2");

export const useChangeTimeAction = (taskId: string, callback?: () => void) => {
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    const onChangeTime: ChangeTimeDialogProps["onConfirm"] = useCallback(({ dueDate, reason }) => {
        block();
        changeTimeFunction({ taskId, dueDate, reason })
            .then(() => {
                openSnackbar("success", "Tidspunkt for opgaven er ændret");
                unblock();
                if ( callback ) callback();
            })
            .catch(() => {
                openSnackbar("error", "Der skete en fejl. Tidspunkt for opgaven er ikke blevet ændret.");
                unblock();
            });

    }, [block, unblock, openSnackbar, taskId, callback]);

    return onChangeTime;
};
