import { MouseEvent, useCallback } from "react";

import { Box, IconButton, makeStyles } from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";

const useStyles = makeStyles({
    root: {
        flexShrink: 0,
        marginLeft: "16px",
    },
});

interface InvoiceTablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: MouseEvent<HTMLButtonElement>, page: number) => void;
}

export const InvoiceTablePaginationActions = (props: InvoiceTablePaginationActionsProps & TablePaginationActionsProps) => {
    const classes = useStyles();

    const { count, page, rowsPerPage, onChangePage } = props;
  
    const goToFirstPage = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, 0);
    }, [onChangePage]);
  
    const goToPreviousPage = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page - 1);
    }, [onChangePage, page]);
  
    const goToNextPage = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page + 1);
    }, [onChangePage, page]);
  
    const goToLastPage = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }, [onChangePage, rowsPerPage, count]);
  
    return (
        <Box className={classes.root}>

            <IconButton disabled={page === 0} onClick={goToFirstPage}>
                <FirstPageIcon />
            </IconButton>

            <IconButton disabled={page === 0} onClick={goToPreviousPage}>
                <KeyboardArrowLeft />
            </IconButton>

            <IconButton disabled={page >= Math.ceil(count / rowsPerPage) - 1} onClick={goToNextPage}>
                <KeyboardArrowRight />
            </IconButton>

            <IconButton disabled={page >= Math.ceil(count / rowsPerPage) - 1} onClick={goToLastPage}>
                <LastPageIcon />
            </IconButton>

        </Box>
    );
};
