import { useMemo } from "react";

import { PageFormItem, GroupFormItem, InfoFormItem, HorizontalButtonsSingleChoiceFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { OnboardingTaskDocument } from "../../task/task.model";
import { NoticeFormItem } from "../../components/form-item/client/form-item/NoticeFormItemComponent";

export interface CheckApplicationFormIds {
    SingleChoiceSeriousApplication: string;
    SingleChoiceApplicationInDanish: string;
    SingleChoiceHasExperience: string;
}

export type CheckApplicationForm<NextPage extends FormItem> = CheckApplicationPage<NextPage>;

type CheckApplicationPage<NextPage extends FormItem> = PageFormItem<
    GroupFormItem<[
        InfoFormItem,
        NoticeFormItem,
        HorizontalButtonsSingleChoiceFormItem<[
            ConfirmationPage,
            HorizontalButtonsSingleChoiceFormItem<[
                ConfirmationPage,
                GroupFormItem<[
                    NoticeFormItem,
                    HorizontalButtonsSingleChoiceFormItem<[
                        ConfirmationPage,
                        NextPage,
                    ]>,
                ]>,
            ]>,
        ]>,
    ]>
>;

type ConfirmationPage = PageFormItem<InfoFormItem>

export const useCheckApplicationForm = <T extends FormItem>(task: OnboardingTaskDocument, nextPage: T, formIds: CheckApplicationFormIds): CheckApplicationForm<T> => {
    return useMemo(() => {

        const unseriousApplicationConfirmationPage: ConfirmationPage = {
            type: "page",
            title: "Afvis ansøgning",
            item: {
                type: "info",
                render: "default",
                text: `
                    Ansøgningen bliver afvist.<br />
                    <br />
                    Ansøgeren informeres om, at ansøgningen blev afvist.
                `,
            },
        };

        const applicationNotInDanishConfirmationPage: ConfirmationPage = {
            type: "page",
            title: "Afvis ansøgning",
            item: {
                type: "info",
                render: "default",
                text: `
                    Ansøgningen bliver afvist.<br />
                    <br />
                    Ansøgeren informeres om, at ansøgningen blev afvist fordi den ikke var på dansk.
                `,
            },
        };

        const unclearIfEnoughExperienceConfirmationPage: ConfirmationPage = {
            type: "page",
            title: "Afvis ansøgning",
            item: {
                type: "info",
                render: "default",
                text: `
                    Ansøgningen bliver afvist.<br />
                    <br />
                    Ansøgeren informeres om, at ansøgningen blev afvist fordi det ikke var tydeligt, om de havde tilstrækkelig erfaring.
                `,
            },
        };

        const page: CheckApplicationPage<T> = {
            type: "page",
            title: "Tjek ansøgning",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: "Afgør om du vil gå videre med ansøgningen.",
                    },
                    {
                        type: "notice",
                        render: "info",
                        html: `
                            <strong>Erfaring med havearbejde</strong><br />
                            <br />
                            ${task.application.experience.split("\n").join("<br />")}
                        `,
                    },
                    {
                        id: formIds.SingleChoiceSeriousApplication,
                        type: "single-choice",
                        mode: "switch page",
                        switchPageIndexes: [0],
                        render: "horizontal-buttons",
                        questionText: "Virker ansøgningen seriøs nok?",
                        answerTexts: ["Nej", "Ja"],
                        answerItems: [
                            unseriousApplicationConfirmationPage,
                            {
                                id: formIds.SingleChoiceApplicationInDanish,
                                type: "single-choice",
                                mode: "switch page",
                                switchPageIndexes: [0],
                                render: "horizontal-buttons",
                                questionText: "Har ansøgeren skrevet på dansk?",
                                answerTexts: ["Nej", "Ja"],
                                answerItems: [
                                    applicationNotInDanishConfirmationPage,
                                    {
                                        type: "group",
                                        render: "all at once",
                                        items: [
                                            {
                                                type: "notice",
                                                render: "warning",
                                                html: `
                                                    Det er tilstrækkeligt, hvis man har erfaring:
                                                    <ul>
                                                        <li>med at passe egen eller andres have</li>
                                                        <li>fra arbejde eller uddannelse</li>
                                                    </ul>
                                                    <br />
                                                    Det er ikke tilstrækkeligt, hvis man kun har erfaring med at hjælpe familie, uden selv at stå for deres haver.
                                                `,
                                            },
                                            {
                                                id: formIds.SingleChoiceHasExperience,
                                                type: "single-choice",
                                                mode: "switch page",
                                                render: "horizontal-buttons",
                                                questionText: "Har ansøgeren tilstrækkelig erfaring med havearbejde?",
                                                answerTexts: ["Jeg tror det ikke", "Ja"],
                                                answerItems: [
                                                    unclearIfEnoughExperienceConfirmationPage,
                                                    nextPage,
                                                ],
                                                selectedChoiceIndex: null,
                                                dirty: false,
                                            }
                                        ]
                                    },
                                ],
                                selectedChoiceIndex: null,
                                dirty: false,
                            },
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                    }
                ],
            },
        };
        
        return page;
    }, [task, nextPage, formIds]);
}
