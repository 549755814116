import { ButtonProps, makeStyles } from "@material-ui/core";

import { ButtonBase } from "./ButtonBase";

const useStyles = makeStyles({
    root: {
        backgroundColor: "#FFFFFF",
        color: "#297A1D",
        border: "1px solid rgba(41, 122, 29, 0.5)",

        "&:hover": {
            backgroundColor: "rgba(41, 122, 29, 0.04)",
            border: "1px solid #297A1D",
        },
    },
});

export const LightButton = (props: ButtonProps) => {
    const classes = useStyles();

    const { className, ...otherProps } = props;

    return (
        <ButtonBase
            className={className ? `${classes.root} ${className}` : classes.root}
            variant="outlined"
            {...otherProps}
        />
    );
};
