import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core";

import { Card } from "../../components/card/Card";
import { LegacyCardContent } from "../../components/card/CardContent";
import { CustomerDocument } from "../../customer/customer.model";
import { GardenerDocument } from "../../gardener/gardener.model";
import { DashedBorderSeparator } from "../../finance/billing/invoices/DashedBorderSeparator";
import { CardHeader } from "../../components/card/CardHeader";
import { CardStatus } from "../../components/card/CardStatus";
import { formatDate, formatServiceDuration, formatServiceTools } from "../../finance/billing/invoices/invoice-detail/utility";
import { PriceSummarySection } from "../../finance/billing/invoices/PriceSummarySection";
import { CreditNoteDocument } from "../../finance/billing/invoices/credit-note.model";

const useStyles = makeStyles(theme => ({
    group: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },

        "& > span": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",

            "& > a": {
                fontSize: "16px",
                fontWeight: 500,
                color: "#297A1D",
                textDecoration: "none",
        
                "&:hover": {
                    color: "#1C5B13",
                    textDecoration: "underline",
                },
            },
        },
    },
    multiline: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        lineHeight: "125%",
        whiteSpace: "pre-wrap",
    },
    topGrid: {
        display: "grid",
        gridTemplateAreas: `
            "gardener invoice-date"
            "customer invoice-due-date"
        `,

        "& > *:nth-child(1)": {
            gridArea: "gardener",
        },
        "& > *:nth-child(2)": {
            gridArea: "customer",
            marginBottom: 0,
        },
        "& > *:nth-child(3)": {
            gridArea: "invoice-date",
        },
        "& > *:nth-child(4)": {
            gridArea: "invoice-due-date",
        },

        "& > *:nth-child(1), & > *:nth-child(2)": {
            marginRight: "32px",
        },
        "& > *:nth-child(3), & > *:nth-child(4)": {
            textAlign: "right",
        },

        [theme.breakpoints.down(500)]: {
            gridTemplateAreas: `
                "gardener"
                "customer"
                "invoice-date"
                "invoice-due-date"
            `,

            "& > *:nth-child(1), & > *:nth-child(2)": {
                marginRight: 0,
                marginBottom: "24px",
            },
            "& > *:nth-child(3), & > *:nth-child(4)": {
                textAlign: "left",
            }
        },
    },
    service: {
        marginBottom: "48px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            display: "block",
            fontSize: "16px",
            fontWeight: 600,
            color: "#4A4A4A",
            marginTop: 0,
            marginBottom: "24px",
        },
    },
}));

interface CreditNoteDetailCardProps {
    creditNote: CreditNoteDocument;
    customer: CustomerDocument | null;
    gardener: GardenerDocument | null;
    button?: JSX.Element;
}

export const CreditNoteDetailCard = (props: CreditNoteDetailCardProps) => {
    const classes = useStyles();

    const { creditNote, customer, gardener, button } = props;

    return (
        <Card>

            <CardStatus status="Kreditnota" color="default" />

            <CardHeader button={button}>
                Kreditnota #{creditNote.creditNoteNumber}
            </CardHeader>

            <LegacyCardContent>

                <div className={classes.topGrid}>

                    <div className={classes.group}>
                        <label>Havemand</label>
                        <span>
                            {gardener ? (
                                <Link to={`/gardeners/context/${creditNote.gardener.id}`}>{gardener.name}</Link>
                            ) : (
                                <Link to={`/gardeners/context/${creditNote.gardener.id}`}>{creditNote.gardener.type === "person" ? creditNote.gardener.name : creditNote.gardener.contactPerson}</Link>
                            )}
                        </span>
                    </div>

                    <div className={classes.group}>
                        <label>Kunde</label>
                        <span>
                            {customer ? (
                                <Link to={`/customers/context/${creditNote.customer.id}`}>{customer.name}</Link>
                            ) : (
                                <Link to={`/customers/context/${creditNote.customer.id}`}>{creditNote.customer.type === "person" ? creditNote.customer.name : creditNote.customer.companyName}</Link>
                            )}
                        </span>
                    </div>

                    <div className={classes.group}>
                        <label>Dato</label>
                        <span>{formatDate(creditNote.creditNoteDate)}</span>
                    </div>

                </div>

                <DashedBorderSeparator />

                <div>
                    {creditNote.services.map((service, index) => (
                        <div key={index} className={classes.service}>
                            <label>Opgave {index + 1}</label>

                            <div className={classes.group}>
                                <label>Beskrivelse</label>
                                <span className={classes.multiline}>{service.description}</span>
                            </div>

                            <div className={classes.group}>
                                <label>Varighed</label>
                                <span>{formatServiceDuration(service.hours)}</span>
                            </div>

                            <div className={classes.group}>
                                <label>Redskaber</label>
                                <span>{formatServiceTools(service.tools)}</span>
                            </div>
                        </div>
                    ))}
                </div>
                
                <DashedBorderSeparator />

                <PriceSummarySection invoice={creditNote} />

            </LegacyCardContent>
        </Card>
    );
};
