import { useEffect, useState } from "react";

import firebase from "firebase/compat/app";

import { DineroContactDocument } from "./dinero-contact.model";

export const useDineroContact = (customerId: string | null | undefined) => {
    const [state, setState] = useState<DineroContactDocument | null>();

    useEffect(() => {
        if ( customerId === null ) return setState(null);
        if ( customerId === undefined ) return setState(undefined);

        setState(undefined);

        return firebase.firestore()
            .collection("dineroContacts")
                .where("customerId", "==", customerId)
            .onSnapshot(snapshot => {
                if ( snapshot.empty ) return setState(null);

                const document = snapshot.docs[0];

                setState({ id: document.id, ...document.data() } as DineroContactDocument);
            });

    }, [customerId]);

    return state;
};
