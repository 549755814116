import { Message } from "../../../../Message";
import { Section } from "../../../../components/section";
import { PaymentCollectionTaskDocument } from "../../../../task/task.model";
import { PaymentCollectionDetailCard } from "./PaymentCollectionDetailCard";

interface PaymentCollectionTaskContextProps {
    task: PaymentCollectionTaskDocument;
}

export const PaymentCollectionTaskContext = (props: PaymentCollectionTaskContextProps) => {
    const { task } = props;

    return (
        <Section>
            {task.hasBeenPerformed ? <Message>Opgaven er allerede blevet udført</Message> : null}

            <PaymentCollectionDetailCard task={task} />
        </Section>
    );
};
