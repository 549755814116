import { useCallback, useMemo } from "react";

import { Container } from "@material-ui/core";
import moment from "moment";

import { ContentSection } from "../../../../layout/ContentSection";
import { SectionHeader } from "../../../../components/section/SectionHeader";
import { Section } from "../../../../components/section";
import { FollowUpList } from "../../FollowUpList";
import { AdhocCustomerTaskDocument } from "../../../../task/task.model";
import { useAdhocCustomerTasks } from "../../appointment/TaskProvider";

export const CustomersTab = () => {
    const adhocTasks = useAdhocCustomerTasks();

    const now = moment().toISOString(true);

    const currentTasks = useMemo(() => {
        return adhocTasks
            .filter(task => task.performAfterDate <= now)
            .sort((a, b) => {
                if ( a.performAfterDate < b.performAfterDate ) return -1;
                if ( a.performAfterDate > b.performAfterDate ) return 1;
                return 0;
            });
    }, [adhocTasks, now]);

    const futureTasks = useMemo(() => {
        return adhocTasks
            .filter(task => task.performAfterDate > now)
            .sort((a, b) => {
                if ( a.performAfterDate < b.performAfterDate ) return -1;
                if ( a.performAfterDate > b.performAfterDate ) return 1;
                return 0;
            });
    }, [adhocTasks, now]);

    const formatPrimaryText = useCallback((task: AdhocCustomerTaskDocument) => {
        return task.customerName;
    }, []);

    return (
        <ContentSection>
            <Container maxWidth="sm" disableGutters>

                <Section>
                    <SectionHeader>Aktuelle opgaver</SectionHeader>

                    <FollowUpList tasks={currentTasks} placeholder="Der er ingen aktuelle opgaver" formatPrimaryText={formatPrimaryText} />
                </Section>

                <Section>
                    <SectionHeader>Fremtidige opgaver</SectionHeader>

                    <FollowUpList tasks={futureTasks} placeholder="Der er ingen fremtidige opgaver" formatPrimaryText={formatPrimaryText} />
                </Section>

            </Container>
        </ContentSection>
    );
};
