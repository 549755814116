import { Comment } from "../components/comment/Comment";
import { CommentBody } from "../components/comment/CommentBody";
import { OnboardingCommentDocument } from "../comment/comment.model";


interface OnboardingCommentProps {
    comment: OnboardingCommentDocument;
}

export const OnboardingComment = (props: OnboardingCommentProps) => {
    const { comment } = props;

    const gardener = comment.gardenerName;

    const title = (() => {
        switch ( comment.data.event ) {
            case "application checked": return "Ansøger udtaget til samtale";
            case "called applicant": return "Vi fik ikke fat i ansøger ift. onboarding";
        }
    })();

    const message = (() => {
        switch ( comment.data.event ) {
            case "application checked":
                return (
                    <>
                        Ansøgning fra {gardener} blev læst igennem og ansøgeren blev udtaget til samtale.
                    </>
                );
            case "called applicant":
                return (
                    <>
                        Vi har ringet til ansøgeren {gardener} {comment.data.count === 1 ? "én gang" : "to gange"}, men fik ikke fat i dem. Vi vil forsøge {comment.data.count === 1 ? "to gange" : "én gang"} til.
                    </>
                );
        }
    })();

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title={title} author={comment.administratorName}>
            <CommentBody>
                {message}
            </CommentBody>
        </Comment>
    );
};
