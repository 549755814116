import { useCallback } from "react";
import { useHistory } from "react-router-dom";

export type CustomerTab = "all" | "deleted";
export type GardenerTab = "all" | "terminated";
export type EvaluateGardenerTab = "gardeners";

export const useNavigation = () => {
    const history = useHistory();

    const goToCustomerList = useCallback((tab: CustomerTab) => {
        history.push(`/customers/${tab}`);
    }, [history]);

    const goToCustomer = useCallback((tab: CustomerTab, customerId: string) => {
        history.push(`/customers/${tab}/${customerId}`);
    }, [history]);

    const goToCustomerGardeningTask = useCallback((tab: CustomerTab, customerId: string, gardeningTaskId: string) => {
        history.push(`/customers/${tab}/${customerId}/gardening-tasks/${gardeningTaskId}`);
    }, [history]);

    const goToGardenerList = useCallback((tab: GardenerTab) => {
        history.push(`/gardeners/${tab}`);
    }, [history]);

    const goToGardener = useCallback((tab: GardenerTab, gardenerId: string) => {
        history.push(`/gardeners/${tab}/${gardenerId}`);
    }, [history]);

    const goToGardenerGardeningTask = useCallback((tab: GardenerTab, gardenerId: string, gardeningTaskId: string) => {
        history.push(`/gardeners/${tab}/${gardenerId}/gardening-tasks/${gardeningTaskId}`);
    }, [history]);

    const goToEvaluateGardenerList = useCallback((tab: EvaluateGardenerTab) => {
        history.push(`/evaluate-gardener/${tab}`);
    }, [history]);

    const goToEvaluateGardenerTask = useCallback((tab: EvaluateGardenerTab, taskId: string) => {
        history.push(`/evaluate-gardener/${tab}/${taskId}`);
    }, [history]);

    const goToEvaluateGardenerTaskGardeningTask = useCallback((tab: EvaluateGardenerTab, taskId: string, gardeningTaskId: string) => {
        history.push(`/evaluate-gardener/${tab}/${taskId}/gardening-tasks/${gardeningTaskId}`);
    }, [history]);

    return {
        goToCustomerList,
        goToCustomer,
        goToCustomerGardeningTask,
        goToGardenerList,
        goToGardener,
        goToGardenerGardeningTask,
        goToEvaluateGardenerList,
        goToEvaluateGardenerTask,
        goToEvaluateGardenerTaskGardeningTask,
    };
};
