import { Children, PropsWithChildren } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    rootDual: {
        display: "flex",

        "& > *": {
            flexBasis: "50%",
            borderRadius: 0,
        },
    },
    rootSingle: {
        display: "flex",

        "& > *": {
            flexBasis: "100%",
            borderRadius: 0,
        },
    },
});

export const CardActions = (props: PropsWithChildren<{}>) => {
    const classes = useStyles();

    const { children } = props;

    const rootClass = Children.count(children) === 2 ? classes.rootDual : classes.rootSingle;

    return (
        <div className={rootClass}>
            {children}
        </div>
    );
};
