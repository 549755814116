import { useCallback, useState } from "react";

import { Container, makeStyles } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import CreateComplaintIcon from "@material-ui/icons/PostAddOutlined";
import TransactionsIcon from "@material-ui/icons/MonetizationOnOutlined";
import Big from "big.js";

import { BackButton } from "../../components/BackButton";
import { Section } from "../../components/section";
import { TitleHeader } from "../../components/TitleHeader";
import { LightButton } from "../../components/button";
import { MenuButton } from "../../components/menu/MenuButton";
import { CustomerDocument } from "../../customer/customer.model";
import { InvoiceDetailCard } from "./InvoiceDetailCard";
import { InvoiceFormContext, InvoiceForm } from "../../finance/billing/invoices/invoice-detail/form/InvoiceForm";
import { UpdateInvoiceData } from "../../finance/billing/invoices/invoice-detail/update-invoice/update-invoice.hook";
import { DineroContactDocument } from "../../finance/billing/pending-approval/dinero-contact.model";
import { CustomerPriceDocument } from "../../finance/billing/registration/customer-price.model";
import { ContentSection } from "../../layout/ContentSection";
import { InvoiceDocument } from "../../finance/billing/invoices/invoice.model";
import { GardenerDocument } from "../../gardener/gardener.model";
import { GardenerPriceDocument } from "../../finance/billing/registration/gardener-price.model";
import { UpdateInvoiceDialogFormContext, useUpdateInvoiceDialog } from "../../finance/billing/invoices/invoice-detail/update-invoice/UpdateInvoiceDialog";
import { MenuItem } from "../../components/menu/MenuItem";
import { CancelInvoiceFormContext, useCancelInvoiceDialog } from "../../finance/billing/invoices/invoice-detail/cancel-invoice/CancelInvoiceDialog";
import { ComplaintDocument } from "../../complaint/complaint.model";
import { ComplaintDetailCard } from "../../complaint/ComplaintDetailCard";
import { CardAlert } from "../../components/card";
import { AdministratorActionData } from "../../workflow/administrator-action.hook";
import { CreateComplaintFormIds, useCreateComplaintDialog } from "../../complaint/CreateComplaintDialog";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { FormProps } from "../../components/form-item/framework/react/FormProvider";
import { SingleChoiceFormItem } from "../../components/form-item/client/components";
import { CheckInvoicePaymentFunction } from "./check-invoice-payment.function";
import { useTransactionsDialog } from "./TransactionsDialog";
import { useCannotEditInvoiceDialog } from "./CannotEditInvoiceDialog";
import { Task } from "../../finance/billing/registration.model";
import { ReissueInvoiceData } from "../../finance/billing/invoices/invoice-detail/reissue-invoice/reissue-invoice.function";
import { ResendInvoiceData } from "../../finance/billing/invoices/invoice-detail/reissue-invoice/resend-invoice.function";
import { ReissueOrResendInvoiceButton } from "../../finance/billing/invoices/invoice-detail/reissue-invoice/ReissueOrResendInvoiceButton";

const useStyles = makeStyles({
    button: {
        display: "block",
        margin: "0 auto",
    },
});

export interface InvoiceDetailPageProps {
    invoice: InvoiceDocument;
    complaint: ComplaintDocument | null;
    gardener: GardenerDocument | null;
    gardenerPrice: GardenerPriceDocument | null;
    customer: CustomerDocument | null;
    customerPrice: CustomerPriceDocument | null;
    contact: DineroContactDocument | null;
    updateInvoiceAction: (form: UpdateInvoiceDialogFormContext, data: Omit<UpdateInvoiceData, "reason">, callback: () => void) => void;
    cancelInvoiceAction: (form: CancelInvoiceFormContext) => void;
    administratorAction: (data: AdministratorActionData | string) => void;
    checkInvoicePaymentFunction: CheckInvoicePaymentFunction;
    reissueInvoiceFunction: (data: ReissueInvoiceData) => void;
    resendInvoiceFunction: (data: ResendInvoiceData) => void;
    isSuperAdministrator: boolean;
    openInvoiceInNewTab: () => void;
    goBack: () => void;
}

export const InvoiceDetailPage = (props: InvoiceDetailPageProps) => {
    const classes = useStyles();
    const { invoice, complaint, gardener, gardenerPrice, customer, customerPrice, contact, updateInvoiceAction, cancelInvoiceAction, administratorAction, checkInvoicePaymentFunction, reissueInvoiceFunction, resendInvoiceFunction, isSuperAdministrator, openInvoiceInNewTab, goBack } = props;

    const openCannotEditInvoiceDialog = useCannotEditInvoiceDialog();
    const [isEditing, setIsEditing] = useState(false);
    const enterEditMode = useCallback(() => {
        const hasDiscount = Boolean(invoice.discount || invoice.gardenerDiscount);
        if ( hasDiscount && !isSuperAdministrator ) return openCannotEditInvoiceDialog();

        setIsEditing(true);
    }, [openCannotEditInvoiceDialog, invoice, isSuperAdministrator]);
    const exitEditMode = useCallback(() => setIsEditing(false), []);

    const updateInvoice = useCallback((form: UpdateInvoiceDialogFormContext, data: Omit<UpdateInvoiceData, "reason">) => {
        updateInvoiceAction(form, data, () => {
            setIsEditing(false);
        });
    }, [updateInvoiceAction]);
    const openUpdateInvoiceDialog = useUpdateInvoiceDialog(updateInvoice);

    const onSubmit = useCallback(async (form: InvoiceFormContext) => {
        openUpdateInvoiceDialog({
            invoiceId: form.invoice.id,
            serviceDate: form.serviceDateInput.serviceDate,
            tasks: form.serviceInputs.map((serviceInput): Task => ({
                description: serviceInput.descriptionInput.description,
                durationInMinutes: Big(serviceInput.durationInput.hours!).times(60).round(0).toNumber(),
                tools: (() => {
                    switch ( serviceInput.toolsInput.tools! ) {
                        case "customer-tools": return "customers-tools";
                        case "gardener-tools": return "helper-tools";
                        case "gardener-large-tools": return "large-tools";
                    }
                })(),
            })),
            discount: form.discountInput.discount || null,
            gardenerDiscount: form.gardenerDiscountInput.gardenerDiscount || null,
        });
    }, [openUpdateInvoiceDialog]);

    const openCancelInvoiceDialog = useCancelInvoiceDialog(cancelInvoiceAction);

    const createComplaintAdapter = useCallback<FormProps<FormItem>["onSubmit"]>((controller, item) => {
        const multipleInvoices = (controller.getItem(CreateComplaintFormIds.SingleChoiceMultipleInvoices, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 1;
        if ( multipleInvoices ) return;

        administratorAction({
            action: "create complaint",
            invoiceId: invoice.id,
        });
    }, [administratorAction, invoice.id]);
    const openCreateComplaintDialog = useCreateComplaintDialog(createComplaintAdapter);

    const alert = complaint ? <CardAlert>Kunden har reklameret over denne faktura</CardAlert> : null;

    const backButton = <BackButton onClick={goBack} />;

    const openTransactionsDialog = useTransactionsDialog();
    const checkPaymentsAndOpenTransactionsDialog = useCallback(() => {
        checkInvoicePaymentFunction(invoice.id, result => {
            const paidAmount = result.transactions.reduce((total, x) => Big(total).plus(x.amount).round(2).toNumber(), 0);
            const balance = Big(paidAmount).minus(result.principal).minus(result.fees).round(2).toNumber();

            openTransactionsDialog({
                invoiceNumber: invoice.invoiceNumber,
                principal: result.principal,
                fees: result.fees,
                transactions: result.transactions,
                balance,
                externalDebtCollectionDate: result.externalDebtCollectionDate,
            });
        });
    }, [openTransactionsDialog, checkInvoicePaymentFunction, invoice]);

    const button = invoice.status === "did-invoice" ? (
        <MenuButton>
            {gardener && gardenerPrice && customer && customerPrice && contact ? <MenuItem icon={<EditIcon />} onClick={enterEditMode}>Ret faktura</MenuItem> : null}
            {customer ? <MenuItem icon={<CancelIcon />} onClick={openCancelInvoiceDialog}>Annuller faktura</MenuItem> : null}
            {customer && contact ? <ReissueOrResendInvoiceButton invoice={invoice} contact={contact} reissueInvoiceFunction={reissueInvoiceFunction} resendInvoiceFunction={resendInvoiceFunction} /> : null}
            {!complaint ? <MenuItem icon={<CreateComplaintIcon />} onClick={openCreateComplaintDialog}>Opret reklamation</MenuItem> : null}
            <MenuItem icon={<TransactionsIcon />} onClick={checkPaymentsAndOpenTransactionsDialog}>Transaktioner</MenuItem>
        </MenuButton>
    ) : (
        <MenuButton>
            <MenuItem icon={<TransactionsIcon />} onClick={checkPaymentsAndOpenTransactionsDialog}>Transaktioner</MenuItem>
        </MenuButton>
    );

    return (
        <>
            <TitleHeader button={backButton}>Faktura {invoice.invoiceNumber}</TitleHeader>
            
            <ContentSection>
                <Container maxWidth="sm" disableGutters>

                    <Section>
                        {gardener && gardenerPrice && customer && customerPrice && contact && invoice.status === "did-invoice" && isEditing ? (
                            <InvoiceForm invoice={invoice} gardener={gardener} gardenerPrice={gardenerPrice} customer={customer} customerPrice={customerPrice} contact={contact} isSuperAdministrator={isSuperAdministrator} onCancel={exitEditMode} onSubmit={onSubmit} />
                        ) : (
                            <InvoiceDetailCard alert={alert} invoice={invoice} customer={customer} gardener={gardener} button={button} />
                        )}

                        {complaint ? (
                            <ComplaintDetailCard complaint={complaint} administratorAction={administratorAction} />
                        ) : null}

                        <LightButton className={classes.button} onClick={openInvoiceInNewTab}>Se faktura</LightButton>
                    </Section>

                </Container>
            </ContentSection>
        </>
    );
};
