import { useCallback } from "react";

import firebase from "firebase/compat/app";
import moment from "moment";

import { useBlockingProgress } from "../../components/blocking-progress";
import { useDialog } from "../../components/dialog";
import { useSnackbar } from "../../components/snackbar";
import { GardenerDocument } from "../gardener.model";
import { CreateTaskDialog, CreateTaskDialogViewModel } from "../../task/task-dialog/CreateTaskDialog";

const createTaskFunction = firebase.app().functions("europe-west3").httpsCallable("Task-create2");

export const useCreateGardenerTask = (gardener: GardenerDocument | null | undefined) => {
    const { openDialog, closeDialog } = useDialog();
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();
    
    const gardenerId = gardener?.id;

    const createTask = useCallback((viewModel: CreateTaskDialogViewModel) => {
        closeDialog();

        const performAfterDate = moment(`${viewModel.performAfterDateDate} ${viewModel.performAfterDateTime}`, "YYYY-MM-DD HH:mm").toISOString(true);

        block();
        Promise.resolve().then(() => {
            return createTaskFunction({
                type: "adhoc-gardener",
                gardenerId,
                whatHasHappened: viewModel.whatHasHappened,
                whatShouldBeDone: viewModel.whatShouldBeDone,
                performAfterDate
            });
        })
            .then(() => {
                openSnackbar("success", "Opgaven er oprettet");
                unblock();
            })
            .catch(() => {
                openSnackbar("error", "Der skete en fejl. Opgaven er ikke blevet oprettet.");
                unblock();
            });

    }, [closeDialog, block, unblock, openSnackbar, gardenerId]);

    return useCallback(() => {
        if ( !gardener ) return;

        openDialog(<CreateTaskDialog onConfirm={createTask} onCancel={closeDialog} />);
    }, [openDialog, closeDialog, gardener, createTask]);
};
