import { useFormDialog } from "../../../components/form-item/client/components";
import { FormProps } from "../../../components/form-item/framework/react/FormProvider";
import { useCloseComplaintForm } from "../../shared/CloseComplaintForm";
import { ComplaintTaskDocument } from "../../../task/task.model";
import { ComplaintStillRelevantForm, useComplaintStillRelevantForm } from "../../shared/ComplaintStillRelevantForm";
import { CustomerReachedForm, useCustomerReachedForm } from "../../shared/CustomerReachedForm";
import { ComplaintDueToMisunderstandingForm, useComplaintDueToMisunderstandingForm } from "../../shared/ComplaintDueToMisunderstandingForm";
import { CustomerOpinionForm, useCustomerOpinionForm } from "../../shared/CustomerOpinionForm";
import { DineroContactDocument } from "../../../finance/billing/pending-approval/dinero-contact.model";

export enum ContactCustomerFormIds {
    TextAreaCloseComplaintDueToMisunderstandingReason = "TextAreaCloseComplaintDueToMisunderstandingReason",
    SingleChoiceHasMisunderstanding = "SingleChoiceHasMisunderstanding",
    SingleChoiceCustomerReached = "SingleChoiceCustomerReached",
    TextAreaCloseComplaintBecauseIrrelevantReason = "TextAreaCloseComplaintBecauseIrrelevantReason",
    SingleChoiceStillRelevant = "SingleChoiceStillRelevant",
    TextAreaCustomerComment = "TextAreaCustomerComment",
    SingleChoiceCustomerProposal = "SingleChoiceCustomerProposal",
    TextAreaCustomerProposal = "TextAreaCustomerProposal",
}

export type ContactCustomerForm =
    ComplaintStillRelevantForm<
        CustomerReachedForm<
            ComplaintDueToMisunderstandingForm<
                CustomerOpinionForm
            >
        >
    >

export const useContactCustomerDialog = (task: ComplaintTaskDocument, contact: DineroContactDocument, onConfirm: FormProps<ContactCustomerForm>["onSubmit"]) => {
    const closeComplaintDueToMisunderstandingReasonForm = useCloseComplaintForm(task.complaint, {
        TextAreaReason: ContactCustomerFormIds.TextAreaCloseComplaintDueToMisunderstandingReason,
    });
    const customerOpinionForm = useCustomerOpinionForm(task.complaint, {
        TextAreaCustomerComment: ContactCustomerFormIds.TextAreaCustomerComment,
        SingleChoiceCustomerProposal: ContactCustomerFormIds.SingleChoiceCustomerProposal,
        TextAreaCustomerProposal: ContactCustomerFormIds.TextAreaCustomerProposal,
    });
    const complaintDueToMisunderstandingForm = useComplaintDueToMisunderstandingForm(closeComplaintDueToMisunderstandingReasonForm, customerOpinionForm, {
        SingleChoiceHasMisunderstanding: ContactCustomerFormIds.SingleChoiceHasMisunderstanding,
    });

    const customerReachedForm = useCustomerReachedForm(task.complaint, contact, complaintDueToMisunderstandingForm, {
        SingleChoiceCustomerReached: ContactCustomerFormIds.SingleChoiceCustomerReached,
    });

    const closeComplaintBecauseIrrelevantForm = useCloseComplaintForm(task.complaint, {
        TextAreaReason: ContactCustomerFormIds.TextAreaCloseComplaintBecauseIrrelevantReason,
    });
    const complaintStillRelevantForm = useComplaintStillRelevantForm(closeComplaintBecauseIrrelevantForm, customerReachedForm, {
        SingleChoiceStillRelevant: ContactCustomerFormIds.SingleChoiceStillRelevant,
    });

    return useFormDialog(complaintStillRelevantForm, onConfirm);
};
