import { useEffect, useMemo, useRef, useState } from "react";

import firebase from "firebase/compat/app";
import moment from "moment";

import { LinearProgress } from "../components/LinearProgress";
import { ResponsiveGrid } from "../components/responsive-grid/ResponsiveGrid";
import { ResponsiveGridItem } from "../components/responsive-grid/ResponsiveGridItem";
import { Section } from "../components/section/Section";
import { SectionHeaderAlternative } from "../components/section/SectionHeaderAlternative";
import { useEventsByTypeInTimePeriod } from "../event/event.hook";
import { EventDocument, GardeningTaskAbortedEventDocument, GardeningTaskClosedEventDocument, MatchAcceptedEventDocument } from "../event/event.model";
import { MetricList } from "./metrics/MetricList";
import { MetricListItem } from "./metrics/MetricListItem";
import { ResponsiveGridBreak } from "../components/responsive-grid/ResponsiveGridBreak";

interface GetPercentageOfLostTasksFunctionResult {
    threeWeeksAgo: {
        label: string,
        rejectionPercentage: number,
        cancellationPercentage: number,
    },
    twoWeeksAgo: {
        label: string,
        rejectionPercentage: number,
        cancellationPercentage: number,
    },
    oneWeekAgo: {
        label: string,
        rejectionPercentage: number,
        cancellationPercentage: number,
    },
}

const getPercentageOfLostTasksFunction: () => Promise<firebase.functions.HttpsCallableResult>
    = firebase.app().functions("europe-west3").httpsCallable("Dashboard-getPercentageOfLostTasks");

export const LostCustomerMetricsSection = () => {
    const customerLostReasons = useCustomerLostReasons();
    const customerLostPercentages = useCustomerLostPercentages();

    if ( !customerLostReasons || !customerLostPercentages ) return <LinearProgress />;

    return (
        <Section>
            <SectionHeaderAlternative>tabte kunder</SectionHeaderAlternative>

            <ResponsiveGrid>

                <ResponsiveGridItem units={1}>
                    <MetricList title={customerLostPercentages.oneWeekAgo.label}>
                        <MetricListItem name="Afvisningsprocent" value={customerLostPercentages.oneWeekAgo.rejectionPercentage + "%"} color="blue" />
                        <MetricListItem name="Afbestillingsprocent" value={customerLostPercentages.oneWeekAgo.cancellationPercentage + "%"} color="blue" />
                    </MetricList>
                </ResponsiveGridItem>

                <ResponsiveGridItem units={1}>
                <MetricList title={customerLostPercentages.twoWeeksAgo.label}>
                        <MetricListItem name="Afvisningsprocent" value={customerLostPercentages.twoWeeksAgo.rejectionPercentage + "%"} color="blue" />
                        <MetricListItem name="Afbestillingsprocent" value={customerLostPercentages.twoWeeksAgo.cancellationPercentage + "%"} color="blue" />
                    </MetricList>
                </ResponsiveGridItem>

                <ResponsiveGridItem units={1}>
                    <MetricList title={customerLostPercentages.threeWeeksAgo.label}>
                        <MetricListItem name="Afvisningsprocent" value={customerLostPercentages.threeWeeksAgo.rejectionPercentage + "%"} color="blue" />
                        <MetricListItem name="Afbestillingsprocent" value={customerLostPercentages.threeWeeksAgo.cancellationPercentage + "%"} color="blue" />
                    </MetricList>
                </ResponsiveGridItem>

                <ResponsiveGridBreak />

                <ResponsiveGridItem units={1}>
                    <MetricList title="Afsætning af opgaver (seneste uge)">
                        <MetricListItem name="Kunden har trukket sin opgave tilbage" value={customerLostReasons.taskWasAborted.lastWeek} color="orange" />
                        <MetricListItem name="Vi kan ikke finde en havemand" value={customerLostReasons.taskWasRejected.lastWeek} color="red" />
                        <MetricListItem name="Havemand accepterede opgave" value={customerLostReasons.taskWasAccepted.lastWeek} color="green" />
                        <MetricListItem name="I alt" bold value={customerLostReasons.total.lastWeek} color="blue" />
                    </MetricList>
                </ResponsiveGridItem>

                <ResponsiveGridItem units={1}>
                    <MetricList title="Afsætning af opgaver (forrige uge)">
                        <MetricListItem name="Kunden har trukket sin opgave tilbage" value={customerLostReasons.taskWasAborted.previousWeek} color="orange" />
                        <MetricListItem name="Vi kan ikke finde en havemand" value={customerLostReasons.taskWasRejected.previousWeek} color="red" />
                        <MetricListItem name="Havemand accepterede opgave" value={customerLostReasons.taskWasAccepted.previousWeek} color="green" />
                        <MetricListItem name="I alt" bold value={customerLostReasons.total.previousWeek} color="blue" />
                    </MetricList>
                </ResponsiveGridItem>

            </ResponsiveGrid>

        </Section>
    );
};

function useCustomerLostReasons() {
    const fourteenDaysAgo = moment().subtract(14, "days").startOf("day").toISOString(true);
    const tomorrow = moment().add(1, "day").startOf("day").toISOString(true);

    const abortedEvents = useEventsByTypeInTimePeriod("gardening-task-aborted", fourteenDaysAgo, tomorrow) as GardeningTaskAbortedEventDocument[];
    const cannotFindGardenerEvents = useEventsByTypeInTimePeriod("gardening-task-closed", fourteenDaysAgo, tomorrow) as GardeningTaskClosedEventDocument[];
    const taskAcceptedEvents = useEventsByTypeInTimePeriod("match-accepted", fourteenDaysAgo, tomorrow) as MatchAcceptedEventDocument[];

    const taskOutcomes = useMemo(() => {
        if ( !cannotFindGardenerEvents || !abortedEvents || !taskAcceptedEvents ) return undefined;

        const abortedTasks = abortedEvents;
        const rejectedTasks = cannotFindGardenerEvents;
        const acceptedTasks = taskAcceptedEvents;

        return { abortedTasks, rejectedTasks, acceptedTasks };
    }, [cannotFindGardenerEvents, abortedEvents, taskAcceptedEvents]);

    const taskWasAborted = useWeeklyPartitionedEvents(taskOutcomes?.abortedTasks);
    const taskWasRejected = useWeeklyPartitionedEvents(taskOutcomes?.rejectedTasks);
    const taskWasAccepted = useWeeklyPartitionedEvents(taskOutcomes?.acceptedTasks);

    if ( !taskWasAborted || !taskWasRejected || !taskWasAccepted ) return undefined;

    const total = {
        previousWeek: taskWasAborted.previousWeek + taskWasRejected.previousWeek + taskWasAccepted.previousWeek,
        lastWeek: taskWasAborted.lastWeek + taskWasRejected.lastWeek + taskWasAccepted.lastWeek,
    }

    return { taskWasAborted, taskWasRejected, taskWasAccepted, total };
}

function useWeeklyPartitionedEvents(events: EventDocument[] | undefined) {
    const fourteenDaysAgo = moment().subtract(14, "days").startOf("day").toISOString(true);
    const sevenDaysAgo = moment().subtract(7, "days").startOf("day").toISOString(true);
    const today = moment().startOf("day").toISOString(true);

    return useMemo(() => {
        if ( !events ) return undefined;

        const previousWeek = events.filter(event => event.creationDate >= fourteenDaysAgo && event.creationDate < sevenDaysAgo).length;
        const lastWeek = events.filter(event => event.creationDate >= sevenDaysAgo && event.creationDate < today).length;

        return { previousWeek, lastWeek };
    }, [events, fourteenDaysAgo, sevenDaysAgo, today]);
}

function useCustomerLostPercentages() {
    const [state, setState] = useState<GetPercentageOfLostTasksFunctionResult>();

    const hasTerminated = useRef(false);
    useEffect(() => {
        hasTerminated.current = false;

        getPercentageOfLostTasksFunction().then(result => {
            if ( !hasTerminated.current ) setState(result.data);
        });

        return () => {
            hasTerminated.current = true;
        };
    }, [hasTerminated]);

    return state;
}
