import { useEffect, useRef, useState } from "react";

import firebase from "firebase/compat/app";

import { GardenerDocument } from "./gardener.model";

export const useTerminatedGardeners = () => {
    const [state, setState] = useState<GardenerDocument[]>();

    useEffect(() => {
        setState(undefined);

        return firebase.firestore()
            .collection("helpers")
                .where("status", "==", "terminated")
            .onSnapshot(collection => {
                const documents = collection.docs.map(document => ({ id: document.id, ...document.data() } as GardenerDocument));
                
                setState(documents);
            });
    }, []);

    return state;
};

export const useActiveGardeners = () => {
    const [state, setState] = useState<GardenerDocument[]>();

    useEffect(() => {
        setState(undefined);

        return firebase.firestore()
            .collection("helpers")
                .where("status", "==", "active")
                .where("protections", "==", [])
            .onSnapshot(collection => {
                const documents = collection.docs.map(document => ({ id: document.id, ...document.data() } as GardenerDocument));
                
                setState(documents);
            });
    }, []);

    return state;
};

export const useGardenersById = (gardenerIds: string[] | undefined) => {
    const [state, setState] = useState<GardenerDocument[]>();

    const hasTerminated = useRef(false);
    useEffect(() => {
        if ( gardenerIds === undefined ) return setState(undefined);

        hasTerminated.current = false;

        setState(undefined);

        Promise.all(gardenerIds.map(async gardenerId => {
            const document = await firebase.firestore().collection("helpers").doc(gardenerId).get();
            if ( !document.exists ) return null;

            return { id: document.id, ...document.data() } as GardenerDocument;
        })).then(optionalGardeners => {
            const gardeners = optionalGardeners.filter(Boolean) as GardenerDocument[];

            if ( !hasTerminated.current ) setState(gardeners);
        });

        return () => {
            hasTerminated.current = true;
        };
    }, [gardenerIds]);

    return state;
}

export const useActiveApplicants = () => {
    const [state, setState] = useState<GardenerDocument[]>();

    useEffect(() => {
        setState(undefined);

        return firebase.firestore()
            .collection("helpers")
                .where("status", "==", "pending")
                .where("protections", "==", [])
            .onSnapshot(collection => {
                const documents = collection.docs.map(document => ({ id: document.id, ...document.data() } as GardenerDocument));
                
                setState(documents);
            });
    }, []);

    return state;
};

export type UseGardener = typeof useGardener;

export const useGardener = (gardenerId: string | null | undefined) => {
    const [state, setState] = useState<GardenerDocument | null>();

    useEffect(() => {
        if ( gardenerId === null ) return setState(null);
        if ( gardenerId === undefined ) return setState(undefined);

        setState(undefined);

        return firebase.firestore().collection("helpers").doc(gardenerId).onSnapshot(document => {
            if ( !document.exists ) return setState(null);

            setState({ id: document.id, ...document.data() } as GardenerDocument);
        });
    }, [gardenerId]);

    return state;
};
