import { useCallback } from "react";
import { useHistory } from "react-router";

export const useGoToCustomerProfile = (tab: "all" | "deleted", customerId: string) => {
    const history = useHistory();

    return useCallback(() => {
        history.push(`/customers/${tab}/${customerId}`);
    }, [history, tab, customerId]);
};
