import { Link } from "react-router-dom";

import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { MatchCreatedCommentDocument } from "../comment.model";

interface MatchCreatedCommentProps {
    context: "customer" | "gardener";
    comment: MatchCreatedCommentDocument;
}

export const MatchCreatedComment = (props: MatchCreatedCommentProps) => {
    const { context, comment } = props;

    const gardener = (() => {
        switch ( context ) {
            case "customer": return <Link to={`/gardeners/context/${comment.gardenerId}`}>{comment.gardenerName}</Link>;
            case "gardener": return <>Havemanden</>;
        }
    })();

    const customer = (() => {
        switch ( context ) {
            case "customer": return <>kunden</>;
            case "gardener": return <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link>;
        }
    })();

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Specifik havemand tilknyttet" author={comment.administratorName}>
            <CommentBody>

                {gardener} er blevet tilknyttet {customer}.<br />
                <br />
                Årsag:<br />
                {comment.reason}

            </CommentBody>
        </Comment>
    );
};
