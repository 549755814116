import { Hit } from "@algolia/client-search";

import { useGoToGardenerProfile } from "../../go-to-gardener-profile.hook";
import { GardenerSearchResultCard } from "./GardenerSearchResultCard";
import { GardenerSearchRecord } from "./gardener-search.hook";

export const ListItem = (props: { gardener: Hit<GardenerSearchRecord> }) => {
    const { gardener } = props;

    const goToGardenerProfile = useGoToGardenerProfile("all", gardener.id);

    return <GardenerSearchResultCard gardener={gardener} goToProfile={goToGardenerProfile} />;
};
