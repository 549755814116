import { useCallback } from "react";

import firebase from "firebase/compat/app";

import { useBlockingProgress } from "../../components/blocking-progress";
import { useDialog } from "../../components/dialog";
import { useSnackbar } from "../../components/snackbar";
import { UnpauseGardenerDialog, UnpauseGardenerFormContext } from "./UnpauseGardenerDialog";

const unpauseGardenerFunction = firebase.app().functions("europe-west3").httpsCallable("ManualAdministratorProtection-deactivate2");

export const useUnpauseGardener = (gardenerId: string | null | undefined) => {
    const { openDialog, closeDialog } = useDialog();
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    const unpauseGardener = useCallback((form: UnpauseGardenerFormContext) => {
        closeDialog();

        block();
        unpauseGardenerFunction({ gardenerId, reason: form.reasonInput.reason })
            .then(() => {
                openSnackbar("success", "Havemanden er genaktiveret");
                unblock();
            })
            .catch(() => {
                openSnackbar("error", "Der skete en fejl. Havemanden er ikke blevet genaktiveret.");
                unblock();
            });

    }, [closeDialog, block, unblock, openSnackbar, gardenerId]);

    return useCallback(() => {
        openDialog(<UnpauseGardenerDialog onConfirm={unpauseGardener} />);
    }, [openDialog, unpauseGardener]);
};
