import { useAddCustomerComment } from "../../../comment/add-comment.hook";
import { useCustomerComments } from "../../../comment/comments.hook";
import { ComplaintTaskDocument } from "../../../task/task.model";
import { LinearProgress } from "../../../components/LinearProgress";
import { useAdministratorAction } from "../../../workflow/administrator-action.hook";
import { useInvoices } from "../../../finance/billing/invoices/invoices.hook";
import { ErrorPage } from "../../../ErrorPage";
import { ContactCustomerDetailPage } from "./ContactCustomerDetailPage";
import { useDineroContact } from "../../../finance/billing/pending-approval/dinero-contacts.hook";

interface IntegrateContactCustomerDetailPageProps {
    task: ComplaintTaskDocument;
    goBack: () => void;
}

export const IntegrateContactCustomerDetailPage = (props: IntegrateContactCustomerDetailPageProps) => {
    const { task, goBack } = props;

    const contact = useDineroContact(task.customerId);
    const invoices = useInvoices(task.complaint.invoiceIds);

    const administratorAction = useAdministratorAction(goBack);

    const comments = useCustomerComments(task?.customerId);
    const addComment = useAddCustomerComment(task?.customerId);

    if ( contact === null ) return <ErrorPage message="Kunne ikke finde kunden" />;
    if ( invoices === null ) return <ErrorPage message="Kunne ikke finde fakturaer" />
    if ( contact === undefined || invoices === undefined || comments === undefined ) return <LinearProgress />;

    return (
        <ContactCustomerDetailPage
            task={task}
            contact={contact}
            invoices={invoices}
            administratorAction={administratorAction}
            comments={comments}
            addComment={addComment}
            goBack={goBack}
        />
    );
};
