import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from "react";

import { FormGroup } from "@material-ui/core";

import { NeutralAction } from "../../components/card/NeutralAction";
import { Dialog, DialogActions, DialogContent, DialogHeader, useDialog } from "../../components/dialog";
import { Label } from "../../components/form/Label";
import { ReasonInputContext, newReasonInputContext, reasonInputHasChanged, validateReasonInput, ReasonInput } from "./ReasonInput";
import { SubmitButton } from "./SubmitButton";

const Context = createContext<{ form: PauseGardenerFormContext, setForm: Dispatch<SetStateAction<PauseGardenerFormContext>> }>(undefined as any);

interface PauseGardenerDialogProps {
    onConfirm: (form: PauseGardenerFormContext) => void;
}

export const PauseGardenerDialog = (props: PauseGardenerDialogProps) => {
    const { onConfirm } = props;
    const { closeDialog } = useDialog();

    const [form, setForm] = useState(newFormContext());
    const value = useMemo(() => ({ form, setForm }), [form, setForm]);

    return (
        <Context.Provider value={value}>
            <Dialog>
                <DialogHeader>Sæt havemanden på pause?</DialogHeader>

                <DialogContent>
                    <div>Havemanden vil ikke længere blive tilbudt opgaver.</div>

                    <FormGroup id="reason-section">
                        <Label htmlFor="reason" style={{ marginBottom: "8px" }}>Årsag til pause</Label>
                        <ReasonInput id="reason" />
                    </FormGroup>
                </DialogContent>

                <DialogActions>
                    <NeutralAction onClick={closeDialog}>Annuller</NeutralAction>
                    <SubmitButton onSubmit={onConfirm} />
                </DialogActions>

            </Dialog>
        </Context.Provider>
    );
};

export const usePauseGardenerForm = () => {
    return useContext(Context);
};

export interface PauseGardenerFormContext {
    validationAnimationDuration: number;
    reasonInput: ReasonInputContext;
}

export const newFormContext = (): PauseGardenerFormContext => {
    return {
        validationAnimationDuration: 200,
        reasonInput: newReasonInputContext(),
    };
};

export const pauseGardenerFormHasChanged = (form: PauseGardenerFormContext) => {
    return  reasonInputHasChanged(form);
};

export const validatePauseGardenerForm = (form: PauseGardenerFormContext) => {
    return validateReasonInput(form);
};

export const scrollToFirstError = (form: PauseGardenerFormContext) => {
    const sectionId = (() => {
        if ( validateReasonInput(form) ) return "reason-section"

        return undefined;
    })();

    if ( !sectionId ) return;

    const section = document.getElementById(sectionId);
    if ( !section ) return;

    const header = document.getElementsByTagName("header").length === 1 ? document.getElementsByTagName("header").item(0) : null;
    const headerOffset = header?.getBoundingClientRect().height ?? 0;

    const offsetPosition = window.scrollY + section.getBoundingClientRect().top - headerOffset;

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
}
