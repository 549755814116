import { useCallback, useEffect } from "react";

import { PositiveAction } from "../../components/card/PositiveAction";
import { scrollToFirstError, UnpauseGardenerFormContext, unpauseGardenerFormHasChanged, useUnpauseGardenerForm, validateUnpauseGardenerForm } from "./UnpauseGardenerDialog";

interface SubmitButtonProps {
    onSubmit: (form: UnpauseGardenerFormContext) => void;
}

export const SubmitButton = (props: SubmitButtonProps) => {
    const { onSubmit } = props;

    const { form, setForm } = useUnpauseGardenerForm();    

    const hasChanged = unpauseGardenerFormHasChanged(form);

    const { validationAnimationDuration } = form;
    useEffect(() => {
        if ( validationAnimationDuration === 200 ) return;

        setForm((previousState): UnpauseGardenerFormContext => ({
            ...previousState,
            validationAnimationDuration: 200,
        }))
    }, [setForm, validationAnimationDuration]);

    const skipValidationAnimation = useCallback(() => {
        setForm((previousState): UnpauseGardenerFormContext => ({
            ...previousState,
            validationAnimationDuration: 0,
        }));
    }, [setForm]);

    const submit = useCallback(() => {
        const anyError = validateUnpauseGardenerForm(form);
        if ( anyError ) {
            skipValidationAnimation();
            setForm((previousState): UnpauseGardenerFormContext => ({
                ...previousState,
                reasonInput: {
                    ...previousState.reasonInput,
                    dirty: true,
                },
            }));
            return scrollToFirstError(form);
        }

        onSubmit(form);
    }, [setForm, skipValidationAnimation, onSubmit, form]);

    return (
        <PositiveAction onClick={submit} disabled={!hasChanged}>Ja, genaktiver</PositiveAction>
    );
};
