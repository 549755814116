import { useParams } from "react-router";

import { Container } from "@material-ui/core";

import { ContentSection } from "../../../../layout/ContentSection";
import { TitleHeader } from "../../../../components/TitleHeader";
import { LinearProgress } from "../../../../components/LinearProgress";
import { BackButton } from "../../../../components/BackButton";
import { Section } from "../../../../components/section";
import { AdhocGardenerFollowUpDetailCard } from "./AdhocGardenerFollowUpDetailCard";
import { useGardenerComments } from "../../../../comment/comments.hook";
import { ActivitySection } from "../../../../comment/ActivitySection";
import { useAddGardenerComment } from "../../../../comment/add-comment.hook";
import { NotFoundPage } from "../../../../NotFoundPage";
import { AdhocGardenerTaskDocument } from "../../../../task/task.model";
import { useTask } from "../../../../task/tasks.hook";
import { ErrorPage } from "../../../../ErrorPage";
import { GardenerCommentList } from "../../../../comment/GardenerCommentList";
import { useGardener } from "../../../../gardener/gardeners.hook";

interface AdhocGardenerFollowUpDetailPageProps {
    goBack: () => void;
}

export const AdhocGardenerFollowUpDetailPage = (props: AdhocGardenerFollowUpDetailPageProps) => {
    const { taskId } = useParams<{ taskId: string }>();
    const { goBack } = props;

    const task = useTask(taskId) as AdhocGardenerTaskDocument | null | undefined;
    const gardener = useGardener(task && task.gardenerId);
    const comments = useGardenerComments(task?.gardenerId);
    const addComment = useAddGardenerComment(task?.gardenerId);

    if ( task === null ) return <NotFoundPage />;
    if ( gardener === null ) return <ErrorPage message="Havemanden kunne ikke findes" />;
    if ( task === undefined || gardener === undefined ) return <LinearProgress />;
    if ( task.type !== "adhoc-gardener" ) return <ErrorPage message="Opgavens type genkendes ikke" />;

    if ( comments === undefined ) return <LinearProgress />;

    const backButton = <BackButton onClick={props.goBack} />;

    return (
        <>
            <TitleHeader button={backButton}>Havemænd</TitleHeader>
            
            <ContentSection>
                <Container maxWidth="sm" disableGutters>

                    <Section>
                        <AdhocGardenerFollowUpDetailCard task={task} gardener={gardener} goBack={goBack} />
                    </Section>

                    <ActivitySection onSave={addComment}>
                        <GardenerCommentList comments={comments} />
                    </ActivitySection>

                </Container>
            </ContentSection>
        </>
    );
};
