import { useEffect, useMemo, useRef, useState } from "react";

import { SearchResponse } from "@algolia/client-search";
import { debounce } from "@material-ui/core";

import { useSearchClient } from "../../../../search-client.hook";
import { InvoiceSearchRecord } from "../invoice-search-record.model";

export const useInvoiceSearch = (searchTerm: string, page: number, pageSize: number) => {
    const terminated = useRef(true);
    const [state, setState] = useState<SearchResponse<InvoiceSearchRecord> | null>();
    
    const searchClient = useSearchClient();
    const performSearch = useMemo(() => {
        return debounce(async (query: string) => {
            if ( terminated.current ) return;
            if ( searchClient === undefined ) return setState(undefined);
            if ( searchClient === null ) return setState(null);

            const response = await searchClient.initIndex("invoices").search<InvoiceSearchRecord>(query, { page, hitsPerPage: pageSize })
            if ( terminated.current ) return;

            setState(response);
        }, 100);
    }, [searchClient, page, pageSize]);
    
    useEffect(() => {
        setState(undefined);
        
        terminated.current = false;
        performSearch(searchTerm);

        return () => {
            terminated.current = true;
        };
    }, [performSearch, searchTerm, terminated]);

    return state;
};
