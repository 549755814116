import { useInvoiceForm } from "./InvoiceForm";
import { CustomerDocument } from "../../../../../customer/customer.model";
import { TextField } from "../../../../../components/form";

export const CustomerInput = () => {
    const { form } = useInvoiceForm();
    const { customerInput } = form;

    return <TextField value={customerInput.customerName} disabled />;
};

export interface CustomerInputContext {
    customerName: string;
}

export const newCustomerInputContext = (customer: CustomerDocument): CustomerInputContext => ({
    customerName: customer.name,
});
