import Big from "big.js";

import { AdminConfigurationDocument } from "../application/configuration.model";
import { GardenerDocument } from "../gardener/gardener.model";
import { TaskDocument } from "../task/task.model";

export const sortByCustomerThenDueDate = <
    Task extends Extract<TaskDocument, { customerName: string }>
>(a: Task, b: Task): number => {
    if ( a.customerName < b.customerName ) return -1;
    if ( a.customerName > b.customerName ) return 1;

    if ( a.performAfterDate < b.performAfterDate ) return -1;
    if ( a.performAfterDate > b.performAfterDate ) return 1;

    return 0;
};

export const sortbyDueDateThenCustomer = <
    Task extends Extract<TaskDocument, { customerName: string }>
>(a: Task, b: Task): number => {
    if ( a.performAfterDate < b.performAfterDate ) return -1;
    if ( a.performAfterDate > b.performAfterDate ) return 1;

    if ( a.customerName < b.customerName ) return -1;
    if ( a.customerName > b.customerName ) return 1;

    return 0;
}

export const sortByGardenerThenCustomerThenDueDate = <
    Task extends Extract<TaskDocument, { gardenerName: string, customerName: string }>
>(a: Task, b: Task): number => {
    if ( a.gardenerName < b.gardenerName ) return -1;
    if ( a.gardenerName > b.gardenerName ) return 1;

    if ( a.customerName < b.customerName ) return -1;
    if ( a.customerName > b.customerName ) return 1;

    if ( a.performAfterDate < b.performAfterDate ) return -1;
    if ( a.performAfterDate > b.performAfterDate ) return 1;

    return 0;
};

export const sortbyDueDateThenGardenerThenType = <
    Task extends Extract<TaskDocument, { gardenerName: string }>,
>(a: Task, b: Task): number => {
    if ( a.performAfterDate < b.performAfterDate ) return -1;
    if ( a.performAfterDate > b.performAfterDate ) return 1;

    if ( a.gardenerName < b.gardenerName ) return -1;
    if ( a.gardenerName > b.gardenerName ) return 1;

    if ( a.type < b.type ) return -1;
    if ( a.type > b.type ) return 1;

    return 0;
}

export const formatTask = (taskType: TaskDocument["type"]) => {
    const taskTypeFormatted = (() => {
        switch ( taskType ) {
            case "evaluate-gardener": return "Evaluer samarbejde";
            case "adhoc": return "Diverse";
            case "adhoc-gardener": return "Diverse";
            case "onboarding": return "Onboarding";
        }
    })();

    return taskTypeFormatted ? `"${taskTypeFormatted}"-opgaven` : "en opgave";
}

export const calculateProfitPerTask = (tasks: number, profit: number): number | null => {
    if ( tasks === 0 ) return null;

    return Big(profit).div(tasks).round(2).toNumber();
}

export const calculateGardenerProfitPerTask = (configuration: AdminConfigurationDocument, performance: Pick<GardenerDocument["taskAgent"], "uniqueTasks" | "profit">): number => {
    const platformProfitPerTask = calculateProfitPerTask(configuration.taskAgent.uniqueTasks, configuration.taskAgent.profit) ?? 0;

    const bonusUniqueTasks = Math.max(10 - performance.uniqueTasks, 0);
    const bonusProfit = Big(bonusUniqueTasks).times(platformProfitPerTask).round(2).toNumber();

    const gardenerUniqueTasks = Big(performance.uniqueTasks).plus(bonusUniqueTasks).round(0).toNumber();
    const gardenerProfit = Big(performance.profit).plus(bonusProfit).round(2).toNumber();

    return Big(gardenerProfit).div(gardenerUniqueTasks).round(2).toNumber();
}
