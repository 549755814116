import { Link } from "react-router-dom";

import { Comment } from "../components/comment/Comment";
import { CommentBody } from "../components/comment/CommentBody";
import { InvoiceResendCommentDocument } from "./comment.model";

interface InvoiceResendCommentProps {
    comment: InvoiceResendCommentDocument;
}

export const InvoiceResendComment = (props: InvoiceResendCommentProps) => {
    const { comment } = props;

    const invoice = <Link to={`/invoices/all/invoice/${comment.invoiceId}`}>Faktura {comment.invoiceNumber}</Link>;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Faktura gensendt" author={comment.administratorName}>
            <CommentBody>
                {invoice} udstedt af <Link to={`/gardeners/context/${comment.gardenerId}`}>{comment.gardenerName}</Link> er blevet gensendt.<br />
                <br />
                Årsag:<br />
                {comment.reason}
            </CommentBody>
        </Comment>
    );
};
