import { useEffect, useState } from "react";

import firebase from "firebase/compat/app";

import { CreditNoteDocument } from "./credit-note.model";

export const useCreditNote = (creditNoteId: string | null | undefined) => {
    const [state, setState] = useState<CreditNoteDocument | null>();

    useEffect(() => {
        if ( creditNoteId === undefined ) return setState(undefined);
        if ( creditNoteId === null ) return setState(null);

        setState(undefined);

        return firebase.firestore().collection("creditNotes").doc(creditNoteId).onSnapshot(document => {
            if ( !document.exists ) return setState(null);

            setState({ id: document.id, ...document.data() } as CreditNoteDocument);
        });
    }, [creditNoteId]);

    return state;
};
