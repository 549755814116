import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        height: "1px",
        backgroundImage: "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='0%25' x2='100%25' y1='50%25' y2='50%25' rx='4' ry='4' stroke='%234A4A4A' stroke-width='1' stroke-dasharray='1%2c 4' stroke-dashoffset='2' stroke-linecap='butt'/%3e%3c/svg%3e\")",
    },
    gutter: {
        paddingTop: "24px",
        paddingBottom: "24px",
    },
    noGutter: {
    }
});

interface DashedBorderSeparatorProps {
    noGutter?: boolean;
}

export const DashedBorderSeparator = (props: DashedBorderSeparatorProps) => {
    const classes = useStyles();

    const gutterClass = props.noGutter ? classes.noGutter : classes.gutter;

    return (
        <div className={`${classes.root} ${gutterClass}`} />
    );
};
