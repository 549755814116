import { useParams } from "react-router";

import { Container } from "@material-ui/core";

import { ContentSection } from "../../../../layout/ContentSection";
import { TitleHeader } from "../../../../components/TitleHeader";
import { LinearProgress } from "../../../../components/LinearProgress";
import { BackButton } from "../../../../components/BackButton";
import { Section } from "../../../../components/section";
import { AdhocCustomerFollowUpDetailCard } from "./AdhocCustomerFollowUpDetailCard";
import { useCustomerComments } from "../../../../comment/comments.hook";
import { ActivitySection } from "../../../../comment/ActivitySection";
import { CustomerCommentList } from "../../../../comment/CustomerCommentList";
import { useAddCustomerComment } from "../../../../comment/add-comment.hook";
import { NotFoundPage } from "../../../../NotFoundPage";
import { AdhocCustomerTaskDocument } from "../../../../task/task.model";
import { useTask } from "../../../../task/tasks.hook";
import { ErrorPage } from "../../../../ErrorPage";
import { useCustomer } from "../../../../customer/customers.hook";

interface AdhocCustomerFollowUpDetailPageProps {
    goBack: () => void;
}

export const AdhocCustomerFollowUpDetailPage = (props: AdhocCustomerFollowUpDetailPageProps) => {
    const { taskId } = useParams<{ taskId: string }>();
    const { goBack } = props;

    const task = useTask(taskId) as AdhocCustomerTaskDocument | null | undefined;
    const customer = useCustomer(task && task.customerId);
    const comments = useCustomerComments(task?.customerId);
    const addComment = useAddCustomerComment(task?.customerId);

    if ( task === null ) return <NotFoundPage />;
    if ( customer === null ) return <ErrorPage message="Kunden kunne ikke findes" />;
    if ( task === undefined || customer === undefined ) return <LinearProgress />;
    if ( task.type !== "adhoc" ) return <ErrorPage message="Opgavens type genkendes ikke" />;

    if ( comments === undefined ) return <LinearProgress />;

    const backButton = <BackButton onClick={props.goBack} />;

    return (
        <>
            <TitleHeader button={backButton}>Kunder</TitleHeader>
            
            <ContentSection>
                <Container maxWidth="sm" disableGutters>

                    <Section>
                        <AdhocCustomerFollowUpDetailCard task={task} customer={customer} goBack={goBack} />
                    </Section>

                    <ActivitySection onSave={addComment}>
                        <CustomerCommentList comments={comments} />
                    </ActivitySection>

                </Container>
            </ContentSection>
        </>
    );
};
