import { useCallback } from "react";

import firebase from "firebase/compat/app";

import { useBlockingProgress } from "../components/blocking-progress";
import { useSnackbar } from "../components/snackbar";
import { useUser } from "../user/UserProvider";
import { CustomerAdministratorCommentDocument, GardenerAdministratorCommentDocument } from "./comment.model";
import { UserDocument } from "../user/user.model";

export const useAddGardenerComment = (gardenerId: string | undefined) => {
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();
    const administrator = useUser();

    return useCallback((text: string) => {
        if ( !gardenerId ) return;

        const comment: Omit<GardenerAdministratorCommentDocument, "id"> = {
            type: "gardener-administrator",
            creationDate: firebase.firestore.FieldValue.serverTimestamp() as any,
            gardenerId,
            administratorId: administrator.id,
            administratorName: administrator.name,
            text: text.trim(),
        };

        block();
        firebase.firestore().collection("comments").add(comment)
            .then(() => {
                unblock();
            })
            .catch(() => {
                openSnackbar("error", "Der skete en fejl. Kommentaren er ikke blevet gemt.");
                unblock();
            });
    }, [block, unblock, openSnackbar, administrator, gardenerId]);
};

export const addCustomerComment = async (administrator: UserDocument, customerId: string, text: string) => {
    const comment: Omit<CustomerAdministratorCommentDocument, "id"> = {
        type: "customer-administrator",
        creationDate: firebase.firestore.FieldValue.serverTimestamp() as any,
        customerId,
        administratorId: administrator.id,
        administratorName: administrator.name,
        text: text.trim(),
    };

    return await firebase.firestore().collection("comments").add(comment);
};

export const useAddCustomerComment = (customerId: string | null | undefined) => {
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();
    const administrator = useUser();

    return useCallback((text: string) => {
        if ( !customerId ) return;

        block();
        addCustomerComment(administrator, customerId, text)
            .then(() => {
                unblock();
            })
            .catch(() => {
                openSnackbar("error", "Der skete en fejl. Kommentaren er ikke blevet gemt.");
                unblock();
            });
    }, [block, unblock, openSnackbar, administrator, customerId]);
};
