import { useMemo } from "react";

import { GroupFormItem, InfoFormItem, PageFormItem, TextAreaFormItem } from "../../components/form-item/client/components";
import { ComplaintDocument } from "../complaint.model";
import { ListFormatter } from "../../formatting/list.formatter";

export interface CloseComplaintFormIds {
    TextAreaReason: string;
}

export type CloseComplaintForm =
    GroupFormItem<[
        PageFormItem<GroupFormItem<[InfoFormItem, TextAreaFormItem]>>,
        ConfirmationPage,
    ]>;

type FirstPage = PageFormItem<GroupFormItem<[InfoFormItem, TextAreaFormItem]>>;
type ConfirmationPage = PageFormItem<InfoFormItem>

export const useCloseComplaintForm = (complaint: ComplaintDocument, formIds: CloseComplaintFormIds): CloseComplaintForm => {
    return useMemo(() => {

        const firstPage: FirstPage = {
            type: "page",
            title: "Afslut reklamation",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Hvis havemanden eller kunden har meddelt, at der ikke længere reklameres, kan vi afslutte reklamationen.<br />
                            <br />
                            OBS: Det er vigtigt, at vi ikke gør dette ved en fejl.
                        `,
                    },
                    {
                        id: formIds.TextAreaReason,
                        type: "text-area",
                        label: "Årsag",
                        placeholder: "Angiv årsagen til at reklamationen afsluttes",
                        value: "",
                        dirty: false,
                    },
                ],
            },
        };

        const listFormatter = new ListFormatter();
        const invoicesListed = listFormatter.formatList(complaint.invoices.map(invoice => `faktura ${invoice.invoiceNumber}`));

        const confirmationPage: ConfirmationPage = {
            type: "page",
            title: "Afslut reklamation",
            item: {
                type: "info",
                render: "default",
                text: [
                    `Reklamationen afsluttes for ${invoicesListed} og eventuel opkrævning genoptages.`,
                    "Kunden modtager en notifikation om, at reklamationen afsluttes og at opkrævningen genoptages.",
                    complaint.status !== "contacting customer" ? "Havemanden modtager en notifikation om, at reklamationen afsluttes og at opkrævningen genoptages." : "Havemanden informeres ikke, da det ikke er nødvendigt.",
                ].join("<br /><br />"),
            },
        };

        return {
            type: "group",
            render: "all at once",
            items: [
                firstPage,
                confirmationPage,
            ],
        };
    }, [complaint, formIds]);
};
