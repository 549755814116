import { useCallback } from "react";
import { useParams } from "react-router";

import { Container, makeStyles } from "@material-ui/core";

import { NotFoundPage } from "../../NotFoundPage";
import { BackButton } from "../../components/BackButton";
import { Section } from "../../components/section";
import { TitleHeader } from "../../components/TitleHeader";
import { LightButton } from "../../components/button";
import { useCustomer } from "../../customer/customers.hook";
import { CreditNoteDetailCard } from "./CreditNoteDetailCard";
import { useCreditNote } from "../../finance/billing/invoices/credit-notes.hook";
import { useGardener } from "../../gardener/gardeners.hook";
import { ContentSection } from "../../layout/ContentSection";
import { LinearProgress } from "../../components/LinearProgress";

const useStyles = makeStyles({
    button: {
        display: "block",
        margin: "0 auto",
    },
});

interface CreditNoteDetailPageProps {
    goBack: () => void;
}

export const CreditNoteDetailPage = (props: CreditNoteDetailPageProps) => {
    const classes = useStyles();

    const { creditNoteId } = useParams<{ creditNoteId: string }>();
    
    const creditNote = useCreditNote(creditNoteId);
    const customer = useCustomer(creditNote?.customer.id);
    const gardener = useGardener(creditNote?.gardener.id);

    const openCreditNoteInNewTab = useCallback(() => {
        if ( !creditNote ) return;

        window.open(`https://gogogarden.dk/billing/invoices/credit-note?id=${creditNote.id}`, "_blank");
    }, [creditNote]);
    
    if ( creditNote === null ) return <NotFoundPage />;
    if ( creditNote === undefined || customer === undefined || gardener === undefined ) return <LinearProgress />;
    
    const backButton = <BackButton onClick={props.goBack} />;

    return (
        <>
            <TitleHeader button={backButton}>Kreditnota #{creditNote.creditNoteNumber}</TitleHeader>
            
            <ContentSection>
                <Container maxWidth="sm" disableGutters>

                    <Section>
                        <CreditNoteDetailCard creditNote={creditNote} customer={customer} gardener={gardener} />
                        <LightButton className={classes.button} onClick={openCreditNoteInNewTab}>Se kreditnota</LightButton>
                    </Section>

                </Container>
            </ContentSection>
        </>
    );
};
