import { Container } from "@material-ui/core";

import { ComplaintTaskDocument } from "../../../task/task.model";
import { CommentDocument } from "../../../comment/comment.model";
import { Message } from "../../../Message";
import { ActivitySection } from "../../../comment/ActivitySection";
import { CustomerCommentList } from "../../../comment/CustomerCommentList";
import { BackButton } from "../../../components/BackButton";
import { Section } from "../../../components/section";
import { TitleHeader } from "../../../components/TitleHeader";
import { ContentSection } from "../../../layout/ContentSection";
import { RegistrationDetailCard } from "../../../finance/billing/pending-approval/RegistrationDetailCard";
import { InstructionCard } from "../../../workflow/InstructionCard";
import { ComplaintDetailCard } from "../../ComplaintDetailCard";
import { InvoiceDocument } from "../../../finance/billing/invoices/invoice.model";
import { AdministratorActionData } from "../../../workflow/administrator-action.hook";
import { GreenButton, VerticalButtons } from "../../../components/new-button";
import { useCallback } from "react";
import { FormProps } from "../../../components/form-item/framework/react/FormProvider";
import { FormItem } from "../../../components/form-item/framework/core/form-item.model";
import { DetermineProposalFormIds, useDetermineProposalDialog } from "./DetermineProposalDialog";
import { SingleChoiceFormItem, TextAreaFormItem } from "../../../components/form-item/client/components";

export interface DetermineProposalDetailPageProps {
    task: ComplaintTaskDocument;
    invoices: InvoiceDocument[];
    administratorAction: (data: AdministratorActionData | string) => void;
    comments: CommentDocument[];
    addComment: (comment: string) => void;
    goBack: () => void;
}

export const DetermineProposalDetailPage = (props: DetermineProposalDetailPageProps) => {
    const { task, invoices, administratorAction, comments, addComment } = props;

    const backButton = <BackButton onClick={props.goBack} />;

    const determineProposalAdapter = useCallback<FormProps<FormItem>["onSubmit"]>((controller, formItem) => {
        const complaintStillRelevant = (controller.getItem(DetermineProposalFormIds.SingleChoiceStillRelevant, formItem) as SingleChoiceFormItem<any[]>).selectedChoiceIndex === 1;
        if ( !complaintStillRelevant ) {
            const reason = (controller.getItem(DetermineProposalFormIds.TextAreaCloseComplaintBecauseIrrelevantReason, formItem) as TextAreaFormItem).value;

            return administratorAction({
                action: "close complaint",
                complaintId: task.complaint.id,
                reason,
            });
        }

        const canOfferProposal = (controller.getItem(DetermineProposalFormIds.SingleChoiceCanOfferProposal, formItem) as SingleChoiceFormItem<any[]>).selectedChoiceIndex === 1;
        if ( !canOfferProposal ) {
            const reason = (controller.getItem(DetermineProposalFormIds.TextAreaHandOverComplaintReason, formItem) as TextAreaFormItem).value;
            return administratorAction({
                action: "hand over complaint to gardener",
                taskId: task.id,
                reason,
            });
        }

        const problem = (controller.getItem(DetermineProposalFormIds.TextAreaProblem, formItem) as TextAreaFormItem).value;
        const proposal = (controller.getItem(DetermineProposalFormIds.TextAreaProposal, formItem) as TextAreaFormItem).value;

        return administratorAction({
            action: "offer proposal for complaint",
            taskId: task.id,
            platformComment: problem,
            platformProposal: proposal,
        });
    }, [administratorAction, task]);
    const openDetermineProposalDialog = useDetermineProposalDialog(task, determineProposalAdapter);

    return (
        <>
            <TitleHeader button={backButton}>Tilbyd løsning</TitleHeader>
            
            <ContentSection>
                <Container maxWidth="sm" disableGutters>

                    <Section>
                        {task.hasBeenPerformed ? <Message>Opgaven er allerede blevet udført</Message> : null}

                        <InstructionCard>
                            Tag stilling til om kunden kan tilbydes en løsning eller om havemanden er nødt til at overtage reklamationen.
                        </InstructionCard>

                        <ComplaintDetailCard complaint={task.complaint} administratorAction={administratorAction} />

                        {invoices.map(invoice => (
                            <RegistrationDetailCard key={invoice.id} invoice={invoice} gardenerName={task.gardenerName} />
                        ))}

                        <VerticalButtons>
                            <GreenButton onClick={openDetermineProposalDialog}>Besvar opgave</GreenButton>
                        </VerticalButtons>

                    </Section>

                    <ActivitySection onSave={addComment}>
                        <CustomerCommentList comments={comments} />
                    </ActivitySection>

                </Container>
            </ContentSection>
        </>
    );
};
