import { Link } from "react-router-dom";

import moment from "moment";

import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { NewRegistrationCommentDocument } from "../comment.model";

interface NewRegistrationCommentProps {
    comment: NewRegistrationCommentDocument;
}

export const NewRegistrationComment = (props: NewRegistrationCommentProps) => {
    const { comment } = props;

    const gardener = <Link to={`/gardeners/context/${comment.gardenerId}`}>{comment.gardenerName}</Link>;
    const customer = <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link>;
    const invoice = comment.invoiceId && comment.invoiceNumber ? <Link to={`/invoices/all/invoice/${comment.invoiceId}`}>Faktura {comment.invoiceNumber}</Link> : null;

    const duration = formatRegistrationDuration(comment.registrationTotalInMinutes);

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Tidsregistrering foretaget">
            <CommentBody>
                {gardener} har registreret {duration} hos {customer} <span style={{ whiteSpace: "nowrap" }}>d. {moment(comment.registrationDate).format("D. MMMM")}</span>.

                {invoice ? <><br /><br />{invoice} sendes automatisk til kunden.</> : null}
            </CommentBody>
        </Comment>
    );
};

function formatRegistrationDuration(durationInMinutes: number): string {
    const unit = durationInMinutes === 60 ? "time" : "timer";
    if ( durationInMinutes % 60 === 0 ) return `${durationInMinutes / 60} ${unit}`;

    const duration = (durationInMinutes / 60).toFixed(2).replaceAll(".", ",");

    return `${duration} ${unit}`;
}
