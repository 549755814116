import { makeStyles } from "@material-ui/core";
import { Message } from "../../Message";
import { GardeningTaskDocument } from "../gardening-task.model";
import { GardeningTaskCustomerListItem } from "./GardeningTaskCustomerListItem";
import { GardenerDocument } from "../../gardener/gardener.model";
import { useMemo } from "react";

const useStyles = makeStyles({
    root: {
        "& > *": {
            marginBottom: "24px",

            "&:last-child": {
                marginBottom: 0,
            },
        },
    },
});

interface GardeningTaskListProps {
    gardeningTasks: GardeningTaskDocument[];
    gardeners: GardenerDocument[];
    onSelect: (gardeningTask: GardeningTaskDocument) => void;
}

export const GardeningTaskCustomerList = (props: GardeningTaskListProps) => {
    const classes = useStyles();
    const { gardeningTasks, gardeners, onSelect } = props;

    const sortedGardeningTasks = useMemo(() => {
        return gardeningTasks.sort((a, b) => {
            const aCreationDate = a.history.find(x => x.event === "created")?.date;
            const bCreationDate = b.history.find(x => x.event === "created")?.date;
            
            if ( !aCreationDate && bCreationDate ) return -1;
            if ( aCreationDate && !bCreationDate ) return 1;

            if ( aCreationDate && bCreationDate ) {
                if ( aCreationDate < bCreationDate ) return 1;
                if ( aCreationDate > bCreationDate ) return -1;
            }

            return 0;
        })
    }, [gardeningTasks]);

    if ( gardeningTasks.length === 0 ) return <Message>Der er ingen opgaver at vise</Message>;

    return (
        
        <div className={classes.root}>

            {sortedGardeningTasks.map(gardeningTask => (
                <GardeningTaskCustomerListItem key={gardeningTask.id} gardeningTask={gardeningTask} gardeners={gardeners} onSelect={onSelect} />
            ))}

        </div>
    );
};
