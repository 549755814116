import { useMemo, useState } from "react";

import { SearchResponse } from "@algolia/client-search";
import { Container, makeStyles } from "@material-ui/core";

import { ContentSection } from "../../../layout/ContentSection";
import { Section } from "../../../components/section";
import { SectionHeader } from "../../../components/section/SectionHeader";
import { Message } from "../../../Message";
import { SearchField } from "../../../components/form/InputField";
import { useSearchClient } from "../../../search-client.hook";
import { ListItem } from "./ListItem";
import { useLastValue } from "../../../last-value.hook";
import { GardenerSearchRecord, useGardenerSearch } from "./gardener-search.hook";
import { GardenerDocument } from "../../gardener.model";

const useStyles = makeStyles({
    list: {
        "& > *": {
            marginBottom: "32px",
        },

        "& > *:last-child": {
            marginBottom: 0,
        },
    },
});

export const GardenerListPageAllTab = () => {
    const classes = useStyles();

    const searchClient = useSearchClient();
    const [searchTerm, setSearchTerm] = useState("");

    const searchResults = useGardenerSearch(searchClient, searchTerm);
    const lastSearchResults = useLastValue(searchResults);

    const resultSections = useSortAndSplit(lastSearchResults);

    return (
        <ContentSection>
            <Container maxWidth="sm" disableGutters className={classes.list}>

                <SearchField fullWidth value={searchTerm} onChange={setSearchTerm} placeholder="Søg" autoFocus />

                {lastSearchResults?.hits.length === 0 ? (
                    <Message>Ingen havemænd matchede din søgning</Message>
                ) : null}

                {resultSections.map(section => {
                    if ( section.results.length === 0 ) return null;

                    return (
                        <Section key={section.title}>
                            <SectionHeader>{section.title}</SectionHeader>

                            {section.results.map(gardener => (
                                <ListItem key={gardener.id} gardener={gardener} />
                            ))}
                        </Section>
                    );
                })}

            </Container>
        </ContentSection>
    );
};

function useSortAndSplit(searchResults: SearchResponse<GardenerSearchRecord> | null | undefined) {
    return useMemo(() => {
        const gardeners = searchResults?.hits || [];
        if ( gardeners.length === 0 ) return [];

        const activeGardenerSection = {
            title: "Aktive havemænd",
            results: gardeners.filter(gardener => gardener.status === "active"),
        };

        const terminatedGardenerSection = {
            title: "Ophørte havemænd",
            results: gardeners.filter(gardener => gardener.status === "terminated"),
        };

        return gardeners[0].status === "active" ? [activeGardenerSection, terminatedGardenerSection] : [terminatedGardenerSection, activeGardenerSection];
    }, [searchResults]);
}
