import { useFormDialog } from "../../../components/form-item/client/components";
import { FormProps } from "../../../components/form-item/framework/react/FormProvider";
import { useCloseComplaintForm } from "../../shared/CloseComplaintForm";
import { ComplaintTaskDocument } from "../../../task/task.model";
import { ComplaintStillRelevantForm, useComplaintStillRelevantForm } from "../../shared/ComplaintStillRelevantForm";
import { CustomerResponseForm, useCustomerResponseForm } from "../../shared/CustomerResponseForm";

export enum AwaitCustomerResponseFormIds {
    SingleChoiceCustomerResponse = "SingleChoiceCustomerResponse",
    TextAreaCloseComplaintBecauseIrrelevantReason = "TextAreaCloseComplaintBecauseIrrelevantReason",
    SingleChoiceStillRelevant = "SingleChoiceStillRelevant",
}

export type AwaitCustomerResponseForm =
    ComplaintStillRelevantForm<
        CustomerResponseForm
    >;

export const useAwaitCustomerResponseDialog = (task: ComplaintTaskDocument, onConfirm: FormProps<AwaitCustomerResponseForm>["onSubmit"]) => {

    const canOfferProposalForm = useCustomerResponseForm({
        SingleChoiceCustomerResponse: AwaitCustomerResponseFormIds.SingleChoiceCustomerResponse,
    });

    const closeComplaintBecauseIrrelevantForm = useCloseComplaintForm(task.complaint, {
        TextAreaReason: AwaitCustomerResponseFormIds.TextAreaCloseComplaintBecauseIrrelevantReason,
    });
    
    const complaintStillRelevantForm = useComplaintStillRelevantForm(closeComplaintBecauseIrrelevantForm, canOfferProposalForm, {
        SingleChoiceStillRelevant: AwaitCustomerResponseFormIds.SingleChoiceStillRelevant,
    });

    return useFormDialog(complaintStillRelevantForm, onConfirm);
};
