import firebase from "firebase/compat/app";
import { useEffect, useState } from "react";

import { UserDocument } from "./user.model";

export const useUser = (userId: string | null | undefined) => {
    const [state, setState] = useState<UserDocument | null>();
  
    useEffect(() => {
        if ( userId === undefined ) return setState(undefined);
        if ( userId === null ) return setState(null);

        setState(undefined);

        return firebase.firestore().collection("users").doc(userId).onSnapshot(document => {
            if ( !document.exists ) return setState(null);

            const user = { id: document.id, ...document.data() } as UserDocument;

            setState(user);
        }, () => setState(null));
    }, [userId]);
  
    return state;
};
