import { Link } from "react-router-dom";
import moment from "moment";

import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { CheckRegistrationClarificationTaskActionCommentDocument } from "../comment.model";

interface CheckRegistrationClarificationTaskActionCommentProps {
    context: "customer" | "gardener";
    comment: CheckRegistrationClarificationTaskActionCommentDocument;
}

export const CheckRegistrationClarificationTaskActionComment = (props: CheckRegistrationClarificationTaskActionCommentProps) => {
    const { context, comment } = props;

    const gardener = (() => {
        switch ( context ) {
            case "customer": return <Link to={`/gardeners/context/${comment.gardenerId}`}>{comment.gardenerName}</Link>;
            case "gardener": return <>havemanden</>;
        }
    })();

    const customer = (() => {
        switch ( context ) {
            case "customer": return <>kunden</>;
            case "gardener": return <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link>;
        }
    })();

    const duration = formatRegistrationDuration(comment.registrationTotalInMinutes);
    const date = formatDate(comment.registrationDate);

    const text = (() => {
        switch ( comment.context.action ) {
            case "invoice": return <>Tidsregistreringen mellem {gardener} og {customer} på {duration} d. <span style={{ whiteSpace: "nowrap" }}>{date}</span> er godkendt.</>;
            case "delete": return <>Tidsregistrering mellem {gardener} og {customer} på {duration} d. <span style={{ whiteSpace: "nowrap" }}>{date}</span> er slettet.</>;
        }
    })();

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Tidsregistrering afklaret" author={comment.administratorName}>
            <CommentBody>
                {text}<br />
                <br />
                Årsag:<br />
                “{comment.context.reason}”
            </CommentBody>
        </Comment>
    );
};

function formatRegistrationDuration(durationInMinutes: number): string {
    const unit = durationInMinutes === 60 ? "time" : "timer";
    if ( durationInMinutes % 60 === 0 ) return `${durationInMinutes / 60} ${unit}`;

    const duration = (durationInMinutes / 60).toFixed(2).replaceAll(".", ",");

    return `${duration} ${unit}`;
}

function formatDate(date: string): string {
    return moment(date).format("D. MMMM");
}
