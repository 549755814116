import { Comment } from "../components/comment/Comment";
import { CommentBody } from "../components/comment/CommentBody";
import { UnorderedList } from "../components/unordered-list/UnorderedList";
import { GuidesUnlockedCommentDocument } from "./comment.model";

interface GuidesUnlockedCommentProps {
    comment: GuidesUnlockedCommentDocument;
}

export const GuidesUnlockedComment = (props: GuidesUnlockedCommentProps) => {
    const { comment } = props;

    const guides = comment.guides.map(guide => {
        switch ( guide ) {
            case "onboarding": return "Generelt om konceptet";
            case "newTask": return "Sådan besvarer du “Ny opgave”";
            case "appointmentTask": return "Sådan besvarer du “Har I lavet en aftale?”";
            case "customerConversation": return "Samtalen med kunden";
            case "registrationTask": return "Sådan besvarer du “Hvordan gik dit besøg?”";
            case "registration": return "Sådan registrerer du tid";
            case "afterRegistration": return "Hvad sker der, når du registrerer tid?";
            case "afterClosedTask": return "Hvad sker der, hvis du lukker en opgave?";
        }

        return null;
    });

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Guides skal gennemføres">
            <CommentBody>
                Havemanden skal nu gennemføre følgende guides:
                <UnorderedList>
                    {guides.map((guide, index) => (
                        <li key={index}>{guide ?? "en ukendt guide"}</li>
                    ))}
                </UnorderedList>
            </CommentBody>
        </Comment>
    );
};
