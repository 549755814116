import { useCallback } from "react";
import { Collapse, MenuItem } from "@material-ui/core";

import { AlternativeHint, Dropdown, Group } from "../../../../../../components/form";
import { validateMandatory } from "../../../../../../components/validation/validation";
import { Service } from "../../../invoice.model";
import { formatServiceTools } from "../../utility";
import { InvoiceFormContext, useInvoiceForm } from "../InvoiceForm";
import { ServiceInputContext, useServiceInput } from ".";

interface ToolsInputProps {
    index: number;
    id?: string;
}

export const ToolsInput = (props: ToolsInputProps) => {
    const { index, id } = props;

    const { form } = useInvoiceForm();
    const { serviceInput, setServiceInput } = useServiceInput(index);
    const { toolsInput } = serviceInput;

    const updateTools = useCallback((tools: string) => {
        setServiceInput((previousState): ServiceInputContext => ({
            ...previousState,
            toolsInput: {
                ...previousState.toolsInput,
                tools: tools as Service["tools"],
            },
        }))
    }, [setServiceInput]);

    const markToolsDirty = useCallback(() => {
        setServiceInput((previousState): ServiceInputContext => ({
            ...previousState,
            toolsInput: {
                ...previousState.toolsInput,
                dirty: true,
            },
        }))
    }, [setServiceInput]);

    const renderPlaceholder = useCallback(() => "Vælg redskaber", []);

    const error = validateToolsInput(form, index);

    return (
        <Group error={Boolean(toolsInput.dirty && error)}>
            <Dropdown labelId={id} variant="outlined" value={toolsInput.tools ?? ""} onChange={updateTools} onBlur={markToolsDirty} displayEmpty renderValue={toolsInput.tools ? undefined : renderPlaceholder}>
                <MenuItem value="customer-tools">{formatServiceTools("customer-tools")}</MenuItem>
                <MenuItem value="gardener-tools">{formatServiceTools("gardener-tools")}</MenuItem>
                <MenuItem value="gardener-large-tools">{formatServiceTools("gardener-large-tools")}</MenuItem>
            </Dropdown>

            <Collapse in={Boolean(toolsInput.dirty && error)} timeout={form.validationAnimationDuration}>
                <AlternativeHint message={error} />
            </Collapse>
        </Group>
    );
}

export interface ToolsInputContext {
    tools: Service["tools"] | undefined;
    dirty: boolean;
}

export const newToolsInputContext = (service: Service): ToolsInputContext => ({
    tools: service.tools,
    dirty: false,
});

export const toolsInputHasChanged = (form: InvoiceFormContext, index: number) => {
    if ( form.invoice.services.length <= index ) return true;

    return form.invoice.services[index].tools !== form.serviceInputs[index].toolsInput.tools;
};

export const validateToolsInput = (form: InvoiceFormContext, index: number) => {
    return validateMandatory(form.serviceInputs[index].toolsInput.tools);
};
