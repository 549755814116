import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { MenuItem } from "@material-ui/core";

import { NeutralAction } from "../../components/card/NeutralAction";
import { PositiveAction } from "../../components/card/PositiveAction";
import { Dialog, DialogActions, DialogContent, DialogHeader, useDialog } from "../../components/dialog";
import { Dropdown } from "../../components/form/Dropdown";
import { Group } from "../../components/form/Group";
import { Label } from "../../components/form/Label";
import { AdhocTaskFormSection, AdhocTaskFormSectionViewModel } from "./AdhocTaskFormSection";

export type CreateTaskDialogViewModel = AdhocTaskFormSectionViewModel;

export interface CreateTaskDialogProps {
    onConfirm: (viewModel: CreateTaskDialogViewModel) => void;
    onCancel: () => void;
}

export const CreateTaskDialog = (props: CreateTaskDialogProps) => {
    const { onConfirm, onCancel } = props;

    const initialAdhocTaskFormSectionViewModel: AdhocTaskFormSectionViewModel = useMemo(() => {
        return {
            type: "adhoc",
            whatHasHappened: "",
            whatHasHappenedDirty: false,
            whatShouldBeDone: "",
            whatShouldBeDoneDirty: false,
            performAfterDateDate: "",
            performAfterDateDateDirty: false,
            performAfterDateTime: "",
            performAfterDateTimeDirty: false,
            error: true,
        };
    }, []);

    const [taskType, setTaskType] = useState("adhoc");
    const [viewModel, setViewModel] = useState<CreateTaskDialogViewModel>(initialAdhocTaskFormSectionViewModel);
    const [hasSubmittedWithError, setHasSubmittedWithError] = useState(false);

    useEffect(() => {
        setHasSubmittedWithError(false);
        switch ( taskType ) {
            case "adhoc": return setViewModel(initialAdhocTaskFormSectionViewModel);
        }
    }, [taskType, initialAdhocTaskFormSectionViewModel]);

    const [shouldScrollToFirstError, setShouldScrollToFirstError] = useState(false);
    const scrollToFirstError = useCallback(() => setShouldScrollToFirstError(true), []);
    useEffect(() => {
        if ( !shouldScrollToFirstError ) return;

        setShouldScrollToFirstError(false);
    }, [shouldScrollToFirstError]);

    const confirm = useCallback(() => {
        if ( document.activeElement ) (document.activeElement as HTMLInputElement).blur();

        if ( viewModel.error ) {
            setHasSubmittedWithError(true);
            return scrollToFirstError();
        }

        onConfirm(viewModel);
    }, [onConfirm, viewModel, scrollToFirstError]);

    return (
        <Dialog>
            <DialogHeader>Planlæg diverse</DialogHeader>

            <DialogContent>

                {false ? (
                    <div>
                        <Label htmlFor="task-type" style={{ marginBottom: "8px" }}>Vælg opgavetype</Label>
                        <Group>
                            <Dropdown variant="outlined" value={taskType} onChange={setTaskType}>
                                <MenuItem value="adhoc">Diverse</MenuItem>
                            </Dropdown>
                        </Group>
                    </div>
                ) : null}

                {(() => {
                    switch ( viewModel.type ) {

                        case "adhoc":
                            return (
                                <AdhocTaskFormSection
                                    viewModel={viewModel}
                                    setViewModel={setViewModel}
                                    hasSubmittedWithError={hasSubmittedWithError}
                                    shouldScrollToFirstError={shouldScrollToFirstError}
                                />
                            );
                        
                        default: return null;
                    }
                })()}

            </DialogContent>

            <DialogActions>
                <NeutralAction onClick={onCancel}>Annuller</NeutralAction>
                <PositiveAction onClick={confirm}>Bekræft</PositiveAction>
            </DialogActions>

        </Dialog>
    );
};

export const useCreateTaskDialog = (onConfirm: CreateTaskDialogProps["onConfirm"]) => {
    const { openDialog, closeDialog } = useDialog();

    const hasConfirmed = useRef(false);
    const confirm: CreateTaskDialogProps["onConfirm"] = useCallback(viewModel => {
        if ( hasConfirmed.current ) return;
        hasConfirmed.current = true;

        closeDialog();

        onConfirm(viewModel);
    }, [closeDialog, onConfirm, hasConfirmed]);

    return useCallback(() => {
        hasConfirmed.current = false;

        openDialog(<CreateTaskDialog onConfirm={confirm} onCancel={closeDialog} />);
    }, [openDialog, closeDialog, confirm, hasConfirmed]);
};
