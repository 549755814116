import { PageFormItem, InfoFormItem, useFormDialog } from "../../components/form-item/client/components";
import { FormProps } from "../../components/form-item/framework/react/FormProvider";
import { GardenerDocument } from "../../gardener/gardener.model";
import { EvaluateGardenerTaskDocument } from "../../task/task.model";
import { ContactGardenerForm, useContactGardenerForm } from "./ContactGardenerForm";
import { InformGardenerOfTermsForm, useInformGardenerOfTermsForm } from "./InformGardenerOfTerms";
import { PartnershipOverviewForm, usePartnershipOverviewForm } from "./PartnershipOverviewForm";
import { ReasonForCallForm, useReasonForCallForm } from "./ReasonForCallForm";

export enum EvaluateGardenerResponseFormIds {
    SingleChoiceAction = "SingleChoiceAction",
    SingleChoiceReachedGardener = "SingleChoiceReachedGardener",
    SingleChoiceWillGiveExtraChance = "SingleChoiceWillGiveExtraChance",
}

export type EvaluateGardenerResponseForm =
    PartnershipOverviewForm<
        ContactGardenerForm<
            ReasonForCallForm<
                InformGardenerOfTermsForm<
                    ConfirmationPage
                >
            >
        >
    >

type ConfirmationPage = PageFormItem<InfoFormItem>

export const useEvaluateGardenerResponseDialog = (task: EvaluateGardenerTaskDocument, gardener: GardenerDocument, now: string, onConfirm: FormProps<EvaluateGardenerResponseForm>["onSubmit"]) => {
    const confirmationPage: ConfirmationPage = {
        type: "page",
        title: "Registrer advarsel",
        item: {
            type: "info",
            render: "default",
            text: `
                Havemanden får mulighed for at acceptere 3 opgaver mere.<br />
                <br />
                Din handling registreres som en advarsel.
            `,
        },
    };

    const informGardenerOfTermsForm = useInformGardenerOfTermsForm(confirmationPage);

    const reasonForCallForm = useReasonForCallForm(task.protection, now, informGardenerOfTermsForm, {
        SingleChoiceWillGiveExtraChance: EvaluateGardenerResponseFormIds.SingleChoiceWillGiveExtraChance,
    });

    const contactGardenerForm = useContactGardenerForm(gardener, task.protection, reasonForCallForm, {
        SingleChoiceReachedGardener: EvaluateGardenerResponseFormIds.SingleChoiceReachedGardener,
    });

    const partnershipOverviewForm = usePartnershipOverviewForm(task.protection, now, contactGardenerForm, {
        SingleChoiceAction: EvaluateGardenerResponseFormIds.SingleChoiceAction,
    });

    return useFormDialog(partnershipOverviewForm, onConfirm);
};
