import { Link } from "react-router-dom";

import { Comment } from "../components/comment/Comment";
import { CommentBody } from "../components/comment/CommentBody";
import { MatchCancelledCommentDocument } from "./comment.model";

interface MatchCancelledCommentProps {
    comment: MatchCancelledCommentDocument;
}

export const MatchCancelledComment = (props: MatchCancelledCommentProps) => {
    const { comment } = props;

    const gardener = <Link to={`/gardeners/context/${comment.gardenerId}`}>{comment.gardenerName}</Link>;
    const customer = <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link>;

    const reason = (() => {
        if ( comment.scenario === "gardener cannot help" ) return "Havemanden kan ikke hjælpe kunden.";
        if ( comment.scenario === "customer does not need help" && comment.reason === "cannot reach customer" ) return "Havemanden kunne ikke få fat i kunden.";
        if ( comment.scenario === "customer does not need help" && comment.reason === "could not find date" ) return "Havemanden kunne ikke finde en dato med kunden.";
        if ( comment.scenario === "customer does not need help" && comment.reason === "customer does not need help" ) return "Kunden har ikke brug for hjælp.";
        if ( comment.scenario === "task inactive" ) return "Opgaven blev fjernet fra havemandens profil efter at være gået 10 dage over tid.";
        if ( comment.scenario === "task inactive 5 days" ) return "Opgaven blev fjernet fra havemandens profil efter at være gået 5 dage over tid.";

        return comment.reason;
    })();

    const response = (() => {
        if ( comment.willLookForNewGardener === undefined || comment.willNotifyCustomer === undefined ) return null;

        return `Kunden er ${comment.willNotifyCustomer ? "informeret" : "ikke informeret"} og der ${comment.willLookForNewGardener ? "ledes efter en ny havemand, medmindre kunden allerede har fået tilknyttet en havemand." : "ledes ikke efter en ny havemand."}`;
    })();

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Kunde afvist">
            <CommentBody>
                {gardener} har afvist {customer}.<br />
                <br />
                Årsag:<br />
                {reason}
                {response ? (
                    <>
                    <br /><br />
                    Handling:<br />
                    {response}
                    </>
                ) : null}
            </CommentBody>
        </Comment>
    );
};
