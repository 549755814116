import { useEffect, useState } from "react";

import firebase from "firebase/compat/app";

import { CommentDocument } from "../comment/comment.model";

export const useGardenerComments = (gardenerId: string | undefined) => {
    const [state, setState] = useState<CommentDocument[]>();

    useEffect(() => {
        if ( gardenerId === undefined ) return setState(undefined);

        setState(undefined);

        return firebase.firestore()
            .collection("comments")
                .where("gardenerId", "==", gardenerId)
            .onSnapshot(collection => {
                const documents = collection.docs.map(document => ({ id: document.id, ...document.data({ serverTimestamps: "estimate" }) } as CommentDocument));
                
                setState(documents);
            });
    }, [gardenerId]);

    return state;
};

export const useCustomerComments = (customerId: string | undefined) => {
    const [state, setState] = useState<CommentDocument[]>();

    useEffect(() => {
        if ( customerId === undefined ) return setState(undefined);

        setState(undefined);

        return firebase.firestore()
            .collection("comments")
                .where("customerId", "==", customerId)
            .onSnapshot(collection => {
                const documents = collection.docs.map(document => ({ id: document.id, ...document.data({ serverTimestamps: "estimate" }) } as CommentDocument));
                
                setState(documents);
            });
    }, [customerId]);

    return state;
};
