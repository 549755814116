import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";

firebase.initializeApp({
  apiKey: "AIzaSyBoVjHjTx5LyDQ1SqVsggTWnbNklhzY7N8",
  authDomain: "sommer-og-fritid.firebaseapp.com",
  databaseURL: "https://sommer-og-fritid.firebaseio.com",
  projectId: "sommer-og-fritid",
  storageBucket: "sommer-og-fritid.appspot.com",
  messagingSenderId: "838002229339",
  appId: "1:838002229339:web:53500b581f06c7771a7f7e",
  measurementId: "G-5H7XQ9SC27"
});

firebase.analytics();
