import { makeStyles } from "@material-ui/core";
import { HTMLAttributes } from "react";

const useStyles = makeStyles({
    root: {
        fontSize: "20px",
        fontWeight: 500,
        fontVariant: "small-caps",
        color: "#818181",

        paddingBottom: "8px",
        margin: 0,
        borderBottom: "1px solid #81818180",
    },
});

export const SectionHeaderAlternative = (props: HTMLAttributes<HTMLHeadingElement>) => {
    const classes = useStyles();

    const { className } = props;

    return (
        // eslint-disable-next-line jsx-a11y/heading-has-content
        <h2
            className={className ? `${classes.root} ${className}` : classes.root}
            {...props}
        />
    );
};
