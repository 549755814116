import { Link } from "react-router-dom";

import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { AppointmentRegisteredCommentDocument } from "../comment.model";

interface AppointmentRegisteredCommentProps {
    context: "customer" | "gardener";
    comment: AppointmentRegisteredCommentDocument;
}

export const AppointmentRegisteredComment = (props: AppointmentRegisteredCommentProps) => {
    const { context, comment } = props;

    const gardener = (() => {
        switch ( context ) {
            case "customer": return <Link to={`/gardeners/context/${comment.gardenerId}`}>{comment.gardenerName}</Link>;
            case "gardener": return <>Havemanden</>;
        }
    })();

    const customer = (() => {
        switch ( context ) {
            case "customer": return <>kunden</>;
            case "gardener": return <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link>;
        }
    })();

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Aftale registreret">
            <CommentBody>
                {gardener} har registreret en aftale med {customer}.<br />
                <br />
                Hvornår:<br />
                {comment.whenIsTheAppointmentScheduled}<br />
                <br />
                Hvad:<br />
                {comment.whatHasBeenAgreedUpon}
            </CommentBody>
        </Comment>
    );
};
