import { useMemo } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";

import { TitleHeader } from "../../components/TitleHeader";
import { TabBarItem } from "../../components/tabs/TabBarItem";
import { TabBar } from "../../components/tabs/TabBar";
import { CustomersPageAllTab } from "./all-tab/CustomersPageAllTab";
import { CustomersPageDeletedTab } from "./deleted-tab/CustomersPageDeletedTab";

export const CustomerListPage = () => {
    const { tab } = useParams<{ tab: string }>();

    const tabIndex = useMemo(() => {
        switch ( tab ) {
            case "all": return 0;
            case "deleted": return 1;

            default: return 0;
        }
    }, [tab]);

    return (
        <>
            <TitleHeader>Kunder</TitleHeader>

            <TabBar index={tabIndex}>

                <TabBarItem href="/customers/all">
                    Alle kunder
                </TabBarItem>

                <TabBarItem href="/customers/deleted">
                    Slettede kunder
                </TabBarItem>

            </TabBar>

            <Switch>

                <Route exact path="/customers/all">
                    <CustomersPageAllTab />
                </Route>

                <Route exact path="/customers/deleted">
                    <CustomersPageDeletedTab />
                </Route>

                <Redirect to="/customers/all" />

            </Switch>
        </>
    );
};
