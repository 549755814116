import { ButtonProps, makeStyles } from "@material-ui/core";

import { ButtonBase } from "./ButtonBase";

const useStyles = makeStyles({
    root: {
        backgroundColor: "#2FA52B",
        color: "#FFFFFF",
        boxShadow: "none",

        "&:hover": {
            backgroundColor: "#238221",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
        },
        
        "&:disabled": {
            backgroundColor: "#818181",
            color: "#FFFFFF",
        },
    },
});

export const GreenButton = (props: ButtonProps) => {
    const classes = useStyles();

    const { className, ...otherProps } = props;

    return (
        <ButtonBase
            className={className ? `${classes.root} ${className}` : classes.root}
            variant="contained"
            {...otherProps}
        />
    );
};
