import { useCallback, useEffect } from "react";

import { PositiveAction } from "../../../../../components/card";
import { InvoiceFormContext, invoiceFormHasChanged, scrollToFirstError, useInvoiceForm, validateInvoiceForm } from "./InvoiceForm";

interface SubmitButtonProps {
    onSubmit: (form: InvoiceFormContext) => void;
}

export const SubmitButton = (props: SubmitButtonProps) => {
    const { onSubmit } = props;
    const { form, setForm } = useInvoiceForm();
    
    const hasChanged = invoiceFormHasChanged(form);
    
    const { validationAnimationDuration } = form;
    useEffect(() => {
        if ( validationAnimationDuration === 200 ) return;

        setForm((previousState): InvoiceFormContext => ({
            ...previousState,
            validationAnimationDuration: 200,
        }))
    }, [setForm, validationAnimationDuration]);

    const skipValidationAnimation = useCallback(() => {
        setForm((previousState): InvoiceFormContext => ({
            ...previousState,
            validationAnimationDuration: 0,
        }));
    }, [setForm]);

    const submit = useCallback(() => {
        const anyError = validateInvoiceForm(form);
        if ( anyError ) {
            skipValidationAnimation();
            setForm((previousState): InvoiceFormContext => ({
                ...previousState,
                serviceDateInput: {
                    ...previousState.serviceDateInput,
                    dirty: true,
                },
                serviceInputs: previousState.serviceInputs.map(serviceInput => ({
                    ...serviceInput,
                    descriptionInput: {
                        ...serviceInput.descriptionInput,
                        dirty: true,
                    },
                    durationInput: {
                        ...serviceInput.durationInput,
                        dirty: true,
                    },
                    toolsInput: {
                        ...serviceInput.toolsInput,
                        dirty: true,
                    },
                })),
                gardenerDiscountInput: {
                    ...previousState.gardenerDiscountInput,
                    dirty: true,
                },
                discountInput: {
                    ...previousState.discountInput,
                    dirty: true,
                },
            }));
            return scrollToFirstError(form);
        }

        onSubmit(form);
    }, [onSubmit, skipValidationAnimation, setForm, form]);

    return <PositiveAction disabled={!hasChanged} onClick={submit}>Udsted ny faktura</PositiveAction>;
};
