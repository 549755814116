import { Comment } from "../components/comment/Comment";
import { CommentBody } from "../components/comment/CommentBody";
import { GardenerAgreementAcceptedCommentDocument } from "./comment.model";

interface GardenerAgreementAcceptedCommentProps {
    comment: GardenerAgreementAcceptedCommentDocument;
}

export const GardenerAgreementAcceptedComment = (props: GardenerAgreementAcceptedCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Samarbejdsaftale accepteret">
            <CommentBody>Havemanden har accepteret en samarbejdsaftale som {comment.mode === "person" ? "privatperson" : "virksomhed"}.</CommentBody>
        </Comment>
    );
};
