import firebase from "firebase/compat/app";
import { useEffect, useState } from "react";

export const useCurrentUser = () => {
    const [state, setState] = useState<string | null>();
  
    useEffect(() => {
        return firebase.auth().onAuthStateChanged(user => {
            const userId = user ? user.uid : null;

            setState(userId);
        });
    }, []);
  
    return state;
};
