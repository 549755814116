import { HTMLAttributes } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        padding: "32px",
        paddingBottom: "98px", /* To be able to scroll past Snackbar */

        [theme.breakpoints.down(560)]: {
            paddingBottom: "66px", /* To be able to scroll past Snackbar */
        },

        [theme.breakpoints.down(500)]: {
            padding: "32px 16px",
        },
    },
}));

export const ContentSection = (props: HTMLAttributes<HTMLDivElement>) => {
    const classes = useStyles();

    const { className, ...otherProps } = props;

    return (
        <div
            className={className ? `${classes.root} ${className}` : classes.root}
            {...otherProps}
        />
    );
};
