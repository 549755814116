import { Comment } from "../../components/comment/Comment";
import { CustomerReactivatedCommentDocument } from "../comment.model";
import { CommentBody } from "../../components/comment/CommentBody";

interface CustomerReactivatedCommentProps {
    comment: CustomerReactivatedCommentDocument;
}

export const CustomerReactivatedComment = (props: CustomerReactivatedCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Kunde genaktiveret" author={comment.administratorName}>
            <CommentBody>
                Årsag til genaktivering:<br />
                {comment.reason}
            </CommentBody>
        </Comment>
    );
};
