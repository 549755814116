import { useCallback, useMemo } from "react";

import { ListItem as MaterialListItem, ListItemText, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    listItem: {
        padding: "12px 16px",

        "&:hover": {
            backgroundColor: "#EBF3EA",
        },

        "& > .MuiTouchRipple-root": {
            color: "#1C5B13",
        },
    },
    listItemText: {
        margin: 0,

        "& > span": {
            fontSize: "16px",
            fontWeight: 500,
            color: "#4A4A4A",
        },

        "& > p": {
            fontSize: "14px",
            fontWeight: 400,
            color: "#818181",
        },
    },
});

interface ListItemProps<Item extends any> {
    item: Item;
    formatPrimaryText: (item: Item) => string;
    formatSecondaryText: (item: Item) => string;
    onClick: (item: Item) => void;
}

export const ListItem = <Item extends any>(props: ListItemProps<Item>) => {
    const classes = useStyles();

    const { item, formatPrimaryText, formatSecondaryText, onClick } = props;

    const primaryText = useMemo(() => {
        return formatPrimaryText(item);
    }, [formatPrimaryText, item]);

    const secondaryText = useMemo(() => {
        return formatSecondaryText(item);
    }, [formatSecondaryText, item]);

    const handleClick = useCallback(() => {
        onClick(item);
    }, [onClick, item]);

    return (
        <MaterialListItem button className={classes.listItem} onClick={handleClick}>
            <ListItemText primary={primaryText} secondary={secondaryText} className={classes.listItemText} />
        </MaterialListItem>
    );
};
