import { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useTask } from "../../task/tasks.hook";
import { ComplaintTaskDocument } from "../../task/task.model";
import { NotFoundPage } from "../../NotFoundPage";
import { LinearProgress } from "../../components/LinearProgress";
import { ErrorPage } from "../../ErrorPage";
import { IntegrateContactCustomerDetailPage } from "./contact-customer/IntegratedContactCustomerDetailPage";
import { IntegratedContactGardenerDetailPage } from "./contact-gardener/IntegratedContactGardenerDetailPage";
import { IntegratedDetermineProposalDetailPage } from "./determine-proposal/IntegratedDetermineProposalDetailPage";
import { IntegratedAwaitCustomerResponseDetailPage } from "./await-customer-response/IntegratedAwaitCustomerResponseDetailPage";

export const IntegratedComplaintDetailPage = () => {
    const { tab, taskId } = useParams<{ tab: string, taskId: string }>();

    const history = useHistory();
    const goBack = useCallback(() => {
        history.push(`/complaint/${tab}`);
    }, [history, tab]);

    const task = useTask(taskId) as ComplaintTaskDocument;

    if ( task === null ) return <NotFoundPage />;
    if ( task === undefined ) return <LinearProgress />;

    if ( task.type === "complaint" && task.complaint.status === "contacting customer" ) return <IntegrateContactCustomerDetailPage task={task} goBack={goBack} />;
    if ( task.type === "complaint" && task.complaint.status === "contacting gardener" ) return <IntegratedContactGardenerDetailPage task={task} goBack={goBack} />;
    if ( task.type === "complaint" && task.complaint.status === "determining proposal" ) return <IntegratedDetermineProposalDetailPage task={task} goBack={goBack} />;
    if ( task.type === "complaint" && task.complaint.status === "awaiting customer response to proposal" ) return <IntegratedAwaitCustomerResponseDetailPage task={task} goBack={goBack} />;

    return <ErrorPage message="Opgavens type genkendes ikke" />;
};
