import firebase from "firebase/compat/app";

import { useEffect, useState } from "react";
import { ManualAdministratorProtectionDocument } from "./protection.model";
import { GuardrailsProtectionDocument } from "../evaluate-gardener/shared/guardrails-protection.model";

export const useManualAdministratorProtection = (gardenerId: string | null | undefined) => {
    const [state, setState] = useState<ManualAdministratorProtectionDocument | null>();

    useEffect(() => {
        if ( gardenerId === undefined ) return setState(undefined);
        if ( gardenerId === null ) return setState(null);

        setState(undefined);

        return firebase.firestore()
            .collection("protections")
                .where("type", "==", "manual-administrator")
                .where("gardenerId", "==", gardenerId)
            .onSnapshot(collection => {
                const document = collection.docs[0];
                if ( !document ) return setState(null);

                const protection = { id: document.id, ...document.data() } as ManualAdministratorProtectionDocument;

                setState(protection);
            });
    }, [gardenerId]);

    return state;
};

export const useGuardrailsProtection = (gardenerId: string | null | undefined) => {
    const [state, setState] = useState<GuardrailsProtectionDocument | null>();

    useEffect(() => {
        if ( gardenerId === undefined ) return setState(undefined);
        if ( gardenerId === null ) return setState(null);

        setState(undefined);

        return firebase.firestore()
            .collection("protections")
                .where("type", "==", "guardrails")
                .where("gardenerId", "==", gardenerId)
            .onSnapshot(collection => {
                const document = collection.docs[0];
                if ( !document ) return setState(null);

                const protection = { id: document.id, ...document.data() } as GuardrailsProtectionDocument;

                setState(protection);
            });
    }, [gardenerId]);

    return state;
};
