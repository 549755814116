import { useCallback, useRef } from "react";

import { Collapse } from "@material-ui/core";
import { da } from "date-fns/locale";
import moment from "moment";

import { AlternativeHint, DateField, Group } from "../../../../../components/form";
import { InvoiceFormContext, useInvoiceForm } from "./InvoiceForm";

interface ServiceDateInputProps {
    id?: string;
}

export const ServiceDateInput = (props: ServiceDateInputProps) => {
    const { id } = props;

    const { form, setForm } = useInvoiceForm();
    const { serviceDateInput } = form;

    const changeServiceDate = useCallback((serviceDate: string) => {
        setForm((previousState): InvoiceFormContext => ({
            ...previousState,
            serviceDateInput: {
                ...previousState.serviceDateInput,
                serviceDate,
            },
        }));
    }, [setForm]);

    const markServiceDateDirty = useCallback(() => {
        setForm((previousState): InvoiceFormContext => ({
            ...previousState,
            serviceDateInput: {
                ...previousState.serviceDateInput,
                dirty: true,
            },
        }));
    }, [setForm]);

    const minimumDate = useRef(moment(form.invoice.serviceDate).subtract(30, "days").toDate()).current;
    const maximumDate = useRef(new Date()).current;
    const error = validateServiceDateInput(form);

    return (
        <Group error={Boolean(serviceDateInput.dirty && error)}>
            <DateField id={id} value={serviceDateInput.serviceDate} onChange={changeServiceDate} onBlur={markServiceDateDirty} placeholder="DD-MM-YYYY" minimumDate={minimumDate} maximumDate={maximumDate} locale={da} />

            <Collapse in={Boolean(serviceDateInput.dirty && error)} timeout={form.validationAnimationDuration}>
                <AlternativeHint message={error} />
            </Collapse>
        </Group>
    );
}

export interface ServiceDateInputContext {
    serviceDate: string;
    dirty: boolean;
}

export const newServiceDateInputContext = (serviceDate: string): ServiceDateInputContext => ({
    serviceDate,
    dirty: false,
});

export const serviceDateInputHasChanged = (form: InvoiceFormContext) => {
    return form.invoice.serviceDate !== form.serviceDateInput.serviceDate;
};

export const validateServiceDateInput = (form: InvoiceFormContext) => {
    const serviceDate = moment(form.serviceDateInput.serviceDate, true);
    if ( !serviceDate.isValid() ) return "Skal være gyldig dato";

    if ( serviceDate.isBefore(moment(form.invoice.serviceDate).subtract(30, "days"), "day") ) return "Må ikke flyttes mere end 30 dage tilbage";
    if ( serviceDate.isAfter(moment(), "day") ) return "Må ikke være i fremtiden";

    return undefined;
};
    