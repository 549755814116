import { useCallback } from "react";

import { makeStyles } from "@material-ui/core";
import moment from "moment";

import { Card } from "../components/card/Card";
import { CardContent } from "../components/card/CardContent";
import { Address } from "../address.model";
import { GardenerDocument } from "./gardener.model";
import { Slider } from "../components/Slider";
import { GardenerApplicationDocument } from "../gardener-application/gardener-application.model";
import { CardAlert } from "../components/card/CardAlert";
import { formatTools } from "./gardener-utility";
import { CardHeader } from "../components/card/CardHeader";
import { formatDate } from "../date-utility";
import { useIsSuperAdministrator } from "../LinkProvider";
import { CardStatus } from "../components/card";
import { useConfiguration } from "../workflow/follow-up/appointment/TaskProvider";
import { formatInteger } from "../finance/billing/invoices/invoice-detail/utility";
import { calculateGardenerProfitPerTask, calculateProfitPerTask } from "../shared/utility";
import { Skill } from "../gardening-task/gardening-task.model";

const useStyles = makeStyles({
    group: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },
    },
    text: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",

        "& > a": {
            fontSize: "16px",
            fontWeight: 500,
            color: "#297A1D",
            textDecoration: "none",
    
            "&:hover": {
                color: "#1C5B13",
                textDecoration: "underline",
            },
        },
    },
    multiline: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        whiteSpace: "pre-wrap",
    },
});

interface GardenerDetailCardProps {
    gardener: GardenerDocument;
    application: GardenerApplicationDocument | null;
    button?: JSX.Element;
}

export const GardenerDetailCard = (props: GardenerDetailCardProps) => {
    const classes = useStyles();

    const { gardener, application, button } = props;

    const performanceCardStatus = usePerformanceCardStatus(gardener);

    const formatSkills = (skills: Skill[]) => {
        if ( skills.length === 0 ) return "Ikke angivet";

        const sortOrder: Skill[] = ["lawn-mowing", "weed-control", "hedge-trimming", "pruning-of-trees-and-shrubs", "disposal-of-garden-waste", "other-garden-services"];

        const string = skills
            .sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b))
            .map(skill => {
                switch ( skill ) {
                    case "lawn-mowing": return "græsslåning";
                    case "weed-control": return "ukrudtsbekæmpelse";
                    case "hedge-trimming": return "hækklipning";
                    case "pruning-of-trees-and-shrubs": return "beskæring";
                    case "disposal-of-garden-waste": return "bortkørsel";
                    case "other-garden-services": return "anden haveservice";
                    default: return "ukendt";
                }
            })
            .join(", ");
    
        return string.substring(0, 1).toUpperCase() + string.substring(1);
    };

    const formatServiceRadius = useCallback((value: number) => {
        return `${value} km`;
    }, [])

    return (
        <Card>

            {performanceCardStatus}

            <CardHeader button={button}>{gardener.name}</CardHeader>

            {gardener.status === "terminated" && gardener.terminationDate ? (
                <CardAlert>
                    <p>{formatDeletionText(gardener.terminationDate!)}</p>
                </CardAlert>
            ) : null}

            {gardener.protections.length > 0 ? (
                <CardAlert>
                    <p>Denne havemand er på pause</p>
                    <ul>
                        {gardener.protections.includes("manual-administrator") ? <li>Sat på pause af administrator</li> : null}
                        {gardener.protections.includes("manual-gardener") ? <li>Har sat sig selv på pause (havemanden kan selv ændre sin status)</li> : null}
                        {gardener.protections.includes("guardrails") ? <li>Har nået sin grænse for nye opgaver</li> : null}
                        {gardener.protections.includes("picky-gardener") ? <li>"Nej tak" til for mange opgaver (havemanden kan selv ændre sin status)</li> : null}
                        {gardener.protections.includes("no-reply") ? <li>“Intet svar” ved flere opgaver (havemanden kan selv ændre sin status)</li> : null}
                        {gardener.protections.includes("process") ? <li>Mangler at besvare opgaver på sin oversigt. Pausen fjernes, når havemanden besvarer sine opgaver.</li> : null}
                    </ul>
                </CardAlert>
            ) : null}

            <CardContent>

                <div className={classes.group}>
                    <label>Id</label>
                    <span className={classes.text}>{gardener.id}</span>
                </div>

                {gardener.cvr ? (
                    <div className={classes.group}>
                        <label>Virksomhed</label>
                        <span className={classes.text}>
                            <a href={`https://datacvr.virk.dk/enhed/virksomhed/${gardener.cvr}`} target="_blank" rel="noreferrer">
                                {gardener.companyName} (CVR: {gardener.cvr})
                            </a>
                        </span>
                    </div>
                ) : null}

                <div className={classes.group}>
                    <label>Adresse</label>
                    <span className={classes.text}>
                        <a href={gpsHref(gardener.address)} target="_blank" rel="noreferrer">
                            {gardener.address.street}<br />
                            {gardener.address.zipCode}
                        </a>
                    </span>
                </div>

                <div className={classes.group}>
                    <label>Email</label>
                    <span className={classes.text}>
                        <a href={`mailto:${gardener.email}`}>{gardener.email}</a>
                    </span>
                </div>

                <div className={classes.group}>
                    <label>Telefon</label>
                    <span className={classes.text}>
                        <a href={`tel:${gardener.phoneNumber}`}>{gardener.phoneNumber}</a>
                    </span>
                </div>

                <div className={classes.group}>
                    <label>Afstand til opgaver</label>
                    <Slider
                        value={gardener.taskAgent.serviceRadiusInKilometers}
                        valueLabelFormat={formatServiceRadius}
                        disabled
                        min={0}
                        max={100}
                        step={5}
                    />
                </div>

                {application ? (
                    <div className={classes.group}>
                        <label>Erfaring med havearbejde</label>
                        <span className={classes.multiline}>{application.experience}</span>
                    </div>
                ) : null}

                <div className={classes.group}>
                    <label>Kundskaber</label>
                    <span className={classes.multiline}>{formatSkills(gardener.taskAgent.skills)}</span>
                </div>

                <div className={classes.group}>
                    <label>Redskaber</label>
                    <span className={classes.multiline}>{formatTools(gardener.taskAgent.tools)}</span>
                </div>

            </CardContent>

        </Card>
    );
};

const usePerformanceCardStatus = (gardener: GardenerDocument) => {
    const configuration = useConfiguration();

    const isSuperAdministrator = useIsSuperAdministrator();
    if ( !isSuperAdministrator ) return null;

    const platformProfitPerTask = calculateProfitPerTask(configuration.taskAgent.uniqueTasks, configuration.taskAgent.profit) ?? 0;
    const gardenerProfitPerTask = calculateGardenerProfitPerTask(configuration, gardener.taskAgent);

    return <CardStatus
        color={gardener.taskAgent.uniqueTasks >= 10 ? "info" : "warning"}
        status={`Profit pr. opgave: ${formatInteger(gardenerProfitPerTask)} kr. (Gennemsnit: ${formatInteger(platformProfitPerTask)} kr.)`}
    />
}

function gpsHref(address: Address): string {
    const query = `${address.street}, ${address.zipCode}`;

    return encodeURI(`https://maps.google.com/?q=${query}`);
}

function formatDeletionText(terminationDate: string): string {
    const deletionDate = moment(terminationDate).add(3, "years").startOf("year").add(2, "months").toISOString(true);
    
    return `Slettes d. ${formatDate(deletionDate)}`;
}
