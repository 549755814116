import { useMemo } from "react";

import { PageFormItem, GroupFormItem, InfoFormItem, SingleChoiceFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { NoticeFormItem } from "../../components/form-item/client/form-item/NoticeFormItemComponent";
import { GuardrailsProtectionDocument, Record } from "../shared/guardrails-protection.model";
import moment from "moment";

export interface PartnershipOverviewFormIds {
    SingleChoiceAction: string;
}

export type PartnershipOverviewForm<NextPage extends FormItem> = PartnershipOverviewPage<NextPage>;

type PartnershipOverviewPage<NextPage extends FormItem> =
    PageFormItem<
        GroupFormItem<[
            GroupFormItem<NoticeFormItem[]>,
            SingleChoiceFormItem<[
                NextPage,
                ConfirmationPage
            ]>,
        ]>
    >
    | PageFormItem<
        GroupFormItem<[
            NoticeFormItem,
            NextPage,
        ]>
    >;

type ConfirmationPage = PageFormItem<InfoFormItem>

export const usePartnershipOverviewForm = <T extends FormItem>(protection: GuardrailsProtectionDocument, now: string, nextPage: T, formIds: PartnershipOverviewFormIds): PartnershipOverviewForm<T> => {
    return useMemo(() => {
        const action = protection.action as Extract<GuardrailsProtectionDocument["action"], { status: "call" | "determine action" }>;

        if ( action.status === "call" ) {
            return {
                type: "page",
                title: "Besvar opgave",
                item: {
                    type: "group",
                    render: "all at once",
                    items: [
                        {
                            type: "notice",
                            render: "info",
                            html: "En medarbejder har allerede vurderet, at vi skal tage en samtale med havemanden.",
                        },
                        nextPage,
                    ],
                },
            };
        }

        const terminateGardenerConfirmationPage: ConfirmationPage = {
            type: "page",
            title: "Ophør samarbejde",
            item: {
                type: "info",
                render: "default",
                text: `
                    Samarbejdet ophøres med havemanden med begrundelsen “samarbejdet fungerer ikke”.<br />
                    <br />
                    Havemanden informeres om, at samarbejdet er ophørt.<br />
                    <br />
                    Hvis havemanden stadig har nogen opgaver på sin liste, vil systemet automatisk lede efter en anden havemand til dem.
                `,
            },
        };

        const notices: NoticeFormItem[] = [];

        const onboardingRecord = protection.records.find(record => record.type === "onboarding");
        if ( onboardingRecord ) {
            notices.push({
                type: "notice",
                render: "info",
                html: `
                    <strong>Onboarded ${formatDate(onboardingRecord.date)}</strong>
                `,
            });
        }

        const recordsAfterIntroduction = protection.records.filter(record => record.date >= "2024-04-01");

        const warningDates = recordsAfterIntroduction.filter(record => record.type === "warning").map(x => x.date);

        for (let i = 0; i < warningDates.length; i++) {
            const contextDate = i > 0 ? warningDates[i - 1] : null;
            const warningDate = warningDates[i];

            notices.push({
                type: "notice",
                render: "info",
                html: `
                    <strong>Advarsel ${formatDate(warningDate)}</strong><br />
                    <br />
                    Baggrund for advarsel:
                    ${createSummary(recordsAfterIntroduction, contextDate, warningDate)}
                `,
            });
        }

        const lastWarningDate = warningDates.length > 0 ? warningDates[warningDates.length - 1] : null;
        notices.push({
            type: "notice",
            render: "warning",
            html: `
                <strong>Grænse nået ${formatDate(action.contextDate)}</strong><br />
                <br />
                Baggrund for at grænsen er nået:
                ${createSummary(recordsAfterIntroduction, lastWarningDate, now)}
            `,
        });
    
        const page: PartnershipOverviewPage<T> = {
            type: "page",
            title: "Besvar opgave",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "group",
                        render: "all at once",
                        items: notices,
                    },
                    {
                        id: formIds.SingleChoiceAction,
                        type: "single-choice",
                        mode: "switch page",
                        render: "vertical-buttons",
                        questionText: "Hvad vil du gerne foretage dig?",
                        answerTexts: [
                            "Tag en samtale med havemanden",
                            "Ophør samarbejde"
                        ],
                        answerItems: [
                            nextPage,
                            terminateGardenerConfirmationPage,
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                        destructive: true,
                    }
                ],
            },
        };
        
        return page;
    }, [protection, now, nextPage, formIds]);
}

function createSummary(records: Record[], fromDate: string | null, toDate: string): string {
    const relevantRecords = records.filter(x => x.date >= "2024-04-01").filter(x => (fromDate ? x.date >= fromDate : true) && x.date < toDate);

    const solvedTasks = relevantRecords.filter(x => x.type === "solved" || x.type === "disputed").length;
    const totalTasks = relevantRecords.filter(x => x.type === "disputed" || x.type === "solved" || x.type === "stalled" || x.type === "lost").length;
    const complaints = relevantRecords.filter(x => x.type === "disputed").length;

    const summaryItems = [`Kun <strong>${solvedTasks} ${formatTaskUnit(solvedTasks)}</strong> løst ud af <strong>${totalTasks} ${formatTaskUnit(totalTasks)}</strong> afsluttet`];
    if ( complaints > 0 ) summaryItems.push(`Modtaget <strong>${complaints} ${formatComplaintUnit(complaints)}</strong>`)

    return `
        <ul>
            ${summaryItems.map(item => `<li>${item}</li>`).join("")}
        </ul>
    `;
}

function formatDate(date: string): string {
    return moment(date).format("D. MMMM YYYY");
}

function formatTaskUnit(count: number): string {
    return count === 1 ? "opgave" : "opgaver";
}

function formatComplaintUnit(count: number): string {
    return count === 1 ? "reklamation" : "reklamationer";
}
