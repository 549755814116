import { useMemo } from "react";

import { PageFormItem, GroupFormItem, InfoFormItem, NoticeFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { CustomerDocument } from "../customer.model";

export type NotbannedForm<NextPage extends FormItem> = BannedPage<NextPage>;

type BannedPage<NextPage extends FormItem> = PageFormItem<
    GroupFormItem<[
        InfoFormItem,
        NoticeFormItem,
    ]>
> | NextPage;

export const useNotBannedForm = <T extends FormItem>(customer: CustomerDocument, nextPage: T): NotbannedForm<T> => {
    return useMemo(() => {    
        if ( !customer.banned ) return nextPage;
        
        const page: BannedPage<T> = {
            type: "page",
            title: "Vi ønsker ikke at servicere denne kunde",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Kunden har på et tidspunkt foretaget sig noget, der har medført, at vi ikke ønsker at servicere kunden.<br />
                            <br />
                            Du kan derfor ikke oprette en ny opgave for kunden.
                        `,
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            <strong>OBS:</strong> Undlad at fortælle kunden at vi ikke vil servicere dem og kontakt i stedet Marianne.
                        `,
                    },
                ],
            },
        };
        
        return page;
    }, [customer, nextPage]);
}
