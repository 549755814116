import { Link } from "react-router-dom";

import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { MatchRemovedCommentDocument } from "../comment.model";

export interface MatchRemovedCommentProps {
    comment: MatchRemovedCommentDocument;
}

export const MatchRemovedComment = (props: MatchRemovedCommentProps) => {
    const { comment } = props;

    const customer = <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link>;
    const gardener = <Link to={`/gardeners/context/${comment.gardenerId}`}>{comment.gardenerName}</Link>;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Tilknytning fjernet">
            <CommentBody>
                {customer} er ikke længere tilknyttet {gardener}.<br />
                <br />
                Årsag:<br />
                {comment.reason}
            </CommentBody>
        </Comment>
    );
};
