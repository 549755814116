import { TextField } from "../../../../../../components/form/InputField";
import { Service } from "../../../invoice.model";
import { ServiceInputContext, useServiceInput } from ".";
import { AlternativeHint, Group } from "../../../../../../components/form";
import { Collapse } from "@material-ui/core";
import { InvoiceFormContext, useInvoiceForm } from "../InvoiceForm";
import { validateMandatory } from "../../../../../../components/validation/validation";
import { useCallback } from "react";

interface DescriptionInputProps {
    index: number;
    id?: string;
}

export const DescriptionInput = (props: DescriptionInputProps) => {
    const { index, id } = props;

    const { form } = useInvoiceForm();
    const { serviceInput, setServiceInput } = useServiceInput(index);
    const { descriptionInput } = serviceInput;

    const updateDescription = useCallback((description: string) => {
        setServiceInput((previousState): ServiceInputContext => ({
            ...previousState,
            descriptionInput: {
                ...previousState.descriptionInput,
                description,
            },
        }))
    }, [setServiceInput]);

    const markDescriptionDirty = useCallback(() => {
        setServiceInput((previousState): ServiceInputContext => ({
            ...previousState,
            descriptionInput: {
                ...previousState.descriptionInput,
                dirty: true,
            },
        }))
    }, [setServiceInput]);

    const error = validateDescriptionInput(form, index);

    return (
        <Group error={Boolean(descriptionInput.dirty && error)}>
            <TextField id={id} value={descriptionInput.description} onChange={updateDescription} onBlur={markDescriptionDirty} />

            <Collapse in={Boolean(descriptionInput.dirty && error)} timeout={form.validationAnimationDuration}>
                <AlternativeHint message={error} />
            </Collapse>
        </Group>
    );
}

export interface DescriptionInputContext {
    description: string;
    dirty: boolean;
}

export const newDescriptionInputContext = (service: Service): DescriptionInputContext => ({
    description: service.description,
    dirty: false,
});

export const descriptionInputHasChanged = (form: InvoiceFormContext, index: number) => {
    if ( form.invoice.services.length <= index ) return true;

    return form.invoice.services[index].description !== form.serviceInputs[index].descriptionInput.description;
};

export const validateDescriptionInput = (form: InvoiceFormContext, index: number) => {
    return validateMandatory(form.serviceInputs[index].descriptionInput.description);
};
