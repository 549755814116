import { useMemo, useState } from "react";

import { makeStyles } from "@material-ui/core";

import { ContentSection } from "../layout/ContentSection";
import { ClusteredMap } from "../components/maps/clustering/ClusteredMap";
import { LinearProgress } from "../components/LinearProgress";
import { useActiveGardeners } from "../gardener/gardeners.hook";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        display: "flex",
    },
    container: {
        flexGrow: 1,
        maxWidth: "1366px",
        maxHeight: "768px",
        margin: "0 auto",
    },
});

export const DashboardPageGardenersTab = () => {
    const classes = useStyles();

    const gardeners = useActiveGardeners();

    const markers = useMemo(() => {
        if ( !gardeners ) return undefined;

        return gardeners.map(gardener => {
            const location = { lat: gardener.address.coordinates.latitude, lng: gardener.address.coordinates.longitude };

            return { href: `/gardeners/context/${gardener.id}`, label: gardener.name, position: location };
        });
    }, [gardeners]);

    const [startingView] = useState(() => {
        return {
            zoom: 7, center: { lat: 55.94813677916688, lng: 10.519916904259285 },
        };
    });

    if ( !markers ) return <LinearProgress />;

    return (
        <ContentSection className={classes.root}>

            <div className={classes.container}>
                <ClusteredMap
                    apiKey="AIzaSyAjye7kZjCsDknd-f8byYp2C6itdDfZl_o"
                    zoom={startingView.zoom}
                    center={startingView.center}
                    markers={markers}
                />
            </div>

        </ContentSection>
    );
};
