import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        padding: "0 !important",
        width: "100%",
    },
});

export const ResponsiveGridBreak = () => {
    const classes = useStyles();

    return (
        <Grid item className={classes.root} />
    );
};
