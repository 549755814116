import { Container, makeStyles } from "@material-ui/core";
import moment from "moment";

import { ContentSection } from "../../../layout/ContentSection";
import { LinearProgress } from "../../../components/LinearProgress";
import { SectionHeader } from "../../../components/section/SectionHeader";
import { Section } from "../../../components/section";
import { useMemo } from "react";
import { GardenerDocument } from "../../gardener.model";
import { useTerminatedGardeners } from "../../gardeners.hook";
import { ListItem } from "./ListItem";
import { Message } from "../../../Message";

const useStyles = makeStyles({
    list: {
        "& > *": {
            marginBottom: "32px",
        },

        "& > *:last-child": {
            marginBottom: 0,
        },
    },
});

export const GardenerListPageTerminatedTab = () => {
    const classes = useStyles();

    const gardenersByTerminationDate = useTerminatedGardenersGroupedByDay();

    if ( gardenersByTerminationDate === undefined ) return <LinearProgress />;

    return (
        <ContentSection>
            <Container maxWidth="sm" disableGutters className={classes.list}>

                {gardenersByTerminationDate.length === 0 ? (
                    <Message>Der er ingen ophørte havemænd</Message>
                ) : null}

                {gardenersByTerminationDate.map(({ day, gardeners }) => (
                    <Section key={day}>
                        <SectionHeader>{formatDate(day)}</SectionHeader>

                        {gardeners.map(gardener => (
                            <ListItem key={gardener.id} gardener={gardener} />
                        ))}
                    </Section>
                ))}

            </Container>
        </ContentSection>
    );
};

function formatDate(date: string): string {
    const text = moment(date).format("dddd D. MMMM YYYY");

    return text.substring(0, 1).toUpperCase() + text.substring(1);
}

function useTerminatedGardenersGroupedByDay() {
    const gardeners = useTerminatedGardeners();

    return useMemo(() => {
        if ( !gardeners ) return undefined;

        const dayMap: { [day: string]: GardenerDocument[] } = {};

        gardeners.forEach(gardener => {
            const day = moment(gardener.terminationDate).startOf("day").toISOString();

            const gardenersForDay = dayMap[day] || [];
            gardenersForDay.push(gardener);

            dayMap[day] = gardenersForDay;
        })

        return Object.keys(dayMap).sort().reverse().map(day => {
            const gardenersForDay = dayMap[day].sort((a, b) => {
                if ( a.terminationDate! < b.terminationDate! ) return -1;
                if ( a.terminationDate! > b.terminationDate! ) return 1;
                return 0;
            }).reverse();

            return { day, gardeners: gardenersForDay };
        });

    }, [gardeners])
};
