import { PropsWithChildren } from "react";

import { Button, ButtonProps, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        padding: "12px 8px",
        backgroundColor: "#2FA52B",
        color: "#FFFFFF",
        textTransform: "none",
        lineHeight: "125%",

        "&:hover": {
            backgroundColor: "#238221",
        },
    },
});

const useButtonStyles = makeStyles({
    disabled: {
        backgroundColor: "#818181",
        color: "#FFFFFF !important",
    },
});

interface PositiveActionProps {
    onClick: () => void;
}

export const PositiveAction = (props: PropsWithChildren<PositiveActionProps & Pick<ButtonProps, "disabled">>) => {
    const classes = useStyles();
    const buttonClasses = useButtonStyles();

    return (
        <Button className={classes.root} classes={buttonClasses} {...props} />
    );
};
