import { useMemo } from "react";

import { PageFormItem, GroupFormItem, InfoFormItem, HorizontalButtonsSingleChoiceFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { CloseComplaintForm } from "./CloseComplaintForm";

export interface ComplaintStillRelevantFormIds {
    SingleChoiceStillRelevant: string;
}

export type ComplaintStillRelevantForm<NextPage extends FormItem> = 
    PageFormItem<
        GroupFormItem<[
            InfoFormItem,
            HorizontalButtonsSingleChoiceFormItem<[
                CloseComplaintForm,
                NextPage,
            ]>
        ]>
    >

export const useComplaintStillRelevantForm = <T extends FormItem>(closeComplaintForm: CloseComplaintForm, nextPage: T, formIds: ComplaintStillRelevantFormIds): ComplaintStillRelevantForm<T> => {
    return useMemo(() => {
        return {
            type: "page",
            title: "Er reklamationen stadig aktuel?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Det kan ske, at kunden eller havemanden informerer os om at reklamationen er blevet løst sideløbende med vores håndtering.<br />
                            <br />
                            Er reklamationen blevet løst i mellemtiden, kan vi afslutte den.
                        `
                    },
                    {
                        id: formIds.SingleChoiceStillRelevant,
                        type: "single-choice",
                        mode: "switch page",
                        render: "horizontal-buttons",
                        questionText: "Reklamerer kunden stadig?",
                        answerTexts: ["Nej", "Ja"],
                        answerItems: [
                            closeComplaintForm,
                            nextPage,
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                    }
                ],
            },
        };
    }, [closeComplaintForm, nextPage, formIds]);
}
