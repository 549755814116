import { useMemo } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";

import moment from "moment";

import { TitleHeader } from "../../components/TitleHeader";
import { TabBar } from "../../components/tabs/TabBar";
import { useOnboardingTasks } from "../../workflow/follow-up/appointment/TaskProvider";
import { TabBarItem } from "../../components/tabs/TabBarItem";
import { IntegratedApplicationTab } from "./IntegratedApplicationTab";
import { IntegratedInterviewTab } from "./IntegratedInterviewTab";

export const IntegratedOnboardingOverviewPage = () => {
    const { tab } = useParams<{ tab: "application" | "interview" }>();

    const tabIndex = useMemo(() => {
        switch ( tab ) {
            case "application": return 0;
            case "interview": return 1;

            default: return 0;
        }
    }, [tab]);

    const now = moment().toISOString(true);

    const onboardingTasks = useOnboardingTasks();
    const applicationTasks = useMemo(() => onboardingTasks.filter(task => task.application.status === "accepted" && task.performAfterDate <= now), [onboardingTasks, now]);
    const interviewTasks = useMemo(() => onboardingTasks.filter(task => task.application.status === "checked" && task.performAfterDate <= now), [onboardingTasks, now]);

    if ( tab !== "application" && tab !== "interview" ) return <Redirect to="/onboarding/application" />;

    return (
        <>
            <TitleHeader>Onboarding</TitleHeader>

            <TabBar index={tabIndex}>
                <TabBarItem href="/onboarding/application">{formatTitle("Ansøgning", applicationTasks.length)}</TabBarItem>
                <TabBarItem href="/onboarding/interview">{formatTitle("Samtale", interviewTasks.length)}</TabBarItem>
            </TabBar>

            <Switch>

                <Route exact path="/onboarding/application">
                    <IntegratedApplicationTab />
                </Route>

                <Route exact path="/onboarding/interview">
                    <IntegratedInterviewTab />
                </Route>

            </Switch>
        </>
    );
};

function formatTitle(text: string, count: number): string {
    if ( count === 0 ) return text;

    return `${text} (${count})`;
};
