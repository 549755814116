import { Comment } from "../components/comment/Comment";
import { CommentBody } from "../components/comment/CommentBody";

import { CommentDocument } from "./comment.model";

interface UnknownCommentProps {
    comment: CommentDocument;
}

export const UnknownComment = (props: UnknownCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Ukendt kommentar">
            <CommentBody>Kommentarens type genkendes ikke</CommentBody>
        </Comment>
    );
};
