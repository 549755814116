import { useCallback } from "react";

import { MenuItem } from "../../../../../components/menu/MenuItem";
import { FormProps } from "../../../../../components/form-item/framework/react/FormProvider";
import { SingleChoiceFormItem, TextAreaFormItem } from "../../../../../components/form-item/client/components";
import { DineroContactDocument } from "../../../pending-approval/dinero-contact.model";
import { InvoiceDocument } from "../../invoice.model";
import { ReissueInvoiceData } from "./reissue-invoice.function";
import { useCannotReissueOrResendInvoiceDialog } from "./CannotReissueOrResendInvoiceDialog";
import { ResendInvoiceData } from "./resend-invoice.function";
import { ReissueOrResendInvoiceForm, ReissueOrResendInvoiceFormIds, useReissueOrResendInvoiceDialog } from "./ReissueOrResendInvoiceDialog";
import { ReissueIcon } from "./ReissueIcon";
import { hasContactInformationChanged, hasCustomerInformationChanged } from "./reissue-invoice.utility";
import { FormItem } from "../../../../../components/form-item/framework/core/form-item.model";

export interface ReissueOrResendInvoiceButtonProps {
    invoice: InvoiceDocument;
    contact: DineroContactDocument;
    reissueInvoiceFunction: (data: ReissueInvoiceData) => void;
    resendInvoiceFunction: (data: ResendInvoiceData) => void;
}

export const ReissueOrResendInvoiceButton = (props: ReissueOrResendInvoiceButtonProps) => {
    const { invoice, contact, reissueInvoiceFunction, resendInvoiceFunction, ...otherProps } = props;

    const openCannotReissueOrResendInvoiceDialog = useCannotReissueOrResendInvoiceDialog();

    const reissueOrResendInvoiceAdapter = useCallback<FormProps<ReissueOrResendInvoiceForm>["onSubmit"]>((controller, item) => {
        const selectedAction = (() => {
            const selectedActionIndex = (controller.getItem(ReissueOrResendInvoiceFormIds.SingleChoiceAction, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex;
            if ( selectedActionIndex === null ) return null;
    
            const actions = ["reissue invoice" as const, "resend invoice" as const];

            return actions[selectedActionIndex];
        })();

        if ( selectedAction === "reissue invoice" ) {
            const customerInformationHasChanged = hasCustomerInformationChanged(invoice, contact);
            if ( !customerInformationHasChanged ) return "customer information has not changed";

            const reason = (controller.getItem(ReissueOrResendInvoiceFormIds.TextAreaReissueInvoiceReason, item) as TextAreaFormItem).value;
            return reissueInvoiceFunction({
                invoiceId: invoice.id,
                reason,
            });
        }

        if ( selectedAction === "resend invoice" ) {
            const contactInformationhasChanged = hasContactInformationChanged(invoice, contact);
            if ( !contactInformationhasChanged ) return "contact information has not changed";

            const reason = (controller.getItem(ReissueOrResendInvoiceFormIds.TextAreaResendInvoiceReason, item) as TextAreaFormItem).value;
            return resendInvoiceFunction({
                invoiceId: invoice.id,
                reason,
            });
        }

        return "expected selected action";

    }, [reissueInvoiceFunction, resendInvoiceFunction, invoice, contact]);
    const openReissueOrResendInvoiceDialog = useReissueOrResendInvoiceDialog(invoice, contact, reissueOrResendInvoiceAdapter);

    const reissueInvoice = useCallback(() => {
        const customerInformationHasChanged = hasCustomerInformationChanged(invoice, contact);
        const contactInformationHasChanged = hasContactInformationChanged(invoice, contact);
        if ( !customerInformationHasChanged && !contactInformationHasChanged ) return openCannotReissueOrResendInvoiceDialog();
        
        openReissueOrResendInvoiceDialog();
    }, [openCannotReissueOrResendInvoiceDialog, openReissueOrResendInvoiceDialog, invoice, contact]);

    return <MenuItem icon={<ReissueIcon />} onClick={reissueInvoice} {...otherProps}>Genudsted/gensend faktura</MenuItem>;
}
