import { useEffect, useState } from "react";

import firebase from "firebase/compat/app";

import { EventDocument } from "./event.model";

export const useEventsByTypeInTimePeriod = (type: EventDocument["type"], fromDate: string, toDate: string) => {
    const [state, setState] = useState<EventDocument[]>();

    useEffect(() => {
        setState(undefined);

        return firebase.firestore()
            .collection("events")
                .where("type", "==", type)
                .where("creationDate", ">=", fromDate)
                .where("creationDate", "<", toDate)
            .onSnapshot(collection => {
                const documents = collection.docs.map(document => ({ id: document.id, ...document.data() } as EventDocument));

                setState(documents);
            });
    }, [type, fromDate, toDate]);

    return state;
};
