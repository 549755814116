import { Comment } from "../components/comment/Comment";
import { OrderStalledCommentDocument } from "./comment.model";
import { CommentBody } from "../components/comment/CommentBody";

interface OrderStalledCustomerCommentProps {
    comment: OrderStalledCommentDocument;
}

export const OrderStalledCustomerComment = (props: OrderStalledCustomerCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Kunde stalled">
            <CommentBody>
                Kunden er blevet stalled.
            </CommentBody>
        </Comment>
    );
};
