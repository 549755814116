import { Section } from "../../../../components/section";
import { TakePaymentDetailCard } from "./TakePaymentDetailCard";
import { Message } from "../../../../Message";
import { TakePaymentTaskDocument } from "../../../../task/task.model";

interface TakePaymentTaskContextProps {
    task: TakePaymentTaskDocument;
}

export const TakePaymentTaskContext = (props: TakePaymentTaskContextProps) => {
    const { task } = props;

    return (
        <Section>
            {task.hasBeenPerformed ? <Message>Opgaven er allerede blevet udført</Message> : null}

            <TakePaymentDetailCard task={task} />
        </Section>
    );
};
