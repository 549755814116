import { Link } from "react-router-dom";

import { InformationOutputCommentDocument } from "./comment.model";
import { Comment } from "../components/comment/Comment";
import { CommentBody } from "../components/comment/CommentBody";

interface InformationOutputCommentProps {
    comment: InformationOutputCommentDocument;
}

export const InformationOutputComment = (props: InformationOutputCommentProps) => {
    const { comment } = props;

    const source = (() => {
        if ( comment.data.scenario === "looking for gardener" ) return "kunde";
        if ( comment.data.scenario === "cannot find gardener" ) return "kunde";
        if ( comment.data.scenario === "abort gardening task" ) return "kunde";
        if ( comment.data.scenario === "looking into duplicate task" ) return "direktion";
    })();

    const title = `Besked sendt til ${source}`;

    const customer = <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link>;
    const message = (() => {
        switch ( comment.data.scenario ) {
            case "looking for gardener": return <>Vi har sendt {customer} en email, som fortæller at vi vil lede efter en havemand.</>;
            case "looking into duplicate task": return <>Direktionen er blevet bedt vurdere hvilken af kundens to samtidige opgaver, der skal findes en havemand til.</>;
            case "cannot find gardener": return <>Vi har sendt {customer} en email, som fortæller at vi ikke kunne finde en havemand.</>;
            case "abort gardening task": return <>Vi har sendt {customer} en email, som fortæller at vi har lukket opgaven og ikke længere leder efter en havemand.</>;
        }
    })();

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title={title}>
            <CommentBody>

                {message}

            </CommentBody>
        </Comment>
    );
};
