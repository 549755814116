import { PageFormItem, InfoFormItem, useFormDialog } from "../../components/form-item/client/components";
import { FormProps } from "../../components/form-item/framework/react/FormProvider";
import { CustomerDocument } from "../customer.model";
import { NotbannedForm, useNotBannedForm } from "./NotBannedForm";
import { SpecifyAddressForm, useSpecifyAddressForm } from "./SpecifyAddressForm";
import { SpecifyDescriptionForm, useSpecifyDescriptionForm } from "./SpecifyDescriptionForm";
import { SpecifyDurationForm, useSpecifyDurationForm } from "./SpecifyDurationForm";
import { SpecifyFindGardenerStrategyForm, useSpecifyFindGardenerStrategyForm } from "./SpecifyFindGardenerStrategyForm";
import { SpecifyFrequencyForm, useSpecifyFrequencyForm } from "./SpecifyFrequencyForm";
import { SpecifyRequiredSkillsForm, useSpecifyRequiredSkillsForm } from "./SpecifyRequiredSkillsForm";
import { SpecifyToolsForm, useSpecifyToolsForm } from "./SpecifyToolsForm";

export enum CreateGardeningTaskFormIds {
    AddressPickerAddress = "AddressPickerAddress",
    MultiChoiceRequiredSkills = "MultiChoiceRequiredSkills",
    TextAreaDescription = "TextAreaDescription",
    SingleChoiceTools = "SingleChoiceTools",
    SingleChoiceDuration = "SingleChoiceDuration",
    SingleChoiceFrequency = "SingleChoiceFrequency",
    SingleChoiceFindGardenerStrategy = "SingleChoiceFindGardenerStrategy",
    GardenerPickerGardener = "GardenerPickerGardener",
    TextAreaReason = "TextAreaReason",
}

export type CreateGardeningTaskForm =
    NotbannedForm<
        SpecifyAddressForm<
            SpecifyRequiredSkillsForm<
                SpecifyDescriptionForm<
                    SpecifyToolsForm<
                        SpecifyDurationForm<
                            SpecifyFrequencyForm<
                                SpecifyFindGardenerStrategyForm<
                                    ConfirmationPage
                                >
                            >
                        >
                    >
                >
            >
        >
    >

type ConfirmationPage = PageFormItem<InfoFormItem>

export const useCreateGardeningTaskDialog = (customer: CustomerDocument, onConfirm: FormProps<CreateGardeningTaskForm>["onSubmit"]) => {
    const confirmationPage: ConfirmationPage = {
        type: "page",
        title: "Opret opgave",
        item: {
            type: "info",
            render: "default",
            text: `
                Opgaven bliver oprettet.<br />
                <br />
                Systemet går i gang med at finde en havemand til opgaven.<br />
                <br />
                Kunden informeres om at opgaven er blevet oprettet.
            `,
        },
    };

    const specifyFindGardenerStrategyForm = useSpecifyFindGardenerStrategyForm(confirmationPage, {
        SingleChoiceFindGardenerStrategy: CreateGardeningTaskFormIds.SingleChoiceFindGardenerStrategy,
        GardenerPickerGardener: CreateGardeningTaskFormIds.GardenerPickerGardener,
        TextAreaReason: CreateGardeningTaskFormIds.TextAreaReason,
    });

    const specifyFrequencyForm = useSpecifyFrequencyForm(specifyFindGardenerStrategyForm, {
        SingleChoiceFrequency: CreateGardeningTaskFormIds.SingleChoiceFrequency,
    });

    const specifyDurationForm = useSpecifyDurationForm(specifyFrequencyForm, {
        SingleChoiceDuration: CreateGardeningTaskFormIds.SingleChoiceDuration,
    });

    const specifyToolsForm = useSpecifyToolsForm(specifyDurationForm, {
        SingleChoiceTools: CreateGardeningTaskFormIds.SingleChoiceTools,
    });

    const specifyDescriptionForm = useSpecifyDescriptionForm(specifyToolsForm, {
        TextAreaDescription: CreateGardeningTaskFormIds.TextAreaDescription,
    });

    const specifyRequiredSkillsForm = useSpecifyRequiredSkillsForm(specifyDescriptionForm, {
        MultiChoiceRequiredSkills: CreateGardeningTaskFormIds.MultiChoiceRequiredSkills,
    });

    const specifyAddressForm = useSpecifyAddressForm(customer, specifyRequiredSkillsForm, {
        AddressPickerAddress: CreateGardeningTaskFormIds.AddressPickerAddress,
    });

    const notBannedForm = useNotBannedForm(customer, specifyAddressForm);

    return useFormDialog(notBannedForm, onConfirm);
};
