import { makeStyles } from "@material-ui/core";

import { SwitchItem } from "./SwitchItem";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",

        "& > *": {
            flexBasis: "50%",
            marginRight: "24px",
        },

        "& > *:last-child": {
            marginRight: 0,
        },

        [theme.breakpoints.down(675)]: {
            flexDirection: "column",

            "& > *": {
                flexBasis: "100%",
                marginRight: 0,
                marginBottom: "16px",
            },
    
            "& > *:last-child": {
                marginBottom: 0,
            },
        },
    },
}));

interface TwoOptionSwitchProps<T> {
    options: Array<{ label: string, value: T }>;
    value: T;
    onChange: (value: T) => void;
}

export const TwoOptionSwitch = <T extends unknown>(props: TwoOptionSwitchProps<T>) => {
    const classes = useStyles();

    const { options, value, onChange } = props;

    return (
        <div className={classes.root}>
            {options.map((option, index) => {
                const on = option.value === value;

                return <SwitchItem key={index} on={on} item={option} onToggle={onChange} />;
            })}
        </div>
    );
};
