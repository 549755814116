import { Typography, Paper, makeStyles } from "@material-ui/core";
import { PropsWithChildren } from "react";

const useStyles = makeStyles({
    root: {
        padding: "16px",
        textAlign: "center",
    },
});

export const Message = (props: PropsWithChildren<{}>) => {
    const classes = useStyles();

    const { children } = props;

    return (
        <Paper className={classes.root}>
            <Typography>{children}</Typography>
        </Paper>
    );
};
