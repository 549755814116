import { useMemo, useRef, useState } from "react";

import { makeStyles } from "@material-ui/core";
import moment from "moment";

import { ContentSection } from "../layout/ContentSection";
import { useCustomersById } from "../customer/customers.hook";
import { useEventsByTypeInTimePeriod } from "../event/event.hook";
import { GardeningTaskClosedEventDocument } from "../event/event.model";
import { ClusteredMap } from "../components/maps/clustering/ClusteredMap";
import { LinearProgress } from "../components/LinearProgress";
import { useLastValue } from "../last-value.hook";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        display: "flex",
    },
    container: {
        flexGrow: 1,
        maxWidth: "1366px",
        maxHeight: "768px",
        margin: "0 auto",
    },
});

export const DashboardPageDeadZonesTab = () => {
    const classes = useStyles();

    const customers = useRelevantCustomers();

    const markers = useMemo(() => {
        if ( !customers ) return undefined;

        return customers.map(customer => {
            const location = { lat: customer.address.coordinates.latitude, lng: customer.address.coordinates.longitude };

            return { href: `/customers/deleted/${customer.id}`, label: customer.name, position: location };
        });
    }, [customers]);

    const [startingView] = useState(() => {
        return {
            zoom: 7, center: { lat: 55.94813677916688, lng: 10.519916904259285 },
        };
    });

    if ( !markers ) return <LinearProgress />;

    return (
        <ContentSection className={classes.root}>

            <div className={classes.container}>
                <ClusteredMap
                    apiKey="AIzaSyAjye7kZjCsDknd-f8byYp2C6itdDfZl_o"
                    zoom={startingView.zoom}
                    center={startingView.center}
                    markers={markers}
                />
            </div>

        </ContentSection>
    );
};

function useRelevantCustomers() {
    const now = useRef(moment().toISOString(true)).current;
    const twoWeeksAgo = moment(now).subtract(14, "days").toISOString(true);
    const gardeningTaskClosedEvents = useEventsByTypeInTimePeriod("gardening-task-closed", twoWeeksAgo, now) as GardeningTaskClosedEventDocument[] | undefined;
    const relevantGardeningTaskClosedEvents = useLastValue(gardeningTaskClosedEvents);

    const customerIds = useMemo(() => {
        if ( !relevantGardeningTaskClosedEvents ) return undefined;

        return Array.from(new Set(relevantGardeningTaskClosedEvents.map(x => x.customerId)));
    }, [relevantGardeningTaskClosedEvents]);

    return useCustomersById(customerIds);
}
