import { Redirect, useParams } from "react-router-dom";

import { LinearProgress } from "../components/LinearProgress";
import { useCustomer } from "./customers.hook";

export const RedirectToCustomerWithAppropriateContext = () => {
    const { customerId } = useParams<{ customerId: string }>();

    const customer = useCustomer(customerId);

    const context: "all" | "deleted" | undefined = (() => {
        if ( customer === undefined ) return undefined;
        if ( customer === null ) return "deleted";

        return customer.status !== "deleted" ? "all" : "deleted";
    })();

    if ( context === undefined ) return <LinearProgress />;

    return <Redirect to={`/customers/${context}/${customerId}`} />;
};
