import { NeutralAction, PositiveAction } from "../components/card";
import { Dialog, DialogActions, DialogContent, DialogHeader, useDialog } from "../components/dialog";

interface DeleteCommentDialogProps {
    onConfirm: () => void;
}

export const DeleteCommentDialog = (props: DeleteCommentDialogProps) => {
    const { onConfirm } = props;

    const { closeDialog } = useDialog();

    return (
        <Dialog>
            <DialogHeader>Slet kommentar?</DialogHeader>

            <DialogContent>
                Dette kan ikke fortrydes.
            </DialogContent>

            <DialogActions>
                <NeutralAction onClick={closeDialog}>Annuller</NeutralAction>
                <PositiveAction onClick={onConfirm}>Ja, slet kommentar</PositiveAction>
            </DialogActions>

        </Dialog>
    );
};
