import { ContentSection } from "../layout/ContentSection";
import { CustomerMetricsSection } from "./CustomerMetricsSection";
import { GardenerMetricsSection } from "./GardenerMetricsSection";
import { LostCustomerMetricsSection } from "./LostCustomersMetricsSection";

export const DashboardPageOverviewTab = () => {
    return (
        <ContentSection>

            <CustomerMetricsSection />
            <LostCustomerMetricsSection />
            <GardenerMetricsSection />

        </ContentSection>
    );
};
