import { useCallback } from "react";

import { NeutralAction } from "../../../components/card/NeutralAction";
import { PositiveAction } from "../../../components/card/PositiveAction";
import { Dialog, DialogActions, DialogContent, DialogHeader, useDialog } from "../../../components/dialog";

export interface DidNotReachCustomerDialogProps {
    onConfirm: () => void;
    onCancel: () => void;
}

export const DidNotReachCustomerDialog = (props: DidNotReachCustomerDialogProps) => {
    const { onCancel, onConfirm } = props;

    return (
        <Dialog>
            <DialogHeader>Jeg fik ikke fat i kunden</DialogHeader>

            <DialogContent>
                Kunden sendes videre i opkrævningsforløbet.
            </DialogContent>

            <DialogActions>
                <NeutralAction onClick={onCancel}>Annuller</NeutralAction>
                <PositiveAction onClick={onConfirm}>Bekræft</PositiveAction>
            </DialogActions>

        </Dialog>
    );
};

export const useDidNotReachCustomerDialog = (onConfirm: DidNotReachCustomerDialogProps["onConfirm"]) => {
    const { openDialog, closeDialog } = useDialog();

    const confirm = useCallback(() => {
        closeDialog();

        onConfirm();
    }, [closeDialog, onConfirm]);

    return useCallback(() => {
        openDialog(<DidNotReachCustomerDialog onConfirm={confirm} onCancel={closeDialog} />);
    }, [openDialog, closeDialog, confirm]);
};
