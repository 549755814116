import { makeStyles } from "@material-ui/core";

import { Card } from "../../../../components/card/Card";
import { LegacyCardContent } from "../../../../components/card/CardContent";
import { CardHeader } from "../../../../components/card/CardHeader";
import { Link } from "react-router-dom";
import { formatDateAccordingToHours } from "../../../../date-utility";
import { TakePaymentTaskDocument } from "../../../../task/task.model";

const useStyles = makeStyles({
    group: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },

        "& > span": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
            lineHeight: "125%",
            whiteSpace: "pre-wrap",

            "& > a": {
                fontSize: "16px",
                fontWeight: 500,
                color: "#297A1D",
                textDecoration: "none",
        
                "&:hover": {
                    color: "#1C5B13",
                    textDecoration: "underline",
                },
            },
        },
    },
    date: {
        whiteSpace: "nowrap",
    },
    order: {
        "& > *": {
            marginBottom: "16px",
        },

        "& > *:last-child": {
            marginBottom: 0,
        }
    },
});

interface TakePaymentDetailCardProps {
    task: TakePaymentTaskDocument;
}

export const TakePaymentDetailCard = (props: TakePaymentDetailCardProps) => {
    const classes = useStyles();

    const { task } = props;

    return (
        <Card>
            <CardHeader>{task.customerName}, faktura {task.invoiceNumber}, træk betaling</CardHeader>

            <LegacyCardContent className={classes.order}>

                <div className={classes.group}>
                    <span>
                        <Link to={`/customers/context/${task.customerId}`}>{task.customerName}</Link> er tilmeldt automatisk betaling.<br />
                        <br />
                        Der trækkes betaling for <Link to={`/invoices/all/invoice/${task.invoiceId}`}>faktura {task.invoiceNumber}</Link> på forfaldsdatoen <span className={classes.date}>d. {formatDateAccordingToHours(task.performAfterDate, 8, 8)}</span>.
                    </span>
                </div>

            </LegacyCardContent>

        </Card>
    );
};
