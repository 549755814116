import { Comment } from "../components/comment/Comment";
import { CommentBody } from "../components/comment/CommentBody";
import { EvaluateGardenerCommentDocument } from "../comment/comment.model";
import { Link } from "react-router-dom";


interface EvaluateGardenerCommentProps {
    comment: EvaluateGardenerCommentDocument;
}

export const EvaluateGardenerComment = (props: EvaluateGardenerCommentProps) => {
    const { comment } = props;

    const gardener = <Link to={`/gardeners/context/${comment.gardenerId}`}>{comment.gardenerName}</Link>;

    const title = (() => {
        switch ( comment.data.event ) {
            case "called gardener": return "Vi fik ikke fat i havemand ift. samarbejde";
            case "issued warning to gardener": return "Advarsel givet ift. samarbejde";
        }
    })();

    const message = (() => {
        switch ( comment.data.event ) {
            case "called gardener":
                switch ( comment.data.count ) {
                    case 1: return <>Vi har ringet til havemanden {gardener} én gang, men fik ikke fat i dem. Vi vil forsøge to gange til.</>;
                    case 2: return <>Vi har ringet til havemanden {gardener} to gange, men fik ikke fat i dem. Vi vil forsøge én gang til.</>;
                    default: return <>Vi har ringet til havemanden {gardener} tre gange, men fik ikke fat i dem. Vi vil ikke forsøge igen.</>;
                }
            case "issued warning to gardener":
                return (
                    <>
                        Havemanden er løbet tør for opgaver, men efter en samtale med havemanden, har vi besluttet at give samarbejdet en chance til.<br />
                        <br />
                        Havemanden får derfor mulighed for at sige ja tak til 3 yderligere opgaver.
                    </>
                );
        }
    })();

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title={title} author={comment.administratorName}>
            <CommentBody>
                {message}
            </CommentBody>
        </Comment>
    );
};
