import { useMemo } from "react";

import { PageFormItem, InfoFormItem, GroupFormItem, TextAreaFormItem, NoticeFormItem } from "../../components/form-item/client/components";
import { ComplaintDocument } from "../complaint.model";

export interface OfferProposalFormIds {
    TextAreaProblem: string;
    TextAreaProposal: string;
}

export type OfferProposalForm =
    PageFormItem<
        GroupFormItem<[
            NoticeFormItem,
            TextAreaFormItem,
            TextAreaFormItem,
            ConfirmationPage,
        ]>
    >

type ConfirmationPage = PageFormItem<InfoFormItem>;

export const useOfferProposalForm = (complaint: ComplaintDocument, formIds: OfferProposalFormIds): OfferProposalForm => {
    return useMemo(() => {

        const confirmationPage: ConfirmationPage = {
            type: "page",
            title: "Tilbyd løsning til kunde",
            item: {
                type: "info",
                render: "default",
                text: `
                    Opgaven afsluttes.<br />
                    <br />
                    Kunden modtager en notifikation, hvor de bliver præsenteret for den tilbudte løsning og bliver bedt om at acceptere den inden for 7 dage.<br />
                    <br />
                    Havemanden modtager en notifikation, hvor de bliver gjort opmærksom på, at kunden er blevet tilbudt en løsning.
                `,
            },
        };

        const problemTextArea: TextAreaFormItem = {
            id: formIds.TextAreaProblem,
            type: "text-area",
            label: "Årsag til reklamation",
            placeholder: "",
            value: complaint.customerOpinion ? `${complaint.customerOpinion.comment}\n\n${complaint.customerOpinion.proposal}` : "",
            dirty: false,
            validation: "mandatory",
        }

        const proposalTextArea: TextAreaFormItem = {
            id: formIds.TextAreaProposal,
            type: "text-area",
            label: "Forslag til løsning",
            placeholder: "",
            value: complaint.gardenerOpinion ? `${complaint.gardenerOpinion.proposal}\n\n${complaint.gardenerOpinion.comment}` : "",
            dirty: false,
            validation: "mandatory",
        };

        return {
            type: "page",
            title: "Tilbyd løsning til kunde",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "notice",
                        render: "info",
                        html: "Fjern irrelevant information fra nedenstående felter. Sørg også for at vi ikke støder kunde eller havemand og gør det gerne tydeligt, hvad kunden opnår i økonomisk besparelse.",
                    },
                    problemTextArea,
                    proposalTextArea,
                    confirmationPage,
                ],
            },
        };
    }, [complaint, formIds]);
}
