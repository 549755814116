import { makeStyles } from "@material-ui/core";
import UpdateIcon from "@material-ui/icons/EditOutlined";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import AddGardenerIcon from "@material-ui/icons/PersonAddOutlined";

import { Card, CardContent, CardHeader, CardStatus } from "../../components/card";
import { Address } from "../../address.model";
import { CustomerDocument } from "../../customer/customer.model";
import { GardeningTaskDocument } from "../gardening-task.model";
import { GardenerDocument } from "../../gardener/gardener.model";
import { Link } from "react-router-dom";
import { FormGroup } from "../../finance/billing/invoices/invoice-detail/FormGroup";
import { determineColorForGardeningTaskStatus, formatDuration, formatFrequency, formatGardeningTaskCreationDateWithTime, formatGardeningTaskStatus, formatRequiredSkills, formatTools } from "../utility";
import { MenuButton } from "../../components/menu/MenuButton";
import { MenuItem } from "../../components/menu/MenuItem";
import { MenuDivider } from "../../components/menu/MenuDivider";
import { useCallback, useMemo } from "react";
import { AbortGardeningTaskDialogProps, useAbortGardeningTaskDialog } from "../../workflow/follow-up/AbortGardeningTaskDialog";
import { AdministratorActionData } from "../../workflow/administrator-action.hook";
import { UpdateGardeningTaskDialogProps, UpdateGardeningTaskDialogViewModel, useUpdateGardeningTaskDialog } from "./UpdateGardeningTaskDialog";
import { SpecificMatchDialogProps, useSpecificMatchDialog } from "../../match/specific-match/SpecificMatchDialog";
import { useGardener } from "../../gardener/gardeners.hook";

const useStyles = makeStyles({
    multiline: {
        whiteSpace: "pre-wrap",
    },
});

interface GardeningTaskDetailCardProps {
    gardeningTask: GardeningTaskDocument;
    customer: CustomerDocument;
    gardener: GardenerDocument | null;
    administratorAction: (data: AdministratorActionData | string) => void;
}

export const GardeningTaskDetailCard = (props: GardeningTaskDetailCardProps) => {
    const classes = useStyles();
    const { gardeningTask, customer, gardener, administratorAction } = props;

    const status = formatGardeningTaskStatus(gardeningTask, "customer");
    const statusColor = determineColorForGardeningTaskStatus(gardeningTask);

    const heading = formatGardeningTaskCreationDateWithTime(gardeningTask);
    const tasks = formatRequiredSkills(gardeningTask.data.requiredSkills);
    const duration = formatDuration(gardeningTask.data.hourInterval);
    const tools = formatTools(gardeningTask.data.tools);
    const frequency = formatFrequency(gardeningTask.data.frequency);

    const createMatchAdapter = useCallback<SpecificMatchDialogProps["onConfirm"]>((viewModel) => {
        return administratorAction({
            action: "give task to gardener",
            gardeningTaskId: gardeningTask.id,
            gardenerId: viewModel.gardenerId,
            reason: viewModel.reason,
        });
    }, [administratorAction, gardeningTask.id]);
    const openCreateMatchDialog = useSpecificMatchDialog({
        gardenerId: "",
        gardenerIdDirty: false,
        reason: "",
        reasonDirty: false,
    }, useGardener, createMatchAdapter);

    const abortGardeningTaskAdapter = useCallback<AbortGardeningTaskDialogProps["onConfirm"]>((data) => {
        administratorAction({
            action: "abort gardening task",
            gardeningTaskId: gardeningTask.id,
            reason: data.reason,
        });
    }, [administratorAction, gardeningTask.id]);
    const openAbortGardeningTaskDialog = useAbortGardeningTaskDialog(abortGardeningTaskAdapter);

    const initialUpdateGardeningTaskDialogViewModel = useMemo<UpdateGardeningTaskDialogViewModel>(() => {
        return {
            addressSearch: {
                searchTerm: `${gardeningTask.data.address.street}, ${gardeningTask.data.address.zipCode}`,
                address: undefined,
            },
            addressSearchDirty: false,
            hourInterval: gardeningTask.data.hourInterval,
            hourIntervalDirty: false,
            tools: gardeningTask.data.tools,
            toolsDirty: false,
            frequency: gardeningTask.data.frequency,
            frequencyDirty: false,
            skills: gardeningTask.data.requiredSkills,
            skillsDirty: false,
            description: gardeningTask.data.description,
            descriptionDirty: false,
        };
    }, [gardeningTask.data]);
    const updateGardeningTaskAdapter = useCallback<UpdateGardeningTaskDialogProps["onConfirm"]>(viewModel => {
        administratorAction({
            action: "update gardening task",
            gardeningTaskId: gardeningTask.id,
            externalAddressId: viewModel.addressSearch.address!.externalId,
            requiredSkills: viewModel.skills,
            frequency: viewModel.frequency,
            hourInterval: viewModel.hourInterval,
            tools: viewModel.tools,
            description: viewModel.description,
        })
    }, [administratorAction, gardeningTask.id]);
    const openUpdateGardeningTaskDialog = useUpdateGardeningTaskDialog(initialUpdateGardeningTaskDialogViewModel, updateGardeningTaskAdapter);

    const menuButton = (
        <MenuButton>
            <MenuItem icon={<UpdateIcon />} onClick={openUpdateGardeningTaskDialog}>Ændr opgave</MenuItem>
            {gardeningTask.status === "looking for gardener" ? [
                <MenuItem key="looking for gardener - 0" icon={<AddGardenerIcon />} onClick={openCreateMatchDialog}>Tilknyt havemand</MenuItem>,
                <MenuDivider key="looking for gardener - 1" />,
                <MenuItem key="looking for gardener - 2" icon={<CancelIcon />} onClick={openAbortGardeningTaskDialog}>Træk opgave tilbage</MenuItem>,
            ] : null}
        </MenuButton>
    );

    return (
        <Card>

            
            <CardStatus color={statusColor} status={status} />

            <CardHeader button={menuButton}>{heading}</CardHeader>

            <CardContent>

                <FormGroup>
                    <label>Kunde</label>
                    <span>
                        <Link to={`/customers/context/${customer.id}`}>{customer.name}</Link>
                    </span>
                </FormGroup>

                <FormGroup>
                    <label>Havemand</label>
                    <span>
                        {gardener ? <Link to={`/gardeners/context/${gardener.id}`}>{gardener.name}</Link> : "Ingen havemand tilknyttet"}
                    </span>
                </FormGroup>

                <FormGroup>
                    <label>Adresse</label>
                    <span>
                        <a href={gpsHref(gardeningTask.data.address)} target="_blank" rel="noreferrer">
                            {gardeningTask.data.address.street}<br />
                            {gardeningTask.data.address.zipCode}
                        </a>
                    </span>
                </FormGroup>

                <FormGroup>
                    <label>Opgaver</label>
                    <span>{tasks}</span>
                </FormGroup>

                <FormGroup>
                    <label>Varighed</label>
                    <span>{duration}</span>
                </FormGroup>

                <FormGroup>
                    <label>Redskaber</label>
                    <span>{tools}</span>
                </FormGroup>

                <FormGroup>
                    <label>{gardeningTask.data.frequency === "once" ? "Engangsopgave" : "Fast opgave"}</label>
                    <span>{frequency}</span>
                </FormGroup>

                <FormGroup>
                    <label>Beskrivelse</label>
                    <span className={classes.multiline}>“{gardeningTask.data.description}”</span>
                </FormGroup>

            </CardContent>
        </Card>
    );
};

function gpsHref(address: Address): string {
    const query = `${address.street}, ${address.zipCode}`;

    return encodeURI(`https://maps.google.com/?q=${query}`);
}
