import { Container } from "@material-ui/core";

import { ComplaintTaskDocument } from "../../../task/task.model";
import { CommentDocument } from "../../../comment/comment.model";
import { Message } from "../../../Message";
import { ActivitySection } from "../../../comment/ActivitySection";
import { CustomerCommentList } from "../../../comment/CustomerCommentList";
import { BackButton } from "../../../components/BackButton";
import { Section } from "../../../components/section";
import { TitleHeader } from "../../../components/TitleHeader";
import { ContentSection } from "../../../layout/ContentSection";
import { GreenButton, VerticalButtons } from "../../../components/new-button";
import { RegistrationDetailCard } from "../../../finance/billing/pending-approval/RegistrationDetailCard";
import { ComplaintDetailCard } from "../../ComplaintDetailCard";
import { InvoiceDocument } from "../../../finance/billing/invoices/invoice.model";
import { AdministratorActionData } from "../../../workflow/administrator-action.hook";
import { useCallback } from "react";
import { FormProps } from "../../../components/form-item/framework/react/FormProvider";
import { FormItem } from "../../../components/form-item/framework/core/form-item.model";
import { SingleChoiceFormItem, TextAreaFormItem } from "../../../components/form-item/client/components";
import { ContactGardenerFormIds, useContactGardenerDialog } from "./ContactGardenerDialog";
import { GardenerDocument } from "../../../gardener/gardener.model";

export interface ContactGardenerDetailPageProps {
    task: ComplaintTaskDocument;
    gardener: GardenerDocument
    invoices: InvoiceDocument[];
    administratorAction: (data: AdministratorActionData | string) => void;
    comments: CommentDocument[];
    addComment: (comment: string) => void;
    goBack: () => void;
}

export const ContactGardenerDetailPage = (props: ContactGardenerDetailPageProps) => {
    const { task, gardener, invoices, administratorAction, comments, addComment } = props;

    const backButton = <BackButton onClick={props.goBack} />;

    const responseAdapter = useCallback<FormProps<FormItem>["onSubmit"]>((controller, item) => {
        const complaintStillRelevant = (controller.getItem(ContactGardenerFormIds.SingleChoiceStillRelevant, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 1;
        if ( !complaintStillRelevant ) {
            const reason = (controller.getItem(ContactGardenerFormIds.TextAreaCloseComplaintBecauseIrrelevantReason, item) as TextAreaFormItem).value;

            return administratorAction({
                action: "close complaint",
                complaintId: task.complaint.id,
                reason,
            })
        }

        const gardenerReached = (controller.getItem(ContactGardenerFormIds.SingleChoiceGardenerReached, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 1;
        if ( !gardenerReached ) {
            return administratorAction({
                action: "register did not reach gardener for complaint",
                taskId: task.id,
            });
        }

        const hasNoProposal = (controller.getItem(ContactGardenerFormIds.SingleChoiceGardenerDecision, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 2;
        if ( hasNoProposal ) {
            const gardenerComment = (controller.getItem(ContactGardenerFormIds.TextAreaGardenerDoesNotHaveProposalGardenerComment, item) as TextAreaFormItem).value;

            return administratorAction({
                action: "register did reach gardener for complaint",
                taskId: task.id,
                gardenerComment,
                gardenerProposal: "",
            });
        }

        const hasOtherProposal = (controller.getItem(ContactGardenerFormIds.SingleChoiceGardenerDecision, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 1;
        if ( hasOtherProposal ) {
            const gardenerComment = (controller.getItem(ContactGardenerFormIds.TextAreaGardenerHasOtherProposalGardenerComment, item) as TextAreaFormItem).value;
            const gardenerProposal = (controller.getItem(ContactGardenerFormIds.TextAreaGardenerHasOtherProposalGardenerProposal, item) as TextAreaFormItem).value;

            return administratorAction({
                action: "register did reach gardener for complaint",
                taskId: task.id,
                gardenerComment,
                gardenerProposal,
            });
        }

        const gardenerComment = (controller.getItem(ContactGardenerFormIds.TextAreaGardenerAcceptsCustomerProposalGardenerComment, item) as TextAreaFormItem).value;
        const gardenerProposal = (controller.getItem(ContactGardenerFormIds.TextAreaGardenerAcceptsCustomerProposalGardenerProposal, item) as TextAreaFormItem).value;

        return administratorAction({
            action: "register did reach gardener for complaint",
            taskId: task.id,
            gardenerComment,
            gardenerProposal,
        });

    }, [administratorAction, task]);
    const openResponseDialog = useContactGardenerDialog(task, gardener, responseAdapter);

    return (
        <>
            <TitleHeader button={backButton}>Kontakt havemand</TitleHeader>
            
            <ContentSection>
                <Container maxWidth="sm" disableGutters>

                    <Section>
                        {task.hasBeenPerformed ? <Message>Opgaven er allerede blevet udført</Message> : null}

                        <ComplaintDetailCard complaint={task.complaint} administratorAction={administratorAction} />

                        {invoices.map(invoice => (
                            <RegistrationDetailCard key={invoice.id} invoice={invoice} gardenerName={task.gardenerName} />
                        ))}

                        {!task.hasBeenPerformed ? (
                            <VerticalButtons>
                                <GreenButton onClick={openResponseDialog}>Besvar opgave</GreenButton>
                            </VerticalButtons>
                        ) : null}

                    </Section>

                    <ActivitySection onSave={addComment}>
                        <CustomerCommentList comments={comments} />
                    </ActivitySection>

                </Container>
            </ContentSection>
        </>
    );
};
