import { useCallback, useState } from "react";

import { Hit } from "@algolia/client-search";
import { Container, makeStyles } from "@material-ui/core";

import { ErrorPage } from "../../ErrorPage";
import { SearchField } from "../../components/form/InputField";
import { useInvoiceSearch } from "../../finance/billing/invoices/all-tab/invoice-search.hook";
import { InvoiceSearchRecord } from "../../finance/billing/invoices/invoice-search-record.model";
import { InvoiceTable } from "../../finance/billing/invoices/table/InvoiceTable";
import { InvoiceTablePagination } from "../../finance/billing/invoices/table/InvoiceTablePagination";
import { useLastValue } from "../../last-value.hook";
import { ContentSection } from "../../layout/ContentSection";
import { LinearProgress } from "../../components/LinearProgress";
import { Message } from "../../Message";

const useStyles = makeStyles({
    content: {
        "& > *": {
            marginBottom: "32px",
        },

        "& > *:last-child": {
            marginBottom: 0,
        },
    },
});

interface AllTabProps {
    goToInvoice: (invoiceId: string) => void;
    goToCreditNote: (creditNoteId: string) => void;
}

export const AllTab = (props: AllTabProps) => {
    const classes = useStyles();
    const { goToInvoice, goToCreditNote } = props;

    const [page, setPage] = useState(0);
    const pageSize = 15;

    const [searchTerm, setSearchTerm] = useState("");
    const changeSearchTerm = useCallback((value) => {
        setSearchTerm(value);
        setPage(0);
    }, [])

    const searchResults = useInvoiceSearch(searchTerm, page, pageSize);
    const lastSearchResults = useLastValue(searchResults);

    const goToDetailPage = useCallback((invoice: Hit<InvoiceSearchRecord>) => {
        if ( invoice.type === "invoice" ) goToInvoice(invoice.objectID);
        if ( invoice.type === "credit-note" ) goToCreditNote(invoice.objectID);
    }, [goToInvoice, goToCreditNote]);

    if ( lastSearchResults === undefined ) return <LinearProgress />;
    if ( lastSearchResults === null ) return <ErrorPage message="Der skete en fejl" />;

    const invoices = lastSearchResults.hits;

    const pagination = <InvoiceTablePagination page={page} onPageChange={setPage} pageSize={pageSize} totalCount={lastSearchResults.nbHits} />;

    return (
        <ContentSection className={classes.content}>

            <Container maxWidth="sm" disableGutters>
                <SearchField fullWidth value={searchTerm} onChange={changeSearchTerm} placeholder="Søg" autoFocus />
            </Container>

            {invoices.length > 0 ? (
                <Container maxWidth="xl" disableGutters>
                    <InvoiceTable invoices={invoices} onSelectInvoice={goToDetailPage} pagination={pagination} />
                </Container>
            ) : (
                <Container maxWidth="sm" disableGutters>
                    <Message>Ingen fakturaer matchede din søgning</Message>
                </Container>
            )}

        </ContentSection>
    );
};
