import { useAddGardenerComment } from "../../../comment/add-comment.hook";
import { useGardenerComments } from "../../../comment/comments.hook";
import { OnboardingTaskDocument } from "../../../task/task.model";
import { LinearProgress } from "../../../components/LinearProgress";
import { useAdministratorAction } from "../../../workflow/administrator-action.hook";
import { ApplicationDetailPage } from "./ApplicationDetailPage";
import { useGardener } from "../../../gardener/gardeners.hook";
import { ErrorPage } from "../../../ErrorPage";
import { usePersonalInformation } from "../../../gardener/personal-information/personal-information.hook";

interface IntegratedApplicationDetailPageProps {
    task: OnboardingTaskDocument;
    goBack: () => void;
}

export const IntegratedApplicationDetailPage = (props: IntegratedApplicationDetailPageProps) => {
    const { task, goBack } = props;

    const gardener = useGardener(task.gardenerId);
    const personalInformation = usePersonalInformation(task.gardenerId);
    const administratorAction = useAdministratorAction(goBack);

    const comments = useGardenerComments(task?.gardenerId);
    const addComment = useAddGardenerComment(task?.gardenerId);

    if ( gardener === null ) return <ErrorPage message="Havemanden kunne ikke findes" />;
    if ( personalInformation === null ) return <ErrorPage message="Kunne ikke finde alle oplysninger" />;
    if ( gardener === undefined || personalInformation === undefined || comments === undefined ) return <LinearProgress />;

    return (
        <ApplicationDetailPage
            gardener={gardener}
            personalInformation={personalInformation}
            task={task}
            administratorAction={administratorAction}
            comments={comments}
            addComment={addComment}
            goBack={goBack}
        />
    );
};
