import { useCallback, useMemo, useState } from "react";

import { Collapse, makeStyles } from "@material-ui/core";

import { NeutralAction } from "../../../components/card/NeutralAction";
import { PositiveAction } from "../../../components/card/PositiveAction";
import { Dialog, DialogActions, DialogContent, DialogHeader, useDialog } from "../../../components/dialog";
import { Label } from "../../../components/form/Label";
import { Group } from "../../../components/form/Group";
import { AlternativeHint } from "../../../components/form/Hint";
import { TextArea } from "../../../components/form/InputField";
import { useAnimation } from "../../../components/validation/animation.hook";

const useStyles = makeStyles({
    text: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        marginTop: 0,
    },
});

export interface DidReachCustomerDialogProps {
    onConfirm: (summary: string) => void;
    onCancel: () => void;
}

export const DidReachCustomerDialog = (props: DidReachCustomerDialogProps) => {
    const classes = useStyles();

    const { onCancel, onConfirm } = props;

    const [summary, setSummary] = useState("");
    const [dirty, setDirty] = useState({ summary: false });
    const markSummaryDirty = useCallback(() => setDirty({ ...dirty, summary: true }), [dirty]);

    const [validationAnimationDuration, skipValidationAnimation] = useAnimation();

    const summaryError = useMemo(() => {
        if ( !summary ) return "Skal udfyldes";

        return undefined;
    }, [summary]);

    const scrollToFirstError = useCallback(() => {
        const sectionId = (() => {
            if ( summaryError ) return "summary-section";

            return undefined;
        })();

        if ( !sectionId ) return;

        const section = document.getElementById(sectionId);
        if ( !section ) return;

        section.scrollIntoView({ behavior: "smooth" });
    }, [summaryError]);

    const confirm = useCallback(() => {
        if ( document.activeElement ) {
            (document.activeElement as HTMLInputElement).blur();
        }

        if ( summaryError ) {
            skipValidationAnimation();
            setDirty({ summary: true });
            return setTimeout(scrollToFirstError, 100);
        }

        onConfirm(summary);
    }, [skipValidationAnimation, scrollToFirstError, onConfirm, summaryError, summary]);

    return (
        <Dialog>
            <DialogHeader>Jeg fik fat i kunden</DialogHeader>

            <DialogContent>

                <p className={classes.text}>
                    Noter nedenfor hvad du har aftalt med kunden.
                </p>

                <div id="summary-section">
                    <Label htmlFor="summary" style={{ marginBottom: "8px" }}>Hvad skal der ske nu?</Label>
                    <Group error={Boolean(dirty.summary && summaryError)}>
                        <TextArea id="summary" value={summary} onChange={setSummary} onBlur={markSummaryDirty} placeholder="Betaler de med det samme eller senere? Vil de ikke betale? Hvorfor?" />

                        <Collapse in={Boolean(dirty.summary && summaryError)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={summaryError} />
                        </Collapse>
                    </Group>
                </div>

            </DialogContent>

            <DialogActions>
                <NeutralAction onClick={onCancel}>Annuller</NeutralAction>
                <PositiveAction onClick={confirm}>Bekræft</PositiveAction>
            </DialogActions>

        </Dialog>
    );
};

export const useDidReachCustomerDialog = (onConfirm: DidReachCustomerDialogProps["onConfirm"]) => {
    const { openDialog, closeDialog } = useDialog();

    const confirm: DidReachCustomerDialogProps["onConfirm"] = useCallback(summary => {
        closeDialog();

        onConfirm(summary);
    }, [closeDialog, onConfirm]);

    return useCallback(() => {
        openDialog(<DidReachCustomerDialog onConfirm={confirm} onCancel={closeDialog} />);
    }, [openDialog, closeDialog, confirm]);
};
