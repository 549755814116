import { useFormDialog } from "../../../components/form-item/client/components";
import { FormProps } from "../../../components/form-item/framework/react/FormProvider";
import { useCloseComplaintForm } from "../../shared/CloseComplaintForm";
import { ComplaintTaskDocument } from "../../../task/task.model";
import { ComplaintStillRelevantForm, useComplaintStillRelevantForm } from "../../shared/ComplaintStillRelevantForm";
import { GardenerReachedForm, useGardenerReachedForm } from "../../shared/GardenerReachedForm";
import { GardenerOpinionForm, useGardenerOpinionForm } from "../../shared/GardenerOpinionForm";
import { GardenerDocument } from "../../../gardener/gardener.model";

export enum ContactGardenerFormIds {
    SingleChoiceGardenerReached = "SingleChoiceGardenerReached",
    TextAreaCloseComplaintBecauseIrrelevantReason = "TextAreaCloseComplaintBecauseIrrelevantReason",
    SingleChoiceStillRelevant = "SingleChoiceStillRelevant",

    TextAreaCustomerComment = "TextAreaCustomerComment",
    TextAreaCustomerProposal = "TextAreaCustomerProposal",
    SingleChoiceGardenerDecision = "SingleChoiceGardenerDecision",

    TextAreaGardenerAcceptsCustomerProposalGardenerComment = "TextAreaGardenerAcceptsCustomerProposalGardenerComment",
    TextAreaGardenerAcceptsCustomerProposalGardenerProposal = "TextAreaGardenerAcceptsCustomerProposalGardenerProposal",

    TextAreaGardenerHasOtherProposalGardenerComment = "TextAreaGardenerHasOtherProposalGardenerComment",
    TextAreaGardenerHasOtherProposalGardenerProposal = "TextAreaGardenerHasOtherProposalGardenerProposal",

    TextAreaGardenerDoesNotHaveProposalGardenerComment = "TextAreaGardenerDoesNotHaveProposalGardenerComment",
}

export type ContactGardenerForm =
    ComplaintStillRelevantForm<
        GardenerReachedForm<
            GardenerOpinionForm
        >
    >

export const useContactGardenerDialog = (task: ComplaintTaskDocument, gardener: GardenerDocument, onConfirm: FormProps<ContactGardenerForm>["onSubmit"]) => {
    const gardenerOpinionForm = useGardenerOpinionForm(task.complaint, {
        TextAreaCustomerComment: ContactGardenerFormIds.TextAreaCustomerComment,
        TextAreaCustomerProposal: ContactGardenerFormIds.TextAreaCustomerProposal,
        SingleChoiceGardenerDecision: ContactGardenerFormIds.SingleChoiceGardenerDecision,
        TextAreaGardenerAcceptsCustomerProposalGardenerComment: ContactGardenerFormIds.TextAreaGardenerAcceptsCustomerProposalGardenerComment,
        TextAreaGardenerAcceptsCustomerProposalGardenerProposal: ContactGardenerFormIds.TextAreaGardenerAcceptsCustomerProposalGardenerProposal,
        TextAreaGardenerHasOtherProposalGardenerComment: ContactGardenerFormIds.TextAreaGardenerHasOtherProposalGardenerComment,
        TextAreaGardenerHasOtherProposalGardenerProposal: ContactGardenerFormIds.TextAreaGardenerHasOtherProposalGardenerProposal,
        TextAreaGardenerDoesNotHaveProposalGardenerComment: ContactGardenerFormIds.TextAreaGardenerDoesNotHaveProposalGardenerComment,
    });

    const gardenerReachedForm = useGardenerReachedForm(task.complaint, gardener, gardenerOpinionForm, {
        SingleChoiceGardenerReached: ContactGardenerFormIds.SingleChoiceGardenerReached,
    });

    const closeComplaintBecauseIrrelevantForm = useCloseComplaintForm(task.complaint, {
        TextAreaReason: ContactGardenerFormIds.TextAreaCloseComplaintBecauseIrrelevantReason,
    });
    const complaintStillRelevantForm = useComplaintStillRelevantForm(closeComplaintBecauseIrrelevantForm, gardenerReachedForm, {
        SingleChoiceStillRelevant: ContactGardenerFormIds.SingleChoiceStillRelevant,
    });

    return useFormDialog(complaintStillRelevantForm, onConfirm);
};
