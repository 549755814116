import { Link } from "react-router-dom";

import { Comment } from "../components/comment/Comment";
import { OfferCommentDocument } from "./comment.model";
import { CommentBody } from "../components/comment/CommentBody";

interface OfferCommentProps {
    comment: OfferCommentDocument;
}

export const OfferComment = (props: OfferCommentProps) => {
    const { comment } = props;

    const author = comment.administratorName ?? "Automatisk håndtering";
    const title = comment.backstopper ? "Kunde tilbudt til bagstopper" : "Kunde tilbudt til havemand";

    const customer = <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link>;
    const gardener = <Link to={`/gardeners/context/${comment.gardenerId}`}>{comment.gardenerName}</Link>;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title={title} author={author}>
            <CommentBody>
                {customer} er blevet tilbudt til {gardener}.
            </CommentBody>
        </Comment>
    );
};
