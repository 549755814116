import { createContext, PropsWithChildren, useContext, useMemo } from "react";

import { LinearProgress } from "../../components/LinearProgress";
import { PaymentCollectionTaskDocument } from "../../task/task.model";
import { useOpenTasksByType } from "../../task/tasks.hook";

interface BillingTaskContext {
    manualPaymentCollectionTasks: PaymentCollectionTaskDocument[];
}

const Context = createContext<BillingTaskContext | undefined>(undefined);

export const BillingTaskProvider = (props: PropsWithChildren<{}>) => {
    const paymentCollectionTasks = useOpenTasksByType("payment-collection");

    const taskMap = useMemo(() => {
        if ( paymentCollectionTasks === undefined ) return undefined;

        const manualPaymentCollectionTasks = paymentCollectionTasks.filter(x => x.status === "call before invoice reminder fee 1" || x.status === "call before debt collection");

        return { manualPaymentCollectionTasks };
    }, [paymentCollectionTasks]);

    if ( taskMap === undefined ) return <LinearProgress />;

    return (
        <Context.Provider value={taskMap}>
            {props.children}
        </Context.Provider>
    );
};

export const useManualPaymentCollectionTasks = () => {
    return useContext(Context)?.manualPaymentCollectionTasks as BillingTaskContext["manualPaymentCollectionTasks"];
}
