import { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useTask } from "../../task/tasks.hook";
import { OnboardingTaskDocument } from "../../task/task.model";
import { NotFoundPage } from "../../NotFoundPage";
import { LinearProgress } from "../../components/LinearProgress";
import { ErrorPage } from "../../ErrorPage";
import { IntegratedApplicationDetailPage } from "./application/IntegratedApplicationDetailPage";
import { IntegratedInterviewDetailPage } from "./interview/IntegratedInterviewDetailPage";

export const IntegratedOnboardingDetailPage = () => {
    const { tab, taskId } = useParams<{ tab: string, taskId: string }>();

    const history = useHistory();
    const goBack = useCallback(() => {
        history.push(`/onboarding/${tab}`);
    }, [history, tab]);

    const task = useTask(taskId) as OnboardingTaskDocument;

    if ( task === null ) return <NotFoundPage />;
    if ( task === undefined ) return <LinearProgress />;

    if ( task.type === "onboarding" && task.application.status === "accepted" ) return <IntegratedApplicationDetailPage task={task} goBack={goBack} />;
    if ( task.type === "onboarding" && task.application.status === "checked" ) return <IntegratedInterviewDetailPage task={task} goBack={goBack} />;

    return <ErrorPage message="Opgavens type genkendes ikke" />;
};
