import { makeStyles } from "@material-ui/core";
import moment from "moment";

import { Card } from "../../../../components/card/Card";
import { LegacyCardContent } from "../../../../components/card/CardContent";
import { CardHeader } from "../../../../components/card/CardHeader";
import { Link } from "react-router-dom";
import { PaymentCollectionTaskDocument } from "../../../../task/task.model";
import { determineExpectedPaymentCollectionDate } from "../../../../date-utility";

const useStyles = makeStyles({
    group: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },

        "& > span": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
            lineHeight: "125%",
            whiteSpace: "pre-wrap",

            "& > a": {
                fontSize: "16px",
                fontWeight: 500,
                color: "#297A1D",
                textDecoration: "none",
        
                "&:hover": {
                    color: "#1C5B13",
                    textDecoration: "underline",
                },
            },
        },
    },
    order: {
        "& > *": {
            marginBottom: "16px",
        },

        "& > *:last-child": {
            marginBottom: 0,
        }
    },
});

interface PaymentCollectionDetailCardProps {
    task: PaymentCollectionTaskDocument;
}

export const PaymentCollectionDetailCard = (props: PaymentCollectionDetailCardProps) => {
    const classes = useStyles();

    const { task } = props;

    const heading = (() => {
        if ( task.status === "schedule call before invoice reminder fee 1" ) return `${task.customerName}, faktura ${task.invoiceNumber}, planlæg opkald inden rykker 1`;
        if ( task.status === "send invoice reminder 1" ) return `${task.customerName}, faktura ${task.invoiceNumber}, påmindelse 1`;
        if ( task.status === "send invoice reminder 2" ) return `${task.customerName}, faktura ${task.invoiceNumber}, påmindelse 2`;
        if ( task.status === "send invoice reminder fee 1" ) return `${task.customerName}, faktura ${task.invoiceNumber}, rykker 1`;
        if ( task.status === "send invoice reminder fee 2" ) return `${task.customerName}, faktura ${task.invoiceNumber}, rykker 2`;
        if ( task.status === "send invoice reminder fee 3" ) return `${task.customerName}, faktura ${task.invoiceNumber}, rykker 3`;
        if ( task.status === "schedule call before debt collection" ) return `${task.customerName}, faktura ${task.invoiceNumber}, planlæg opkald inden inkasso`;
    })();

    const customer = <Link to={`/customers/context/${task.customerId}`}>{task.customerName}</Link>;
    const invoice = <Link to={`/invoices/all/invoice/${task.invoiceId}`}>faktura {task.invoiceNumber}</Link>;

    const performAfterDate = moment(determineExpectedPaymentCollectionDate(task.performAfterDate)).format("D MMMM HH:mm");

    const message = (() => {
        if ( task.status === "send invoice reminder 1" ) {
            return (
                <>
                    {customer} har ikke betalt {invoice} til tiden.<br />
                    <br />
                    Første betalingspåmindelse sendes {performAfterDate} på sms og email. Havemanden informeres samtidig på email.
                </>
            );
        }

        if ( task.status === "send invoice reminder 2" ) {
            return (
                <>
                    {customer} har ikke betalt {invoice} til tiden.<br />
                    <br />
                    Anden betalingspåmindelse sendes {performAfterDate} på sms og email.
                </>
            );
        }

        if ( task.status === "schedule call before invoice reminder fee 1" ) {
            return (
                <>
                    {customer} har ikke betalt {invoice} efter at have fået tilsendt 2 betalingspåmindelser.<br />
                    <br />
                    Opkald planlægges {performAfterDate} inden afsendelse af første rykker.
                </>
            )
        }

        if ( task.status === "send invoice reminder fee 1" ) {
            return (
                <>
                    {customer} har ikke betalt {invoice} til tiden.<br />
                    <br />
                    Første rykker sendes {performAfterDate} på sms og email.
                </>
            );
        }

        if ( task.status === "send invoice reminder fee 2" ) {
            return (
                <>
                    {customer} har ikke betalt {invoice} til tiden.<br />
                    <br />
                    Anden rykker sendes {performAfterDate} på sms og email.
                </>
            );
        }

        if ( task.status === "send invoice reminder fee 3" ) {
            return (
                <>
                    {customer} har ikke betalt {invoice} til tiden.<br />
                    <br />
                    Tredje rykker sendes {performAfterDate} på sms og email.
                </>
            );
        }

        if ( task.status === "schedule call before debt collection" ) {
            return (
                <>
                    {customer} har ikke betalt {invoice} efter at have fået tilsendt 3 rykkere.<br />
                    <br />
                    Opkald planlægges {performAfterDate} inden inkasso.
                </>
            )
        }
    })();

    return (
        <Card>
            <CardHeader>{heading}</CardHeader>

            <LegacyCardContent className={classes.order}>

                <div className={classes.group}>
                    <span>
                        {message}
                    </span>
                </div>

            </LegacyCardContent>

        </Card>
    );
};
