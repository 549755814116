import { Link } from "react-router-dom";

import { Comment } from "../components/comment/Comment";
import { CommentBody } from "../components/comment/CommentBody";
import { DidNotReachCustomerBeforeInvoiceDebtCollectionCommentDocument, DidNotReachCustomerBeforeInvoiceReminderFee1CommentDocument, DidReachCustomerBeforeInvoiceDebtCollectionCommentDocument, DidReachCustomerBeforeInvoiceReminderFee1CommentDocument, DidSendLetterWithInvoiceReminderFee1CommentDocument, DidSendLetterWithInvoiceReminderFee2CommentDocument, DidSendLetterWithInvoiceReminderFee3CommentDocument, InvoiceReminder1CommentDocument, InvoiceReminder2CommentDocument, InvoiceReminderFee1CommentDocument, InvoiceReminderFee2CommentDocument, InvoiceReminderFee3CommentDocument } from "../finance/billing/payment-collection/payment-collection-comment.model";

interface PaymentCollectionCommentProps {
    comment:
        | InvoiceReminder1CommentDocument | InvoiceReminder2CommentDocument
        | DidNotReachCustomerBeforeInvoiceReminderFee1CommentDocument | DidReachCustomerBeforeInvoiceReminderFee1CommentDocument
        | InvoiceReminderFee1CommentDocument | InvoiceReminderFee2CommentDocument | InvoiceReminderFee3CommentDocument
        | DidSendLetterWithInvoiceReminderFee1CommentDocument | DidSendLetterWithInvoiceReminderFee2CommentDocument | DidSendLetterWithInvoiceReminderFee3CommentDocument
        | DidNotReachCustomerBeforeInvoiceDebtCollectionCommentDocument | DidReachCustomerBeforeInvoiceDebtCollectionCommentDocument
}

export const PaymentCollectionComment = (props: PaymentCollectionCommentProps) => {
    const { comment } = props;

    const customer = <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link>;
    const invoice = <Link to={`/invoices/all/invoice/${comment.invoiceId}`}>faktura {comment.invoiceNumber}</Link>;

    const title = (() => {
        switch ( comment.type ) {
            case "invoice-reminder-1": return "Første betalingspåmindelse afsendt";
            case "invoice-reminder-2": return "Anden betalingspåmindelse afsendt";
            case "did-not-reach-customer-before-invoice-reminder-fee-1": return "Jeg fik ikke fat i kunden";
            case "did-reach-customer-before-invoice-reminder-fee-1": return "Jeg fik fat i kunden";
            case "invoice-reminder-fee-1": return "Første rykker afsendt";
            case "invoice-reminder-fee-2": return "Anden rykker afsendt";
            case "invoice-reminder-fee-3": return "Tredje rykker afsendt";
            case "did-send-letter-with-invoice-reminder-fee-1": return "Første rykker afsendt med post";
            case "did-send-letter-with-invoice-reminder-fee-2": return "Anden rykker afsendt med post";
            case "did-send-letter-with-invoice-reminder-fee-3": return "Tredje rykker afsendt med post";
            case "did-not-reach-customer-before-invoice-debt-collection": return "Jeg fik ikke fat i kunden";
            case "did-reach-customer-before-invoice-debt-collection": return "Jeg fik fat i kunden";
        }
    })();

    const message = (() => {
        switch ( comment.type ) {
            case "invoice-reminder-1": return <>Første betalingspåmindelse vedr. {invoice} er afsendt til {customer}.</>;
            case "invoice-reminder-2": return <>Anden betalingspåmindelse vedr. {invoice} er afsendt til {customer}.</>;
            case "did-not-reach-customer-before-invoice-reminder-fee-1": return <>{customer} blev ringet op vedr. udstedelse af første rykker for {invoice}, men jeg fik ikke fat i vedkommende.</>
            case "did-reach-customer-before-invoice-reminder-fee-1":
                return (
                    <>
                        {customer} blev ringet op vedr. udstedelse af første rykker for {invoice} og vi talte om følgende:<br />
                        <br />
                        {comment.summary}
                    </>
                );
            case "invoice-reminder-fee-1": return <>Første rykker vedr. {invoice} er afsendt til {customer}.</>;
            case "invoice-reminder-fee-2": return <>Anden rykker vedr. {invoice} er afsendt til {customer}.</>;
            case "invoice-reminder-fee-3": return <>Tredje rykker vedr. {invoice} er afsendt til {customer}.</>;
            case "did-send-letter-with-invoice-reminder-fee-1": return <>Første rykker vedr. {invoice} er afsendt med post til {customer}.</>;
            case "did-send-letter-with-invoice-reminder-fee-2": return <>Tredje rykker vedr. {invoice} er afsendt med post til {customer}.</>;
            case "did-send-letter-with-invoice-reminder-fee-3": return <>Tredje rykker vedr. {invoice} er afsendt med post til {customer}.</>;
            case "did-not-reach-customer-before-invoice-debt-collection": return <>{customer} blev ringet op vedr. inkasso for {invoice}, men jeg fik ikke fat i vedkommende.</>;
            case "did-reach-customer-before-invoice-debt-collection":
                return (
                    <>
                        {customer} blev ringet op vedr. inkasso for {invoice} og vi talte om følgende:<br />
                        <br />
                        {comment.summary}
                    </>
                );
        }
    })();

    const author = "administratorName" in comment ? comment.administratorName : undefined;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title={title} author={author}>
            <CommentBody>
                {message}
            </CommentBody>
        </Comment>
    );
};
