import Big from "big.js";

import { Collapse, MenuItem } from "@material-ui/core";
import { ServiceInputContext, useServiceInput } from ".";
import { AlternativeHint, Dropdown, Group } from "../../../../../../components/form";
import { Service } from "../../../invoice.model";
import { InvoiceFormContext, useInvoiceForm } from "../InvoiceForm";
import { validateMandatory } from "../../../../../../components/validation/validation";
import { useCallback, useMemo } from "react";

interface DurationInputProps {
    index: number;
    id?: string;
}

export const DurationInput = (props: DurationInputProps) => {
    const { index, id } = props;

    const { form } = useInvoiceForm();
    const { serviceInput, setServiceInput } = useServiceInput(index);
    const { durationInput } = serviceInput;

    const updateDuration = useCallback((durationInHours: string) => {
        setServiceInput((previousState): ServiceInputContext => ({
            ...previousState,
            durationInput: {
                ...previousState.durationInput,
                hours: parseFloat(durationInHours),
            },
        }))
    }, [setServiceInput]);

    const markDurationDirty = useCallback(() => {
        setServiceInput((previousState): ServiceInputContext => ({
            ...previousState,
            durationInput: {
                ...previousState.durationInput,
                dirty: true,
            },
        }))
    }, [setServiceInput]);

    const renderPlaceholder = useCallback(() => "Vælg varighed", []);

    const durationOptions = useMemo(() => {
        const options = [];

        const numberOfHours = 24;
        for (let i = 1; i <= 4 * numberOfHours; i++) {
            const value = Big(i).times(0.25).round(2).toNumber();

            const hours = Math.floor(i / 4).toString().padStart(1, "0");
            const minutes = ((i % 4) * 15).toString().padStart(2, "0");

            const label = `${hours}t ${minutes}m`;

            options.push({ value, label });
        }

        return options;
    }, []);

    const error = validateDurationInput(form, index);

    return (
        <Group error={Boolean(durationInput.dirty && error)}>
            <Dropdown labelId={id} variant="outlined" value={durationInput.hours ?? ""} onChange={updateDuration} onBlur={markDurationDirty} displayEmpty renderValue={durationInput.hours ? undefined : renderPlaceholder}>
                {durationOptions.map((option, index) => (
                    <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                ))}
            </Dropdown>

            <Collapse in={Boolean(durationInput.dirty && error)} timeout={form.validationAnimationDuration}>
                <AlternativeHint message={error} />
            </Collapse>
        </Group>
    );
}

export interface DurationInputContext {
    hours: number | undefined;
    dirty: boolean;
}

export const newDurationInputContext = (service: Service): DurationInputContext => ({
    hours: service.hours,
    dirty: false,
});

export const durationInputHasChanged = (form: InvoiceFormContext, index: number) => {
    if ( form.invoice.services.length <= index ) return true;

    return form.invoice.services[index].hours !== form.serviceInputs[index].durationInput.hours;
};

export const validateDurationInput = (form: InvoiceFormContext, index: number) => {
    return validateMandatory(form.serviceInputs[index].durationInput.hours);
};
