import { useEffect, useRef, useState } from "react";

import firebase from "firebase/compat/app";

import { InvoiceDocument } from "./invoice.model";

export const useInvoices = (invoiceIds: string[] | null | undefined) => {
    const terminated = useRef(true);
    const [state, setState] = useState<InvoiceDocument[] | null>();
    
    useEffect(() => {
        if ( invoiceIds === undefined ) return setState(undefined);
        if ( invoiceIds === null ) return setState(null);

        setState(undefined);
        
        terminated.current = false;

        Promise.all(invoiceIds.map(async invoiceId => {
            const document = await firebase.firestore().collection("invoices").doc(invoiceId).get();
            if ( !document.exists ) return null;

            return { id: document.id, ...document.data() } as InvoiceDocument;
        })).then(invoices => {
            if ( terminated.current ) return;

            const relevantInvoices = invoices.filter(Boolean) as InvoiceDocument[];

            setState(relevantInvoices);
        });

        return () => {
            terminated.current = true;
        };
    }, [invoiceIds, terminated]);

    return state;
};

export const useInvoice = (invoiceId: string | null | undefined) => {
    const [state, setState] = useState<InvoiceDocument | null>();

    useEffect(() => {
        if ( invoiceId === undefined ) return setState(undefined);
        if ( invoiceId === null ) return setState(null);

        setState(undefined);

        return firebase.firestore().collection("invoices").doc(invoiceId).onSnapshot(document => {
            if ( !document.exists ) return setState(null);

            setState({ id: document.id, ...document.data() } as InvoiceDocument);
        });
    }, [invoiceId]);

    return state;
};
