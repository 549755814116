import { createContext, Dispatch, SetStateAction, useCallback, useContext, useMemo, useState } from "react";

import { NeutralAction } from "../../../../../components/card/NeutralAction";
import { Dialog, DialogActions, DialogContent, DialogHeader, useDialog } from "../../../../../components/dialog";
import { Label } from "../../../../../components/form/Label";
import { FormGroup } from "../FormGroup";
import { newReasonInputContext, ReasonInput, ReasonInputContext, reasonInputHasChanged, validateReasonInput } from "./ReasonInput";
import { SubmitButton } from "./SubmitButton";
import { UpdateInvoiceData } from "./update-invoice.hook";

const Context = createContext<{ form: UpdateInvoiceDialogFormContext, setForm: Dispatch<SetStateAction<UpdateInvoiceDialogFormContext>> }>(undefined as any);

interface UpdateInvoiceDialogProps {
    data: Omit<UpdateInvoiceData, "reason">;
    onConfirm: (form: UpdateInvoiceDialogFormContext, data: Omit<UpdateInvoiceData, "reason">) => void;
    onCancel: () => void;
}

export const UpdateInvoiceDialog = (props: UpdateInvoiceDialogProps) => {
    const { data, onConfirm, onCancel } = props;

    const [form, setForm] = useState(newFormContext());
    const value = useMemo(() => ({ form, setForm }), [form, setForm]);

    return (
        <Context.Provider value={value}>
            <Dialog>
                <DialogHeader>Udsted ny faktura?</DialogHeader>

                <DialogContent>
                    <div>Kunden vil modtage den nye faktura og en kreditnota på den oprindelige faktura.</div>

                    <FormGroup id="reason-section">
                        <Label htmlFor="reason" style={{ marginBottom: "8px" }}>Årsag til rettelse</Label>
                        <ReasonInput id="reason" />
                    </FormGroup>

                </DialogContent>

                <DialogActions>
                    <NeutralAction onClick={onCancel}>Annuller</NeutralAction>
                    <SubmitButton data={data} onSubmit={onConfirm} />
                </DialogActions>

            </Dialog>
        </Context.Provider>
    );
};

export const useUpdateInvoiceDialogForm = () => {
    return useContext(Context);
};

export interface UpdateInvoiceDialogFormContext {
    validationAnimationDuration: number;
    reasonInput: ReasonInputContext;
}

export const newFormContext = (): UpdateInvoiceDialogFormContext => {
    return {
        validationAnimationDuration: 200,
        reasonInput: newReasonInputContext(),
    };
};

export const updateInvoiceDialogFormHasChanged = (form: UpdateInvoiceDialogFormContext) => {
    return reasonInputHasChanged(form);
};

export const validateUpdateInvoiceDialogForm = (form: UpdateInvoiceDialogFormContext) => {
    return validateReasonInput(form);
};

export const scrollToFirstError = (form: UpdateInvoiceDialogFormContext) => {
    const sectionId = (() => {
        if ( validateReasonInput(form) ) return "reason-section"

        return undefined;
    })();

    if ( !sectionId ) return;

    const section = document.getElementById(sectionId);
    if ( !section ) return;

    const header = document.getElementsByTagName("header").length === 1 ? document.getElementsByTagName("header").item(0) : null;
    const headerOffset = header?.getBoundingClientRect().height ?? 0;

    const offsetPosition = window.scrollY + section.getBoundingClientRect().top - headerOffset;

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
}

export const useUpdateInvoiceDialog = (onConfirm: UpdateInvoiceDialogProps["onConfirm"]) => {
    const { openDialog, closeDialog } = useDialog();

    const confirm: UpdateInvoiceDialogProps["onConfirm"] = useCallback((form, data) => {
        closeDialog();

        onConfirm(form, data);
    }, [closeDialog, onConfirm]);

    return useCallback((data: Omit<UpdateInvoiceData, "reason">) => {
        openDialog(<UpdateInvoiceDialog data={data} onConfirm={confirm} onCancel={closeDialog} />);
    }, [openDialog, closeDialog, confirm]);
};
