import { FocusEvent, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { debounce, Grow, makeStyles, MenuItem, MenuItemProps, MenuList, Paper, Popper, PopperProps } from "@material-ui/core";

import { TextField, TextFieldProps } from "./InputField";

const popperModifiers = {
    flip: { enabled: false },
};

const growStyle = {
    marginTop: "2px",
    transformOrigin: "center top",
};

const useStyles = makeStyles(theme => ({
    suggestions: {
        zIndex: theme.zIndex.modal,
    },
    paper: {
        border: "1px solid rgb(0, 0, 0, 0.23)",
        overflow: "hidden",

        "& > ul": {
            padding: 0,
        },

        "& > ul > li": {
            padding: "8px 16px",
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
            whiteSpace: "normal",
            borderBottom: "1px solid #EDEDED",

            "&:last-child": {
                borderBottom: "none",
            },

            "&.no-focus": {
                backgroundColor: "#FFFFFF",
            },

            "&.focus": {
                backgroundColor: "#EDEDED",
            },
        },
    },
}));

interface AddressFieldProps extends Omit<TextFieldProps, "value" | "onChange" | "onKeyDown"> {
    searchTerm: string;
    value: AddressFieldAddress | undefined
    onChange: (value: { searchTerm: string, address: AddressFieldAddress | undefined }) => void;
}

export const AddressField = (props: AddressFieldProps) => {
    const classes = useStyles();

    const { searchTerm, value, onChange, onFocus, onBlur, ...otherProps } = props;

    const [suggestions, setSuggestions] = useState<AddressFieldAddress[]>([]);

    const onTypeInSearchField = useCallback((searchTerm: string) => {
        onChange({ searchTerm, address: undefined });
    }, [onChange]);

    const onSelectSuggestion = useCallback((index: number) => {
        const address = suggestions[index];
        const searchTerm = `${address.street}, ${address.zipCode}`;

        onChange({ searchTerm, address });
    }, [onChange, suggestions]);

    const [allowSuggestions, setAllowSuggestions] = useState(false);

    const showSuggestions = useCallback((event: FocusEvent<any>) => {
        setAllowSuggestions(true);

        if ( onFocus ) onFocus(event);
    }, [onFocus]);

    const hideSuggestions = useCallback((event: FocusEvent<any>) => {
        if ( suggestions.length === 1 ) onSelectSuggestion(0);

        setAllowSuggestions(false);
        setFocusIndex(undefined);

        if ( onBlur ) onBlur(event);
    }, [onBlur, suggestions, onSelectSuggestion]);

    const controllerRef = useRef<AbortController>();

    const doSearch = useMemo(() => {
        return debounce((term: string) => {
            controllerRef.current = new AbortController();
            search(term, controllerRef.current)
                .then(addresses => {
                    setFocusIndex(undefined);
                    setSuggestions(addresses);
                })
                .catch(error => {
                    if ( error.name === "AbortError" ) return;
    
                    console.error(error);
                });
        }, 100);
    }, []);

    const trimmedSearchTerm = searchTerm.trim();

    const [focusIndex, setFocusIndex] = useState<number>();

    useEffect(() => {
        setFocusIndex(undefined);
        if ( controllerRef.current ) controllerRef.current.abort();
        if ( !trimmedSearchTerm ) return setSuggestions([]);

        doSearch(trimmedSearchTerm);
    }, [doSearch, trimmedSearchTerm]);

    const textField = useRef<any>();

    const onSuggestionFocus = useCallback((index: number) => {
        setFocusIndex(index);
    }, []);

    const onKeyDown = useCallback((event: KeyboardEvent<any>) => {
        if ( suggestions.length === 0 ) return;

        if ( event.key === "ArrowDown" ) {
            event.preventDefault();
            if ( focusIndex === undefined ) return setFocusIndex(0);
            if ( focusIndex === (suggestions.length - 1) ) return setFocusIndex(0);
            
            return setFocusIndex(focusIndex + 1);
        }

        if ( event.key === "ArrowUp" ) {
            event.preventDefault();
            if ( focusIndex === undefined ) return setFocusIndex(suggestions.length - 1);
            if ( focusIndex === 0 ) return setFocusIndex(suggestions.length - 1);
            
            return setFocusIndex(focusIndex - 1);
        }

        if ( event.key === "Enter" ) {
            event.preventDefault();

            if ( focusIndex === undefined ) return;

            onSelectSuggestion(focusIndex);
            
            if ( document.activeElement ) {
                (document.activeElement as HTMLInputElement).blur();
            }
        }
    }, [onSelectSuggestion, suggestions, focusIndex]);

    const anchor = textField.current;
    const popperStyle = useMemo<PopperProps["style"]>(() => {
        if ( !anchor ) return undefined;

        return { width: anchor.clientWidth };
    }, [anchor]);

    return (
        <>
            <TextField inputRef={textField} autoComplete="completely-off" value={searchTerm} onKeyDown={onKeyDown} onChange={onTypeInSearchField} onFocus={showSuggestions} onBlur={hideSuggestions} {...otherProps}  />

            <Popper open={allowSuggestions && suggestions.length > 0} style={popperStyle} anchorEl={textField.current} role={undefined} transition className={classes.suggestions} modifiers={popperModifiers}>
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} style={growStyle}>
                        <Paper className={classes.paper} elevation={3}>
                            <MenuList>
                                {suggestions.map((suggestion, index) => (
                                    <Suggestion key={index} className={index === focusIndex ? "focus" : "no-focus"} index={index} onFocus={onSuggestionFocus} onSelect={onSelectSuggestion}>
                                        {suggestion.street}, {suggestion.zipCode}
                                    </Suggestion>
                                ))}
                            </MenuList>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

interface SuggestionProps extends Omit<MenuItemProps<any>, "onSelect"> {
    index: number;
    onFocus: (index: number) => void;
    onSelect: (index: number) => void;
}

const Suggestion = (props: SuggestionProps) => {
    const { index, onFocus, onSelect, ...otherProps } = props;

    const focus = useCallback(() => {
        onFocus(index);
    }, [onFocus, index]);

    const select = useCallback(() => {
        onSelect(index);
    }, [onSelect, index]);

    return (
        <MenuItem onMouseEnter={focus} onClick={select} {...otherProps} />
    );
}

export interface DawaAddress {
    "forslagstekst": string;
    "data": {
      "id": string;
      "postnr": string;
      "postnrnavn": string;
      "x": number;
      "y": number;
    };
}

export interface AddressFieldAddress {
    externalId: string;
    street: string;
    zipCode: string;
    coordinates: {
        latitude: number;
        longitude: number;
    };
}

export const useAddressPreloadingHack = (searchTerm: string, addressSearch: AddressFieldAddress | undefined, onExactAddress: (address: AddressFieldAddress) => void, onAmbiguousAddress: () => void) => {
    const hasInitiated = useRef(false);

    const abortControllerRef = useRef<AbortController>();
    useEffect(() => {
        if ( hasInitiated.current ) return;
        hasInitiated.current = true;

        const needsExternalId = searchTerm && !addressSearch;
        if ( !needsExternalId ) return;

        abortControllerRef.current = new AbortController();
        search(searchTerm, abortControllerRef.current)
            .then(addresses => {
                const matchingAddresses = addresses.filter(address => `${address.street}, ${address.zipCode}` === searchTerm);

                if ( matchingAddresses.length === 1 ) {
                    onExactAddress(matchingAddresses[0]);
                } else {
                    onAmbiguousAddress();
                }
            })
            .catch(error => {
                if ( error.name === "AbortError" ) return;

                console.error(error);
            });
    }, [onExactAddress, onAmbiguousAddress, hasInitiated, searchTerm, addressSearch, abortControllerRef]);
}

export const search = async (searchTerm: string, abortController?: AbortController) => {
    const response = await fetch(`https://dawa.aws.dk/autocomplete?q=${searchTerm}&per_side=5&fuzzy=&startfra=adresse`, { signal: abortController?.signal });
    const dawaAddresses = await response.json() as DawaAddress[];

    const mappedAddresses = dawaAddresses.map(address => {
        const zipCode = `${address.data.postnr} ${address.data.postnrnavn}`;
        const street = address.forslagstekst.split(`, ${zipCode}`)[0];

        return {
            externalId: address.data.id,
            street,
            zipCode,
            coordinates: {
                latitude: address.data.y,
                longitude: address.data.x,
            }
        } as AddressFieldAddress;
    });

    return mappedAddresses;
}
