import { Typography, Container, Paper, makeStyles } from "@material-ui/core";

import { ContentSection } from "./layout/ContentSection";

const useStyles = makeStyles({
    paper: {
        padding: "16px",
        textAlign: "center",
    },
});

interface ErrorPageProps {
    message: string;
}

export const ErrorPage = (props: ErrorPageProps) => {
    const classes = useStyles();

    const { message } = props;

    return (
        <ContentSection>

            <Container maxWidth="sm" disableGutters>

                <Paper className={classes.paper}>
                    <Typography>{message}</Typography>
                </Paper>

            </Container>

        </ContentSection>
    );
};
