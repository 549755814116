import { useCallback } from "react";

import firebase from "firebase/compat/app";

import { useBlockingProgress } from "../../components/blocking-progress";
import { useSnackbar } from "../../components/snackbar";
import { ReactivateCustomerDialogProps } from "./ReactivateCustomerDialog";

interface ReactivateCustomerData {
    customerId: string;
    reason: string;
}

const reactivateCustomerFunction: (data: ReactivateCustomerData) => Promise<firebase.functions.HttpsCallableResult>
    = firebase.app().functions("europe-west3").httpsCallable("Customer-reactivate");

export const useReactivateCustomerAction = (customerId: string, callback?: () => void) => {
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    const action: ReactivateCustomerDialogProps["onConfirm"] = useCallback(viewModel => {
        block();

        const data: ReactivateCustomerData = {
            customerId,
            reason: viewModel.reason,
        };

        reactivateCustomerFunction(data)
            .then(() => {
                openSnackbar("success", "Din handling er registreret");
                unblock();
                if ( callback ) callback();
            })
            .catch(() => {
                openSnackbar("error", "Der skete en fejl. Din handling er ikke blevet registreret.");
                unblock();
            });

    }, [block, unblock, openSnackbar, customerId, callback]);

    return action;
};
