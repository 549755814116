import { Container } from "@material-ui/core";

import { BackButton } from "../../components/BackButton";
import { TitleHeader } from "../../components/TitleHeader";
import { CustomerDocument } from "../../customer/customer.model";
import { GardenerDocument } from "../../gardener/gardener.model";
import { ContentSection } from "../../layout/ContentSection";
import { GardeningTaskDocument } from "../gardening-task.model";
import { Section } from "../../components/section";
import { GardeningTaskDetailCard } from "./GardeningTaskDetailCard";
import { AdministratorActionData } from "../../workflow/administrator-action.hook";

interface GardeningTaskDetailPageProps {
    gardeningTask: GardeningTaskDocument;
    customer: CustomerDocument;
    gardener: GardenerDocument | null;
    goBack: () => void;
    administratorAction: (data: AdministratorActionData | string) => void;
}

export const GardeningTaskDetailPage = (props: GardeningTaskDetailPageProps) => {
    const { gardeningTask, customer, gardener, goBack, administratorAction } = props;

    return (
        <>
            <TitleHeader button={<BackButton onClick={goBack} />}>Opgave fra {customer.name}</TitleHeader>
            
            <ContentSection>
                <Container maxWidth="sm" disableGutters>

                    <Section>
                        <GardeningTaskDetailCard
                            gardeningTask={gardeningTask}
                            customer={customer}
                            gardener={gardener}
                            administratorAction={administratorAction}
                        />
                    </Section>

                </Container>
            </ContentSection>
        </>
    );
};
