import AddIcon from "@material-ui/icons/AddCircleOutline";

import { InvoiceFormContext, useInvoiceForm } from "../InvoiceForm";
import { useCallback } from "react";
import { GreenTextButton } from "../../../../../../components/text-button";

export const AddServiceButton = () => {
    const { setForm } = useInvoiceForm();

    const addTask = useCallback(() => {
        setForm((previousState): InvoiceFormContext => ({
            ...previousState,
            serviceInputs: [
                ...previousState.serviceInputs,
                {
                    descriptionInput: {
                        description: "",
                        dirty: false,
                    },
                    durationInput: {
                        hours: undefined,
                        dirty: false,
                    },
                    toolsInput: {
                        tools: undefined,
                        dirty: false,
                    },
                },
            ],
        }));
    }, [setForm]);

    return <GreenTextButton icon={<AddIcon />} onClick={addTask}>Tilføj opgave</GreenTextButton>;
}
