import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 560,
            md: 760,
            lg: 995,
            xl: 1152,
        },
    },
    typography: {
        htmlFontSize: 16,
        fontSize: 16,
        fontFamily: "Montserrat, sans-serif",
    },
});
