import deepEqual from "deep-equal";

import { CustomerNotification, EanCustomerNotification, EmailCustomerNotification, InvoiceCustomerCompany, InvoiceCustomerPerson, InvoiceDocument, LetterCustomerNotification } from "../../invoice.model";
import { DineroContactDocument } from "../../../pending-approval/dinero-contact.model";
import { daysBetween } from "../../../../../date-utility";

export function hasCustomerInformationChanged(invoice: InvoiceDocument, contact: DineroContactDocument): boolean {
    const customerBefore = invoice.customer;
    const customerAfter = mapCustomer(contact);

    return !deepEqual(customerBefore, customerAfter, { strict: true });
}

export function hasContactInformationChanged(invoice: InvoiceDocument, contact: DineroContactDocument): boolean {
    const notificationBefore = determineRelevantNotificationData(invoice.customerNotification);
    const notificationAfter = determineRelevantNotificationData(mapCustomerNotification(contact));

    return !deepEqual(notificationBefore, notificationAfter);
}

export function havePaymentTermsChanged(invoice: InvoiceDocument, contact: DineroContactDocument): boolean {
    const numberOfDaysToPayBefore = daysBetween(invoice.invoiceDate, invoice.invoiceDueDate);

    return numberOfDaysToPayBefore !== contact.numberOfDaysToPay;
}

export function mapCustomer(contact: DineroContactDocument): InvoiceDocument["customer"] {
    return contact.cvr ? mapCustomerCompany(contact) : mapCustomerPerson(contact);
}

function mapCustomerCompany(contact: DineroContactDocument): InvoiceCustomerCompany {
    if ( !contact.cvr ) throw new Error("Contact does not have cvr");

    return {
        id: contact.customerId,
        type: "company",
        companyName: contact.name,
        address: {
            street: contact.address.street,
            zipCode: contact.address.zipCode,
        },
        cvr: contact.cvr,
        att: contact.att,
    };
}

function mapCustomerPerson(contact: DineroContactDocument): InvoiceCustomerPerson {
    return {
        id: contact.customerId,
        type: "person",
        name: contact.name,
        address: {
            street: contact.address.street,
            zipCode: contact.address.zipCode,
        },
    };
}

export function mapCustomerNotification(contact: DineroContactDocument): InvoiceDocument["customerNotification"] {
    if ( contact.ean ) return { type: "ean", ean: contact.ean };
    if ( contact.email ) return { type: "email", email: contact.email, phoneNumber: contact.phoneNumber };

    return { type: "letter", address: contact.address, phoneNumber: contact.phoneNumber };
}

function determineRelevantNotificationData(customerNotification: CustomerNotification): Pick<EanCustomerNotification, "type" | "ean"> | Pick<EmailCustomerNotification, "type" | "email" > | Pick<LetterCustomerNotification, "type" | "address"> {
    switch ( customerNotification.type ) {
        case "ean": return { type: "ean", ean: customerNotification.ean };
        case "email": return { type: "email", email: customerNotification.email };
        case "letter": return { type: "letter", address: customerNotification.address };
    }
}
