import { useCallback } from "react";

import { Chip as MaterialChip, ChipProps as MaterialChipProps, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
    root: {
        padding: "8px 24px",
        borderRadius: "24px",
        height: "auto",
        fontSize: "16px",
        fontWeight: 500,

        "& .MuiChip-label": {
            padding: 0,
            whiteSpace: "normal",
        },
    },
    off: {
        backgroundColor: "transparent",
        color: "#A259FF",
        border: "1px solid #A259FF",
        
        "&:focus": {
            backgroundColor: "transparent",
        },

        "&:hover": {
            backgroundColor: "rgba(162, 89, 255, 0.08) !important",
        },
    },
    on: {
        backgroundColor: "#A259FF",
        color: "#FFFFFF",
        border: "1px solid #A259FF",

        "&:focus": {
            backgroundColor: "#A259FF",
        },

        "&:hover": {
            backgroundColor: "#7A14FF",
        },
    },
});

export interface SwitchItemProps<T> extends Omit<MaterialChipProps, "variant" | "onChange" | "disabled"> {
    on: boolean;
    item: { label: string, value: T };
    onToggle: (value: T) => void;
}

export const SwitchItem = <T extends unknown>(props: SwitchItemProps<T>) => {
    const classes = useStyles();

    const { className, item, on, onToggle, ...otherProps } = props;

    const stateClass = on ? classes.on : classes.off;
    const variant = on ? "default" : "outlined";

    const click = useCallback(() => {
        onToggle(item.value);
    }, [onToggle, item]);

    return (
        <MaterialChip
            className={className ? `${classes.root} ${stateClass} ${className}` : `${classes.root} ${stateClass}`}
            variant={variant}
            onClick={click}
            label={item.label}
            {...otherProps}
        />
    );
}
