import { makeStyles } from "@material-ui/core";

import { calculateInvoiceTotal, formatPrice } from "./invoice-detail/utility";
import { InvoiceDocument } from "./invoice.model";
import { CreditNoteDocument } from "./credit-note.model";

const useStyles = makeStyles({
    discount: {
        marginBottom: "24px",

        "& > div": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "8px",

            "&:last-child": {
                marginBottom: 0,
            }
        },

        "& > div > div": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
        }
    },
    summary: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "& > label": {
            fontSize: "16px",
            fontWeight: 500,
            color: "#4A4A4A",
        },

        "& > span": {
            fontSize: "16px",
            fontWeight: 600,
            color: "#4A4A4A",
        },
    },
});

interface PriceSummarySectionProps {
    invoice: InvoiceDocument | CreditNoteDocument
}

export const PriceSummarySection = (props: PriceSummarySectionProps) => {
    const classes = useStyles();

    const { invoice } = props;

    return (
        <>
            {invoice.discount || invoice.gardenerDiscount ? (
                <div className={classes.discount}>
                    <div>
                        <label>Oprindelig pris</label>
                        <span>{formatPrice(calculateInvoiceTotal(invoice.services, null, null))}</span>
                    </div>

                    <div>
                        <label>Rabat fra havemand</label>
                        <span>-{formatPrice(invoice.gardenerDiscount ?? 0)}</span>
                    </div>

                    <div>
                        <label>Rabat fra Go Go Garden</label>
                        <span>-{formatPrice(invoice.discount ?? 0)}</span>
                    </div>
                </div>
            ) : null}

            <div className={classes.summary}>
                <label>Total</label>
                <span>{formatPrice(calculateInvoiceTotal(invoice.services, invoice.discount, invoice.gardenerDiscount))}</span>
            </div>
        </>
    );
};
