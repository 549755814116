import { useMemo } from "react";

import moment from "moment";

export const useTime = (value: string): string | undefined => {
    return useMemo(() => {
        if ( !value ) return undefined;
        if ( !moment(value, "HH:mm", true).isValid() ) return "Skal være på formen \"14:30\"";

        return undefined;
    }, [value]);
}

export const useTimeAfterCutoff = (date: string, value: string, cutoffDate: string): string | undefined => {
    if ( !value ) return undefined
    if ( !moment(value, "HH:mm", true).isValid() ) return undefined;

    if ( !date ) return undefined;

    const combinedDate = moment(moment(date).format("YYYY-MM-DD") + " " + value, "YYYY-MM-DD HH:mm").toISOString(true);
    if ( !moment(combinedDate).isSame(cutoffDate, "day") ) return undefined;
    if ( moment(combinedDate).isBefore(cutoffDate, "minute") ) return `Skal være efter ${moment(cutoffDate).format("HH:mm")}`;

    return undefined;
}
