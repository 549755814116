import { useMemo } from "react";

import { PageFormItem, GroupFormItem, VerticalButtonsSingleChoiceFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";

export interface SpecifyFrequencyFormIds {
    SingleChoiceFrequency: string;
}

export type SpecifyFrequencyForm<NextPage extends FormItem> = SpecifyFrequencyPage<NextPage>;

type SpecifyFrequencyPage<NextPage extends FormItem> = PageFormItem<
    GroupFormItem<[
        VerticalButtonsSingleChoiceFormItem<NextPage[]>,
    ]>
>;

export const useSpecifyFrequencyForm = <T extends FormItem>(nextPage: T, formIds: SpecifyFrequencyFormIds): SpecifyFrequencyForm<T> => {
    return useMemo(() => {

        const page: SpecifyFrequencyPage<T> = {
            type: "page",
            title: "Angiv frekvens",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        id: formIds.SingleChoiceFrequency,
                        type: "single-choice",
                        mode: "switch page",
                        render: "vertical-buttons",
                        questionText: "Hvor ofte ønskes opgaven udført?",
                        answerTexts: [
                            "Det er en engangsopgave",
                            "Fast hver uge",
                            "Fast hver anden uge",
                            "Fast hver måned",
                        ],
                        answerItems: [nextPage, nextPage, nextPage, nextPage],
                        selectedChoiceIndex: null,
                        dirty: false,
                    },
                ],
            },
        };
        
        return page;
    }, [nextPage, formIds]);
}
