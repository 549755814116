import { PropsWithChildren, useCallback, useState } from "react";

import { makeStyles } from "@material-ui/core";

import { SectionHeader } from "../components/section/SectionHeader";
import { TextArea } from "../components/form/InputField";
import { GreenButton, VerticalButtons } from "../components/button";

const useStyles = makeStyles({
    section: {
        marginBottom: "64px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > *": {
            marginBottom: "32px",

            "&:last-child": {
                marginBottom: 0,
            },
        },
    },
    commentField: {
        "& > *": {
            marginBottom: "16px",

            "&:last-child": {
                marginBottom: 0,
            },
        },
    },
});

interface CommentSectionProps {
    onSave: (comment: string) => void;
}

export const ActivitySection = (props: PropsWithChildren<CommentSectionProps>) => {
    const classes = useStyles();

    const { children, onSave } = props;

    const [comment, setComment] = useState("");

    const saveComment = useCallback(() => {
        onSave(comment);
        setComment("");
    }, [onSave, comment]);

    return (          
        <section className={classes.section}>
            <SectionHeader>Aktivitet</SectionHeader>

            <div className={classes.commentField}>
                <TextArea fullWidth rows={1} value={comment} onChange={setComment} placeholder="Skriv en kommentar" />
                {comment ? (
                    <VerticalButtons>
                        <GreenButton onClick={saveComment}>Gem</GreenButton>
                    </VerticalButtons>
                ) : null}
            </div>

            {children}
        </section>
    );
};
