import { useEffect } from "react";

import { LinearProgress } from "../components/LinearProgress";

interface RedirectToLoginProps {
    fresh?: boolean;
}

export const RedirectToLogin =  (props: RedirectToLoginProps) => {
    const { fresh } = props;

    useEffect(() => {
        const isProduction = window.location.hostname === "admin.gogogarden.dk";

        if ( fresh && isProduction ) return window.location.replace("https://login.gogogarden.dk");

        const returnUrl = fresh ? window.location.origin : window.location.href;
        window.location.replace(`https://login.gogogarden.dk?returnUrl=${returnUrl}`);
    }, [fresh]);

    return <LinearProgress />;
};
