import { useMemo } from "react";

import { PageFormItem, GroupFormItem, InfoFormItem, HorizontalButtonsSingleChoiceFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { OnboardingTaskDocument } from "../../task/task.model";
import { GardenerDocument } from "../../gardener/gardener.model";
import { NoticeFormItem } from "../../components/form-item/client/form-item/NoticeFormItemComponent";

export interface ContactApplicantFormIds {
    SingleChoiceReachedApplicant: string;
}

export type ContactApplicantForm<NextPage extends FormItem> = ContactApplicantPage<NextPage>;

type ContactApplicantPage<ValidPage extends FormItem> = PageFormItem<
    GroupFormItem<[
        InfoFormItem,
        NoticeFormItem,
        HorizontalButtonsSingleChoiceFormItem<[
            ConfirmationPage,
            ValidPage,
        ]>
    ]>
>;

type ConfirmationPage = PageFormItem<InfoFormItem>

export const useContactApplicantForm = <T extends FormItem>(gardener: GardenerDocument, task: OnboardingTaskDocument, nextPage: T, formIds: ContactApplicantFormIds): ContactApplicantForm<T> => {
    return useMemo(() => {

        const firstAttemptConfirmationPage: ConfirmationPage = {
            type: "page",
            title: "Udskyd opgave efter første kontaktforsøg",
            item: {
                type: "info",
                render: "default",
                text: `
                    Opgaven udskydes til i morgen.<br />
                    <br />
                    Ansøgeren informeres om, at vi har forsøgt at komme i kontakt med dem ift. deres ansøgning, og at vi vil kontakte dem igen.
                `,
            },
        };

        const secondAttemptConfirmationPage: ConfirmationPage = {
            type: "page",
            title: "Udskyd opgave efter andet kontaktforsøg",
            item: {
                type: "info",
                render: "default",
                text: `
                    Opgaven udskydes til i morgen.<br />
                    <br />
                    Ansøgeren informeres ikke.
                `,
            },
        };

        const thirdAttemptConfirmationPage: ConfirmationPage = {
            type: "page",
            title: "Afvis ansøgning efter tredje kontaktforsøg",
            item: {
                type: "info",
                render: "default",
                text: `
                    Ansøgningen bliver afvist.<br />
                    <br />
                    Ansøgeren informeres om, at ansøgningen blev afvist fordi vi ikke kunne komme i kontakt med dem.
                `,
            },
        };

        const confirmationPage: ConfirmationPage = (() => {
            const attempts = task.application.history.filter(x => x.event === "called applicant").length + 1;
            if ( attempts === 1 ) return firstAttemptConfirmationPage;
            if ( attempts === 2 ) return secondAttemptConfirmationPage;

            return thirdAttemptConfirmationPage;
        })();
    
        const page: ContactApplicantPage<T> = {
            type: "page",
            title: "Kontakt ansøger",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Ring til ansøgeren på <strong>${gardener.phoneNumber}</strong> med henblik på at have en onboarding-samtale.
                        `,
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            Får du ikke fat i ansøgeren, skal du ikke lægge en besked.
                        `,
                    },
                    {
                        id: formIds.SingleChoiceReachedApplicant,
                        type: "single-choice",
                        mode: "switch page",
                        render: "horizontal-buttons",
                        questionText: "Fik du fat i ansøgeren?",
                        answerTexts: ["Nej", "Ja"],
                        answerItems: [
                            confirmationPage,
                            nextPage,
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                    }
                ],
            },
        };
        
        return page;
    }, [gardener, task, nextPage, formIds]);
}
