import { makeStyles } from "@material-ui/core";

import { Message } from "../../Message";
import { GardeningTaskDocument } from "../gardening-task.model";
import { CustomerDocument } from "../../customer/customer.model";
import { GardeningTaskGardenerListItem } from "./GardeningTaskGardenerListItem";

const useStyles = makeStyles({
    root: {
        "& > *": {
            marginBottom: "24px",

            "&:last-child": {
                marginBottom: 0,
            },
        },
    },
});

interface GardeningTaskGardenerListProps {
    gardeningTasks: GardeningTaskDocument[];
    customers: CustomerDocument[];
    onSelect: (gardeningTask: GardeningTaskDocument) => void;
}

export const GardeningTaskGardenerList = (props: GardeningTaskGardenerListProps) => {
    const classes = useStyles();
    const { gardeningTasks, customers, onSelect } = props;

    if ( gardeningTasks.length === 0 ) return <Message>Der er ingen opgaver at vise</Message>;

    return (
        
        <div className={classes.root}>

            {gardeningTasks.map(gardeningTask => (
                <GardeningTaskGardenerListItem key={gardeningTask.id} gardeningTask={gardeningTask} customers={customers} onSelect={onSelect} />
            ))}

        </div>
    );
};
