import { Dispatch, SetStateAction, useCallback } from "react";

import { Collapse, makeStyles } from "@material-ui/core";

import { Label } from "../../components/form/Label";
import { Group } from "../../components/form/Group";
import { AlternativeHint } from "../../components/form/Hint";
import { TextField } from "../../components/form/InputField";
import { UpdateGardenerDialogErrorModel, UpdateGardenerDialogViewModel } from "./UpdateGardenerDialog";

const useStyles = makeStyles({
    warning: {
        color: "#E37F09 !important",
    }
});

export interface CompanySectionProps {
    viewModel: UpdateGardenerDialogViewModel;
    setViewModel: Dispatch<SetStateAction<UpdateGardenerDialogViewModel>>;
    errorModel: UpdateGardenerDialogErrorModel;
    validationAnimationDuration: number;
}

export const CompanySection = (props: CompanySectionProps) => {
    const classes = useStyles();
    const { viewModel, setViewModel, errorModel, validationAnimationDuration } = props;

    const changeCvr = useCallback((value: string) => {
        setViewModel(viewModel => ({
            ...viewModel,
            company: { ...viewModel.company, cvr: value },
        }));
    }, [setViewModel])

    const markCvrDirty = useCallback(() => {
        setViewModel(viewModel => ({
            ...viewModel,
            company: { ...viewModel.company, cvrDirty: true },
        }));
    }, [setViewModel]);

    return (
        <>
            <div id="cvr-section">
                <Label htmlFor="cvr" style={{ marginBottom: "8px" }}>CVR-nummer</Label>
                <Group error={Boolean(viewModel.company.cvrDirty && errorModel.cvr)}>
                    <TextField id="cvr" value={viewModel.company.cvr} onChange={changeCvr} onBlur={markCvrDirty} />

                    <Collapse in={viewModel.company.cvrDirty} timeout={validationAnimationDuration}>
                        {errorModel.cvr ? (
                            <AlternativeHint message={errorModel.cvr} />
                        ) : (
                            <AlternativeHint className={classes.warning} message="Udfør kun denne ændring efter du har tjekket tegningsregel" />
                        )}
                    </Collapse>
                </Group>
            </div>
        </>
    );
};
