import { useMemo } from "react";

import { PageFormItem, InfoFormItem, VerticalButtonsSingleChoiceFormItem } from "../../components/form-item/client/components";

export interface CustomerResponseFormIds {
    SingleChoiceCustomerResponse: string;
}

export type CustomerResponseForm = 
    PageFormItem<
        VerticalButtonsSingleChoiceFormItem<[
            ConfirmationPage,
            ConfirmationPage,
            ConfirmationPage,
        ]>
    >;

type ConfirmationPage = PageFormItem<InfoFormItem>;

export const useCustomerResponseForm = (formIds: CustomerResponseFormIds): CustomerResponseForm => {
    return useMemo(() => {
        const acceptedProposalConfirmationPage: ConfirmationPage = {
            type: "page",
            title: "Kunden har accepteret løsningen",
            item: {
                type: "info",
                render: "default",
                text: `
                    Opgaven afsluttes.<br />
                    <br />
                    Havemanden modtager en notifikation om, at kunden har accepteret løsningen og at reklamationen derfor er løst.<br />
                    <br />
                    Kunden modtager en notifikation om, at reklamationen er løst og evt. rettelser ifm. løsningen foretages hurtigst muligt.<br />
                    <br />
                    Direktionen modtager en email med instruktion om at effektuere løsningen, såfremt der skal udstedes fakturaer/kreditnotaer.
                `,
            },
        };

        const rejectedProposalConfirmationPage: ConfirmationPage = {
            type: "page",
            title: "Kunden har afvist løsningen",
            item: {
                type: "info",
                render: "default",
                text: `
                    Opgaven afsluttes.<br />
                    <br />
                    Havemanden modtager en notifikation om, at kunden har afvist den tilbudte løsning og at de nu må finde en løsning sammen med kunden.<br />
                    <br />
                    Kunden modtager en notifikation om, at vi har forsøgt at finde en løsning med dem, men at det ikke er lykkedes og at havemanden er blevet informeret om reklamationen.
                `,
            },
        };

        const noResponseConfirmationPage: ConfirmationPage = {
            type: "page",
            title: "Kunden har ikke svaret",
            item: {
                type: "info",
                render: "default",
                text: `
                    Opgaven afsluttes.<br />
                    <br />
                    Havemanden modtager en notifikation om, at kunden ikke har besvaret den tilbudte løsning og at de nu må finde en løsning sammen med kunden.<br />
                    <br />
                    Kunden modtager en notifikation om, at vi har forsøgt at finde en løsning med dem, men at de ikke har besvaret den tilbudte løsning og at havemanden er blevet informeret om reklamationen.
                `,
            },
        };

        return {
            type: "page",
            title: "Har kunden accepteret løsningen?",
            item: {
                id: formIds.SingleChoiceCustomerResponse,
                type: "single-choice",
                mode: "switch page",
                render: "vertical-buttons",
                questionText: null,
                answerTexts: ["Kunden har accepteret løsningen", "Kunden har afvist løsningen", "Kunden har ikke svaret"],
                answerItems: [
                    acceptedProposalConfirmationPage,
                    rejectedProposalConfirmationPage,
                    noResponseConfirmationPage,
                ],
                selectedChoiceIndex: null,
                dirty: false,
            },
        };
    }, [formIds]);
}
