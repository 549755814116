import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core";

import { usePrevious } from "../../../previous.hook";
import { ClusterMarker } from "./cluster-marker.model";
import { ClusterRenderer } from "./ClusterRenderer";

const useStyles = makeStyles(theme => ({
    root: {
        fontFamily: theme.typography.fontFamily,
        fontSize: "16px",
        fontWeight: 500,
        color: "#297A1D",
        textDecoration: "none",

        "&:hover": {
            color: "#1C5B13",
            textDecoration: "underline",
        },
    },
}));

export const useClusterMarkers = (map: google.maps.Map | undefined, markers: ClusterMarker[]) => {
    const classes = useStyles();

    const [state, setState] = useState<google.maps.Marker[]>();
    const previousState = usePrevious(state);

    const renderer = useRef(new ClusterRenderer()).current;

    const history = useHistory();

    useEffect(() => {
        if ( !map ) return;

        previousState?.forEach(marker => {
            google.maps.event.clearListeners(marker, "click");
        });

        const infoWindow = new google.maps.InfoWindow({ content: "", disableAutoPan: true });

        const clusterMarkers = markers.map(marker => {
            const clusterMarker = renderer.render({ count: 1, position: marker.position });

            clusterMarker.addListener("click", () => {
                const element = document.createElement("a");
                element.className = classes.root;
                element.href = `${window.location.protocol}//${window.location.host}${marker.href}`;
                element.text = marker.label;

                element.onclick = (event: MouseEvent) => {
                    event.preventDefault();

                    history.push(marker.href);
                };

                infoWindow.setContent(element);
                infoWindow.open(map, clusterMarker);
            });

            return clusterMarker;
        });

        setState(clusterMarkers);
    }, [history, map, renderer, previousState, classes, markers]);

    return state;
};
