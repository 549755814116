import { PropsWithChildren } from "react";

import { Card, CardContent, CardHeader } from "../components/card";
import { CardGroup } from "../components/card/CardGroup";

export const InstructionCard = (props: PropsWithChildren<{}>) => {
    return (
        <Card>
            <CardHeader>Instruktion</CardHeader>

            <CardContent>

                <CardGroup>
                    <span>
                        {props.children}
                    </span>
                </CardGroup>

            </CardContent>

        </Card>
    );
};
