import { TitleHeader } from "../../components/TitleHeader";
import { TabBarItem } from "../../components/tabs/TabBarItem";
import { TabBar } from "../../components/tabs/TabBar";
import { GardenerListPageAllTab } from "./all-tab/GardenerListPageAllTab";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { GardenerListPageTerminatedTab } from "./terminated-tab/GardenerListPageTerminatedTab";
import { useMemo } from "react";

export const GardenerListPage = () => {
    const { tab } = useParams<{ tab: "all" | "terminated" }>();

    const tabIndex = useMemo(() => {
        switch ( tab ) {
            case "all": return 0;
            case "terminated": return 1;

            default: return 0;
        }
    }, [tab]);

    if ( tab !== "all" && tab !== "terminated" ) return <Redirect to="/gardeners/all" />;

    return (
        <>
            <TitleHeader>Havemænd</TitleHeader>

            <TabBar index={tabIndex}>
                <TabBarItem href="/gardeners/all">Alle havemænd</TabBarItem>
                <TabBarItem href="/gardeners/terminated">Ophørte havemænd</TabBarItem>
            </TabBar>

            <Switch>
                <Route exact path="/gardeners/all">
                    <GardenerListPageAllTab />
                </Route>

                <Route exact path="/gardeners/terminated">
                    <GardenerListPageTerminatedTab />
                </Route>

                <Redirect to="/gardeners/all" />
            </Switch>
        </>
    );
};
