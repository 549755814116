import { useEffect, useState } from "react";

import firebase from "firebase/compat/app";

import { GardeningTaskDocument } from "./gardening-task.model";

export const useGardeningTasksByCustomerId = (customerId: string) => {
    const [state, setState] = useState<GardeningTaskDocument[]>();

    useEffect(() => {
        setState(undefined);

        return firebase.firestore().collection("gardeningTasks").where("customerId", "==", customerId).onSnapshot(snapshot => {
            const gardeningTasks = snapshot.docs.map(document => ({ id: document.id, ...document.data() } as GardeningTaskDocument));

            setState(gardeningTasks);
        });
    }, [customerId]);

    return state;
};

export const useGardeningTasksOfferedToGardener = (gardenerId: string | null | undefined) => {
    const [state, setState] = useState<GardeningTaskDocument[] | null>();

    useEffect(() => {
        if ( gardenerId === undefined ) return setState(undefined);
        if ( gardenerId === null ) return setState(null);

        setState(undefined);

        return firebase.firestore().collection("gardeningTasks").where("matchmaking.currentlyOfferedGardenerIds", "array-contains", gardenerId).onSnapshot(snapshot => {
            const gardeningTasks = snapshot.docs.map(document => ({ id: document.id, ...document.data() } as GardeningTaskDocument));

            setState(gardeningTasks);
        });
    }, [gardenerId]);

    return state;
};

export const useGardeningTasksByGardenerIdAndStatus = (gardenerId: string | null | undefined, status: GardeningTaskDocument["status"]) => {
    const [state, setState] = useState<GardeningTaskDocument[] | null>();

    useEffect(() => {
        if ( gardenerId === undefined ) return setState(undefined);
        if ( gardenerId === null ) return setState(null);

        setState(undefined);

        return firebase.firestore().collection("gardeningTasks")
            .where("matchmaking.selectedGardenerId", "==", gardenerId)
            .where("status", "==", status)
            .onSnapshot(snapshot => {
                const gardeningTasks = snapshot.docs.map(document => ({ id: document.id, ...document.data() } as GardeningTaskDocument));

                setState(gardeningTasks);
            });
    }, [gardenerId, status]);

    return state;
};

export const useGardeningTasksByGardenerIdWorkingStatus = (gardenerId: string | null | undefined, workingStatus: GardeningTaskDocument["working"]["status"]) => {
    const [state, setState] = useState<GardeningTaskDocument[] | null>();

    useEffect(() => {
        if ( gardenerId === undefined ) return setState(undefined);
        if ( gardenerId === null ) return setState(null);

        setState(undefined);

        return firebase.firestore().collection("gardeningTasks")
            .where("matchmaking.selectedGardenerId", "==", gardenerId)
            .where("status", "==", "found gardener")
            .where("working.status", "==", workingStatus)
            .onSnapshot(snapshot => {
                const gardeningTasks = snapshot.docs.map(document => ({ id: document.id, ...document.data() } as GardeningTaskDocument));

                setState(gardeningTasks);
            });
    }, [gardenerId, workingStatus]);

    return state;
};

export const useGardeningTasksBySelectedGardenerId = (gardenerId: string | null | undefined) => {
    const [state, setState] = useState<GardeningTaskDocument[] | null>();

    useEffect(() => {
        if ( gardenerId === undefined ) return setState(undefined);
        if ( gardenerId === null ) return setState(null);

        setState(undefined);

        return firebase.firestore().collection("gardeningTasks").where("matchmaking.selectedGardenerId", "==", gardenerId).onSnapshot(snapshot => {
            const gardeningTasks = snapshot.docs.map(document => ({ id: document.id, ...document.data() } as GardeningTaskDocument));

            setState(gardeningTasks);
        });
    }, [gardenerId]);

    return state;
};

export const useGardeningTask = (gardeningTaskId: string | null | undefined) => {
    const [state, setState] = useState<GardeningTaskDocument | null>();

    useEffect(() => {
        if ( gardeningTaskId === undefined ) return setState(undefined);
        if ( gardeningTaskId === null ) return setState(null);

        setState(undefined);

        return firebase.firestore().collection("gardeningTasks").doc(gardeningTaskId).onSnapshot(document => {
            if ( !document.exists ) return setState(null);

            const gardeningTask = { id: document.id, ...document.data() } as GardeningTaskDocument;

            setState(gardeningTask);
        });
    }, [gardeningTaskId]);

    return state;
};
