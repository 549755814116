import { Link } from "react-router-dom";

import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { MatchOfferRetractedCommentDocument } from "../comment.model";

export interface MatchOfferRetractedCommentProps {
    comment: MatchOfferRetractedCommentDocument;
}

export const MatchOfferRetractedComment = (props: MatchOfferRetractedCommentProps) => {
    const { comment } = props;

    const customer = <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link>;

    const gardener = <Link to={`/gardeners/context/${comment.gardenerId}`}>{comment.gardenerName}</Link>;

    const reason = (() => {
        switch ( comment.reason ) {
            case "match-deleted": return "Tilknytning blev fjernet.";
            default: return comment.reason;
        }
    })();

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Tilbud trukket tilbage">
            <CommentBody>
                {customer} er ikke længere tilbudt til {gardener}.<br />
                <br />
                Årsag:<br />
                {reason}
            </CommentBody>
        </Comment>
    );
};
