import { useMemo } from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core";

import { Card, CardHeader, CardStatus, LegacyCardContent } from "../../components/card";
import { CustomerDocument } from "../../customer/customer.model";
import { GardenerDocument } from "../../gardener/gardener.model";
import { DashedBorderSeparator } from "../../finance/billing/invoices/DashedBorderSeparator";
import { FormGroup } from "../../finance/billing/invoices/invoice-detail/FormGroup";
import { formatDate, formatServiceDuration, formatServiceTools } from "../../finance/billing/invoices/invoice-detail/utility";
import { PriceSummarySection } from "../../finance/billing/invoices/PriceSummarySection";
import { InvoiceDocument } from "../../finance/billing/invoices/invoice.model";

const useStyles = makeStyles(theme => ({
    multiline: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        lineHeight: "125%",
        whiteSpace: "pre-wrap",
    },
    topGrid: {
        display: "grid",
        gridAutoColumns: "minmax(0, 1fr)",
        gridAutoFlow: "column",
        gridTemplateAreas: `
            "gardener invoice-date"
            "customer invoice-due-date"
        `,
        marginBottom: "24px",

        "& > *:nth-child(1)": {
            gridArea: "gardener",
        },
        "& > *:nth-child(2)": {
            gridArea: "customer",
            marginBottom: 0,
        },
        "& > *:nth-child(3)": {
            gridArea: "invoice-date",
        },
        "& > *:nth-child(4)": {
            gridArea: "invoice-due-date",
        },

        "& > *:nth-child(1), & > *:nth-child(2)": {
            marginRight: "32px",
        },
        "& > *:nth-child(3), & > *:nth-child(4)": {
            textAlign: "right",
        },

        [theme.breakpoints.down(500)]: {
            gridTemplateAreas: `
                "gardener"
                "customer"
                "invoice-date"
                "invoice-due-date"
            `,

            "& > *:nth-child(1), & > *:nth-child(2)": {
                marginRight: 0,
                marginBottom: "24px",
            },
            "& > *:nth-child(3), & > *:nth-child(4)": {
                textAlign: "left",
            }
        },
    },
    service: {
        marginBottom: "48px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            display: "block",
            fontSize: "16px",
            fontWeight: 600,
            color: "#4A4A4A",
            marginTop: 0,
            marginBottom: "24px",
        },
    },
}));

interface InvoiceDetailCardProps {
    alert?: JSX.Element | null;
    invoice: InvoiceDocument;
    customer: CustomerDocument | null;
    gardener: GardenerDocument | null;
    button?: JSX.Element;
}

export const InvoiceDetailCard = (props: InvoiceDetailCardProps) => {
    const classes = useStyles();

    const { alert, invoice, customer, gardener, button } = props;

    const gardenerName = useMemo(() => {
        if ( gardener ) return gardener.name;

        return invoice.gardener.type !== "company" ? invoice.gardener.name : invoice.gardener.contactPerson;
    }, [gardener, invoice.gardener]);

    const customerName = useMemo(() => {
        if ( customer ) return customer.name;

        return invoice.customer.type === "person" ? invoice.customer.name : invoice.customer.companyName;
    }, [customer, invoice.customer]);

    return (
        <Card>

            {(() => {
                switch ( invoice.status ) {
                    case "will-credit": return <CardStatus status="Krediteres" color="alt" />;
                    case "did-credit": return <CardStatus status="Krediteret" color="info" />;
                    default: return null;
                }
            })()}

            <CardHeader button={button}>
                Faktura {invoice.invoiceNumber}
            </CardHeader>

            {alert}

            <LegacyCardContent>

                <div className={classes.topGrid}>

                    <FormGroup>
                        <label>Havemand</label>
                        <span>
                            <Link to={`/gardeners/context/${invoice.gardener.id}`}>{gardenerName}</Link>
                        </span>
                    </FormGroup>

                    <FormGroup>
                        <label>Kunde</label>
                        <span>
                            <Link to={`/customers/context/${invoice.customer.id}`}>{customerName}</Link>
                        </span>
                    </FormGroup>

                    <FormGroup>
                        <label>Fakturadato</label>
                        <span>{formatDate(invoice.invoiceDate)}</span>
                    </FormGroup>

                    <FormGroup>
                        <label>Forfaldsdato</label>
                        <span>{formatDate(invoice.invoiceDueDate)}</span>
                    </FormGroup>

                </div>

                <div>
                    <FormGroup>
                        <label>Dato for besøg</label>
                        <span>{formatDate(invoice.serviceDate)}</span>
                    </FormGroup>
                </div>

                <DashedBorderSeparator />

                <div>
                    {invoice.services.map((service, index) => (
                        <div key={index} className={classes.service}>
                            <label>Opgave {index + 1}</label>

                            <FormGroup>
                                <label>Beskrivelse</label>
                                <span className={classes.multiline}>{service.description}</span>
                            </FormGroup>

                            <FormGroup>
                                <label>Varighed</label>
                                <span>{formatServiceDuration(service.hours)}</span>
                            </FormGroup>

                            <FormGroup>
                                <label>Redskaber</label>
                                <span>{formatServiceTools(service.tools)}</span>
                            </FormGroup>

                        </div>
                    ))}
                </div>
                
                <DashedBorderSeparator />

                <PriceSummarySection invoice={invoice} />

            </LegacyCardContent>

        </Card>
    );
};
