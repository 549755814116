import { useMemo } from "react";

import { PageFormItem, GroupFormItem, InfoFormItem, TextAreaFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { GardenerDocument } from "../../gardener/gardener.model";
import { NoticeFormItem } from "../../components/form-item/client/form-item/NoticeFormItemComponent";

export interface CheckNameFormIds {
    TextAreaName: string;
}

export type CheckNameForm<NextPage extends FormItem> = CheckNamePage<NextPage>;

type CheckNamePage<NextPage extends FormItem> =
    PageFormItem<
        GroupFormItem<[
            InfoFormItem,
            NoticeFormItem,
            TextAreaFormItem,
            NextPage,
        ]>
    >;

export const useCheckNameForm = <T extends FormItem>(gardener: GardenerDocument, nextPage: T, formIds: CheckNameFormIds): CheckNameForm<T> => {
    return useMemo(() => {

        const page: CheckNamePage<T> = {
            type: "page",
            title: "Bekræft ansøgerens navn",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Bed ansøgeren om at bekræfte deres navn og ret det, hvis det er forkert.
                        `,
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            <ul>
                                <li>Skal være ansøgerens fulde navn i rigtig rækkefølge</li>
                                <li>Du må ikke ændre navnet til en helt anden person</li>
                                <li>Ingen forkortelser, punktummer eller andre specialtegn</li>
                            </ul>
                        `,
                    },
                    {
                        id: formIds.TextAreaName,
                        type: "text-area",
                        render: "text-field",
                        label: "Ansøgerens fulde navn",
                        placeholder: null,
                        value: gardener.name,
                        dirty: false,
                    },
                    nextPage,
                ],
            },
        };
        
        return page;
    }, [gardener, nextPage, formIds]);
}
