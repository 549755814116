import { HTMLAttributes } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    legacyRoot: {
        padding: "24px",

        [theme.breakpoints.down(500)]: {
            padding: "24px 16px",
        },
    },
    root: {
        padding: "24px",

        [theme.breakpoints.down(500)]: {
            padding: "24px 16px",
        },

        "& > *": {
            marginBottom: "16px",
        },

        "& > *:last-child": {
            marginBottom: 0,
        }
    },
}));

export const LegacyCardContent = (props: HTMLAttributes<HTMLDivElement>) => {
    const classes = useStyles();

    const { className, ...otherProps } = props;

    return (
        <div className={className ? `${classes.legacyRoot} ${className}` : classes.legacyRoot} {...otherProps} />
    );
};

export const CardContent = (props: HTMLAttributes<HTMLDivElement>) => {
    const classes = useStyles();

    const { className, ...otherProps } = props;

    return (
        <div className={className ? `${classes.root} ${className}` : classes.root} {...otherProps} />
    );
};
