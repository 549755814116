import { useMemo } from "react";

import { PageFormItem, GroupFormItem, InfoFormItem, SingleChoiceFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { NoticeFormItem } from "../../components/form-item/client/form-item/NoticeFormItemComponent";
import { GuardrailsProtectionDocument } from "../shared/guardrails-protection.model";

export interface ReasonForCallFormIds {
    SingleChoiceWillGiveExtraChance: string;
}

export type ReasonForCallForm<NextPage extends FormItem> = ReasonForCallPage<NextPage>;

type ReasonForCallPage<NextPage extends FormItem> =
    PageFormItem<
        GroupFormItem<[
            InfoFormItem,
            NoticeFormItem,
            NoticeFormItem,
            NoticeFormItem,
            SingleChoiceFormItem<[
                ConfirmationPage,
                NextPage
            ]>,
        ]>
    >;

type ConfirmationPage = PageFormItem<InfoFormItem>

export const useReasonForCallForm = <T extends FormItem>(protection: GuardrailsProtectionDocument, now: string, nextPage: T, formIds: ReasonForCallFormIds): ReasonForCallForm<T> => {
    return useMemo(() => {
        const confirmationPage: ConfirmationPage = {
            type: "page",
            title: "Ophør samarbejde",
            item: {
                type: "info",
                render: "default",
                text: `
                    Samarbejdet ophøres med havemanden med begrundelsen “samarbejdet fungerer ikke”.<br />
                    <br />
                    Havemanden informeres om, at samarbejdet er ophørt.<br />
                    <br />
                    Hvis havemanden stadig har nogen opgaver på sin liste, vil systemet automatisk lede efter en anden havemand til dem.
                `,
            },
        };

        const warningDates = protection.records.filter(x => x.type === "warning").map(x => x.date);
        const lastWarningDate = warningDates.length > 0 ? warningDates[warningDates.length - 1] : null;
        const relevantRecords = protection.records.filter(x => x.date >= "2024-04-01").filter(x => (lastWarningDate ? x.date >= lastWarningDate : true) && x.date < now);
        const solvedTasks = relevantRecords.filter(x => x.type === "solved" || x.type === "disputed").length;
        const totalTasks = relevantRecords.filter(x => x.type === "disputed" || x.type === "solved" || x.type === "stalled" || x.type === "lost").length;
        const complaints = relevantRecords.filter(x => x.type === "disputed").length;

        const complaintsParagraph = (() => {
            if ( complaints === 0 ) return ".";
            if ( solvedTasks === 1 ) return `, som du <strong>modtog en reklamation</strong> på.`;

            return `, hvor du modtog en <strong>reklamation på ${complaints}</strong> af dem.`;
        })();
    
        const page: ReasonForCallPage<T> = {
            type: "page",
            title: "Fortæl havemanden om hvorfor du ringer",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: "Stil havemanden disse spørgsmål for at finde ud af, om de skal have en chance til.",
                    },
                    {
                        type: "notice",
                        render: "info",
                        html: `
                            <strong>Hvorfor er så få af dine opgaver blevet løst?</strong><br />
                            <br />
                            Af de seneste <strong>${totalTasks} opgaver</strong>, som du har sagt ja tak til, har du kun løst <strong>${solvedTasks} ${solvedTasks === 1 ? "opgave" : "opgaver"}</strong>${complaintsParagraph}<br />
                            <br />
                            Resten er enten blevet lukket uden at der er lavet en tidsregistrering eller blevet planlagt langt ude i fremtiden. Hvordan kan det være?
                        `,
                    },
                    {
                        type: "notice",
                        render: "info",
                        html: `
                            <strong>Sådan fungerer systemet</strong><br />
                            <br />
                            Det fungerer sådan, at hver gang du løser en opgave, så får du mulighed for at få tilbudt endnu flere nye opgaver.
                            Omvendt vil du blive tilbudt færre nye opgaver, når du ikke løser en opgave, som du har sagt ja tak til.<br />
                            <br />
                            Vi forventer ikke, at 100% af alle opgaver lykkes, fordi meget afhænger også af kunden.
                            Vi kigger på hvor ofte opgaverne generelt lykkes på tværs af alle havemændene på platformen. Så længe man ligger omkring gennemsnittet, så løber man ikke tør for opgaver.<br />
                            <br />
                            Fungerer vores samarbejde godt, har vi kun interesse i, at tilbyde dig så mange opgaver som muligt.
                        `,
                    },
                    {
                        type: "notice",
                        render: "info",
                        html: `
                            <strong>Du er løbet tør for opgaver, men kan få en chance til</strong><br />
                            <br />
                            Grunden til jeg ringer er, at du har nået vores grænse, fordi du har løst for få af dine opgaver, og du kan derfor ikke få tilbudt flere nye opgaver.<br />
                            <br />
                            Hvis du tror, at du kan gøre det bedre og du stadig gerne vil være en del af platformen, så kan vi godt give samarbejdet en chance til. Vil du gerne det?
                        `,
                    },
                    {
                        id: formIds.SingleChoiceWillGiveExtraChance,
                        type: "single-choice",
                        mode: "switch page",
                        render: "horizontal-buttons",
                        questionText: "Vil du give samarbejdet med havemanden en chance til?",
                        answerTexts: [
                            "Nej",
                            "Ja"
                        ],
                        answerItems: [
                            confirmationPage,
                            nextPage,
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                    }
                ],
            },
        };
        
        return page;
    }, [protection, now, nextPage, formIds]);
}
