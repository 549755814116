import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core";
import CloseComplaintIcon from "@material-ui/icons/CheckCircleOutline";

import { Card, CardHeader, CardStatus, CardContent, CardAlert } from "../components/card";
import { FormGroup } from "../finance/billing/invoices/invoice-detail/FormGroup";
import { ComplaintDocument } from "./complaint.model";
import { UnorderedList } from "../components/unordered-list/UnorderedList";
import { AdministratorActionData } from "../workflow/administrator-action.hook";
import { useCallback } from "react";
import { MenuButton } from "../components/menu/MenuButton";
import { MenuItem } from "../components/menu/MenuItem";
import { CloseComplaintFormIds, useCloseComplaintDialog } from "./CloseComplaintDialog";
import { FormProps } from "../components/form-item/framework/react/FormProvider";
import { FormItem } from "../components/form-item/framework/core/form-item.model";
import { TextAreaFormItem } from "../components/form-item/client/components";
import { DashedBorderSeparator } from "../finance/billing/invoices/DashedBorderSeparator";

const useStyles = makeStyles({
    link: {
        fontSize: "16px",
        fontWeight: 500,
        color: "#297A1D",
        textDecoration: "none",

        "&:hover": {
            color: "#1C5B13",
            textDecoration: "underline",
        },
    },
});

interface ComplaintDetailCardProps {
    complaint: ComplaintDocument;
    administratorAction: (data: AdministratorActionData | string) => void;
}

export const ComplaintDetailCard = (props: ComplaintDetailCardProps) => {
    const classes = useStyles();
    const { complaint, administratorAction } = props;

    const closeComplaintAdapter = useCallback<FormProps<FormItem>["onSubmit"]>((controller, item) => {
        const reason = (controller.getItem(CloseComplaintFormIds.TextAreaReason, item) as TextAreaFormItem).value;

        administratorAction({
            action: "close complaint",
            complaintId: complaint.id,
            reason,
        });
    }, [administratorAction, complaint.id]);
    const openCloseComplaintDialog = useCloseComplaintDialog(complaint, closeComplaintAdapter);

    const button = complaint.status !== "resolved" ? (
        <MenuButton>
            <MenuItem icon={<CloseComplaintIcon />} onClick={openCloseComplaintDialog}>Afslut reklamation</MenuItem>
        </MenuButton>
    ) : undefined;

    const resolvedEvent = complaint.history.find(x => x.event === "complaint resolved") as Extract<ComplaintDocument["history"][number], { event: "complaint resolved" }>;

    return (
        <Card>

            {(() => {
                switch ( complaint.status ) {
                    case "contacting customer": return <CardStatus status="Kunden skal kontaktes" color="warning" />;
                    case "contacting gardener": return <CardStatus status="Havemanden skal kontaktes" color="warning" />;
                    case "determining proposal": return <CardStatus status="Reklamationen er overdraget til direktionen" color="warning" />;
                    case "awaiting customer response to proposal": return <CardStatus status="Afventer kundens svar på tilbudt løsning" color="warning" />;
                    case "resolved": return <CardStatus status="Reklamationen er løst" color="success" />;
                    case "handed over to gardener": return <CardStatus status="Reklamationen er overdraget til havemanden" color="danger" />;
                    default: return null;
                }
            })()}

            <CardHeader button={button}>Reklamation</CardHeader>

            {resolvedEvent ? (
                <CardAlert>
                    <p>Denne reklamation er afsluttet</p>
                    <p>“{resolvedEvent.reason.text}”</p>
                </CardAlert>
            ) : null}

            <CardContent>

                <FormGroup>
                    <label>Fakturaer, der blev reklameret over</label>
                    <UnorderedList>
                        {complaint.invoices.map(invoice => (
                            <li key={invoice.id}>
                                <Link to={`/invoices/all/invoice/${invoice.id}`} className={classes.link}>Faktura {invoice.invoiceNumber}</Link>
                            </li>
                        ))}
                    </UnorderedList>
                </FormGroup>

                {complaint.customerOpinion || complaint.status === "contacting customer" ? (
                    <>
                        <FormGroup>
                            <DashedBorderSeparator noGutter />
                        </FormGroup>

                        <FormGroup>
                            <label>Hvad siger kunden?</label>
                            <span>{complaint.customerOpinion?.comment || "Ikke angivet endnu"}</span>
                        </FormGroup>

                        <FormGroup>
                            <label>Kundens løsningsforslag</label>
                            <span>{complaint.customerOpinion?.proposal || "Ikke angivet endnu"}</span>
                        </FormGroup>
                    </>
                ) : null}

                {complaint.gardenerOpinion || complaint.status === "contacting gardener" ? (
                    <>
                        <FormGroup>
                            <DashedBorderSeparator noGutter />
                        </FormGroup>

                        <FormGroup>
                            <label>Hvad siger havemanden?</label>
                            <span>{complaint.gardenerOpinion?.comment || "Ikke angivet endnu"}</span>
                        </FormGroup>

                        <FormGroup>
                            <label>Havemandens løsningsforslag</label>
                            <span>{complaint.gardenerOpinion?.proposal || "Ikke angivet endnu"}</span>
                        </FormGroup>
                    </>
                ) : null}

                {complaint.platformOpinion || complaint.status === "determining proposal" ? (
                    <>
                        <FormGroup>
                            <DashedBorderSeparator noGutter />
                        </FormGroup>

                        <FormGroup>
                            <label>Hvad siger Go Go Garden?</label>
                            <span>{complaint.platformOpinion?.comment || "Ikke angivet endnu"}</span>
                        </FormGroup>

                        <FormGroup>
                            <label>Go Go Gardens løsningsforslag</label>
                            <span>{complaint.platformOpinion?.proposal || "Ikke angivet endnu"}</span>
                        </FormGroup>
                    </>
                ) : null}

            </CardContent>

        </Card>
    );
};
