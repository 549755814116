import { FocusEvent, useCallback } from "react";

import { Collapse, makeStyles } from "@material-ui/core";

import { TextField } from "../../../../../components/form/InputField";
import { InvoiceFormContext, useInvoiceForm } from "./InvoiceForm";
import { calculateGardenerCommissionIncludingVat, formatNumber } from "../utility";
import { Group } from "../../../../../components/form/Group";
import { AlternativeHint } from "../../../../../components/form/Hint";
import { determineServices } from "./utility";

const useStyles = makeStyles({
    root: {
        "& > input": {
            textAlign: "right",
            paddingRight: "8px",
        },
    },
    adornment: {
        whiteSpace: "nowrap",
    },
    warning: {
        color: "#E37F09 !important",
    },
});

interface GardenerDiscountInputProps {
    id?: string;
}

export const GardenerDiscountInput = (props: GardenerDiscountInputProps) => {
    const classes = useStyles();

    const { id } = props;

    const { form, setForm } = useInvoiceForm();
    const { gardenerDiscountInput } = form;

    const updateGardenerDiscount = useCallback((text: string) => {
        let seenComma = false;
        let gardenerDiscountFormatted = text.split("").filter(x => {
            if ( x.match(/\d/) ) return true;
            
            if ( x.match(",") && !seenComma ) {
                seenComma = true;
                return true;
            }

            return false;
        }).join("");
        gardenerDiscountFormatted = gardenerDiscountFormatted.indexOf(",") !== 0 ? gardenerDiscountFormatted : gardenerDiscountFormatted.substring(1);
        gardenerDiscountFormatted = gardenerDiscountFormatted.indexOf(",") === -1 ? gardenerDiscountFormatted : gardenerDiscountFormatted.split(",")[0] + "," + gardenerDiscountFormatted.split(",")[1].substring(0, 2);

        const gardenerDiscount = gardenerDiscountFormatted ? parseFloat(parseFloat(gardenerDiscountFormatted.replace(",", ".")).toFixed(2)) : null;

        setForm((previousState): InvoiceFormContext => ({
            ...previousState,
            gardenerDiscountInput: {
                ...previousState.gardenerDiscountInput,
                gardenerDiscount,
                gardenerDiscountFormatted,
            },
        }))
    }, [setForm]);

    const selectText = useCallback((event: FocusEvent<HTMLInputElement>) => {
        setTimeout(() => {
            event.target.setSelectionRange(0, event.target.value.length);
        }, 0);
    }, []);

    const onBlur = useCallback(() => {
        setForm((previousState): InvoiceFormContext => ({
            ...previousState,
            gardenerDiscountInput: {
                ...previousState.gardenerDiscountInput,
                gardenerDiscountFormatted: formatNumber(previousState.gardenerDiscountInput.gardenerDiscount ?? 0),
                dirty: true,
            },
        }))
    }, [setForm]);

    const adornment = <span className={classes.adornment}>kr.</span>;

    const error = validateGardenerDiscountInput(form);

    return (
        <Group error={Boolean(gardenerDiscountInput.dirty && error)}>
            <TextField id={id} value={gardenerDiscountInput.gardenerDiscountFormatted} onChange={updateGardenerDiscount} onFocus={selectText} onBlur={onBlur} endAdornment={adornment} className={classes.root} autoComplete="off" />

            <Collapse in={Boolean(gardenerDiscountInput.dirty)} timeout={form.validationAnimationDuration}>
                <AlternativeHint message={error} />
            </Collapse>
        </Group>
    );
}

export interface GardenerDiscountInputContext {
    gardenerDiscount: number | null;
    gardenerDiscountFormatted: string;
    dirty: boolean;
}

export const newGardenerDiscountInputContext = (gardenerDiscount: number | null): GardenerDiscountInputContext => ({
    gardenerDiscount,
    gardenerDiscountFormatted: formatNumber(gardenerDiscount ?? 0),
    dirty: true,
});

export const gardenerDiscountInputHasChanged = (form: InvoiceFormContext) => {
    return form.invoice.gardenerDiscount !== form.gardenerDiscountInput.gardenerDiscount;
};

export const validateGardenerDiscountInput = (form: InvoiceFormContext) => {
    if ( !form.gardenerDiscountInput.gardenerDiscount ) return undefined;

    const services = determineServices(form);
    const serviceCommission = calculateGardenerCommissionIncludingVat(services);
    if ( form.gardenerDiscountInput.gardenerDiscount > serviceCommission ) return "Må ikke overstige havemandens honorar";

    return undefined;
};
