import { useCallback, useRef, useState } from "react";

import { Collapse, makeStyles } from "@material-ui/core";
import { da } from "date-fns/locale";
import moment from "moment";

import { NeutralAction } from "../../components/card/NeutralAction";
import { PositiveAction } from "../../components/card/PositiveAction";
import { Dialog, DialogActions, DialogContent, DialogHeader, useDialog } from "../../components/dialog";
import { Label } from "../../components/form/Label";
import { Group } from "../../components/form/Group";
import { AlternativeHint } from "../../components/form/Hint";
import { useAnimation } from "../../components/validation/animation.hook";
import { DateField, TextArea, TimeField } from "../../components/form/InputField";
import { useTime, useTimeAfterCutoff } from "../../components/validation/time.hook";
import { useDate, useDateAfterCutoff } from "../../components/validation/date.hook";
import { useMandatory } from "../../components/validation/mandatory.hook";

const useStyles = makeStyles(theme => ({
    text: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        marginTop: 0,
    },
    doubleGroup: {
        display: "flex",

        "& > *": {
            flexBasis: "50%",
            marginRight: "24px",
        },

        "& > *:last-child": {
            marginRight: 0,
        },

        [theme.breakpoints.down(675)]: {
            flexDirection: "column",

            "& > *": {
                flexBasis: "100%",
                marginRight: 0,
                marginBottom: "24px",
            },
    
            "& > *:last-child": {
                marginBottom: 0,
            },
        },
    },
}));

export interface ChangeTimeDialogProps {
    onConfirm: (data: { dueDate: string, reason: string }) => void;
    onCancel: () => void;
    limitTime: string | undefined;
}

export const ChangeTimeDialog = (props: ChangeTimeDialogProps) => {
    const classes = useStyles();

    const { onConfirm, onCancel, limitTime } = props;

    const [performAfterDateDate, setPerformAfterDateDate] = useState("");
    const [performAfterDateDateDirty, setPerformAfterDateDateDirty] = useState(false);
    const markPerformAfterDateDateDirty = useCallback(() => setPerformAfterDateDateDirty(true), []);

    const [performAfterDateTime, setPerformAfterDateTime] = useState(limitTime ?? "");
    const [performAfterDateTimeDirty, setPerformAfterDateTimeDirty] = useState(false);
    const markPerformAfterDateTimeDirty = useCallback(() => setPerformAfterDateTimeDirty(true), []);

    const [reason, setReason] = useState("");
    const [reasonDirty, setReasonDirty] = useState(false);
    const markReasonDirty = useCallback(() => setReasonDirty(true), []);

    const [validationAnimationDuration, skipValidationAnimation] = useAnimation();
    const targetDate = useTargetDate(limitTime);

    const performAfterDateDateError = [useMandatory(performAfterDateDate), useDate(performAfterDateDate), useDateAfterCutoff(performAfterDateDate, targetDate)].find(Boolean);
    const performAfterDateTimeError = [useMandatory(performAfterDateTime), useTime(performAfterDateTime), useTimeAfterCutoff(performAfterDateDate, performAfterDateTime, targetDate)].find(Boolean);
    const reasonError = [useMandatory(reason)].find(Boolean);

    const scrollToFirstError = useCallback(() => {
        const sectionId = (() => {
            if ( performAfterDateDateError ) return "perform-after-date-date-section";
            if ( performAfterDateTimeError ) return "perform-after-date-time-section";
            if ( reasonError ) return "reason-section";

            return undefined;
        })();

        if ( !sectionId ) return;

        const section = document.getElementById(sectionId);
        if ( !section ) return;

        section.scrollIntoView({ behavior: "smooth" });
    }, [performAfterDateDateError, performAfterDateTimeError, reasonError]);

    const dueDate = moment(`${performAfterDateDate} ${performAfterDateTime}`, "YYYY-MM-DD HH:mm").toISOString(true);
    const dueDateError = Boolean(performAfterDateDateError || performAfterDateTimeError);

    const confirm = useCallback(() => {
        if ( document.activeElement ) {
            (document.activeElement as HTMLInputElement).blur();
        }

        if ( dueDateError || reasonError ) {
            skipValidationAnimation();
            setPerformAfterDateDateDirty(true);
            setPerformAfterDateTimeDirty(true);
            setReasonDirty(true);
            return setTimeout(scrollToFirstError, 100);
        }

        onConfirm({ dueDate, reason });
    }, [skipValidationAnimation, scrollToFirstError, onConfirm, dueDate, dueDateError, reason, reasonError]);

    return (
        <Dialog>
            <DialogHeader>Ændr tidspunkt?</DialogHeader>

            <DialogContent>

                <p className={classes.text}>Tidspunktet for udførsel af opgaven vil blive ændret til det valgte.</p>

                <div className={limitTime ? undefined : classes.doubleGroup}>

                    <div id="perform-after-date-date-section">
                        <Label htmlFor="perform-after-date-date" style={{ marginBottom: "8px" }}>Dato for udførsel</Label>
                        <Group error={Boolean(performAfterDateDateDirty && performAfterDateDateError)}>
                            <DateField id="perform-after-date-date" value={performAfterDateDate} onChange={setPerformAfterDateDate} onBlur={markPerformAfterDateDateDirty} placeholder="DD-MM-YYYY" minimumDate={new Date(targetDate)} locale={da} />

                            <Collapse in={Boolean(performAfterDateDateDirty && performAfterDateDateError)} timeout={validationAnimationDuration}>
                                <AlternativeHint message={performAfterDateDateError} />
                            </Collapse>
                        </Group>
                    </div>

                    {limitTime ? null : (
                        <div id="perform-after-date-time-section">
                            <Label htmlFor="perform-after-date-time" style={{ marginBottom: "8px" }}>Klokkeslæt</Label>
                            <Group error={Boolean(performAfterDateTimeDirty && performAfterDateTimeError)}>
                                <TimeField id="perform-after-date-time" value={performAfterDateTime} onChange={setPerformAfterDateTime} onBlur={markPerformAfterDateTimeDirty} placeholder="HH:mm" />

                                <Collapse in={Boolean(performAfterDateTimeDirty && performAfterDateTimeError)} timeout={validationAnimationDuration}>
                                    <AlternativeHint message={performAfterDateTimeError} />
                                </Collapse>
                            </Group>
                        </div>
                    )}


                </div>

                <div id="reason-section">
                    <Label htmlFor="reason" style={{ marginBottom: "8px" }}>Årsag til udskydelse</Label>
                    <Group error={Boolean(reasonDirty && reasonError)}>
                        <TextArea id="reason" value={reason} onChange={setReason} onBlur={markReasonDirty} placeholder="Angiv årsagen til at du vil udskyde opgaven" />

                        <Collapse in={Boolean(reasonDirty && reasonError)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={reasonError} />
                        </Collapse>
                    </Group>
                </div>

            </DialogContent>

            <DialogActions>
                <NeutralAction onClick={onCancel}>Annuller</NeutralAction>
                <PositiveAction onClick={confirm}>Ja, ændr tidspunkt</PositiveAction>
            </DialogActions>

        </Dialog>
    );
};

function useTargetDate(limitTime: string | undefined): string {
    const targetDate = useRef(moment().toISOString(true)).current;

    if ( !limitTime ) return targetDate;

    const afterCutoff = moment(targetDate).format("HH:mm") >= limitTime;
    if ( !afterCutoff ) return targetDate;

    return moment(targetDate).add(1, "day").startOf("day").toISOString(true);
}

export const useChangeTimeDialog = (onConfirm: ChangeTimeDialogProps["onConfirm"], limitTime: ChangeTimeDialogProps["limitTime"]) => {
    const { openDialog, closeDialog } = useDialog();

    const confirm: ChangeTimeDialogProps["onConfirm"] = useCallback((reason) => {
        closeDialog();

        onConfirm(reason);
    }, [closeDialog, onConfirm]);

    return useCallback(() => {
        openDialog(<ChangeTimeDialog onConfirm={confirm} onCancel={closeDialog} limitTime={limitTime} />);
    }, [openDialog, closeDialog, confirm, limitTime]);
};
