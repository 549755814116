import { useCallback } from "react";

import firebase from "firebase/compat/app";

import { useBlockingProgress } from "../../../../../components/blocking-progress";
import { useSnackbar } from "../../../../../components/snackbar";
import { UpdateInvoiceDialogFormContext } from "./UpdateInvoiceDialog";
import { RegistrationDocument } from "../../../registration.model";

const updateInvoiceFunction = firebase.app().functions("europe-west3").httpsCallable("Invoice-update5");

export interface UpdateInvoiceData {
    invoiceId: string;
    serviceDate: string;
    tasks: RegistrationDocument["tasks"];
    discount: number | null;
    gardenerDiscount: number | null;
    reason: string;
}

export const useUpdateInvoiceAction = () => {
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    return useCallback((form: UpdateInvoiceDialogFormContext, data: Omit<UpdateInvoiceData, "reason">, callback?: () => void) => {
        block();
        
        const reason = form.reasonInput.reason;
        updateInvoiceFunction({ ...data, reason })
            .then(() => {
                openSnackbar("success", "Den nye faktura udstedes");
                unblock();
                if ( callback ) callback();
            })
            .catch(() => {
                openSnackbar("error", "Der skete en fejl. Den nye faktura er ikke blevet udstedt.");
                unblock();
            });
    }, [block, unblock, openSnackbar]);
};
