import { useMemo } from "react";
import { Redirect, useParams } from "react-router-dom";

import { TitleHeader } from "../../components/TitleHeader";
import { TabBar } from "../../components/tabs/TabBar";
import { TabBarItem } from "../../components/tabs/TabBarItem";
import { IntegratedAllTab } from "./IntegratedAllTab";

export const IntegratedInvoiceOverviewPage = () => {
    const { tab } = useParams<{ tab: "all" }>();

    const tabIndex = useMemo(() => {
        switch ( tab ) {
            case "all": return 0;
        }
    }, [tab]);

    if ( tab !== "all" ) return <Redirect to="/invoices/all" />;

    return (
        <>
            <TitleHeader>Fakturaer</TitleHeader>

            <TabBar index={tabIndex}>
                <TabBarItem href="/invoices/all">Alle fakturaer</TabBarItem>
            </TabBar>

            {(() => {
                if ( tab === "all" ) return <IntegratedAllTab />;

                return null;
            })()}
        </>
    );
};
