import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";

import { GardenerPage } from "./GardenerPage";
import { useGardener } from "./gardeners.hook";
import { LinearProgress } from "../components/LinearProgress";
import { NotFoundPage } from "../NotFoundPage";
import { useGardeningTasksByGardenerIdWorkingStatus, useGardeningTasksOfferedToGardener } from "../gardening-task/gardening-task.hook";
import { ErrorPage } from "../ErrorPage";
import { useOptionalCustomersById } from "../customer/customers.hook";
import { GardenerTab, useNavigation } from "../navigation.hook";
import { GardeningTaskDocument } from "../gardening-task/gardening-task.model";
import { useGuardrailsProtection } from "../protection/protection.hook";

export const IntegratedGardenerPage = () => {
    const { tab, gardenerId } = useParams<{ tab: GardenerTab, gardenerId: string }>();

    const { goToGardenerList, goToGardenerGardeningTask } = useNavigation();

    const goBack = useCallback(() => {
        goToGardenerList(tab);
    }, [goToGardenerList, tab]);

    const gardener = useGardener(gardenerId);
    const protection = useGuardrailsProtection(gardener && gardener.id);
    const gardeningTasks = useCurrentGardeningTasks(gardener && gardener.id);

    const customerIds = useMemo(() => {
        if ( !gardeningTasks ) return undefined;

        const customerIdSet = new Set<string>();
        gardeningTasks.forEach(x => customerIdSet.add(x.customerId));

        return Array.from(customerIdSet);
    }, [gardeningTasks])
    const customers = useOptionalCustomersById(customerIds);

    const onSelectGardeningTask = useCallback((gardeningTask: GardeningTaskDocument) => {
        goToGardenerGardeningTask(tab, gardenerId, gardeningTask.id);
    }, [goToGardenerGardeningTask, tab, gardenerId]);

    if ( gardener === null ) return <NotFoundPage />;
    if ( protection === null ) return <ErrorPage message="Kunne ikke finde protection" />;
    if ( gardeningTasks === null ) return <ErrorPage message="Kunne ikke finde opgaver" />;
    if ( gardener === undefined || protection === undefined || gardeningTasks === undefined || customers === undefined ) return <LinearProgress />;
    if ( gardener.status !== "active" && gardener.status !== "terminated" ) return <NotFoundPage />;

    return <GardenerPage gardener={gardener} protection={protection} gardeningTasks={gardeningTasks} customers={customers} goBack={goBack} onSelectGardeningTask={onSelectGardeningTask} />;
};

export const useCurrentGardeningTasks = (gardenerId: string | null | undefined) => {
    const offeredTasks = useGardeningTasksOfferedToGardener(gardenerId);
    const schedulingVisitTasks = useGardeningTasksByGardenerIdWorkingStatus(gardenerId, "scheduling visit");
    const performingVisitTasks = useGardeningTasksByGardenerIdWorkingStatus(gardenerId, "performing visit");

    return useMemo(() => {
        if ( offeredTasks === null || schedulingVisitTasks === null || performingVisitTasks === null ) return null;
        if ( offeredTasks === undefined || schedulingVisitTasks === undefined || performingVisitTasks === undefined ) return undefined;

        return [
            ...offeredTasks.sort((a, b) => {
                const aOfferedDate = a.history.find(x => x.event === "offered to gardener")?.date;
                const bOfferedDate = b.history.find(x => x.event === "offered to gardener")?.date;

                if ( !aOfferedDate && bOfferedDate ) return -1;
                if ( aOfferedDate && !bOfferedDate ) return 1;

                if ( aOfferedDate && bOfferedDate ) {
                    if ( aOfferedDate < bOfferedDate ) return 1;
                    if ( aOfferedDate > bOfferedDate ) return -1;
                }

                return 0;
            }),
            ...[...schedulingVisitTasks, ...performingVisitTasks].sort((a, b) => {
                const aMatchedDate = a.history.find(x => x.event === "gardener accepted task" || x.event === "task given to gardener")?.date;
                const bMatchedDate = b.history.find(x => x.event === "gardener accepted task" || x.event === "task given to gardener")?.date;

                if ( !aMatchedDate && bMatchedDate ) return -1;
                if ( aMatchedDate && !bMatchedDate ) return 1;

                if ( aMatchedDate && bMatchedDate ) {
                    if ( aMatchedDate < bMatchedDate ) return 1;
                    if ( aMatchedDate > bMatchedDate ) return -1;
                }

                return 0;
            }),
        ];
    }, [offeredTasks, schedulingVisitTasks, performingVisitTasks]);
}
