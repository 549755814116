import { FocusEvent, useCallback } from "react";

import { Collapse, makeStyles } from "@material-ui/core";
import Big from "big.js";

import { TextField } from "../../../../../components/form/InputField";
import { InvoiceFormContext, useInvoiceForm } from "./InvoiceForm";
import { calculateInvoiceTotal, calculateServiceCommissionIncludingVat, formatNumber } from "../utility";
import { Group } from "../../../../../components/form/Group";
import { AlternativeHint } from "../../../../../components/form/Hint";
import { determineServices } from "./utility";

const useStyles = makeStyles({
    root: {
        "& > input": {
            textAlign: "right",
            paddingRight: "8px",
        },
    },
    adornment: {
        whiteSpace: "nowrap",
    },
});

interface DiscountInputProps {
    id?: string;
}

export const DiscountInput = (props: DiscountInputProps) => {
    const classes = useStyles();

    const { id } = props;

    const { form, setForm } = useInvoiceForm();
    const { discountInput } = form;

    const updateDiscount = useCallback((text: string) => {
        let seenComma = false;
        let discountFormatted = text.split("").filter(x => {
            if ( x.match(/\d/) ) return true;
            
            if ( x.match(",") && !seenComma ) {
                seenComma = true;
                return true;
            }

            return false;
        }).join("");
        discountFormatted = discountFormatted.indexOf(",") !== 0 ? discountFormatted : discountFormatted.substring(1);
        discountFormatted = discountFormatted.indexOf(",") === -1 ? discountFormatted : discountFormatted.split(",")[0] + "," + discountFormatted.split(",")[1].substring(0, 2);

        const discount = discountFormatted ? parseFloat(parseFloat(discountFormatted.replace(",", ".")).toFixed(2)) : null;

        setForm((previousState): InvoiceFormContext => ({
            ...previousState,
            discountInput: {
                ...previousState.discountInput,
                discount,
                discountFormatted,
            },
        }))
    }, [setForm]);

    const selectText = useCallback((event: FocusEvent<HTMLInputElement>) => {
        setTimeout(() => {
            event.target.setSelectionRange(0, event.target.value.length);
        }, 0);
    }, []);

    const onBlur = useCallback(() => {
        setForm((previousState): InvoiceFormContext => ({
            ...previousState,
            discountInput: {
                ...previousState.discountInput,
                discountFormatted: formatNumber(previousState.discountInput.discount ?? 0),
                dirty: true,
            },
        }))
    }, [setForm]);

    const adornment = <span className={classes.adornment}>kr.</span>;

    const error = validateDiscountInput(form);

    return (
        <Group error={Boolean(discountInput.dirty && error)}>
            <TextField id={id} value={discountInput.discountFormatted} onChange={updateDiscount} onFocus={selectText} onBlur={onBlur} endAdornment={adornment} className={classes.root} autoComplete="off" />

            <Collapse in={Boolean(discountInput.dirty && error)} timeout={form.validationAnimationDuration}>
                <AlternativeHint message={error} />
            </Collapse>
        </Group>
    );
}

export interface DiscountInputContext {
    discount: number | null;
    discountFormatted: string;
    dirty: boolean;
}

export const newDiscountInputContext = (discount: number | null): DiscountInputContext => ({
    discount,
    discountFormatted: formatNumber(discount ?? 0),
    dirty: false,
});

export const discountInputHasChanged = (form: InvoiceFormContext) => {
    return form.invoice.discount !== form.discountInput.discount;
};

export const validateDiscountInput = (form: InvoiceFormContext) => {
    if ( !form.discountInput.discount ) return undefined;

    const services = determineServices(form);
    const serviceCommission = calculateServiceCommissionIncludingVat(services, 0, 0);
    if ( form.discountInput.discount > serviceCommission ) return "Må ikke overstige vores honorar";

    const invoiceTotal = calculateInvoiceTotal(services, null, null);
    const invoiceTotalAfterDiscounts = Big(invoiceTotal).minus(form.discountInput.discount).round(2).minus(form.gardenerDiscountInput.gardenerDiscount ?? 0).round(2).toNumber();
    if ( invoiceTotalAfterDiscounts === 0 ) return "Annuller faktura i stedet for at rette den til 0 kr.";

    return undefined;
};
