import { useMemo } from "react";

import { PageFormItem, GroupFormItem, VerticalButtonsMultiChoiceFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { NoticeFormItem } from "../../components/form-item/client/form-item/NoticeFormItemComponent";

export interface SpecifyRequiredSkillsFormIds {
    MultiChoiceRequiredSkills: string;
}

export type SpecifyRequiredSkillsForm<NextPage extends FormItem> = SpecifyRequiredSkillsPage<NextPage>;

type SpecifyRequiredSkillsPage<NextPage extends FormItem> = PageFormItem<
    GroupFormItem<[
        NoticeFormItem,
        VerticalButtonsMultiChoiceFormItem<null[], NextPage>,
    ]>
>;

export const useSpecifyRequiredSkillsForm = <T extends FormItem>(nextPage: T, formIds: SpecifyRequiredSkillsFormIds): SpecifyRequiredSkillsForm<T> => {
    return useMemo(() => {

        const page: SpecifyRequiredSkillsPage<T> = {
            type: "page",
            title: "Angiv opgaver",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "notice",
                        render: "info",
                        html: "Jo flere opgaver der slås til, jo sværere er det at finde en havemand.",
                    },
                    {
                        id: formIds.MultiChoiceRequiredSkills,
                        type: "multi-choice",
                        render: "vertical-buttons",
                        questionText: "Opgaver der skal løses",
                        answerTexts: [
                            "Græsslåning",
                            "Ukrudtsbekæmpelse",
                            "Hækklipning",
                            "Beskæring",
                            "Bortkørsel af haveaffald",
                            "Anden haveservice",
                        ],
                        answerItems: [],
                        afterItem: nextPage,
                        selectedChoiceIndexes: [],
                        dirty: false,
                    },
                ],
            },
        };
        
        return page;
    }, [nextPage, formIds]);
}
