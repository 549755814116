import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { NewGardeningTaskCommentDocument } from "../comment.model";
import { makeStyles } from "@material-ui/core";
import { formatDuration, formatFrequency, formatRequiredSkills, formatTools } from "../../gardening-task/utility";

const useStyles = makeStyles({
    label: {
        fontWeight: 600,
    },
});

interface NewGardeningTaskCommentProps {
    comment: NewGardeningTaskCommentDocument;
}

export const NewGardeningTaskComment = (props: NewGardeningTaskCommentProps) => {
    const classes = useStyles();
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Opgave oprettet">
            <CommentBody>

                <span className={classes.label}>Adresse:</span> {comment.data.address.street}, {comment.data.address.zipCode}<br />
                <span className={classes.label}>Opgaver:</span> {formatRequiredSkills(comment.data.requiredSkills)}<br />
                <span className={classes.label}>Varighed:</span> {formatDuration(comment.data.hourInterval)}<br />
                <span className={classes.label}>Redskaber:</span> {formatTools(comment.data.tools)}<br />
                <span className={classes.label}>{comment.data.frequency === "once" ? "Engangsopgave:" : "Fast opgave:"}</span> {formatFrequency(comment.data.frequency)}<br />
                <br />

                <span className={classes.label}>Beskrivelse af opgaven:</span><br />
                “{comment.data.description}”
            </CommentBody>
        </Comment>
    );
};
