import { useCallback } from "react";

import { LegacyListItem } from "../list/LegacyListItem";

export interface ListSectionItemProps {
    heading: string;
    subheading: string;
    index: number;
    onClick: (index: number) => void;
}

export const ListSectionItem = (props: ListSectionItemProps) => {
    const { heading, subheading, index, onClick } = props;

    const onSelectCustomer = useCallback(() => {
        onClick(index);
    }, [onClick, index]);

    return (
        <LegacyListItem primary={heading} secondary={subheading} onClick={onSelectCustomer} />
    );
};
