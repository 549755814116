import { Collapse } from "@material-ui/core";

import { FormItemSupport } from "../../framework/core/form-item.support";
import { AlternativeHint, Group, Label, TextField } from "../../../form";
import { useForm } from "../../framework/react/FormProvider";
import { useCallback, useEffect, useMemo } from "react";
import { Validation } from "../../../validation/validation";
import * as validator from "../../../../match/specific-match/specific-match.validation";
import { GardenerDocument } from "../../../../gardener/gardener.model";
import { useGardener } from "../../../../gardener/gardeners.hook";
import { useBlockingProgress } from "../../../blocking-progress";

export interface GardenerPickerFormItem {
    id: string;
    type: "gardener-picker",
    dirty: boolean;
    value: string;
    gardener: GardenerDocument | null | undefined,
    lookedUpGardenerId: string;
}

interface GardenerPickerFormItemComponentProps {
    item: GardenerPickerFormItem;
}

export const GardenerPickerFormItemComponent = (props: GardenerPickerFormItemComponentProps) => {
    const { controller, validationAnimationDuration, updateItem } = useForm();
    const { item } = props;

    const gardener = useGardener(item.value || null);

    const { block, unblock } = useBlockingProgress();

    useEffect(() => {
        if ( item.value !== item.gardener?.id ) updateItem(item.id, (state: GardenerPickerFormItem) => ({ ...state, gardener }));
    }, [updateItem, item.id, item.value, item.gardener, gardener]);

    useEffect(() => {
        if ( item.gardener === undefined ) block();
        else unblock();
    }, [block, unblock, item.gardener]);

    const updateValue = useCallback((value: string) => {
        updateItem(item.id, (state: GardenerPickerFormItem) => ({ ...state, value }));
    }, [updateItem, item.id]);

    const markDirty = useCallback(() => {
        updateItem(item.id, state => controller.markDirty(state));
    }, [controller, updateItem, item.id]);

    const gardenerIdError = useMemo(() => validateGardenerId(item), [item]);

    return (
        <>
            <div id={`${item.id}-section`}>
                <Label htmlFor={`${item.id}-gardener-id`} style={{ marginBottom: "8px" }}>Havemandens id</Label>
                <Group error={Boolean(item.dirty && gardenerIdError?.severity === "error")}>
                    <TextField id="gardener-id" value={item.value} onChange={updateValue} onBlur={markDirty} autoComplete="off" />

                    <Collapse in={Boolean(item.dirty && gardenerIdError)} timeout={validationAnimationDuration}>
                        <AlternativeHint severity={gardenerIdError?.severity} message={gardenerIdError?.message} />
                    </Collapse>
                </Group>


            </div>

            <div>
                <Label htmlFor={`${item.id}-gardener-name`} style={{ marginBottom: "8px" }}>Havemandens navn</Label>
                <Group>
                    <TextField id={`${item.id}-gardener-name`} value={item.gardener?.name ?? ""} disabled />
                </Group>
            </div>
        </>
    );
};

export class GardenerPickerFormItemSupport implements FormItemSupport<GardenerPickerFormItem> {
    supportedType = "gardener-picker" as const;
    updateChildren = undefined;

    markDirty(item: GardenerPickerFormItem): GardenerPickerFormItem {
        return { ...item, dirty: true };
    }

    getAllChildren() {
        return [];
    }

    getActiveChildren() {
        return [];
    }

    blocksParent(item: GardenerPickerFormItem): boolean {
        return validate(item)?.severity === "error";
    }
}

const validate = (item: GardenerPickerFormItem): Validation => {
    const validators = [validateGardenerId(item)];

    const error = validators.find(x => x?.severity === "error");
    if ( error ) return error;

    const warning = validators.find(x => x?.severity === "warning");
    if ( warning ) return warning;

    return undefined;
}

const validateGardenerId = (item: GardenerPickerFormItem): Validation => {
    const validators = [validator.validateGardenerId(item.value, item.gardener)];

    const error = validators.find(x => x?.severity === "error");
    if ( error ) return error;

    const warning = validators.find(x => x?.severity === "warning");
    if ( warning ) return warning;

    return undefined;
}
