import { useCallback, useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router";

import { Container } from "@material-ui/core";
import moment from "moment";

import { ContentSection } from "../../../layout/ContentSection";
import { SectionHeader } from "../../../components/section/SectionHeader";
import { Section } from "../../../components/section";
import { Message } from "../../../Message";
import { List } from "../../../components/list/List";
import { useOpenTasksByTypes } from "../../../task/tasks.hook";
import { LinearProgress } from "../../../components/LinearProgress";
import { ListItem } from "../../../components/list/ListItem";
import { PaymentCollectionTaskDocument, TakePaymentTaskDocument } from "../../../task/task.model";
import { determineExpectedPaymentCollectionDate } from "../../../date-utility";

type ExpectedPaymentCollectionStatus = "send invoice reminder 1" | "send invoice reminder 2" | "schedule call before invoice reminder fee 1" | "send invoice reminder fee 1" | "send invoice reminder fee 2" | "send invoice reminder fee 3" | "schedule call before debt collection";
type RelevantTask = TakePaymentTaskDocument | Omit<PaymentCollectionTaskDocument, "status"> & { status: ExpectedPaymentCollectionStatus };

export const AutomaticTab = () => {
    const tasks = useRelevantTasks();

    const formatPrimaryText = useCallback((task: RelevantTask) => {
        switch ( task.type ) {
            case "take-payment": return `${task.customerName}, faktura ${task.invoiceNumber}, træk betaling`;
            case "payment-collection":
                switch ( task.status ) {
                    case "send invoice reminder 1": return `${task.customerName}, faktura ${task.invoiceNumber}, påmindelse 1`;
                    case "send invoice reminder 2": return `${task.customerName}, faktura ${task.invoiceNumber}, påmindelse 2`;
                    case "schedule call before invoice reminder fee 1": return `${task.customerName}, faktura ${task.invoiceNumber}, planlæg opkald inden rykker 1`;
                    case "send invoice reminder fee 1": return `${task.customerName}, faktura ${task.invoiceNumber}, rykker 1`;
                    case "send invoice reminder fee 2": return `${task.customerName}, faktura ${task.invoiceNumber}, rykker 2`;
                    case "send invoice reminder fee 3": return `${task.customerName}, faktura ${task.invoiceNumber}, rykker 3`;
                    case "schedule call before debt collection": return `${task.customerName}, faktura ${task.invoiceNumber}, planlæg opkald inden inkasso`;
                }
        }
    }, []);

    const formatSecondaryText = useCallback((task: RelevantTask) => {
        let date: string;
        if ( task.type === "take-payment" ) {
            date = moment(task.performAfterDate).startOf("hour").add(8, "hours").toISOString(true);
        } else {
            date = determineExpectedPaymentCollectionDate(task.performAfterDate);
        }
        
        const formattedDate = moment(date).format("D MMMM HH:mm");

        return `Håndteres automatisk ${formattedDate}`;
    }, []);

    const history = useHistory();
    const { path } = useRouteMatch();

    const goToTaskPage = useCallback((task: RelevantTask) => {
        history.push(`${path}/${task.type}/${task.id}`);
    }, [history, path]);

    if ( tasks === undefined ) return <LinearProgress />;

    return (
        <ContentSection>
            <Container maxWidth="sm" disableGutters>

                <Section>
                    <SectionHeader>Automatisk håndtering</SectionHeader>

                    {tasks.length > 0 ? (
                        <List>
                            {tasks.map(task => (
                                <ListItem
                                    key={task.id}
                                    item={task}
                                    formatPrimaryText={formatPrimaryText}
                                    formatSecondaryText={formatSecondaryText}
                                    onClick={goToTaskPage}
                                />
                            ))}
                        </List>
                    ) : (
                        <Message>Der er ingen opgaver til automatisk håndtering</Message>
                    )}
                </Section>

            </Container>
        </ContentSection>
    );
};

function useRelevantTasks(): RelevantTask[] | undefined {
    const taskTypes: RelevantTask["type"][] = useMemo(() => ["take-payment", "payment-collection"], []);
    const tasks = useOpenTasksByTypes(taskTypes);

    return useMemo(() => {
        if ( tasks === undefined ) return undefined;

        return tasks
            .filter(task => {
                if ( task.type !== "payment-collection" ) return true;

                const expectedStatuses: ExpectedPaymentCollectionStatus[] = ["send invoice reminder 1", "send invoice reminder 2", "schedule call before invoice reminder fee 1", "send invoice reminder fee 1", "send invoice reminder fee 2", "send invoice reminder fee 3", "schedule call before debt collection"];
                return expectedStatuses.includes(task.status as ExpectedPaymentCollectionStatus);
            })
            .sort((a, b) => {
                const aPerformAfterDate = a.type === "take-payment" ? a.performAfterDate : determineExpectedPaymentCollectionDate(a.performAfterDate);
                const bPerformAfterDate = b.type === "take-payment" ? b.performAfterDate : determineExpectedPaymentCollectionDate(b.performAfterDate);

                if ( aPerformAfterDate < bPerformAfterDate ) return -1;
                if ( aPerformAfterDate > bPerformAfterDate ) return 1;

                return 0;
            }) as RelevantTask[];
    }, [tasks]);
};
