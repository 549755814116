import { Container } from "@material-ui/core";

import { EvaluateGardenerTaskDocument } from "../../task/task.model";
import { CommentDocument } from "../../comment/comment.model";
import { ActivitySection } from "../../comment/ActivitySection";
import { BackButton } from "../../components/BackButton";
import { Section, SectionHeader } from "../../components/section";
import { TitleHeader } from "../../components/TitleHeader";
import { ContentSection } from "../../layout/ContentSection";
import { GardenerCommentList } from "../../comment/GardenerCommentList";
import { EvaluateGardenerDetailCard } from "./EvaluateGardenerCard";
import { GardeningTaskGardenerList } from "../../gardening-task/list/GardeningTaskGardenerList";
import { GardeningTaskDocument } from "../../gardening-task/gardening-task.model";
import { CustomerDocument } from "../../customer/customer.model";
import { GreenButton, VerticalButtons } from "../../components/new-button";
import { Message } from "../../Message";
import { EvaluateGardenerResponseFormIds, useEvaluateGardenerResponseDialog } from "./EvaluateGardenerResponseDialog";
import { GardenerDocument } from "../../gardener/gardener.model";
import { useCallback } from "react";
import { FormProps } from "../../components/form-item/framework/react/FormProvider";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { AdministratorActionData } from "../../workflow/administrator-action.hook";
import { SingleChoiceFormItem } from "../../components/form-item/client/components";

export interface EvaluateGardenerDetailPageProps {
    task: EvaluateGardenerTaskDocument;
    gardener: GardenerDocument,
    currentGardeningTasks: GardeningTaskDocument[];
    finishedGardeningTasks: GardeningTaskDocument[];
    customers: CustomerDocument[];
    comments: CommentDocument[];
    addComment: (comment: string) => void;
    goBack: () => void;
    onSelectGardeningTask: (gardeningTask: GardeningTaskDocument) => void;
    administratorAction: (data: AdministratorActionData | string) => void;
    now: string;
}

export const EvaluateGardenerDetailPage = (props: EvaluateGardenerDetailPageProps) => {
    const { task, gardener, currentGardeningTasks, finishedGardeningTasks, customers, comments, addComment, goBack, onSelectGardeningTask, administratorAction, now } = props;

    const backButton = <BackButton onClick={goBack} />;

    const title = (() => {
        switch ( task.type ) {
            case "evaluate-gardener": return "Havemænd";
        }
    })();

    const responseAdapter = useCallback<FormProps<FormItem>["onSubmit"]>((controller, item) => {
        const selectedAction = (() => {
            if ( task.protection.action.status === "call" ) return "call gardener" as const;

            const selectedActionIndex = (controller.getItem(EvaluateGardenerResponseFormIds.SingleChoiceAction, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex;
            if ( selectedActionIndex === null ) return null;
    
            const actions = ["call gardener" as const, "terminate gardener" as const];

            return actions[selectedActionIndex];
        })();

        if ( !selectedAction ) return "expected selected action";

        if ( selectedAction === "terminate gardener" ) {
            return administratorAction({
                action: "terminate gardener",
                taskId: task.id,
            });
        }

        const reachedGardener = (controller.getItem(EvaluateGardenerResponseFormIds.SingleChoiceReachedGardener, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 1;
        if ( !reachedGardener ) {
            return administratorAction({
                action: "register partnership contact attempt",
                taskId: task.id,
            });
        }

        const willGiveExtraChance = (controller.getItem(EvaluateGardenerResponseFormIds.SingleChoiceWillGiveExtraChance, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 1;
        if ( !willGiveExtraChance ) {
            return administratorAction({
                action: "terminate gardener",
                taskId: task.id,
            });
        }

        return administratorAction({
            action: "issue warning to gardener",
            taskId: task.id,
        });

    }, [administratorAction, task]);
    const openEvaluateGardenerResponseDialog = useEvaluateGardenerResponseDialog(task, gardener, now, responseAdapter);

    return (
        <>
            <TitleHeader button={backButton}>{title}</TitleHeader>
            
            <ContentSection>
                <Container maxWidth="sm" disableGutters>

                    <Section>
                        {task.hasBeenPerformed ? <Message>Opgaven er allerede blevet udført</Message> : null}
                        <EvaluateGardenerDetailCard task={task} goBack={goBack} />

                        {!task.hasBeenPerformed ? (
                            <VerticalButtons>
                                <GreenButton onClick={openEvaluateGardenerResponseDialog}>Besvar opgave</GreenButton>
                            </VerticalButtons>
                        ) : null}
                    </Section>

                    <Section>
                        <SectionHeader>Igangværende opgaver ({task.protection.currentProvidedGardeningTaskIds.length}/{Math.floor(task.protection.score)})</SectionHeader>
                        <GardeningTaskGardenerList gardeningTasks={currentGardeningTasks} customers={customers} onSelect={onSelectGardeningTask} />
                    </Section>

                    <Section>
                        <SectionHeader>Seneste afsluttede opgaver</SectionHeader>
                        <GardeningTaskGardenerList gardeningTasks={finishedGardeningTasks} customers={customers} onSelect={onSelectGardeningTask} />
                    </Section>

                    <ActivitySection onSave={addComment}>
                        <GardenerCommentList comments={comments} />
                    </ActivitySection>

                </Container>
            </ContentSection>
        </>
    );
};
