import { useEffect, useMemo, useRef } from "react";

import { MarkerClusterer } from "@googlemaps/markerclusterer";

import { ClusterRenderer } from "./ClusterRenderer";


export const useClustering = (map: google.maps.Map | undefined, markers: google.maps.Marker[] | undefined) => {
    const renderer = useRef(new ClusterRenderer()).current;

    const clusterer = useMemo(() => {
        if ( !map ) return undefined;

        return new MarkerClusterer({ map, renderer })
    }, [map, renderer]);

    useEffect(() => {
        if ( !clusterer || !markers ) return;

        clusterer.clearMarkers();
        clusterer.addMarkers(markers);
    }, [clusterer, markers]);
};
