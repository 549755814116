import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Collapse, makeStyles } from "@material-ui/core";
import firebase from "firebase/compat/app";

import { NeutralAction } from "../../components/card/NeutralAction";
import { PositiveAction } from "../../components/card/PositiveAction";
import { Dialog, DialogActions, DialogContent, DialogHeader, useDialog } from "../../components/dialog";
import { Label } from "../../components/form/Label";
import { Group } from "../../components/form/Group";
import { AlternativeHint } from "../../components/form/Hint";
import { useAnimation } from "../../components/validation/animation.hook";
import { validateMandatory, Validation } from "../../components/validation/validation";
import { TextArea, TextField } from "../../components/form/InputField";
import { UseGardener } from "../../gardener/gardeners.hook";
import { useBlockingProgress } from "../../components/blocking-progress";
import { validateGardenerId } from "./specific-match.validation";

const useStyles = makeStyles({
    text: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        marginTop: 0,
    },
});

export interface SpecificMatchDialogViewModel {
    gardenerId: string;
    gardenerIdDirty: boolean;
    reason: string;
    reasonDirty: boolean;
}

export interface SpecificMatchDialogErrorModel {
    any: boolean;
    gardenerId: Validation;
    reason: string | undefined;
}

export interface SpecificMatchDialogProps {
    initialViewModel: SpecificMatchDialogViewModel;
    useGardener: UseGardener;
    onCancel: () => void;
    onConfirm: (viewModel: SpecificMatchDialogViewModel, idempotencyKey: string) => void;
}

export const SpecificMatchDialog = (props: SpecificMatchDialogProps) => {
    const idempotencyKey = useRef(firebase.firestore().collection("x").doc().id);
    const classes = useStyles();

    const { initialViewModel, useGardener, onCancel, onConfirm } = props;
    const [viewModel, setViewModel] = useState(initialViewModel);

    const gardener = useGardener(viewModel.gardenerId || null);

    const { block, unblock } = useBlockingProgress();
    useEffect(() => {
        if ( gardener === undefined ) block();
        else unblock();
    }, [block, unblock, gardener]);

    const errorModel = useMemo<SpecificMatchDialogErrorModel>(() => {
        const gardenerIdError = [validateGardenerId(viewModel.gardenerId, gardener)].find(Boolean);
        const reasonError = [validateMandatory(viewModel.reason)].find(Boolean);

        return {
            any: Boolean(gardenerIdError?.severity === "error" || reasonError),
            gardenerId: gardenerIdError,
            reason: reasonError,
        };
    }, [viewModel, gardener]);

    const changeGardenerId = useCallback((value: string) => {
        (document.activeElement as any)?.blur();

        setViewModel(viewModel => ({ ...viewModel, gardenerId: value }));
    }, [setViewModel])

    const markGardenerIdDirty = useCallback(() => {
        setViewModel(viewModel => ({ ...viewModel, gardenerIdDirty: true }));
    }, [setViewModel]);

    const changeReason = useCallback((value: string) => {
        setViewModel(viewModel => ({ ...viewModel, reason: value }));
    }, [setViewModel])

    const markReasonDirty = useCallback(() => {
        setViewModel(viewModel => ({ ...viewModel, reasonDirty: true }));
    }, [setViewModel]);

    const [validationAnimationDuration, skipValidationAnimation] = useAnimation();

    const scrollToFirstError = useCallback(() => {
        const sectionId = (() => {
            if ( errorModel.gardenerId ) return "gardener-id-section";
            if ( errorModel.reason ) return "reason-section";

            return undefined;
        })();

        if ( !sectionId ) return;

        const section = document.getElementById(sectionId);
        if ( !section ) return;

        section.scrollIntoView({ behavior: "smooth" });
    }, [errorModel]);

    const confirm = useCallback(() => {
        if ( document.activeElement ) {
            (document.activeElement as HTMLInputElement).blur();
        }

        if ( errorModel.any ) {
            skipValidationAnimation();
            setViewModel(viewModel => ({
                ...viewModel,
                gardenerIdDirty: true,
                emailDirty: true,
                reasonDirty: true,
            }));

            return setTimeout(scrollToFirstError, 100);
        }

        onConfirm(viewModel, idempotencyKey.current);
    }, [skipValidationAnimation, scrollToFirstError, onConfirm, errorModel.any, viewModel, idempotencyKey]);

    return (
        <Dialog>
            <DialogHeader>Tilknyt havemand</DialogHeader>

            <DialogContent>

                <p className={classes.text}>Havemanden bliver tilknyttet opgaven med det samme. Havemanden får ikke tilsendt et tilbud først. Nedenstående årsag sendes ikke til havemanden.</p>

                <div id="gardener-id-section">
                    <Label htmlFor="gardener-id" style={{ marginBottom: "8px" }}>Havemandens id</Label>
                    <Group error={Boolean(viewModel.gardenerIdDirty && errorModel.gardenerId?.severity === "error")}>
                        <TextField id="gardener-id" value={viewModel.gardenerId} onChange={changeGardenerId} onBlur={markGardenerIdDirty} autoComplete="off" />

                        <Collapse in={Boolean(viewModel.gardenerIdDirty && errorModel.gardenerId)} timeout={validationAnimationDuration}>
                            <AlternativeHint severity={errorModel.gardenerId?.severity} message={errorModel.gardenerId?.message} />
                        </Collapse>
                    </Group>
                </div>

                <div id="gardener-name-section">
                    <Label htmlFor="gardener-name" style={{ marginBottom: "8px" }}>Havemandens navn</Label>
                    <Group>
                        <TextField id="gardener-name" value={gardener?.name ?? ""} disabled />
                    </Group>
                </div>

                <div id="reason-section">
                    <Label htmlFor="reason" style={{ marginBottom: "8px" }}>Årsag</Label>
                    <Group error={Boolean(viewModel.reasonDirty && errorModel.reason)}>
                        <TextArea id="reason" value={viewModel.reason} onChange={changeReason} onBlur={markReasonDirty} placeholder="Angiv årsagen til at du tilknytter en specifik havemand til kunden" />

                        <Collapse in={Boolean(viewModel.reasonDirty && errorModel.reason)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.reason} />
                        </Collapse>
                    </Group>
                </div>

            </DialogContent>

            <DialogActions>
                <NeutralAction onClick={onCancel}>Annuller</NeutralAction>
                <PositiveAction onClick={confirm}>Bekræft</PositiveAction>
            </DialogActions>

        </Dialog>
    );
};

export const useSpecificMatchDialog = (initialViewModel: SpecificMatchDialogViewModel | undefined, useGardener: UseGardener, onConfirm: SpecificMatchDialogProps["onConfirm"]) => {
    const { openDialog, closeDialog } = useDialog();

    const confirm: SpecificMatchDialogProps["onConfirm"] = useCallback((data, idempotencyKey) => {
        closeDialog();

        onConfirm(data, idempotencyKey);
    }, [closeDialog, onConfirm]);

    return useCallback(() => {
        if ( !initialViewModel ) return;

        openDialog(<SpecificMatchDialog initialViewModel={initialViewModel} useGardener={useGardener} onCancel={closeDialog} onConfirm={confirm} />);
    }, [openDialog, closeDialog, confirm, initialViewModel, useGardener]);
};
