import { Link } from "react-router-dom";

import moment from "moment";

import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { NewVisitCommentDocument } from "../comment.model";

interface NewVisitCommentProps {
    comment: NewVisitCommentDocument;
}

export const NewVisitComment = (props: NewVisitCommentProps) => {
    const { comment } = props;

    const gardener = <Link to={`/gardeners/context/${comment.gardenerId}`}>{comment.gardenerName}</Link>;
    const customer = <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link>;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Besøg foretaget">
            <CommentBody>
                {gardener} har været på besøg hos {customer} <span style={{ whiteSpace: "nowrap" }}>d. {moment(comment.appointmentDate).format("D. MMMM")}</span> uden at udføre havearbejde.
            </CommentBody>
        </Comment>
    );
};
