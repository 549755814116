import { useCallback, useEffect, useRef, useState } from "react";

import { Button, ButtonProps, ClickAwayListener, Grow, MenuList, Paper, Popper } from "@material-ui/core";

interface UserMenuButtonProps extends Omit<ButtonProps, "onClick"> {
    id: string;
    text: string;
    children: (closeMenu: () => void) => JSX.Element[];
    hide?: boolean;
}

export const UserMenuButton = (props: UserMenuButtonProps) => {
    const { id, text, hide, children, className, ...otherProps } = props;

    const [open, setOpen] = useState(false);
    const anchorReference = useRef<HTMLButtonElement>(null);

    const toggleMenu = useCallback(() => {
        setOpen(previouslyOpen => !previouslyOpen);
    }, []);

    const closeMenu = useCallback(() => {
        setOpen(false);
    }, []);

    const closeIfTab = useCallback((event: React.KeyboardEvent) => {
        if ( event.key === "Tab" ) {
            event.preventDefault();
            setOpen(false);
        }
    }, []);

    useEffect(() => {
        if ( hide ) {
            closeMenu();
        }
    }, [closeMenu, hide]);

    const activeClass = open ? "active" : "inactive";

    return (
        <>
            <Button
                ref={anchorReference}
                aria-controls={open ? id : undefined}
                aria-haspopup="true"
                onClick={toggleMenu}
                className={className ? `${activeClass} ${className}` : activeClass}
                {...otherProps}
            >
                {text}
            </Button>

            <Popper open={open} anchorEl={anchorReference.current} placement="bottom-end" role={undefined} transition disablePortal>
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={closeMenu}>
                                <MenuList autoFocusItem={open} id={id} onKeyDown={closeIfTab}>
                                    {children(closeMenu)}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
