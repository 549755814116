import { PageFormItem, InfoFormItem, useFormDialog } from "../../../../../components/form-item/client/components";

type ConfirmationPage = PageFormItem<InfoFormItem>

export const useCannotReissueOrResendInvoiceDialog = () => {
    const confirmationPage: ConfirmationPage = {
        type: "page",
        title: "Genudsted/gensend faktura",
        item: {
            type: "info",
            render: "default",
            text: "Fakturaen kan ikke genudstedes eller gensendes, da kundens faktureringsoplysninger ikke er blevet ændret.",
        },
    };

    return useFormDialog(confirmationPage);
};
