import { useCallback, useMemo } from "react";

import { Container } from "@material-ui/core";
import moment from "moment";

import { EvaluateGardenerTaskDocument } from "../../task/task.model";
import { ListSection, ListSectionProps } from "../../components/admin-list-section/ListSection";
import { ContentSection } from "../../layout/ContentSection";
import { sortbyDueDateThenGardenerThenType } from "../../shared/utility";

type Task = EvaluateGardenerTaskDocument;

interface GardenersTabProps {
    currentTasks: Task[];
    futureTasks: Task[];
    goToTask: (task: Task) => void;
}

export const GardenersTab = (props: GardenersTabProps) => {
    const { currentTasks, futureTasks, goToTask } = props;

    const currentTaskListItems: ListSectionProps["items"] = useMemo(() => {
        return currentTasks.sort(sortbyDueDateThenGardenerThenType).map(mapToListItem);
    }, [currentTasks]);

    const goToCurrentTask = useCallback((index: number) => {
        const task = currentTasks[index];

        goToTask(task);
    }, [goToTask, currentTasks]);

    const futureTaskListItems = useMemo(() => {
        return futureTasks.sort(sortbyDueDateThenGardenerThenType).map(mapToListItem);
    }, [futureTasks]);

    const goToFutureTask = useCallback((index: number) => {
        const task = futureTasks[index];

        goToTask(task);
    }, [goToTask, futureTasks]);

    return (
        <ContentSection>
            <Container maxWidth="sm" disableGutters>

                <ListSection
                    title="Aktuelle opgaver"
                    placeholder="Der er ingen aktuelle opgaver"
                    items={currentTaskListItems}
                    onClick={goToCurrentTask}
                />

                <ListSection
                    title="Fremtidige opgaver"
                    placeholder="Der er ingen fremtidige opgaver"
                    items={futureTaskListItems}
                    onClick={goToFutureTask}
                />

            </Container>
        </ContentSection>
    );
};

const mapToListItem = (task: Task): ListSectionProps["items"][0] => {
    const formattedDate = moment(task.performAfterDate).format("D MMMM HH:mm");

    return {
        heading: task.gardenerName,
        subheading: `Skal udføres ${formattedDate}`,
    };
}
