import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { LegacyGardenerPausedCommentDocument } from "../comment.model";

interface LegacyGardenerPausedCommentProps {
    comment: LegacyGardenerPausedCommentDocument;
}

export const LegacyGardenerPausedComment = (props: LegacyGardenerPausedCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Havemand er på administrativ pause" author={comment.administratorName}>
            <CommentBody>
                Årsag:<br />
                {comment.reason}
            </CommentBody>
        </Comment>
    );
};
