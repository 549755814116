import { useMemo } from "react";

import { PageFormItem, GroupFormItem, InfoFormItem, HorizontalButtonsSingleChoiceFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { GardenerDocument } from "../../gardener/gardener.model";
import { NoticeFormItem } from "../../components/form-item/client/form-item/NoticeFormItemComponent";

export interface CheckCompanySigningFormIds {
    SingleChoiceCanSignForCompany: string;
}

export type CheckCompanySigningForm<NextPage extends FormItem> = CheckCompanySigningPage<ConfirmationPage, NextPage> | NextPage;

type CheckCompanySigningPage<InvalidNamePage extends FormItem, ValidNamePage extends FormItem> = PageFormItem<
    GroupFormItem<[
        InfoFormItem,
        NoticeFormItem,
        HorizontalButtonsSingleChoiceFormItem<[
            InvalidNamePage,
            ValidNamePage,
        ]>
    ]>
>;

type ConfirmationPage = PageFormItem<InfoFormItem>

export const useCheckCompanySigningForm = <T extends FormItem>(gardener: GardenerDocument, nextPage: T, formIds: CheckCompanySigningFormIds): CheckCompanySigningForm<T> => {
    return useMemo(() => {
        if ( !gardener.cvr ) return nextPage;

        const confirmationPage: ConfirmationPage = {
            type: "page",
            title: "Afvis ansøgning",
            item: {
                type: "info",
                render: "default",
                text: `
                    Ansøgningen bliver afvist.<br />
                    <br />
                    Ansøgeren informeres om, at ansøgningen blev afvist fordi virksomhedens tegningsregel ikke er opfyldt.
                `,
            },
        };

        const page: CheckCompanySigningPage<ConfirmationPage, T> = {
            type: "page",
            title: "Tjek tegningsregel",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Tjek at <strong>${gardener.name}</strong> opfylder tegningsreglen for <a href="https://datacvr.virk.dk/enhed/virksomhed/${gardener.cvr}" target="_blank">${gardener.companyName} (CVR: ${gardener.cvr})</a>.
                        `,
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            Vi kræver at tegningsreglen kan opfyldes af ansøgeren alene, men vi accepterer, at der ikke er et perfekt match mellem ansøgerens fulde navn og en tegningsberettiget.
                        `,
                    },
                    {
                        id: formIds.SingleChoiceCanSignForCompany,
                        type: "single-choice",
                        mode: "switch page",
                        render: "horizontal-buttons",
                        questionText: "Kan ansøgeren opfylde tegningsreglen alene?",
                        answerTexts: ["Nej", "Ja"],
                        answerItems: [
                            confirmationPage,
                            nextPage,
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                    }
                ],
            },
        };
        
        return page;
    }, [gardener, nextPage, formIds]);
}
