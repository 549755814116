import { useCallback, useMemo, useRef, useState } from "react";

import { Collapse, makeStyles } from "@material-ui/core";

import { NeutralAction, PositiveAction } from "../../components/card";
import { Dialog, DialogActions, DialogContent, DialogHeader, useDialog } from "../../components/dialog";
import { AlternativeHint } from "../../components/form/Hint";
import { TextArea } from "../../components/form/InputField";
import { useAnimation } from "../../components/validation/animation.hook";
import { validateMandatory } from "../../components/validation/validation";
import { Label } from "../../components/form/Label";
import { Group } from "../../components/form/Group";

export interface ReactivateCustomerDialogViewModel {
    reason: string;
    reasonDirty: boolean;
}

export interface ReactivateCustomerDialogErrorModel {
    any: boolean;
    reason: string | undefined;
}

const useStyles = makeStyles({
    text: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        marginTop: 0,
    },
});

export interface ReactivateCustomerDialogProps {
    initialViewModel: ReactivateCustomerDialogViewModel;
    onConfirm: (viewModel: ReactivateCustomerDialogViewModel) => void;
    onCancel: () => void;
}

export const ReactivateCustomerDialog = (props: ReactivateCustomerDialogProps) => {
    const classes = useStyles();
    const { initialViewModel, onConfirm, onCancel } = props;
    const [viewModel, setViewModel] = useState(initialViewModel);

    const errorModel = useMemo<ReactivateCustomerDialogErrorModel>(() => {
        const reasonError = [validateMandatory(viewModel.reason)].find(Boolean);

        return {
            any: Boolean(reasonError),
            reason: reasonError,
        };
    }, [viewModel]);

    const changeReason = useCallback((value: string) => {
        setViewModel(viewModel => ({ ...viewModel, reason: value }));
    }, [setViewModel])

    const markReasonDirty = useCallback(() => {
        setViewModel(viewModel => ({ ...viewModel, reasonDirty: true }));
    }, [setViewModel]);

    const [validationAnimationDuration, skipValidationAnimation] = useAnimation();

    const scrollToFirstError = useCallback(() => {
        const sectionId = (() => {
            if ( errorModel.reason ) return "reason-section";

            return undefined;
        })();

        if ( !sectionId ) return;

        const section = document.getElementById(sectionId);
        if ( !section ) return;

        section.scrollIntoView({ behavior: "smooth" });
    }, [errorModel]);

    const confirm = useCallback(() => {
        if ( document.activeElement ) {
            (document.activeElement as HTMLInputElement).blur();
        }

        if ( errorModel.any ) {
            skipValidationAnimation();
            setViewModel(viewModel => ({
                ...viewModel,
                reasonDirty: true,
            }));

            return setTimeout(scrollToFirstError, 100);
        }

        onConfirm(viewModel);
    }, [skipValidationAnimation, scrollToFirstError, onConfirm, errorModel.any, viewModel]);

    return (
        <Dialog>
            <DialogHeader>Genaktiver kunde</DialogHeader>

            <DialogContent>

                <p className={classes.text}>Kunden vil ikke længere være slettet.</p>

                <div id="reason-section">
                    <Label htmlFor="reason" style={{ marginBottom: "8px" }}>Årsag</Label>
                    <Group error={Boolean(viewModel.reasonDirty && errorModel.reason)}>
                        <TextArea id="reason" value={viewModel.reason} onChange={changeReason} onBlur={markReasonDirty} placeholder="Hvorfor vil du genaktivere kunden?" />

                        <Collapse in={Boolean(viewModel.reasonDirty && errorModel.reason)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.reason} />
                        </Collapse>
                    </Group>
                </div>

            </DialogContent>

            <DialogActions>
                <NeutralAction onClick={onCancel}>Annuller</NeutralAction>
                <PositiveAction onClick={confirm}>Bekræft</PositiveAction>
            </DialogActions>

        </Dialog>
    );
};

export const useReactivateCustomerDialog = (onConfirm: ReactivateCustomerDialogProps["onConfirm"], initialViewModel?: ReactivateCustomerDialogViewModel) => {
    const { openDialog, closeDialog } = useDialog();

    const hasConfirmed = useRef(false);
    const confirm: ReactivateCustomerDialogProps["onConfirm"] = useCallback(data => {
        if ( hasConfirmed.current ) return;
        hasConfirmed.current = true;

        closeDialog();

        onConfirm(data);
    }, [closeDialog, onConfirm, hasConfirmed]);

    const viewModel = useRef<ReactivateCustomerDialogViewModel>(initialViewModel ?? {
        reason: "",
        reasonDirty: false,
    });

    return useCallback(() => {
        hasConfirmed.current = false;

        openDialog(<ReactivateCustomerDialog initialViewModel={viewModel.current} onConfirm={confirm} onCancel={closeDialog} />);
    }, [openDialog, closeDialog, confirm, viewModel, hasConfirmed]);
};
