import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { CreditNoteDetailPage } from "./CreditNoteDetailPage";

export const IntegratedCreditNoteDetailPage = () => {
    const history = useHistory();
    const goBack = useCallback(() => {
        history.push("/invoices/all");
    }, [history]);

    return (
        <CreditNoteDetailPage goBack={goBack} />
    );
};
