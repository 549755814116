import { useMemo, useRef, useState } from "react";

import { makeStyles } from "@material-ui/core";
import moment from "moment";

import { ContentSection } from "../layout/ContentSection";
import { ClusteredMap } from "../components/maps/clustering/ClusteredMap";
import { LinearProgress } from "../components/LinearProgress";
import { useEventsByTypeInTimePeriod } from "../event/event.hook";
import { RegistrationSubmittedEventDocument } from "../event/event.model";
import { useCustomers } from "../customer/customers.hook";
import { CustomerDocument } from "../customer/customer.model";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        display: "flex",
    },
    container: {
        flexGrow: 1,
        maxWidth: "1366px",
        maxHeight: "768px",
        margin: "0 auto",
    },
});

export const DashboardPageVisitsTab = () => {
    const classes = useStyles();

    const customers = useRelevantCustomers();

    const markers = useMemo(() => {
        if ( !customers ) return undefined;

        return customers.map(customer => {
            const location = { lat: customer.address.coordinates.latitude, lng: customer.address.coordinates.longitude };

            return { href: `/customers/context/${customer.id}`, label: customer.name, position: location };
        });
    }, [customers]);

    const [startingView] = useState(() => {
        return {
            zoom: 7, center: { lat: 55.94813677916688, lng: 10.519916904259285 },
        };
    });

    if ( !markers ) return <LinearProgress />;

    return (
        <ContentSection className={classes.root}>

            <div className={classes.container}>
                <ClusteredMap
                    apiKey="AIzaSyAjye7kZjCsDknd-f8byYp2C6itdDfZl_o"
                    zoom={startingView.zoom}
                    center={startingView.center}
                    markers={markers}
                />
            </div>

        </ContentSection>
    );
};

function useRelevantCustomers() {
    const now = useRef(moment().toISOString(true)).current;
    const twoWeeksAgo = moment(now).subtract(14, "days").toISOString(true);
    const events = useEventsByTypeInTimePeriod("registration-submitted", twoWeeksAgo, now) as RegistrationSubmittedEventDocument[];

    const customers = useCustomers();

    return useMemo(() => {
        if ( !events || !customers ) return undefined;

        const customerMap: { [customerId: string]: CustomerDocument } = {};
        customers.forEach(customer => customerMap[customer.id] = customer);

        return events.map(event => customerMap[event.customerId]).filter(x => x);
    }, [events, customers]);
}
