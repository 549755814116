import { useCallback } from "react";

import { ListItem } from "./ListItem";

interface LegacyListItemProps {
    primary: string;
    secondary: string;
    onClick: () => void;
}

export const LegacyListItem = (props: LegacyListItemProps) => {
    const { primary, secondary, onClick } = props;

    const formatPrimaryText = useCallback(() => primary, [primary]);
    const formatSecondaryText = useCallback(() => secondary, [secondary]);

    return (
        <ListItem
            item={undefined}
            formatPrimaryText={formatPrimaryText}
            formatSecondaryText={formatSecondaryText}
            onClick={onClick}
        />
    );
};
