import { Link } from "react-router-dom";

import { Comment } from "../../components/comment/Comment";
import { AutomaticPaymentFailedCommentDocument } from "../comment.model";
import { CommentBody } from "../../components/comment/CommentBody";
import { UnorderedList } from "../../components/unordered-list/UnorderedList";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    link: {
        fontSize: "16px",
        fontWeight: 500,
        color: "#297A1D",
        textDecoration: "none",

        "&:hover": {
            color: "#1C5B13",
            textDecoration: "underline",
        },
    }
});

interface AutomaticPaymentFailedCommentProps {
    comment: AutomaticPaymentFailedCommentDocument;
}

export const AutomaticPaymentFailedComment = (props: AutomaticPaymentFailedCommentProps) => {
    const classes = useStyles();

    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Automatisk betaling">
            <CommentBody>

                Vi kunne ikke trække betaling for følgende fakturaer automatisk:

                <UnorderedList>
                    {comment.invoices.map(invoice => (
                        <li>
                            <Link to={`/invoices/all/invoice/${invoice.id}`} className={classes.link}>Faktura {invoice.invoiceNumber}</Link>
                        </li>
                    ))}
                </UnorderedList>
                <br />
                Kunden er derfor blevet afmeldt automatisk betaling og bedt om at tage stilling til betaling.

            </CommentBody>
        </Comment>
    );
};
