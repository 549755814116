import { PropsWithChildren } from "react";

import { makeStyles } from "@material-ui/core";

import { Topbar } from "./Topbar";
import { SideNavigation } from "./SideNavigation";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
    },

    container: {
        flexGrow: 1,
        display: "flex",
    },

    main: {
        flexGrow: 1,
        width: "100%",
        minWidth: 0,

        display: "flex",
        flexDirection: "column",
    },
});

export const Layout = (props: PropsWithChildren<{}>) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <header>
                <Topbar />
            </header>

            <div className={classes.container}>
                <SideNavigation />

                <main className={classes.main}>{props.children}</main>
            </div>
        </div>
    );
};
