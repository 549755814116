import { useCallback } from "react";

import firebase from "firebase/compat/app";
import moment from "moment";

import { useBlockingProgress } from "../../components/blocking-progress";
import { useDialog } from "../../components/dialog";
import { useSnackbar } from "../../components/snackbar";
import { AdhocGardenerTaskDocument, EvaluateGardenerTaskDocument } from "../../task/task.model";
import { CreateTaskDialog, CreateTaskDialogViewModel } from "../../task/task-dialog/CreateTaskDialog";

const createTaskFunction = firebase.app().functions("europe-west3").httpsCallable("Task-create2");

const closeTaskFunction = async (taskId: string) => {
    await firebase.firestore().collection("tasks").doc(taskId).update({ hasBeenPerformed: true });
}

export const usePlanGardenerFollowUp = (task: AdhocGardenerTaskDocument | EvaluateGardenerTaskDocument, goBack: () => void) => {
    const { openDialog, closeDialog } = useDialog();
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    const gardenerId = task.gardenerId;
    const taskId = task.id;

    const planFollowUp = useCallback((viewModel: CreateTaskDialogViewModel) => {
        closeDialog();

        const performAfterDate = moment(`${viewModel.performAfterDateDate} ${viewModel.performAfterDateTime}`, "YYYY-MM-DD HH:mm").toISOString(true);

        block();
        Promise.resolve().then(() => {
            return createTaskFunction({
                type: "adhoc-gardener",
                gardenerId,
                whatHasHappened: viewModel.whatHasHappened,
                whatShouldBeDone: viewModel.whatShouldBeDone,
                performAfterDate
            });
        })
            .then(() => closeTaskFunction(taskId))
            .then(() => {
                openSnackbar("success", "Opfølgningen er planlagt");
                goBack();
            })
            .catch(() => openSnackbar("error", "Der skete en fejl. Opfølgningen er ikke blevet planlagt."))
            .finally(() => unblock());

    }, [closeDialog, block, unblock, openSnackbar, goBack, gardenerId, taskId]);

    return useCallback(() => {
        openDialog(<CreateTaskDialog onConfirm={planFollowUp} onCancel={closeDialog} />);
    }, [openDialog, closeDialog, planFollowUp]);
};
