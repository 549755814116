import { useEffect, useMemo, useState } from "react";

import firebase from "firebase/compat/app";
import { ComplaintDocument } from "./complaint.model";

export const useComplaintWithInvoiceId = (invoiceId: string | null | undefined): ComplaintDocument | null | undefined => {
    const invoiceIds = useMemo(() => {
        if ( invoiceId === undefined ) return undefined;
        if ( invoiceId === null ) return null;

        return [invoiceId];
    }, [invoiceId]);

    const complaints = useComplaintsWithSomeInvoiceIds(invoiceIds);

    if ( complaints === undefined ) return undefined;
    if ( complaints === null ) return null;
    if ( complaints.length === 0 ) return null;

    return complaints[0];
};

export const useComplaintsWithSomeInvoiceIds = (invoiceIds: string[] | null | undefined) => {
    const [state, setState] = useState<ComplaintDocument[] | null>();

    useEffect(() => {
        if ( invoiceIds === undefined ) return setState(undefined);
        if ( invoiceIds === null ) return setState(null);

        setState(undefined);

        return firebase.firestore()
            .collection("complaints")
                .where("invoiceIds", "array-contains-any", invoiceIds)
            .onSnapshot(collection => {
                const documents = collection.docs.map(document => ({ id: document.id, ...document.data() } as ComplaintDocument));
                
                setState(documents);
            });
    }, [invoiceIds]);

    return state;
};

