import { PageFormItem, InfoFormItem, useFormDialog } from "../../components/form-item/client/components";

type ConfirmationPage = PageFormItem<InfoFormItem>

export const useCannotEditInvoiceDialog = () => {
    const confirmationPage: ConfirmationPage = {
        type: "page",
        title: "Ret faktura",
        item: {
            type: "info",
            render: "default",
            text: `
                Du kan ikke rette fakturaen, da der er udstedt rabat.<br />
                <br />
                Henvend dig i stedet til Marianne.
            `,
        },
    };

    return useFormDialog(confirmationPage);
};
