import { useMemo } from "react";

import { HorizontalButtonsSingleChoiceFormItem, InfoFormItem, PageFormItem, useFormDialog } from "../components/form-item/client/components";
import { FormProps } from "../components/form-item/framework/react/FormProvider";

export enum CreateComplaintFormIds {
    SingleChoiceMultipleInvoices = "single-choice-multiple-invoices",
}

export type CreateComplaintForm =
    PageFormItem<
        HorizontalButtonsSingleChoiceFormItem<[ConfirmationPage, ConfirmationPage]>
    >;

type ConfirmationPage = PageFormItem<InfoFormItem>

export const useCreateComplaintDialog = (onConfirm: FormProps<CreateComplaintForm>["onSubmit"]) => {

    const form: CreateComplaintForm = useMemo(() => {
        const createSingleInvoiceConfirmationPage: ConfirmationPage = {
            type: "page",
            title: "Opret reklamation",
            item: {
                type: "info",
                render: "default",
                text: `
                    Der oprettes en opgave i “Kontakt kunde.<br />
                    <br />
                    Eventuel opkrævning for fakturaen sættes i bero, mens reklamationen står på.<br />
                    <br />
                    Kunden modtager en notifikation om, at vi har oprettet en reklamation på fakturaen og vil kontakte dem.
                `,
            }
        };
    
        const createMultipleInvoicesConfirmationPage: ConfirmationPage = {
            type: "page",
            title: "Ræk ud til Marianne",
            item: {
                type: "info",
                render: "default",
                text: `
                    Det er kun Emil og Marianne, som kan oprette en reklamation for flere fakturaer på én gang. Ræk derfor ud til Marianne.<br />
                    <br />
                    Fortæl hende hvilke fakturaer det drejer sig om (angiv fakturanumrene).
                `,
            }
        };

        return {
            type: "page",
            title: "Drejer reklamationen sig om mere end én faktura?",
            item: {
                id: CreateComplaintFormIds.SingleChoiceMultipleInvoices,
                type: "single-choice",
                mode: "switch page",
                render: "horizontal-buttons",
                questionText: null,
                answerTexts: ["Nej", "Ja"],
                answerItems: [
                    createSingleInvoiceConfirmationPage,
                    createMultipleInvoicesConfirmationPage,
                ],
                selectedChoiceIndex: null,
                dirty: false,
            },
        };
    }, []);

    return useFormDialog(form, onConfirm);
};
