import { useMemo } from "react";

import { PageFormItem, InfoFormItem, GroupFormItem, SingleChoiceFormItem, TextAreaFormItem, NoticeFormItem } from "../../components/form-item/client/components";
import { ComplaintDocument } from "../complaint.model";

export interface GardenerOpinionFormIds {
    TextAreaCustomerComment: string;
    TextAreaCustomerProposal: string;
    SingleChoiceGardenerDecision: string;

    TextAreaGardenerAcceptsCustomerProposalGardenerComment: string;
    TextAreaGardenerAcceptsCustomerProposalGardenerProposal: string;

    TextAreaGardenerHasOtherProposalGardenerComment: string;
    TextAreaGardenerHasOtherProposalGardenerProposal: string;

    TextAreaGardenerDoesNotHaveProposalGardenerComment: string;
}

export type GardenerOpinionForm =
    PageFormItem<
        GroupFormItem<[
            NoticeFormItem,
            TextAreaFormItem,
            TextAreaFormItem,
            SingleChoiceFormItem<[
                GardenerDoesHaveProposalPages,
                GardenerDoesHaveProposalPages,
                GardenerDoesNotHaveProposalPages,
            ]>
        ]>
    >

type GardenerDoesHaveProposalPages =
    GroupFormItem<[
        PageFormItem<
            GroupFormItem<[
                TextAreaFormItem,
                TextAreaFormItem,
                NoticeFormItem,
                TextAreaFormItem,
                TextAreaFormItem,
            ]>
        >,
        ConfirmationPage,
    ]>

type GardenerDoesNotHaveProposalPages =
    GroupFormItem<[
        PageFormItem<
            TextAreaFormItem
        >,
        ConfirmationPage,
    ]>

type ConfirmationPage = PageFormItem<InfoFormItem>;

export const useGardenerOpinionForm = (complaint: ComplaintDocument, formIds: GardenerOpinionFormIds): GardenerOpinionForm => {
    return useMemo(() => {

        const confirmationPage: ConfirmationPage = {
            type: "page",
            title: "Reklamationen overdrages til direktionen",
            item: {
                type: "info",
                render: "default",
                text: `
                    Opgaven afsluttes.<br />
                    <br />
                    Direktionen modtager en notifikation om reklamationen og tager stilling til, hvilken løsning kunden eventuelt kan tilbydes.<br />
                    <br />
                    Tilbydes kunden en løsning, informerer direktionen både havemanden og kunden om dette.<br />
                    <br />
                    Accepterer kunden den tilbudte løsning effektuerer direktionen denne og informerer både havemanden og kunden om dette.
                `,
            },
        };

        const gardenerDoesNotHaveProposalPages: GardenerDoesNotHaveProposalPages = {
            type: "group",
            render: "all at once",
            items: [
                {
                    type: "page",
                    title: "Havemanden vil ikke tilbyde kunden en løsning",
                    item: {
                        id: formIds.TextAreaGardenerDoesNotHaveProposalGardenerComment,
                        type: "text-area",
                        label: "Kommentar fra havemanden",
                        placeholder: "Hvorfor vil havemanden ikke tilbyde en løsning?",
                        value: "",
                        dirty: false,
                    }
                },
                confirmationPage,
            ],
        };

        const customerCommentTextArea: TextAreaFormItem = {
            id: formIds.TextAreaCustomerComment,
            type: "text-area",
            label: "Problemet ifølge kunden",
            placeholder: "",
            value: complaint.customerOpinion?.comment ?? "",
            dirty: false,
            disabled: true,
        }

        const customerProposalTextArea: TextAreaFormItem = {
            id: formIds.TextAreaCustomerProposal,
            type: "text-area",
            label: "Kundens løsningsforslag",
            placeholder: "",
            value: complaint.customerOpinion?.proposal ?? "",
            dirty: false,
            disabled: true,
        };

        const gardenerAcceptsCustomerProposalPages: GardenerDoesHaveProposalPages = {
            type: "group",
            render: "all at once",
            items: [
                {
                    type: "page",
                    title: "Havemanden accepterer kundens løsning",
                    item: {
                        type: "group",
                        render: "all at once",
                        items: [
                            customerCommentTextArea,
                            customerProposalTextArea,
                            {
                                type: "notice",
                                render: "info",
                                html: "Kunden og havemanden får ikke disse beskrivelser at se.",
                            },
                            {
                                id: formIds.TextAreaGardenerAcceptsCustomerProposalGardenerProposal,
                                type: "text-area",
                                label: "Havemandens løsningsforslag",
                                placeholder: "Beskriv løsningen. Tilbydes der rabat, så uddyb hvem der giver hvad i rabat.",
                                value: "",
                                dirty: false,
                            },
                            {
                                id: formIds.TextAreaGardenerAcceptsCustomerProposalGardenerComment,
                                type: "text-area",
                                label: "Evt. kommentar fra havemanden",
                                placeholder: "Brug dette felt, hvis havemanden vil tilknytte en kommentar til reklamationen",
                                value: "",
                                dirty: false,
                                validation: "optional",
                            },
                        ],
                    },
                },
                confirmationPage,
            ],
        };

        const gardenerHasOtherProposalPages: GardenerDoesHaveProposalPages = {
            type: "group",
            render: "all at once",
            items: [
                {
                    type: "page",
                    title: "Havemanden tilbyder kunden en anden løsning",
                    item: {
                        type: "group",
                        render: "all at once",
                        items: [
                            customerCommentTextArea,
                            customerProposalTextArea,
                            {
                                type: "notice",
                                render: "info",
                                html: "Kunden og havemanden får ikke disse beskrivelser at se.",
                            },
                            {
                                id: formIds.TextAreaGardenerHasOtherProposalGardenerProposal,
                                type: "text-area",
                                label: "Havemandens løsningsforslag",
                                placeholder: "Beskriv løsningen. Tilbydes der rabat, så uddyb hvem der giver hvad i rabat.",
                                value: "",
                                dirty: false,
                            },
                            {
                                id: formIds.TextAreaGardenerHasOtherProposalGardenerComment,
                                type: "text-area",
                                label: "Evt. kommentar fra havemanden",
                                placeholder: "Brug dette felt, hvis havemanden vil tilknytte en kommentar til reklamationen",
                                value: "",
                                dirty: false,
                                validation: "optional",
                            },
                        ],
                    },
                },
                confirmationPage,
            ],
        };

        return {
            type: "page",
            title: "Hvad ønsker havemanden at gøre?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "notice",
                        render: "info",
                        html: `
                            Bed havemanden forholde sig til problemet ifølge kunden og kundens løsningsforslag.<br />
                            <br />
                            Vil han tilbyde kunden en løsning? Det kunne f.eks. være:<br />
                            <ul>
                                <li>rabat fra havemanden <strong>(hvor meget?)</strong></li>
                                <li>at havemanden skal udbedre manglen</li>
                                <li>erstatning fra havemanden <strong>(hvor meget?)</strong></li>
                                <li>at droppe kravet mod kunden ved at annullere fakturaen</li>
                            </ul>
                        `,
                    },
                    customerCommentTextArea,
                    customerProposalTextArea,
                    {
                        id: formIds.SingleChoiceGardenerDecision,
                        type: "single-choice",
                        mode: "switch page",
                        render: "vertical-buttons",
                        questionText: "Hvordan ønsker havemanden at håndtere reklamationen?",
                        answerTexts: [
                            "Havemanden accepterer kundens løsning",
                            "Havemanden tilbyder kunden en anden løsning",
                            "Havemanden vil ikke tilbyde kunden en løsning",
                        ],
                        answerItems: [
                            gardenerAcceptsCustomerProposalPages,
                            gardenerHasOtherProposalPages,
                            gardenerDoesNotHaveProposalPages,
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                    }
                ],
            },
        };
    }, [complaint, formIds]);
}
