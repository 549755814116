import { useCallback } from "react";
import { useHistory } from "react-router";

export const useGoToGardenerProfile = (tab: "all" | "terminated", gardenerId: string) => {
    const history = useHistory();

    return useCallback(() => {
        history.push(`/gardeners/${tab}/${gardenerId}`);
    }, [history, tab, gardenerId]);
};
