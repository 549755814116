import { useCallback } from "react";
import { GardenerDocument } from "./gardener.model";

export const useLogInAsGardener = (gardener: GardenerDocument | null | undefined) => {
    return useCallback(() => {
        if ( !gardener ) return;

        return window.open(`https://web.gogogarden.dk/gardeners/${gardener.id}`, "_blank");
    }, [gardener]);
};
