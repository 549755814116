import { List, makeStyles, Paper, Typography } from "@material-ui/core";

import { TaskDocument } from "../../task/task.model";
import { FollowUpListItem } from "./FollowUpListItem";

const useStyles = makeStyles({
    paper: {
        padding: "16px",
        textAlign: "center",
    },
});

interface FollowUpListProps<T extends TaskDocument> {
    tasks: T[];
    formatPrimaryText: (task: T) => string;
    placeholder: string;
}

export const FollowUpList = <T extends TaskDocument>(props: FollowUpListProps<T>) => {
    const classes = useStyles();

    const { tasks, formatPrimaryText, placeholder } = props;

    if ( tasks.length === 0 ) {
        return (
            <Paper className={classes.paper}>
                <Typography>{placeholder}</Typography>
            </Paper>
        );
    }

    return (
        <Paper>
            <List disablePadding>
                {tasks.map(task => (
                    <FollowUpListItem key={task.id} task={task} formatPrimaryText={formatPrimaryText} />
                ))}
            </List>
        </Paper>
    );
};
