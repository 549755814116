import { Link } from "react-router-dom";

import { Comment } from "../components/comment/Comment";
import { OfferAcceptedCommentDocument } from "../comment/comment.model";
import { CommentBody } from "../components/comment/CommentBody";

interface OfferAcceptedCustomerCommentProps {
    comment: OfferAcceptedCommentDocument;
}

export const OfferAcceptedCustomerComment = (props: OfferAcceptedCustomerCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Havemand tilknyttet">
            <CommentBody>
                <Link to={`/gardeners/context/${comment.gardenerId}`}>{comment.gardenerName}</Link> har sagt ja tak til kunden.
            </CommentBody>
        </Comment>
    );
};
