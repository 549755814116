import "core-js/stable";
import "cross-fetch/polyfill";

import React from "react";
import ReactDOM from "react-dom";

import { ThemeProvider } from "@material-ui/core";
import "moment/locale/da";

import "./index.css";
import "./firebase";
import { Application } from "./Application";
import { theme } from "./theme";
import { UserProvider } from "./user/UserProvider";
import { DialogProvider } from "./components/dialog/DialogProvider";
import { SnackbarProvider } from "./components/snackbar";
import { BlockingProgressProvider } from "./components/blocking-progress";

ReactDOM.render(
    <React.StrictMode>

        <ThemeProvider theme={theme}>
            <UserProvider>
                <BlockingProgressProvider>
                    <DialogProvider>
                        <SnackbarProvider>

                                <Application />
                        
                        </SnackbarProvider>
                    </DialogProvider>
                </BlockingProgressProvider>
            </UserProvider>
        </ThemeProvider>

    </React.StrictMode>,
    document.getElementById("root")
);
