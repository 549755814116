import { Link } from "react-router-dom";

import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { AppointmentChangedCommentDocument } from "../comment.model";
import { formatDateIncludingWeekday } from "../../date-utility";

interface AppointmentChangedCommentProps {
    context: "customer" | "gardener";
    comment: AppointmentChangedCommentDocument;
}

export const AppointmentChangedComment = (props: AppointmentChangedCommentProps) => {
    const { context, comment } = props;

    const gardener = (() => {
        switch ( context ) {
            case "customer": return <Link to={`/gardeners/context/${comment.gardenerId}`}>{comment.gardenerName}</Link>;
            case "gardener": return <>Havemanden</>;
        }
    })();

    const customer = (() => {
        switch ( context ) {
            case "customer": return <>kunden</>;
            case "gardener": return <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link>;
        }
    })();



    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Aftale ændret">
            <CommentBody>
                {gardener} har ændret sin aftale med {customer}.<br />
                <br />
                Årsag:<br />
                {comment.reasonForChange}
                {comment.scheduledDate ? (
                    <>
                        <br />
                        <br />
                        Ny dato:<br />
                        {formatDateIncludingWeekday(comment.scheduledDate)}
                    </>
                ) : null}
                {comment.description ? (
                    <>
                        <br />
                        <br />
                        Ny beskrivelse:<br />
                        {comment.description}
                    </>
                ) : null}
            </CommentBody>
        </Comment>
    );
};
