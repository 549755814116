import { Container } from "@material-ui/core";

import { OnboardingTaskDocument } from "../../../task/task.model";
import { CommentDocument } from "../../../comment/comment.model";
import { Message } from "../../../Message";
import { ActivitySection } from "../../../comment/ActivitySection";
import { BackButton } from "../../../components/BackButton";
import { Section } from "../../../components/section";
import { TitleHeader } from "../../../components/TitleHeader";
import { ContentSection } from "../../../layout/ContentSection";
import { AdministratorActionData } from "../../../workflow/administrator-action.hook";
import { useCallback } from "react";
import { FormProps } from "../../../components/form-item/framework/react/FormProvider";
import { FormItem } from "../../../components/form-item/framework/core/form-item.model";
import { GardenerCommentList } from "../../../comment/GardenerCommentList";
import { ApplicationTaskDetailCard } from "./ApplicationTaskDetailCard";
import { GreenButton, VerticalButtons } from "../../../components/new-button";
import { ApplicationResponseFormIds, useApplicationResponseDialog } from "./ApplicationResponseDialog";
import { GardenerDocument } from "../../../gardener/gardener.model";
import { SingleChoiceFormItem } from "../../../components/form-item/client/components";
import { ApplicationDetailCard } from "../../../gardener-application/ApplicationDetailCard";
import { PersonalInformationDocument } from "../../../gardener/personal-information/personal-information.model";

export interface ApplicationDetailPageProps {
    gardener: GardenerDocument;
    personalInformation: PersonalInformationDocument;
    task: OnboardingTaskDocument;
    administratorAction: (data: AdministratorActionData | string) => void;
    comments: CommentDocument[];
    addComment: (comment: string) => void;
    goBack: () => void;
}

export const ApplicationDetailPage = (props: ApplicationDetailPageProps) => {
    const { gardener, personalInformation, task, administratorAction, comments, addComment } = props;

    const backButton = <BackButton onClick={props.goBack} />;

    const responseAdapter = useCallback<FormProps<FormItem>["onSubmit"]>((controller, item) => {
        const seriousApplication = (controller.getItem(ApplicationResponseFormIds.SingleChoiceSeriousApplication, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 1;
        if ( !seriousApplication ) {
            return administratorAction({
                action: "reject application",
                taskId: task.id,
                reason: "applicant is a bad fit",
            });
        }

        const applicationInDanish = (controller.getItem(ApplicationResponseFormIds.SingleChoiceApplicationInDanish, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 1;
        if ( !applicationInDanish ) {
            return administratorAction({
                action: "reject application",
                taskId: task.id,
                reason: "application not in danish",
            });
        }

        const hasExperience = (controller.getItem(ApplicationResponseFormIds.SingleChoiceHasExperience, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 1;
        if ( !hasExperience ) {
            return administratorAction({
                action: "reject application",
                taskId: task.id,
                reason: "applicant experience not evident",
            });
        }
        
        if ( gardener.cvr ) {
            const canSignForCompany = (controller.getItem(ApplicationResponseFormIds.SingleChoiceCanSignForCompany, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 1;
            if ( !canSignForCompany ) {
                return administratorAction({
                    action: "reject application",
                    taskId: task.id,
                    reason: "applicant does not sign for specified company",
                });
            }
        }
        
        return administratorAction({
            action: "schedule call about application",
            taskId: task.id,
        });
    }, [administratorAction, gardener, task]);
    const openResponseDialog = useApplicationResponseDialog(gardener, task, responseAdapter);

    return (
        <>
            <TitleHeader button={backButton}>Ansøgning</TitleHeader>
            
            <ContentSection>
                <Container maxWidth="sm" disableGutters>

                    <Section>
                        {task.hasBeenPerformed ? <Message>Opgaven er allerede blevet udført</Message> : null}

                        <ApplicationTaskDetailCard task={task} />
                        <ApplicationDetailCard gardener={gardener} personalInformation={personalInformation} application={task.application} />

                        {!task.hasBeenPerformed ? (
                            <VerticalButtons>
                                <GreenButton onClick={openResponseDialog}>Besvar opgave</GreenButton>
                            </VerticalButtons>
                        ) : null}

                    </Section>

                    <ActivitySection onSave={addComment}>
                        <GardenerCommentList comments={comments} />
                    </ActivitySection>

                </Container>
            </ContentSection>
        </>
    );
};
