import { useMemo } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";

import moment from "moment";

import { TitleHeader } from "../../components/TitleHeader";
import { TabBar } from "../../components/tabs/TabBar";
import { useComplaintTasks } from "../../workflow/follow-up/appointment/TaskProvider";
import { TabBarItem } from "../../components/tabs/TabBarItem";
import { IntegratedContactCustomerTab } from "./IntegratedContactCustomerTab";
import { IntegratedContactGardenerTab } from "./IntegratedContactGardenerTab";
import { IntegratedDetermineProposalTab } from "./IntegratedDetermineProposalTab";
import { useIsSuperAdministrator } from "../../LinkProvider";
import { IntegratedAwaitCustomerResponseTab } from "./IntegratedAwaitCustomerResponseTab";

export const IntegratedComplaintOverviewPage = () => {
    const { tab } = useParams<{ tab: "contact-customer" | "contact-gardener" | "determine-proposal" | "await-customer-response" }>();

    const isSuperAdministrator = useIsSuperAdministrator();

    const tabIndex = useMemo(() => {
        switch ( tab ) {
            case "contact-customer": return 0;
            case "contact-gardener": return 1;
            case "determine-proposal": return isSuperAdministrator ? 2 : 0;
            case "await-customer-response": return isSuperAdministrator ? 3: 0;

            default: return 0;
        }
    }, [tab, isSuperAdministrator]);

    const now = moment().toISOString(true);

    const complaintTasks = useComplaintTasks();
    const contactCustomerTasks = useMemo(() => complaintTasks.filter(task => task.complaint.status === "contacting customer" && task.performAfterDate <= now), [complaintTasks, now]);
    const contactGardenerTasks = useMemo(() => complaintTasks.filter(task => task.complaint.status === "contacting gardener" && task.performAfterDate <= now), [complaintTasks, now]);

    if ( tab !== "contact-customer" && tab !== "contact-gardener" && (!isSuperAdministrator || tab !== "determine-proposal") && (!isSuperAdministrator || tab !== "await-customer-response") ) return <Redirect to="/complaint/contact-customer" />;

    return (
        <>
            <TitleHeader>Reklamation</TitleHeader>

            <TabBar index={tabIndex}>
                <TabBarItem href="/complaint/contact-customer">{formatTitle("Kontakt kunde", contactCustomerTasks.length)}</TabBarItem>
                <TabBarItem href="/complaint/contact-gardener">{formatTitle("Kontakt havemand", contactGardenerTasks.length)}</TabBarItem>
                {isSuperAdministrator ? <TabBarItem href="/complaint/determine-proposal">Tilbyd løsning</TabBarItem> : null}
                {isSuperAdministrator ? <TabBarItem href="/complaint/await-customer-response">Afvent svar fra kunde</TabBarItem> : null}
            </TabBar>

            <Switch>

                <Route exact path="/complaint/contact-customer">
                    <IntegratedContactCustomerTab />
                </Route>

                <Route exact path="/complaint/contact-gardener">
                    <IntegratedContactGardenerTab />
                </Route>

                <Route exact path="/complaint/determine-proposal">
                    <IntegratedDetermineProposalTab />
                </Route>

                <Route exact path="/complaint/await-customer-response">
                    <IntegratedAwaitCustomerResponseTab />
                </Route>

            </Switch>
        </>
    );
};

function formatTitle(text: string, count: number): string {
    if ( count === 0 ) return text;

    return `${text} (${count})`;
};
