import { DetailedHTMLProps, HTMLAttributes } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        listStyle: "none",
        paddingLeft: "8px",
        margin: 0,

        "& > li": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
            paddingLeft: "13px",
        
            "&::before": {
                content: '"\\2022"',
                fontWeight: 600,
                marginRight: "8px",
                marginLeft: "-13px",
            },
        }
    },
});

export const UnorderedList = (props: DetailedHTMLProps<HTMLAttributes<HTMLUListElement>, HTMLUListElement>) => {
    const classes = useStyles();

    const { className, ...otherProps } = props;

    return (
        <ul className={className ? `${classes.root} ${className}` : classes.root} {...otherProps}>
            {props.children}
        </ul>
    );
};
