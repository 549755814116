import { useMemo } from "react";

import { PageFormItem, InfoFormItem, GroupFormItem, SingleChoiceFormItem, TextAreaFormItem, NoticeFormItem } from "../../components/form-item/client/components";
import { ComplaintDocument } from "../complaint.model";
import { ListFormatter } from "../../formatting/list.formatter";

export interface CustomerOpinionFormIds {
    TextAreaCustomerComment: string;
    SingleChoiceCustomerProposal: string;
    TextAreaCustomerProposal: string;
}

export type CustomerOpinionForm =
    GroupFormItem<[
        CustomerCommentPage,
        PageFormItem<
            GroupFormItem<[
                NoticeFormItem,
                SingleChoiceFormItem<[
                    CustomerProposalPage,
                    ConfirmationPage,
                    ConfirmationPage
                ]>
            ]>
        >,
    ]>;

type CustomerCommentPage =
    PageFormItem<
        GroupFormItem<[
            InfoFormItem,
            NoticeFormItem,
            TextAreaFormItem,
        ] | [NoticeFormItem, TextAreaFormItem]>
    >

type CustomerProposalPage =
    GroupFormItem<[
        PageFormItem<
            GroupFormItem<[
                NoticeFormItem,
                InfoFormItem,
                NoticeFormItem,
                TextAreaFormItem,
            ] | [NoticeFormItem, NoticeFormItem, TextAreaFormItem]>
        >,
        ConfirmationPage,
    ]>;

type ConfirmationPage = PageFormItem<InfoFormItem>;

export const useCustomerOpinionForm = (complaint: ComplaintDocument, formIds: CustomerOpinionFormIds): CustomerOpinionForm => {
    return useMemo(() => {
        const listFormatter = new ListFormatter();
        const invoicesListed = listFormatter.formatList(complaint.invoices.map(x => `faktura ${x.invoiceNumber}`));

        const customerProposalPage: CustomerProposalPage = {
            type: "group",
            render: "all at once",
            items: [
                {
                    type: "page",
                    title: "Hvad mener kunden kan være en løsning?",
                    item: {
                        type: "group",
                        render: "all at once",
                        items: complaint.customerOpinion?.proposal ? [
                            {
                                type: "notice",
                                render: "info",
                                html: `
                                    Hvilken løsning ønsker kunden?<br />
                                    <ul>
                                        <li>rabat fra havemanden <strong>(hvor meget?)</strong></li>
                                        <li>at havemanden skal udbedre manglen</li>
                                        <li>erstatning fra havemanden <strong>(hvor meget?)</strong>
                                    </ul>
                                `,
                            },
                            {
                                type: "info",
                                render: "default",
                                text: "Uddyb løsningsforslaget, hvis du får mere information fra kunden.",
                            },
                            {
                                type: "notice",
                                render: "warning",
                                html: `
                                    <strong>OBS:</strong> Havemanden kan få denne beskrivelse at se
                                `,
                            },
                            {
                                id: formIds.TextAreaCustomerProposal,
                                type: "text-area",
                                label: "Kundens løsningsforslag",
                                placeholder: "Beskriv kundens løsningsforslag",
                                value: complaint.customerOpinion.proposal,
                                dirty: false,
                            },
                        ] : [
                            {
                                type: "notice",
                                render: "info",
                                html: `
                                    Hvilken løsning ønsker kunden?<br />
                                    <ul>
                                        <li>rabat fra havemanden <strong>(hvor meget?)</strong></li>
                                        <li>at havemanden skal udbedre manglen</li>
                                        <li>erstatning fra havemanden <strong>(hvor meget?)</strong>
                                    </ul>
                                `,
                            },
                            {
                                type: "notice",
                                render: "warning",
                                html: `
                                    <strong>OBS:</strong> Havemanden kan få denne beskrivelse at se
                                `,
                            },
                            {
                                id: formIds.TextAreaCustomerProposal,
                                type: "text-area",
                                label: "Kundens løsningsforslag",
                                placeholder: "Beskriv kundens løsningsforslag",
                                value: "",
                                dirty: false,
                            },
                        ],
                    },
                },
                {
                    type: "page",
                    title: "Vi fortsætter reklamationen",
                    item: {
                        type: "info",
                        render: "default",
                        text: `
                            Der oprettes en opgave i “Kontakt havemand”.<br />
                            <br />
                            Havemanden modtager en notifikation om, at der er modtaget en reklamation på ${invoicesListed} og at vi vil forsøge at kontakte dem.
                        `,
                    },
                },
            ],
        };

        const customerHasNoProposalConfirmationPageTextLines = [];
        if ( complaint.customerOpinion?.proposal ) customerHasNoProposalConfirmationPageTextLines.push("Vi fjerner den beskrevne løsning fra reklamationen.");
        customerHasNoProposalConfirmationPageTextLines.push("Opgaven afsluttes.");
        customerHasNoProposalConfirmationPageTextLines.push("Kunden modtager en notifikation om, at vi har forsøgt at finde en løsning med dem, men at det ikke er lykkedes og at havemanden er blevet informeret om reklamationen.");
        customerHasNoProposalConfirmationPageTextLines.push(`Havemanden modtager en notifikation om, at der er modtaget en reklamation på ${invoicesListed} og at de nu må finde en løsning sammen med kunden.`);
        
        const customerHasNoProposalConfirmationPage: ConfirmationPage = {
            type: "page",
            title: "Reklamationen overdrages til havemanden",
            item: {
                type: "info",
                render: "default",
                text: customerHasNoProposalConfirmationPageTextLines.join("<br /><br />"),
            },
        };

        const customerCommentPage: CustomerCommentPage = {
            type: "page",
            title: "Hvad mener kunden er problemet?",
            item: {
                type: "group",
                render: "all at once",
                items: complaint.customerOpinion?.comment ? [
                    {
                        type: "info",
                        render: "default",
                        text: "Uddyb problemet, hvis du får mere information fra kunden.",
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            <strong>OBS:</strong> Havemanden kan få denne beskrivelse at se
                        `,
                    },
                    {
                        id: formIds.TextAreaCustomerComment,
                        type: "text-area",
                        label: "Problemet ifølge kunden",
                        placeholder: "Beskriv problemet ifølge kunden",
                        value: complaint.customerOpinion?.comment,
                        dirty: false,
                    },
                ] : [
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            <strong>OBS:</strong> Havemanden kan få denne beskrivelse at se
                        `,
                    },
                    {
                        id: formIds.TextAreaCustomerComment,
                        type: "text-area",
                        label: "Problemet ifølge kunden",
                        placeholder: "Beskriv problemet ifølge kunden",
                        value: "",
                        dirty: false,
                    },
                ],
            },
        };
        
        return {
            type: "group",
            render: "all at once",
            items: [
                customerCommentPage,
                {
                    type: "page",
                    title: "Hvad ønsker kunden?",
                    item: {
                        type: "group",
                        render: "all at once",
                        items: [
                            {
                                type: "notice",
                                render: "info",
                                html: `
                                    Hør kunden hvad der skal til, for at reklamationen kan løses. Hjælp dem med at finde frem til en <strong>rimelig</strong> løsning.<br />
                                    <br />
                                    Løsningen kan være én af følgende:<br />
                                    <ul>
                                        <li>rabat fra havemanden <strong>(hvor meget?)</strong></li>                                    
                                        <li>at havemanden skal udbedre manglen</li>
                                        <li>erstatning fra havemanden <strong>(hvor meget?)</strong></li>
                                    </ul>
                                `,
                            },
                            {
                                id: formIds.SingleChoiceCustomerProposal,
                                type: "single-choice",
                                mode: "switch page",
                                render: "vertical-buttons",
                                questionText: "Har kunden et løsningsforslag?",
                                answerTexts: [
                                    "Kunden har et rimeligt løsningsforslag",
                                    "Kunden har kun urimelige løsningsforslag",
                                    "Kunden har ingen løsningsforslag",
                                ],
                                answerItems: [
                                    customerProposalPage,
                                    customerHasNoProposalConfirmationPage,
                                    customerHasNoProposalConfirmationPage,
                                ],
                                selectedChoiceIndex: null,
                                dirty: false,
                            },
                        ],
                    },
                },
            ]
        };
    }, [complaint, formIds]);
}
