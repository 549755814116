import { Divider, DividerProps } from "@material-ui/core";

interface MenuDividerProps extends DividerProps {
    closeMenu?: () => void;
}

export const MenuDivider = (props: MenuDividerProps) => {
    const { closeMenu, ...otherProps } = props;

    return <Divider {...otherProps} />;
};
