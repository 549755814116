import { useCallback, useMemo, useState } from "react";

import { Collapse, MenuItem } from "@material-ui/core";

import { NeutralAction } from "../../components/card/NeutralAction";
import { PositiveAction } from "../../components/card/PositiveAction";
import { Dialog, DialogActions, DialogContent, DialogHeader, useDialog } from "../../components/dialog";
import { Label } from "../../components/form/Label";
import { Group } from "../../components/form/Group";
import { AlternativeHint } from "../../components/form/Hint";
import { useAnimation } from "../../components/validation/animation.hook";
import { validateAddress, validateMandatory, validateNotEmpty } from "../../components/validation/validation";
import { TextArea } from "../../components/form/InputField";
import { TwoOptionSwitch } from "../../components/switch/TwoOptionSwitch";
import { Dropdown } from "../../components/form/Dropdown";
import { ChipSelector } from "../../components/chip-selector/ChipSelector";
import { AddressField, AddressFieldAddress, useAddressPreloadingHack } from "../../components/form";
import { HourInterval, TaskTools, TaskFrequency, Skill } from "../gardening-task.model";

export interface UpdateGardeningTaskDialogViewModel {
    addressSearch: {
        searchTerm: string;
        address: AddressFieldAddress | undefined;
    },
    addressSearchDirty: boolean;
    hourInterval: HourInterval;
    hourIntervalDirty: boolean;
    tools: TaskTools;
    toolsDirty: boolean;
    frequency: TaskFrequency;
    frequencyDirty: boolean;
    skills: Skill[];
    skillsDirty: boolean;
    description: string;
    descriptionDirty: boolean;
}

export interface UpdateGardeningTaskDialogErrorModel {
    any: string | undefined;
    addressSearch: string | undefined;
    hourInterval: string | undefined;
    tools: string | undefined;
    frequency: string | undefined;
    skills: string | undefined;
    description: string | undefined;
}

export interface UpdateGardeningTaskDialogProps {
    initialViewModel: UpdateGardeningTaskDialogViewModel;
    onConfirm: (viewModel: UpdateGardeningTaskDialogViewModel) => void;
    onCancel: () => void;
}

export const UpdateGardeningTaskDialog = (props: UpdateGardeningTaskDialogProps) => {
    const { initialViewModel, onConfirm, onCancel } = props;
    const [viewModel, setViewModel] = useState(initialViewModel);

    const errorModel = useMemo<UpdateGardeningTaskDialogErrorModel>(() => {
        const addressSearchError = [validateMandatory(viewModel.addressSearch.searchTerm), validateAddress(viewModel.addressSearch.address)].find(Boolean);
        const hourIntervalError = [].find(Boolean);
        const toolsError = [].find(Boolean);
        const frequencyError = [].find(Boolean);
        const skillsError = [validateNotEmpty(viewModel.skills)].find(Boolean);
        const descriptionError = [validateMandatory(viewModel.description)].find(Boolean);

        return {
            any: addressSearchError || hourIntervalError || toolsError || frequencyError || skillsError || descriptionError,
            addressSearch: addressSearchError,
            hourInterval: hourIntervalError,
            tools: toolsError,
            frequency: frequencyError,
            skills: skillsError,
            description: descriptionError,
        };
    }, [viewModel]);

    const changeAddressSearch = useCallback((value: UpdateGardeningTaskDialogViewModel["addressSearch"]) => {
        setViewModel(viewModel => ({ ...viewModel, addressSearch: value }));
    }, [setViewModel])

    const markAddressSearchDirty = useCallback(() => {
        setViewModel(viewModel => ({ ...viewModel, addressSearchDirty: true }));
    }, [setViewModel]);

    const onExactAddress = useCallback((address: AddressFieldAddress) => {
        if ( viewModel.addressSearch.searchTerm !== initialViewModel.addressSearch.searchTerm ) return;

        changeAddressSearch({
            searchTerm: initialViewModel.addressSearch.searchTerm,
            address,
        });
    }, [changeAddressSearch, initialViewModel, viewModel]);

    useAddressPreloadingHack(initialViewModel.addressSearch.searchTerm, initialViewModel.addressSearch.address, onExactAddress, markAddressSearchDirty);

    const hourIntervalOptions = useMemo(() => [
        { label: "1-2 timer", value: "1-2 hours" as HourInterval },
        { label: "3-5 timer", value: "3-5 hours" as HourInterval },
        { label: "Mere end 6 timer", value: "more-than-6-hours" as HourInterval },
    ], []);

    const changeHourInterval = useCallback((hourInterval: string) => {
        setViewModel(viewModel => ({ ...viewModel, hourInterval: hourInterval as HourInterval }));
    }, []);
    
    const markHourIntervalDirty = useCallback(() => {
        setViewModel(viewModel => ({ ...viewModel, hourIntervalDirty: true }));
    }, [setViewModel]);

    const toolsOptions = useMemo(() => [
        { label: "Kunden", value: "customer-tools" as TaskTools },
        { label: "Havemanden", value: "helper-tools" as TaskTools },
    ], []);
    const changeTools = useCallback((tools: TaskTools) => {
        setViewModel(viewModel => ({ ...viewModel, tools, toolsDirty: true }));
    }, []);

    const frequencyOptions = useMemo(() => [
        { label: "En enkelt gang", value: "once" as TaskFrequency },
        { label: "En gang om ugen", value: "once-every-week" as TaskFrequency },
        { label: "En gang hver anden uge", value: "once-every-second-week" as TaskFrequency },
        { label: "En gang om måneden", value: "once-every-month" as TaskFrequency },
    ], []);

    const changeFrequency = useCallback((frequency: string) => {
        setViewModel(viewModel => ({ ...viewModel, frequency: frequency as TaskFrequency }));
    }, []);
    
    const markFrequencyDirty = useCallback(() => {
        setViewModel(viewModel => ({ ...viewModel, frequencyDirty: true }));
    }, [setViewModel]);

    const skillsOptions = useMemo<Skill[]>(() => ["lawn-mowing", "weed-control", "hedge-trimming", "pruning-of-trees-and-shrubs", "disposal-of-garden-waste", "other-garden-services"], []);

    const changeSkills = useCallback((skills: Skill[]) => {
        setViewModel(viewModel => ({ ...viewModel, skills, skillsDirty: true }));
    }, []);
    
    const formatSkill = useCallback((value: Skill) => {
        switch ( value ) {
            case "lawn-mowing": return "Græsslåning";
            case "weed-control": return "Ukrudtsbekæmpelse";
            case "hedge-trimming": return "Hækklipning";
            case "pruning-of-trees-and-shrubs": return "Beskæring";
            case "disposal-of-garden-waste": return "Bortkørsel af haveaffald";
            case "other-garden-services": return "Anden haveservice";
        }
    }, []);

    const changeDescription = useCallback((value: string) => {
        setViewModel(viewModel => ({ ...viewModel, description: value }));
    }, [setViewModel])

    const markDescriptionDirty = useCallback(() => {
        setViewModel(viewModel => ({ ...viewModel, descriptionDirty: true }));
    }, [setViewModel]);

    const [validationAnimationDuration, skipValidationAnimation] = useAnimation();

    const scrollToFirstError = useCallback(() => {
        const sectionId = (() => {
            if ( errorModel.hourInterval ) return "hour-interval-section";
            if ( errorModel.tools ) return "tools-section";
            if ( errorModel.frequency ) return "frequency-section";
            if ( errorModel.skills ) return "skills-section";
            if ( errorModel.description ) return "description-section";

            return undefined;
        })();

        if ( !sectionId ) return;

        const section = document.getElementById(sectionId);
        if ( !section ) return;

        section.scrollIntoView({ behavior: "smooth" });
    }, [errorModel]);

    const confirm = useCallback(() => {
        if ( document.activeElement ) {
            (document.activeElement as HTMLInputElement).blur();
        }

        if ( errorModel.any ) {
            skipValidationAnimation();
            setViewModel(viewModel => ({
                ...viewModel,
                hourIntervalDirty: true,
                toolsDirty: true,
                frequencyDirty: true,
                skillsDirty: true,
                descriptionDirty: true,
            }));

            return setTimeout(scrollToFirstError, 100);
        }

        onConfirm(viewModel);
    }, [skipValidationAnimation, scrollToFirstError, onConfirm, errorModel.any, viewModel]);

    return (
        <Dialog>
            <DialogHeader>Ændr opgave</DialogHeader>

            <DialogContent>

                <div id="address-search-section">
                    <Label htmlFor="address-search" style={{ marginBottom: "8px" }}>Adresse</Label>
                    <Group error={Boolean(viewModel.addressSearchDirty && errorModel.addressSearch)}>
                        <AddressField id="address-search" searchTerm={viewModel.addressSearch.searchTerm} value={viewModel.addressSearch.address} onChange={changeAddressSearch} onBlur={markAddressSearchDirty} />

                        <Collapse in={Boolean(viewModel.addressSearchDirty && errorModel.addressSearch)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.addressSearch} />
                        </Collapse>
                    </Group>
                </div>

                <div id="skills-section">
                    <Label style={{ marginBottom: "8px" }}>Opgaver</Label>
                    <Group error={Boolean(viewModel.skillsDirty && errorModel.skills)}>

                        <ChipSelector
                            values={viewModel.skills}
                            onChange={changeSkills}
                            onFormat={formatSkill}
                            options={skillsOptions}
                        />

                        <Collapse in={Boolean(viewModel.skillsDirty && errorModel.skills)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.skills} />
                        </Collapse>
                    </Group>
                </div>

                <div id="hour-interval-section">
                    <Label id="hour-interval-label" style={{ marginBottom: "8px" }}>Hvor lang tid forventes opgaven at tage?</Label>
                    <Group error={Boolean(viewModel.hourIntervalDirty && errorModel.hourInterval)}>

                        <Dropdown labelId="hour-interval-label" variant="outlined" value={viewModel.hourInterval} onChange={changeHourInterval} onBlur={markHourIntervalDirty}>
                            {hourIntervalOptions.map(option => (
                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            ))}
                        </Dropdown>

                        <Collapse in={Boolean(viewModel.hourIntervalDirty && errorModel.hourInterval)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.hourInterval} />
                        </Collapse>
                    </Group>
                </div>

                <div id="tools-section">
                    <Label style={{ marginBottom: "8px" }}>Hvem skal stille redskaber til rådighed?</Label>
                    <Group error={Boolean(viewModel.toolsDirty && errorModel.tools)}>

                        <TwoOptionSwitch options={toolsOptions} value={viewModel.tools} onChange={changeTools} />

                        <Collapse in={Boolean(viewModel.toolsDirty && errorModel.tools)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.tools} />
                        </Collapse>
                    </Group>
                </div>

                <div id="frequency-section">
                    <Label id="frequency-label" style={{ marginBottom: "8px" }}>Hvor ofte er der brug for hjælp?</Label>
                    <Group error={Boolean(viewModel.frequencyDirty && errorModel.frequency)}>

                        <Dropdown labelId="frequency-label" variant="outlined" value={viewModel.frequency} onChange={changeFrequency} onBlur={markFrequencyDirty}>
                            {frequencyOptions.map(option => (
                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            ))}
                        </Dropdown>

                        <Collapse in={Boolean(viewModel.frequencyDirty && errorModel.frequency)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.frequency} />
                        </Collapse>
                    </Group>
                </div>

                <div id="description-section">
                    <Label htmlFor="description" style={{ marginBottom: "8px" }}>Beskrivelse</Label>
                    <Group error={Boolean(viewModel.descriptionDirty && errorModel.description)}>
                        <TextArea id="description" value={viewModel.description} onChange={changeDescription} onBlur={markDescriptionDirty} />

                        <Collapse in={Boolean(viewModel.descriptionDirty && errorModel.description)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.description} />
                        </Collapse>
                    </Group>
                </div>

            </DialogContent>

            <DialogActions>
                <NeutralAction onClick={onCancel}>Annuller</NeutralAction>
                <PositiveAction onClick={confirm}>Bekræft</PositiveAction>
            </DialogActions>

        </Dialog>
    );
};

export const useUpdateGardeningTaskDialog = (initialViewModel: UpdateGardeningTaskDialogViewModel | undefined, onConfirm: UpdateGardeningTaskDialogProps["onConfirm"]) => {
    const { openDialog, closeDialog } = useDialog();

    const confirm: UpdateGardeningTaskDialogProps["onConfirm"] = useCallback((data) => {
        closeDialog();

        onConfirm(data);
    }, [closeDialog, onConfirm]);

    return useCallback(() => {
        if ( !initialViewModel ) return;

        openDialog(<UpdateGardeningTaskDialog initialViewModel={initialViewModel} onConfirm={confirm} onCancel={closeDialog} />);
    }, [openDialog, closeDialog, confirm, initialViewModel]);
};
