import { Service } from "../../invoice.model";
import { InvoiceFormContext } from "./InvoiceForm";
import { validateServiceInput } from "./service";

export const determineServices = (form: InvoiceFormContext): Service[] => {
    return form.serviceInputs
        .filter((_, index) => !validateServiceInput(form, index))
        .map(serviceInput => ({
            description: serviceInput.descriptionInput.description,
            hours: serviceInput.durationInput.hours!,
            tools: serviceInput.toolsInput.tools!,
            hourlyCustomerPrice: (() => {
                switch ( serviceInput.toolsInput.tools! ) {
                    case "customer-tools": return form.customerPrice.customerTools;
                    case "gardener-tools": return form.customerPrice.gardenerTools;
                    case "gardener-large-tools": return form.customerPrice.gardenerLargeTools;
                }
            })(),
            hourlyGardenerPrice: (() => {
                switch ( serviceInput.toolsInput.tools! ) {
                    case "customer-tools": return form.gardenerPrice.customerTools;
                    case "gardener-tools": return form.gardenerPrice.gardenerTools;
                    case "gardener-large-tools": return form.gardenerPrice.gardenerLargeTools;
                }
            })()
        }));
}
