import { Comment } from "../../components/comment/Comment";
import { AutomaticPaymentSubscriptionCommentDocument } from "../comment.model";
import { CommentBody } from "../../components/comment/CommentBody";
import { Link } from "react-router-dom";

interface AutomaticPaymentSubscriptionCommentProps {
    comment: AutomaticPaymentSubscriptionCommentDocument;
}

export const AutomaticPaymentSubscriptionComment = (props: AutomaticPaymentSubscriptionCommentProps) => {
    const { comment } = props;

    const customer = <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link>;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Automatisk betaling">
            <CommentBody>
                {customer} har tilmeldt sig automatisk betaling.
            </CommentBody>
        </Comment>
    );
};
