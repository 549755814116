import { InfoFormItem, PageFormItem, useFormDialog } from "../../../components/form-item/client/components";
import { FormProps } from "../../../components/form-item/framework/react/FormProvider";
import { OnboardingTaskDocument } from "../../../task/task.model";
import { CheckCompanySigningForm, useCheckCompanySigningForm } from "../../shared/CheckCompanySigning";
import { GardenerDocument } from "../../../gardener/gardener.model";
import { CheckApplicationForm, useCheckApplicationForm } from "../../shared/CheckApplicationForm";

export enum ApplicationResponseFormIds {
    SingleChoiceSeriousApplication = "SingleChoiceSeriousApplication",
    SingleChoiceApplicationInDanish = "SingleChoiceApplicationInDanish",
    SingleChoiceHasExperience = "SingleChoiceHasExperience",
    SingleChoiceCanSignForCompany = "SingleChoiceCanSignForCompany",
}

export type ApplicationResponseForm =
    CheckApplicationForm<
        CheckCompanySigningForm<
            ConfirmationPage
        >
    >

type ConfirmationPage = PageFormItem<InfoFormItem>

export const useApplicationResponseDialog = (gardener: GardenerDocument, task: OnboardingTaskDocument, onConfirm: FormProps<ApplicationResponseForm>["onSubmit"]) => {
    
    const confirmationPage: ConfirmationPage = {
        type: "page",
        title: "Udtag til samtale",
        item: {
            type: "info",
            render: "default",
            text: `
                Der oprettes en opgave i “samtale”.<br />
                <br />
                Ansøgeren informeres ikke omkring dette.
            `,
        },
    };

    const checkCompanySigningForm = useCheckCompanySigningForm(gardener, confirmationPage, {
        SingleChoiceCanSignForCompany: ApplicationResponseFormIds.SingleChoiceCanSignForCompany,
    });

    const checkApplicationForm = useCheckApplicationForm(task, checkCompanySigningForm, {
        SingleChoiceSeriousApplication: ApplicationResponseFormIds.SingleChoiceSeriousApplication,
        SingleChoiceApplicationInDanish: ApplicationResponseFormIds.SingleChoiceApplicationInDanish,
        SingleChoiceHasExperience: ApplicationResponseFormIds.SingleChoiceHasExperience,
    });

    return useFormDialog(checkApplicationForm, onConfirm);
};
