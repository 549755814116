import { Link } from "react-router-dom";

import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { InvoiceUpdateCommentDocument } from "../comment.model";

interface CustomerInvoiceUpdateCommentProps {
    comment: InvoiceUpdateCommentDocument;
}

export const CustomerInvoiceUpdateComment = (props: CustomerInvoiceUpdateCommentProps) => {
    const { comment } = props;

    const invoice = <Link to={`/invoices/all/invoice/${comment.invoiceId}`}>Faktura {comment.invoiceNumber}</Link>;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Faktura rettet" author={comment.administratorName}>
            <CommentBody>
                {invoice} udstedt af <Link to={`/gardeners/context/${comment.gardenerId}`}>{comment.gardenerName}</Link> er blevet rettet.<br />
                <br />
                Årsag:<br />
                {comment.reason}
            </CommentBody>
        </Comment>
    );
};
