import { Link } from "react-router-dom";

import moment from "moment";

import { InformationInputCommentDocument } from "./comment.model";
import { Comment } from "../components/comment/Comment";
import { CommentBody } from "../components/comment/CommentBody";

interface InformationInputCommentProps {
    comment: InformationInputCommentDocument;
}

export const InformationInputComment = (props: InformationInputCommentProps) => {
    const { comment } = props;

    const administrator = comment.data.administrator;

    const source = (() => {
        if ( administrator ) return "administration";

        if ( comment.data.scenario === "create task" ) return "kunde";

        return "system";
    })();

    const title = `Besked modtaget fra ${source}`;

    const customer = source !== "kunde" ? <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link> : <>Jeg</>;
    const message = (() => {
        switch ( comment.data.scenario ) {
            case "create task": return <>{customer} har brug for hjælp i haven.</>;
            case "task timeout deadline":
                if ( comment.data.hasBackstopAttempt ) return <>Opgaven fra {customer} lukkes, hvis ikke der er fremskridt inden {formatDate(comment.data.timeoutDeadline)}.</>;
                else return <>Opgaven fra {customer} tilbydes til en bagstopper eller lukkes, hvis ikke der er fremskridt inden {formatDate(comment.data.timeoutDeadline)}.</>;

            case "cannot find gardener": return <>Det var ikke muligt at finde en havemand til opgaven fra {customer}.</>;
            case "abort gardening task":
                return (
                    <>
                        Der er ikke brug for en havemand alligevel til opgaven fra {customer}.<br />
                        <br />
                        Årsag:<br />
                        {comment.data.reason}
                    </>
                );
        }
    })();

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title={title} author={comment.data.administrator?.name}>
            <CommentBody>

                {message}

            </CommentBody>
        </Comment>
    );
};

const formatDate = (date: string): string => {
    const dayOfWeek = moment(date).format("dddd");
    const dayAndMonth = moment(date).format("D MMMM");
    const time = moment(date).format("HH:mm");

    return `${dayOfWeek} d. ${dayAndMonth} kl. ${time}`;
}
