import { PropsWithChildren } from "react";

import { List as MaterialList, Paper } from "@material-ui/core";

export const List = (props: PropsWithChildren<{}>) => {
    const { children } = props;

    return (
        <Paper>
            <MaterialList disablePadding>

                {children}

            </MaterialList>
        </Paper>
    );
};
