import { useMemo } from "react";

import { PageFormItem, HorizontalButtonsSingleChoiceFormItem, InfoFormItem, GroupFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { CloseComplaintForm } from "./CloseComplaintForm";

export interface ComplaintDueToMisunderstandingFormIds {
    SingleChoiceHasMisunderstanding: string;
}

export type ComplaintDueToMisunderstandingForm<NextPage extends FormItem> = 
    PageFormItem<
        GroupFormItem<[
            InfoFormItem,
            HorizontalButtonsSingleChoiceFormItem<[
                NextPage,
                CloseComplaintForm,
            ]>
        ]>
    >;

export const useComplaintDueToMisunderstandingForm = <T extends FormItem>(closeComplaintForm: CloseComplaintForm, nextPage: T, formIds: ComplaintDueToMisunderstandingFormIds): ComplaintDueToMisunderstandingForm<T> => {
    return useMemo(() => {
        return {
            type: "page",
            title: "Er der tale om en misforståelse?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Det kan ske, at reklamationen skyldes en misforståelse, f.eks. vedrørende tidsforbrug ved kørsel til genbrugspladsen.

                            Kan misforståelsen løses i telefonen, kan reklamationen afsluttes.
                        `,
                    },
                    {
                        id: formIds.SingleChoiceHasMisunderstanding,
                        type: "single-choice",
                        mode: "switch page",
                        render: "horizontal-buttons",
                        questionText: null,
                        answerTexts: ["Nej", "Ja"],
                        answerItems: [
                            nextPage,
                            closeComplaintForm,
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                    },
                ],
            },
        };
    }, [closeComplaintForm, nextPage, formIds]);
}
