import { useCallback } from "react";

import firebase from "firebase/compat/app";

import { useBlockingProgress } from "../../../../../components/blocking-progress";
import { useSnackbar } from "../../../../../components/snackbar";
import { CancelInvoiceFormContext } from "./CancelInvoiceDialog";

const creditInvoiceFunction = firebase.app().functions("europe-west3").httpsCallable("Invoice-credit2");

export const useCancelInvoiceAction = (invoiceId: string | null | undefined) => {
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    return useCallback((form: CancelInvoiceFormContext) => {
        if ( !invoiceId ) return;

        block();
        creditInvoiceFunction({ invoiceId, reason: form.reasonInput.reason })
            .then(() => {
                openSnackbar("success", "Fakturaen annulleres");
                unblock();
            })
            .catch(() => {
                openSnackbar("error", "Der skete en fejl. Fakturaen er ikke blevet annulleret.");
                unblock();
            });

    }, [block, unblock, openSnackbar, invoiceId]);
};
