import { useMemo } from "react";

import { PageFormItem, HorizontalButtonsSingleChoiceFormItem, InfoFormItem, GroupFormItem, TextAreaFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";

export interface CanOfferProposalFormIds {
    SingleChoiceCanOfferProposal: string;
    TextAreaHandOverComplaintReason: string;
}

export type CanOfferProposalForm<NextPage extends FormItem> = 
    PageFormItem<
        HorizontalButtonsSingleChoiceFormItem<[
            HandOverComplaintToGardenerPage,
            NextPage,
        ]>
    >;

type HandOverComplaintToGardenerPage = PageFormItem<
    GroupFormItem<[
        TextAreaFormItem,
        ConfirmationPage,
    ]>
>;

type ConfirmationPage = PageFormItem<InfoFormItem>;

export const useCanOfferProposalForm = <T extends FormItem>(nextPage: T, formIds: CanOfferProposalFormIds): CanOfferProposalForm<T> => {
    return useMemo(() => {
        const confirmationPage: ConfirmationPage = {
            type: "page",
            title: "Reklamationen overdrages til havemanden",
            item: {
                type: "info",
                render: "default",
                text: `
                    Opgaven afsluttes.<br />
                    <br />
                    Havemanden modtager en notifikation om, at vi har forsøgt at finde en løsning med dem, men at det ikke er lykkedes og at de nu må finde en løsning sammen med kunden.<br />
                    <br />
                    Kunden modtager en notifikation om, at vi ikke kunne finde en løsning med havemanden, men at havemanden er informeret om reklamationen.
                `,
            },
        };

        const handOverComplaintToGardenerPage: HandOverComplaintToGardenerPage = {
            type: "page",
            title: "Overdrag reklamation til havemand",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        id: formIds.TextAreaHandOverComplaintReason,
                        type: "text-area",
                        label: "Årsag",
                        placeholder: "Hvorfor overdrages reklamationen til havemanden?",
                        value: "",
                        dirty: false,
                        validation: "mandatory",
                    },
                    confirmationPage,
                ],
            },
        };

        return {
            type: "page",
            title: "Vil vi tilbyde en løsning til kunden?",
            item: {
                id: formIds.SingleChoiceCanOfferProposal,
                type: "single-choice",
                mode: "switch page",
                render: "horizontal-buttons",
                questionText: null,
                answerTexts: ["Nej", "Ja"],
                answerItems: [
                    handOverComplaintToGardenerPage,
                    nextPage,
                ],
                selectedChoiceIndex: null,
                dirty: false,
            },
        };
    }, [nextPage, formIds]);
}
