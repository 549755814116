import { useMemo } from "react";
import { PageFormItem, InfoFormItem, GroupFormItem, TextAreaFormItem, NoticeFormItem } from "../../../../../components/form-item/client/components";
import { CustomerNotification, InvoiceDocument } from "../../invoice.model";
import { DineroContactDocument } from "../../../pending-approval/dinero-contact.model";
import { hasContactInformationChanged, havePaymentTermsChanged, mapCustomerNotification } from "./reissue-invoice.utility";

export interface ResendInvoiceFormIds {
    TextAreaReason: string;
}

export type ResendInvoiceForm = ConfirmationPage | ResendInvoiceBranch;

type ResendInvoiceBranch =
    GroupFormItem<[
        PageFormItem<
            GroupFormItem<[
                NoticeFormItem,
                NoticeFormItem,
                TextAreaFormItem,
            ] | [
                NoticeFormItem,
                NoticeFormItem,
                NoticeFormItem,
                TextAreaFormItem,

            ]>
        >,
        ConfirmationPage,
    ]>;

type ConfirmationPage = PageFormItem<InfoFormItem>;

export const useResendInvoiceForm = (invoice: InvoiceDocument, contact: DineroContactDocument, formIds: ResendInvoiceFormIds): ResendInvoiceForm => {
    return useMemo((): ResendInvoiceForm => {

        const cannotResendInvoiceConfirmationPage: ConfirmationPage = {
            type: "page",
            title: "Gensend faktura med gamle oplysninger",
            item: {
                type: "info",
                render: "default",
                text: "Fakturaen kan ikke gensendes, da relevante faktureringsoplysninger ikke er blevet ændret for kunden.",
            },
        };

        const contactInformationHasChanged = hasContactInformationChanged(invoice, contact);
        if ( !contactInformationHasChanged ) return cannotResendInvoiceConfirmationPage;

        const customerNotificationBefore = invoice.customerNotification;
        const customerNotificationAfter = mapCustomerNotification(contact);

        const paymentTermsHaveChanged = havePaymentTermsChanged(invoice, contact);

        const willReissueWarning: NoticeFormItem = {
            type: "notice",
            render: "warning",
            html: `
                <strong>Faktura genudstedes alligevel</strong><br />
                <br />
                Kunden skal have en anden betalingsfrist, da fakturaen afsendes med ${formatCustomerNotificationType(customerNotificationBefore)} i stedet for ${formatCustomerNotificationType(customerNotificationAfter)}.
            `,
        };
        
        const contactNoticeBefore: NoticeFormItem = {
            type: "notice",
            render: "info",
            html: `
                <strong>Faktura tidligere sendt via ${formatCustomerNotificationType(customerNotificationBefore)}</strong><br />
                <br />
                ${formatCustomerNotification(customerNotificationBefore)}
            `,
        };

        const contactNoticeAfter: NoticeFormItem = {
            type: "notice",
            render: "info",
            html: `
                <strong>Faktura gensendes via ${formatCustomerNotificationType(customerNotificationAfter)}</strong><br />
                <br />
                ${formatCustomerNotification(customerNotificationAfter)}
            `,
        };

        const textAreaReason: TextAreaFormItem = {
            id: formIds.TextAreaReason,
            type: "text-area",
            label: "Årsag",
            placeholder: "Angiv årsagen til at du vil gensende fakturaen",
            value: "",
            dirty: false,
            validation: "mandatory",
        };

        const confirmationPage: ConfirmationPage = !paymentTermsHaveChanged ? {
            type: "page",
            title: "Gensend faktura med gamle oplysninger",
            item: {
                type: "info",
                render: "default",
                text: "Fakturaen forbliver uændret, men sendes til kunden igen via dennes nye faktureringsoplysninger.",
            },
        } : {
            type: "page",
            title: "Genudsted faktura med nye oplysninger",
            item: {
                type: "info",
                render: "default",
                text: "Der udstedes en kreditnota for den oprindelige faktura og der udstedes herefter en ny faktura med de nye oplysninger.",
            },
        };

        const canReissueInvoiceBranch: ResendInvoiceBranch = {
            type: "group",
            render: "all at once",
            items: [
                {
                    type: "page",
                    title: "Gensend faktura med gamle oplysninger",
                    item: {
                        type: "group",
                        render: "all at once",
                        items: !havePaymentTermsChanged(invoice, contact) ? [
                            contactNoticeBefore,
                            contactNoticeAfter,
                            textAreaReason,
                        ] : [
                            willReissueWarning,
                            contactNoticeBefore,
                            contactNoticeAfter,
                            textAreaReason,
                        ],
                    }
                },
                confirmationPage,
            ],
        };

        return canReissueInvoiceBranch;
    }, [invoice, contact, formIds]);
};

function formatCustomerNotificationType(customerNotification: CustomerNotification): string {
    switch ( customerNotification.type ) {
        case "ean": return "EAN";
        case "email": return "email";
        case "letter": return "brev";
    }
}

function formatCustomerNotification(customerNotification: CustomerNotification): string {
    switch ( customerNotification.type ) {
        case "ean": return customerNotification.ean;
        case "email": return customerNotification.email;
        case "letter": return `${customerNotification.address.street}, ${customerNotification.address.zipCode}`;
    }
}
