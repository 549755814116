import { Hit } from "@algolia/client-search";
import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@material-ui/core";

import { InvoiceSearchRecord } from "../invoice-search-record.model";
import { InvoiceTableRow } from "./InvoiceTableRow";

const useStyles = makeStyles({
    table: {
        "& th": {
            padding: "12px 24px",
            fontSize: "14px",
            fontWeight: 500,
            color: "#4A4A4A",
            whiteSpace: "nowrap",
        },

        "& th:nth-child(2), & th:nth-child(3), & td:nth-child(2), & td:nth-child(3)": {
            width: "92px",
        },
    },
    price: {
        fontWeight: 500,
        color: "#297A1D",
    },
});

interface InvoiceTableProps {
    invoices: Hit<InvoiceSearchRecord>[];
    onSelectInvoice: (invoice: Hit<InvoiceSearchRecord>) => void;
    pagination: JSX.Element;
}

export const InvoiceTable = (props: InvoiceTableProps) => {
    const classes = useStyles();

    const { invoices, onSelectInvoice, pagination } = props;

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">

                <TableHead>
                    <TableRow>
                        <TableCell>Faktura</TableCell>
                        <TableCell>Dato</TableCell>
                        <TableCell>Forfaldsdato</TableCell>
                        <TableCell>Kunde</TableCell>
                        <TableCell>Havemand</TableCell>
                        <TableCell>Total</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {invoices.map((invoice) => (
                        <InvoiceTableRow key={invoice.invoiceId} invoice={invoice} onSelectInvoice={onSelectInvoice} />
                    ))}
                </TableBody>

                <TableFooter>
                    <TableRow>
                        {pagination}
                    </TableRow>
                </TableFooter>

            </Table>
        </TableContainer>
    );
};
