import { PropsWithChildren } from "react";

import { makeStyles, Paper } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        padding: "16px",
    },
    title: {
        fontSize: "16px",
        fontWeight: 500,
        color: "#4A4A4A",
        margin: 0,
        marginBottom: "32px",
    },
});

interface MetricListProps {
    title: string;
}

export const MetricList = (props: PropsWithChildren<MetricListProps>) => {
    const classes = useStyles();

    const { title, children } = props;

    return (
        <Paper className={classes.root}>
            <h4 className={classes.title}>{title}</h4>

            {children}
        </Paper>
    );
};
