import moment from "moment";

import { TextField } from "../../../../../components/form/InputField";
import { DineroContactDocument } from "../../../pending-approval/dinero-contact.model";
import { useInvoiceForm } from "./InvoiceForm";

export const InvoiceDueDateInput = () => {
    const { form } = useInvoiceForm();
    const { invoiceDueDateInput } = form;

    return <TextField value={invoiceDueDateInput.invoiceDueDate} disabled />;
}

export interface InvoiceDueDateInputContext {
    invoiceDueDate: string;
}

export const newInvoiceDueDateInputContext = (today: string, contact: DineroContactDocument): InvoiceDueDateInputContext => {
    const invoiceDueDate = moment(today).add(contact.numberOfDaysToPay, "days").format("DD-MM-YYYY");

    return {
        invoiceDueDate,
    };
};
