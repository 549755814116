import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

import moment from "moment";

import { TaskDocument } from "../../task/task.model";
import { useOnboardingTasks } from "../../workflow/follow-up/appointment/TaskProvider";
import { InterviewTab } from "./InterviewTab";

export const IntegratedInterviewTab = () => {
    const history = useHistory();
    const goToTask = useCallback((task: TaskDocument) => {
        history.push(`/onboarding/interview/${task.id}`);
    }, [history]);

    const now = moment().toISOString(true);

    const tasks = useOnboardingTasks();
    const relevantTasks = tasks.filter(task => task.application.status === "checked");

    const currentTasks = useMemo(() => relevantTasks.filter(task => task.performAfterDate <= now), [relevantTasks, now]);
    const futureTasks = useMemo(() => relevantTasks.filter(task => task.performAfterDate > now), [relevantTasks, now]);

    return (
        <InterviewTab
            currentTasks={currentTasks}
            futureTasks={futureTasks}
            goToTask={goToTask}
        />
    );
};
