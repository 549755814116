import { useCallback, useMemo, useRef, useState } from "react";

import { Collapse } from "@material-ui/core";
import firebase from "firebase/compat/app";

import { NeutralAction } from "../../components/card/NeutralAction";
import { PositiveAction } from "../../components/card/PositiveAction";
import { Dialog, DialogActions, DialogContent, DialogHeader, useDialog } from "../../components/dialog";
import { Label } from "../../components/form/Label";
import { Group } from "../../components/form/Group";
import { AlternativeHint } from "../../components/form/Hint";
import { useAnimation } from "../../components/validation/animation.hook";
import { validateAddress, validateAtt, validateCvr, validateEan, validateEmail, validateMandatory, validateRelaxedPhoneNumber, Validation } from "../../components/validation/validation";
import { EmailField, TextField } from "../../components/form/InputField";
import { BillingSection } from "./BillingSection";
import { AddressField, AddressFieldAddress, useAddressPreloadingHack } from "../../components/form/AddressField";

export interface UpdateCustomerDialogProps {
    initialViewModel: UpdateCustomerDialogViewModel;
    onConfirm: (viewModel: UpdateCustomerDialogViewModel, idempotencyKey: string) => void;
    onCancel: () => void;
}

export interface UpdateCustomerDialogViewModel {
    name: string;
    nameDirty: boolean;
    email: string;
    emailDirty: boolean;
    phoneNumber: string;
    phoneNumberDirty: boolean;
    addressSearch: {
        searchTerm: string;
        address: AddressFieldAddress | undefined;
    },
    addressSearchDirty: boolean;
    billing: {
        name: string;
        nameDirty: boolean;
        email: string;
        emailDirty: boolean;
        phoneNumber: string;
        phoneNumberDirty: boolean;
        street: string;
        streetDirty: boolean;
        zipCode: string;
        zipCodeDirty: boolean;
        type: "person" | "company";
        typeDirty: boolean;
        company: {
            cvr: string;
            cvrDirty: boolean;
            att: string;
            attDirty: boolean;
            ean: string;
            eanDirty: boolean;
        },
    },
}

export interface UpdateCustomerDialogErrorModel {
    any: boolean;
    name: string | undefined;
    email: string | undefined;
    phoneNumber: string | undefined;
    addressSearch: string | undefined;
    billingName: string | undefined;
    billingEmail: string | undefined;
    billingPhoneNumber: string | undefined;
    billingStreet: string | undefined;
    billingZipCode: string | undefined;
    billingType: string | undefined;
    billingCompanyCvr: string | undefined;
    billingCompanyAtt: Validation;
    billingCompanyEan: string | undefined;
}

export const UpdateCustomerDialog = (props: UpdateCustomerDialogProps) => {
    const idempotencyKey = useRef(firebase.firestore().collection("x").doc().id);

    const { initialViewModel, onConfirm, onCancel } = props;
    const [viewModel, setViewModel] = useState(initialViewModel);

    const errorModel = useMemo<UpdateCustomerDialogErrorModel>(() => {
        const nameError = [validateMandatory(viewModel.name)].find(Boolean);
        const emailError = [validateEmail(viewModel.email)].find(Boolean);
        const phoneNumberError = [validateMandatory(viewModel.phoneNumber), validateRelaxedPhoneNumber(viewModel.phoneNumber)].find(Boolean);
        const addressSearchError = [validateMandatory(viewModel.addressSearch.searchTerm), validateAddress(viewModel.addressSearch.address)].find(Boolean);

        const billingNameError = [validateMandatory(viewModel.billing.name)].find(Boolean);
        const billingEmailError = [validateEmail(viewModel.billing.email)].find(Boolean);
        const billingPhoneNumberError = [validateMandatory(viewModel.billing.phoneNumber), validateRelaxedPhoneNumber(viewModel.billing.phoneNumber)].find(Boolean);
        const billingStreetError = [validateMandatory(viewModel.billing.street)].find(Boolean);
        const billingZipCodeError = [validateMandatory(viewModel.billing.zipCode)].find(Boolean);
        const billingTypeError = [].find(Boolean);

        const billingCompanyCvrError = viewModel.billing.type === "company" ? [validateMandatory(viewModel.billing.company.cvr), validateCvr(viewModel.billing.company.cvr)].find(Boolean) : undefined;
        const billingCompanyAttError = viewModel.billing.type === "company" ? [validateAtt(viewModel.billing.company.att, viewModel.billing.company.ean)].find(Boolean) : undefined;
        const billingCompanyEanError = [validateEan(viewModel.billing.company.ean)].find(Boolean);

        return {
            any: Boolean(nameError || emailError || phoneNumberError || addressSearchError || billingNameError || billingEmailError || billingPhoneNumberError || billingStreetError || billingZipCodeError || billingTypeError || billingCompanyCvrError || billingCompanyAttError?.severity === "error" || billingCompanyEanError),
            name: nameError,
            email: emailError,
            phoneNumber: phoneNumberError,
            addressSearch: addressSearchError,
            billingName: billingNameError,
            billingEmail: billingEmailError,
            billingPhoneNumber: billingPhoneNumberError,
            billingStreet: billingStreetError,
            billingZipCode: billingZipCodeError,
            billingType: billingTypeError,
            billingCompanyCvr: billingCompanyCvrError,
            billingCompanyAtt: billingCompanyAttError,
            billingCompanyEan: billingCompanyEanError,
        };
    }, [viewModel]);

    const changeName = useCallback((value: string) => {
        setViewModel(viewModel => ({ ...viewModel, name: value }));
    }, [setViewModel])

    const markNameDirty = useCallback(() => {
        setViewModel(viewModel => ({ ...viewModel, nameDirty: true }));
    }, [setViewModel]);

    const changeEmail = useCallback((value: string) => {
        setViewModel(viewModel => ({ ...viewModel, email: value }));
    }, [setViewModel])

    const markEmailDirty = useCallback(() => {
        setViewModel(viewModel => ({ ...viewModel, emailDirty: true }));
    }, [setViewModel]);

    const changePhoneNumber = useCallback((value: string) => {
        setViewModel(viewModel => ({ ...viewModel, phoneNumber: value }));
    }, [setViewModel])

    const markPhoneNumberDirty = useCallback(() => {
        setViewModel(viewModel => ({ ...viewModel, phoneNumberDirty: true }));
    }, [setViewModel]);

    const changeAddressSearch = useCallback((value: UpdateCustomerDialogViewModel["addressSearch"]) => {
        setViewModel(viewModel => ({ ...viewModel, addressSearch: value }));
    }, [setViewModel])

    const markAddressSearchDirty = useCallback(() => {
        setViewModel(viewModel => ({ ...viewModel, addressSearchDirty: true }));
    }, [setViewModel]);

    const onExactAddress = useCallback((address: AddressFieldAddress) => {
        if ( viewModel.addressSearch.searchTerm !== initialViewModel.addressSearch.searchTerm ) return;

        changeAddressSearch({
            searchTerm: initialViewModel.addressSearch.searchTerm,
            address,
        });
    }, [changeAddressSearch, initialViewModel, viewModel]);

    useAddressPreloadingHack(initialViewModel.addressSearch.searchTerm, initialViewModel.addressSearch.address, onExactAddress, markAddressSearchDirty);

    const [validationAnimationDuration, skipValidationAnimation] = useAnimation();

    const scrollToFirstError = useCallback(() => {
        const sectionId = (() => {
            if ( errorModel.name ) return "name-section";
            if ( errorModel.email ) return "email-section";
            if ( errorModel.phoneNumber ) return "phone-number-section";
            if ( errorModel.addressSearch ) return "address-search-section";
            if ( errorModel.billingName ) return "billing-name-section";
            if ( errorModel.billingEmail ) return "billing-email-section";
            if ( errorModel.billingPhoneNumber ) return "billing-phone-number-section";
            if ( errorModel.billingStreet ) return "billing-street-section";
            if ( errorModel.billingZipCode ) return "billing-zip-code-section";
            if ( errorModel.billingType ) return "billing-type-section";
            if ( errorModel.billingCompanyCvr ) return "billing-company-cvr-section";
            if ( errorModel.billingCompanyAtt ) return "billing-company-att-section";
            if ( errorModel.billingCompanyEan ) return "billing-company-ean-section";

            return undefined;
        })();

        if ( !sectionId ) return;

        const section = document.getElementById(sectionId);
        if ( !section ) return;

        section.scrollIntoView({ behavior: "smooth" });
    }, [errorModel]);

    const confirm = useCallback(() => {
        if ( document.activeElement ) {
            (document.activeElement as HTMLInputElement).blur();
        }

        if ( errorModel.any ) {
            skipValidationAnimation();
            setViewModel(viewModel => ({
                ...viewModel,
                nameDirty: true,
                emailDirty: true,
                phoneNumberDirty: true,
                addressSearchDirty: true,
                billing: {
                    ...viewModel.billing,
                    nameDirty: true,
                    emailDirty: true,
                    phoneNumberDirty: true,
                    streetDirty: true,
                    zipCodeDirty: true,
                    typeDirty: true,
                    company: {
                        ...viewModel.billing.company,
                        cvrDirty: true,
                        attDirty: true,
                        eanDirty: true,
                    },
                },
            }));

            return setTimeout(scrollToFirstError, 100);
        }

        onConfirm(viewModel, idempotencyKey.current);
    }, [skipValidationAnimation, scrollToFirstError, onConfirm, errorModel.any, viewModel, idempotencyKey]);

    return (
        <Dialog>
            <DialogHeader>Ændr oplysninger</DialogHeader>

            <DialogContent>

                <div id="name-section">
                    <Label htmlFor="name" style={{ marginBottom: "8px" }}>Navn</Label>
                    <Group error={Boolean(viewModel.nameDirty && errorModel.name)}>
                        <TextField id="name" value={viewModel.name} onChange={changeName} onBlur={markNameDirty} />

                        <Collapse in={Boolean(viewModel.nameDirty && errorModel.name)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.name} />
                        </Collapse>
                    </Group>
                </div>

                <div id="email-section">
                    <Label htmlFor="email" style={{ marginBottom: "8px" }}>Email</Label>
                    <Group error={Boolean(viewModel.emailDirty && errorModel.email)}>
                        <EmailField id="email" value={viewModel.email} onChange={changeEmail} onBlur={markEmailDirty} />

                        <Collapse in={Boolean(viewModel.emailDirty && errorModel.email)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.email} />
                        </Collapse>
                    </Group>
                </div>

                <div id="phone-number-section">
                    <Label htmlFor="phone-number" style={{ marginBottom: "8px" }}>Telefon</Label>
                    <Group error={Boolean(viewModel.phoneNumberDirty && errorModel.phoneNumber)}>
                        <TextField id="phone-number" value={viewModel.phoneNumber} onChange={changePhoneNumber} onBlur={markPhoneNumberDirty} />

                        <Collapse in={Boolean(viewModel.phoneNumberDirty && errorModel.phoneNumber)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.phoneNumber} />
                        </Collapse>
                    </Group>
                </div>

                <div id="address-search-section">
                    <Label htmlFor="address-search" style={{ marginBottom: "8px" }}>Adresse</Label>
                    <Group error={Boolean(viewModel.addressSearchDirty && errorModel.addressSearch)}>
                        <AddressField id="address-search" searchTerm={viewModel.addressSearch.searchTerm} value={viewModel.addressSearch.address} onChange={changeAddressSearch} onBlur={markAddressSearchDirty} />

                        <Collapse in={Boolean(viewModel.addressSearchDirty && errorModel.addressSearch)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.addressSearch} />
                        </Collapse>
                    </Group>
                </div>

                <BillingSection
                    viewModel={viewModel}
                    setViewModel={setViewModel}
                    errorModel={errorModel}
                    validationAnimationDuration={validationAnimationDuration}
                />

            </DialogContent>

            <DialogActions>
                <NeutralAction onClick={onCancel}>Annuller</NeutralAction>
                <PositiveAction onClick={confirm}>Bekræft</PositiveAction>
            </DialogActions>

        </Dialog>
    );
};

export const useUpdateCustomerDialog = (initialViewModel: UpdateCustomerDialogViewModel | undefined, onConfirm: UpdateCustomerDialogProps["onConfirm"]) => {
    const { openDialog, closeDialog } = useDialog();

    const confirm: UpdateCustomerDialogProps["onConfirm"] = useCallback((data, idempotencyKey) => {
        closeDialog();

        onConfirm(data, idempotencyKey);
    }, [closeDialog, onConfirm]);

    return useCallback(() => {
        if ( !initialViewModel ) return;

        openDialog(<UpdateCustomerDialog initialViewModel={initialViewModel} onConfirm={confirm} onCancel={closeDialog} />);
    }, [openDialog, closeDialog, confirm, initialViewModel]);
};
