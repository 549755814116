import danishSsn from "danish-ssn";
import moment from "moment";

import { AddressFieldAddress } from "../form/AddressField";
import { globalTopLevelDomains } from "./global-top-level-domains";

export type Validation = {
    severity: "error" | "warning";
    message: string;
} | undefined;

export const validateMandatory = (value: any) => {
    if ( !value ) return "Skal udfyldes";

    return undefined;
};

export const validateMandatoryValidation = (value: any): Validation => {
    if ( !value ) return { severity: "error", message: "Skal udfyldes" };

    return undefined;
};

export const validateString = (value: any, expectedValue: string) => {
    if ( !value ) return undefined;
    if ( value !== expectedValue ) return `Skal være "${expectedValue}"`;

    return undefined;
};

export const validateNotEmpty = (value: any[]) => {
    if ( value.length === 0 ) return "Skal vælge mindst én";

    return undefined;
};

export const validateCpr = (value: string): Validation => {
    if ( !value ) return undefined;

    if ( !value.match(/^\d{6}-\d{4}$/g) ) return { severity: "error", message: "Skal være på formen \"123456-1234\"" };

    const result = danishSsn(value);
    if ( !result.valid ) return { severity: "error", message: "Skal være et gyldigt CPR-nummer" };

    const age = moment().diff(result.date, "year");
    if ( age < 18 ) return { severity: "error", message: `CPR-nummeret angiver en alder på ${age} år` };

    if ( result.sex === "Female" ) return { severity: "warning", message: "CPR-nummeret angiver en kvinde" };
    if ( result.sex === "Male" ) return { severity: "warning", message: "CPR-nummeret angiver en mand" };

    return undefined;
};

export const validateCvr = (value: string) => {
    if ( !value ) return undefined;

    if ( !value.match(/^\d{8}$/g) ) return "Skal være på formen \"12345678\"";

    return undefined;
};

export const validateEan = (value: string) => {
    if ( !value ) return undefined;

    if ( !value.startsWith("57") ) return "Skal starte med 57";
    if ( !value.match(/^\d{13}$/g) ) return "Skal være 13 cifre langt";

    return undefined;
};

export const validateAtt = (att: string, ean: string): Validation => {
    if ( !ean ) return undefined;

    if ( !att ) return validateMandatoryValidation(att);

    return { severity: "warning", message: "Skal være en kontaktperson" };
}

export const validateEmail = (value: string) => {
    if ( !value ) return undefined;

    const topLevelDomainMatch = globalTopLevelDomains.some(topLevelDomain => value.trim().toLowerCase().endsWith("." + topLevelDomain.toLowerCase()));
    if ( !topLevelDomainMatch ) return "Skal være en gyldig email";

    // eslint-disable-next-line no-control-regex,no-useless-escape
    const emailRegex = /^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
    if ( !value.trim().toLowerCase().match(emailRegex) ) return "Skal være en gyldig email";

    return undefined;
};

export const validateRelaxedPhoneNumber = (value: string) => {
    if ( !value ) return undefined;

    const danishPhoneNumberRegex = /^\d{8}$/;
    
    const trimmedPhoneNumber = value.replace(/\s/g, "");
    if ( trimmedPhoneNumber.match(danishPhoneNumberRegex) ) return undefined;
    if ( !trimmedPhoneNumber.startsWith("+") ) return "Skal være på formen \"12345678\" eller starte med +";

    const danishPhoneNumberWithPrefixRegex = /^\+45\d{8}$/;
    if ( trimmedPhoneNumber.startsWith("+45") && !trimmedPhoneNumber.match(danishPhoneNumberWithPrefixRegex) ) return "Skal være på formen \"12345678\" eller \"+4512345678\"";

    const phoneNumberRegex = /^\+[1-9]\d{7,14}$/;
    if ( !trimmedPhoneNumber.match(phoneNumberRegex) ) return "Skal være et gyldigt telefonnummer";

    return undefined;
};

export const validateStrictPhoneNumber = (value: string) => {
    const stringWithoutWhitespace = value.replace(/\s/g, "");

    if ( !stringWithoutWhitespace ) return undefined;
    if ( !stringWithoutWhitespace.match(/^\d{8}$/) ) return "Skal være på formen \"12345678\"";

    return undefined;
};

export const validateAddress = (value: AddressFieldAddress | undefined) => {
    if ( !value ) return "Skal vælge en adresse fra listen";

    return undefined;
};

export const validateAddressValidation = (value: AddressFieldAddress | undefined): Validation => {
    if ( !value ) return { severity: "error", message: "Skal vælge en adresse fra listen" };

    return undefined;
};

export const validateDate = (value: string) => {
    if ( !moment(value, moment.ISO_8601, true).isValid() ) return "Skal være på formen \"24-12-2020\"";

    return undefined;
}

export const validateDateNotInTheFuture = (value: string, now: string) => {
    if ( !value ) return undefined;
    if ( validateDate(value) ) return undefined;

    if ( moment(now).startOf("day").isBefore(value) ) return "Skal senest være i dag";

    return undefined;
}

export const validateDateNotBefore = (value: string, date: string) => {
    if ( !value ) return undefined;
    if ( validateDate(value) ) return undefined;

    if ( moment(value).isBefore(date, "day") ) return `Skal tidligst være ${moment(date).format("DD-MM-YYYY")}`;

    return undefined;
}

export const validateDateNotAfter = (value: string, date: string) => {
    if ( !value ) return undefined;
    if ( validateDate(value) ) return undefined;

    if ( moment(value).isAfter(date, "day") ) return `Skal senest være ${moment(date).format("DD-MM-YYYY")}`;

    return undefined;
}
