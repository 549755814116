import { useCallback } from "react";

import firebase from "firebase/compat/app";

import { useBlockingProgress } from "../../components/blocking-progress";
import { useDialog } from "../../components/dialog";
import { useSnackbar } from "../../components/snackbar";
import { TaskDocument } from "../../task/task.model";
import { CloseTaskDialogViewModel, useCloseTaskDialog } from "./CloseTaskDialog";

export interface CloseTaskData {
    taskId: string;
    reason: string;
}

const closeTaskFunction: (data: CloseTaskData) => Promise<firebase.functions.HttpsCallableResult>
    = firebase.app().functions("europe-west3").httpsCallable("Task-close");

export const useCloseTask = (task: TaskDocument, goBack: () => void) => {
    const closeTaskAction = useCloseTaskAction(task.id, goBack);

    return useCloseTaskDialog(closeTaskAction);
};

export const useCloseTaskAction = (taskId: string, goBack: () => void) => {
    const { closeDialog } = useDialog();
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    return useCallback((viewModel: CloseTaskDialogViewModel) => {
        closeDialog();

        const data: CloseTaskData = {
            taskId,
            reason: viewModel.reason,
        };

        block();
        closeTaskFunction(data)
            .then(() => {
                openSnackbar("success", "Opgaven er afsluttet");
                goBack();
            })
            .catch(() => openSnackbar("error", "Der skete en fejl. Opgaven er ikke blevet afsluttet."))
            .finally(() => unblock());

    }, [closeDialog, block, unblock, openSnackbar, goBack, taskId]);
};
