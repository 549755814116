import { useEffect, useMemo, useRef, useState } from "react";

import { SearchResponse } from "@algolia/client-search";
import { debounce } from "@material-ui/core";
import { SearchClient } from "algoliasearch/lite";
import { GardenerDocument } from "../../gardener.model";

export interface GardenerSearchRecord {
    objectID: string;
    id: string;
    status: "active" | "terminated";
    name: string;
    email: string;
    phoneNumber: string;
    address: GardenerDocument["address"],
    cvr: string | null;
    companyName: string | null;
}

export const useGardenerSearch = (searchClient: SearchClient | undefined, searchTerm: string) => {
    const terminated = useRef(true);
    const [state, setState] = useState<SearchResponse<GardenerSearchRecord> | null>();

    const performSearch = useMemo(() => {
        return debounce(async (query: string) => {
            if ( terminated.current ) return;
            if ( !searchClient || !query ) return setState(null);

            const response = await searchClient.initIndex("gardeners").search<GardenerSearchRecord>(query, { hitsPerPage: 10 })
            if ( terminated.current ) return;

            setState(response);
        }, 100);
    }, [searchClient]);
    
    useEffect(() => {
        setState(undefined);
        
        terminated.current = false;
        performSearch(searchTerm);

        return () => {
            terminated.current = true;
        };
    }, [performSearch, searchTerm, terminated]);

    return state;
};
