import { useMemo } from "react";

import { PageFormItem, GroupFormItem, InfoFormItem, HorizontalButtonsSingleChoiceFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { NoticeFormItem } from "../../components/form-item/client/form-item/NoticeFormItemComponent";

export interface AskApplicantFormIds {
    SingleChoiceWillOnboardApplicant: string;
}

export type AskApplicantForm<NextPage extends FormItem> = AskApplicantPage<NextPage>;

type AskApplicantPage<NextPage extends FormItem> =
    PageFormItem<
        GroupFormItem<[
            InfoFormItem,
            NoticeFormItem,
            NoticeFormItem,
            NoticeFormItem,
            HorizontalButtonsSingleChoiceFormItem<[
                ConfirmationPage,
                NextPage,
            ]>,
        ]>
    >

type ConfirmationPage = PageFormItem<InfoFormItem>

export const useAskApplicantForm = <T extends FormItem>(nextPage: T, formIds: AskApplicantFormIds): AskApplicantForm<T> => {
    return useMemo(() => {

        const confirmationPage: ConfirmationPage = {
            type: "page",
            title: "Afvis ansøgning",
            item: {
                type: "info",
                render: "default",
                text: `
                    Ansøgningen bliver afvist.<br />
                    <br />
                    Ansøgeren informeres om, at ansøgningen blev afvist.
                `,
            },
        };
    
        const page: AskApplicantPage<T> = {
            type: "page",
            title: "Spørg ind til ansøgeren",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: "Stil ansøgeren disse spørgsmål for at få et indtryk af deres evner og personlighed og afgør om du vil onboarde ansøgeren.",
                    },
                    {
                        type: "notice",
                        render: "info",
                        html: `
                            <strong>Spørgsmål 1</strong><br />
                            <br />
                            Først vil jeg gerne høre, hvad der fik dig til at sende os en ansøgning?
                        `,
                    },
                    {
                        type: "notice",
                        render: "info",
                        html: `
                            <strong>Spørgsmål 2</strong><br />
                            <br />
                            Vil du fortælle mig lidt om din erfaring med havearbejde?
                        `,
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: `
                            Du må kun onboarde ansøgeren, hvis denne har gode kommunikationsevner, tilstrækkelig erfaring og er flink.
                        `,
                    },
                    {
                        id: formIds.SingleChoiceWillOnboardApplicant,
                        type: "single-choice",
                        mode: "switch page",
                        render: "horizontal-buttons",
                        questionText: "Vil du onboarde ansøgeren?",
                        answerTexts: ["Nej", "Ja"],
                        answerItems: [
                            confirmationPage,
                            nextPage,
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                    },
                ],
            },
        };
        
        return page;
    }, [nextPage, formIds]);
}
