import moment from "moment";

export const formatDate = (date: string): string => {
    return moment(date).format("D MMMM YYYY");
}

export const legacyFormatDate = (date: string): string => {
    return moment(date).format("D MMMM HH:mm");
}

export const formatDateIncludingWeekday = (date: string): string => {
    const dayFormatted = moment(date).format("dddd");
    const dateFormatted = formatDate(date);
    const text = `${dayFormatted} d. ${dateFormatted}`;

    return text.slice(0, 1).toUpperCase() + text.slice(1);
}

export const formatDateAccordingToHours = (date: string, earliestHour: number, latestHour: number): string => {
    const earliestDate = moment(date).startOf("hour").hour(earliestHour).toISOString(true);
    if ( moment(date).isBefore(earliestDate) ) return legacyFormatDate(earliestDate);

    const latestDate = moment(date).startOf("hour").hour(latestHour).toISOString(true);
    const nextDate = moment(earliestDate).add(1, "day").toISOString(true);
    if ( moment(date).isAfter(latestDate) ) return legacyFormatDate(nextDate);

    return legacyFormatDate(date);
}

export const formatDateAccordingToHoursSkippingWeekend = (date: string, earliestHour: number, latestHour: number): string => {
    const earliestDate = skipPastWeekend(moment(date).clone().startOf("hour").hour(earliestHour).toISOString(true));
    if ( moment(date).isBefore(earliestDate) ) return legacyFormatDate(earliestDate);

    const latestDate = skipPastWeekend(moment(date).clone().startOf("hour").hour(latestHour).toISOString(true));
    const nextDate = skipPastWeekend(moment(earliestDate).add(1, "day").toISOString(true));
    if ( moment(date).isAfter(latestDate) ) return legacyFormatDate(nextDate);

    return legacyFormatDate(date);
}

export const skipAccordingToHoursAndMinutesSkippingWeekendAsWell = (date: string, earliest: { hour: number, minute: number }, latest: { hour: number, minute: number }): string => {
    const earliestDate = skipPastWeekend(moment(date).clone().startOf("hour").hour(earliest.hour).startOf("minute").minute(earliest.minute).toISOString(true));
    if ( moment(date).isBefore(earliestDate) ) return earliestDate;

    const latestDate = skipPastWeekend(moment(date).clone().startOf("hour").hour(latest.hour).startOf("minute").minute(latest.minute).toISOString(true));
    const nextDate = skipPastWeekend(moment(earliestDate).add(1, "day").toISOString(true));
    if ( moment(date).isAfter(latestDate) ) return nextDate;

    return date;
}

export const skipPastWeekend = (date: string) => {
    if ( moment(date).isoWeekday() <= 5 ) return date;

    const daysUntilMonday = 8 - moment(date).isoWeekday();
    return moment(date).clone().add(daysUntilMonday, "days").toISOString(true);
}

export const determineExpectedPaymentCollectionDate = (performAfterDate: string): string => {
    const expectedPaymentReceivedDate = determineExpectedPaymentReceivedDate(performAfterDate);
    return determineExpectedPaymentPromptedDate(expectedPaymentReceivedDate);
}

const determineExpectedPaymentReceivedDate = (performAfterDate: string): string => {
    const weekday = moment(performAfterDate).isoWeekday();

    const thursday = 4;
    if ( weekday <= thursday ) return moment(performAfterDate).add(1, "day").startOf("day").toISOString(true);
    
    const nextMonday = 8;
    
    const numberOfDaysUntilNextMonday = nextMonday - weekday;
    
    return moment(performAfterDate).add(numberOfDaysUntilNextMonday, "days").startOf("day").toISOString(true);
}

const determineExpectedPaymentPromptedDate = (expectedPaymentReceivedDate: string): string => {
    const weekday = moment(expectedPaymentReceivedDate).isoWeekday();

    const thursday = 4;
    if ( weekday <= thursday ) return moment(expectedPaymentReceivedDate).add(1, "day").startOf("day").add(8, "hours").toISOString(true);
    
    const nextMonday = 8;
    
    const numberOfDaysUntilNextMonday = nextMonday - weekday;
    
    return moment(expectedPaymentReceivedDate).add(numberOfDaysUntilNextMonday, "days").startOf("day").add(8, "hours").toISOString(true);
}

export function daysBetween(a: string, b: string): number {
    const difference = -moment(a).diff(b);
    return Math.floor(moment.duration(difference).asDays());
}
