import { Comment } from "../components/comment/Comment";
import { CommentBody } from "../components/comment/CommentBody";
import { GuideCompletedCommentDocument } from "./comment.model";

interface GuideCompletedCommentProps {
    comment: GuideCompletedCommentDocument;
}

export const GuideCompletedComment = (props: GuideCompletedCommentProps) => {
    const { comment } = props;

    const guide = (() => {
        switch ( comment.guide ) {
            case "onboarding": return "Generelt om konceptet";
            case "newTask": return "Sådan besvarer du “Ny opgave”";
            case "appointmentTask": return "Sådan besvarer du “Har I lavet en aftale?”";
            case "customerConversation": return "Samtalen med kunden";
            case "registrationTask": return "Sådan besvarer du “Hvordan gik dit besøg?”";
            case "registration": return "Sådan registrerer du tid";
            case "afterRegistration": return "Hvad sker der, når du registrerer tid?";
            case "afterClosedTask": return "Hvad sker der, hvis du lukker en opgave?";
        }
    })();

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Guide gennemført">
            <CommentBody>
                Havemanden har gennemført {guide ? `guiden "${guide}"` : "en ukendt guide"}.
            </CommentBody>
        </Comment>
    );
};
