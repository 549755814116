import { useFormDialog } from "../components/form-item/client/components";
import { FormProps } from "../components/form-item/framework/react/FormProvider";
import { ComplaintDocument } from "./complaint.model";
import { CloseComplaintForm, useCloseComplaintForm } from "./shared/CloseComplaintForm";

export enum CloseComplaintFormIds {
    TextAreaReason = "TextAreaReason",
}

export const useCloseComplaintDialog = (complaint: ComplaintDocument, onConfirm: FormProps<CloseComplaintForm>["onSubmit"]) => {
    const form = useCloseComplaintForm(complaint, {
        TextAreaReason: CloseComplaintFormIds.TextAreaReason,
    });

    return useFormDialog(form, onConfirm);
};
