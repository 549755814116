import { Dispatch, SetStateAction, useCallback } from "react";

import { Collapse } from "@material-ui/core";

import { Label } from "../../components/form/Label";
import { Group } from "../../components/form/Group";
import { AlternativeHint } from "../../components/form/Hint";
import { TextField } from "../../components/form/InputField";
import { UpdateGardenerDialogErrorModel, UpdateGardenerDialogViewModel } from "./UpdateGardenerDialog";

export interface PersonSectionProps {
    viewModel: UpdateGardenerDialogViewModel;
    setViewModel: Dispatch<SetStateAction<UpdateGardenerDialogViewModel>>;
    errorModel: UpdateGardenerDialogErrorModel;
    validationAnimationDuration: number;
}

export const PersonSection = (props: PersonSectionProps) => {
    const { viewModel, setViewModel, errorModel, validationAnimationDuration } = props;

    const changeCpr = useCallback((value: string) => {
        setViewModel(viewModel => ({
            ...viewModel,
            person: { ...viewModel.person, cpr: value },
        }));
    }, [setViewModel])

    const markCprDirty = useCallback(() => {
        setViewModel(viewModel => ({
            ...viewModel,
            person: { ...viewModel.person, cprDirty: true },
        }));
    }, [setViewModel]);

    return (
        <div id="cpr-section">
            <Label htmlFor="cpr" style={{ marginBottom: "8px" }}>CPR-nummer</Label>
            <Group error={Boolean(viewModel.person.cprDirty && errorModel.cpr?.severity === "error")}>
                <TextField id="cpr" value={viewModel.person.cpr} onChange={changeCpr} onBlur={markCprDirty} />

                <Collapse in={Boolean(viewModel.person.cprDirty && errorModel.cpr)} timeout={validationAnimationDuration}>
                    <AlternativeHint severity={errorModel.cpr?.severity} message={errorModel.cpr?.message} />
                </Collapse>
            </Group>
        </div>
    );
};
