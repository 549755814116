import moment from "moment";

import { formatDate } from "../date-utility";
import { GardeningTaskDocument, Skill } from "./gardening-task.model";
import { ListFormatter } from "../formatting/list.formatter";

export const formatGardeningTaskStatus = (gardeningTask: GardeningTaskDocument, context: "customer" | "gardener"): string => {
    switch ( gardeningTask.status ) {
        case "looking for gardener": return context === "customer" ? "leder efter havemand" : "tilbudt til havemand";
        case "aborted": return "trukket tilbage";
        case "could not find gardener": return "vi kunne ikke finde en havemand";
        case "found gardener":
            if ( gardeningTask.working.status === "scheduling visit" ) return "besøg endnu ikke planlagt";
            if ( gardeningTask.working.status === "performing visit" ) return "besøg planlagt";
            if ( gardeningTask.working.status === "has finished working" ) return "kunde faktureret";
            break;
            
        case "gardener stopped": return "havemand har lukket opgave ";
    }

    return "ukendt status";
}

export const determineColorForGardeningTaskStatus = (gardeningTask: GardeningTaskDocument): "default" | "info" | "success" | "warning" | "danger" => {
    switch ( gardeningTask.status ) {
        case "looking for gardener": return "info";
        case "aborted": return "default";
        case "could not find gardener": return "danger";
        case "found gardener":
            if ( gardeningTask.working.status === "scheduling visit" ) return "info";
            if ( gardeningTask.working.status === "performing visit" ) return "info";
            if ( gardeningTask.working.status === "has finished working" ) return "success";
            break;
            
        case "gardener stopped": return "danger";
    }

    return "default";
}

export const formatGardeningTaskCreationDate = (gardeningTask: GardeningTaskDocument): string => {
    const createdEvent = gardeningTask.history.find(x => x.event === "created");
    if ( !createdEvent ) return "Opgave fra ukendt dato";

    return `Opgave fra ${formatDate(createdEvent.date)}`;
}

export const formatGardeningTaskCreationDateWithTime = (gardeningTask: GardeningTaskDocument): string => {
    const createdEvent = gardeningTask.history.find(x => x.event === "created");
    if ( !createdEvent ) return "Opgave fra ukendt dato";

    return `Opgave fra ${moment(createdEvent.date).format("D MMMM YYYY HH:mm")}`;
}

export const formatRequiredSkills = (requiredSkills: GardeningTaskDocument["data"]["requiredSkills"]): string => {
    const skills = requiredSkills
        .sort((a, b) => {
            const sortOrder: Skill[] = ["lawn-mowing", "weed-control", "hedge-trimming", "pruning-of-trees-and-shrubs", "disposal-of-garden-waste", "other-garden-services"];
            return sortOrder.indexOf(a) - sortOrder.indexOf(b);
        })
        .map(skill => {
            switch ( skill ) {
                case "lawn-mowing": return "græsslåning";
                case "weed-control": return "ukrudtsbekæmpelse";
                case "hedge-trimming": return "hækklipning";
                case "pruning-of-trees-and-shrubs": return "beskæring";
                case "disposal-of-garden-waste": return "bortkørsel af haveaffald";
                case "other-garden-services": return "anden haveservice";
            }
    
            return ""; // To squelch faulty linting
        })
        .filter(Boolean);
    
    const result = new ListFormatter().formatList(skills);
    
    return result.slice(0, 1).toUpperCase() + result.slice(1);
}

export const formatTools = (tools: GardeningTaskDocument["data"]["tools"]): string => {
    switch ( tools ) {
        case "customer-tools": return "Kunden kan stille redskaber til rådighed";
        case "helper-tools": return "Havemanden skal medbringe redskaber";
    }
}

export const formatDuration = (hourInterval: GardeningTaskDocument["data"]["hourInterval"]): string => {
    switch ( hourInterval ) {
        case "1-2 hours": return "Ca. 1-2 timer";
        case "3-5 hours": return "Ca. 3-5 timer";
        case "more-than-6-hours": return "Mere end 6 timer";
    }
}

export const formatFrequency = (frequency: GardeningTaskDocument["data"]["frequency"]): string => {
    switch ( frequency ) {
        case "once": return "Opgaven ønskes blot udført én gang";
        case "once-every-week": return "Opgaven ønskes udført hver uge";
        case "once-every-second-week": return "Opgaven ønskes udført hver anden uge";
        case "once-every-month": return "Opgaven ønskes udført hver måned";
    }
}
