import { useCallback } from "react";

import firebase from "firebase/compat/app";
import moment from "moment";

import { useBlockingProgress } from "../../components/blocking-progress";
import { useSnackbar } from "../../components/snackbar";
import { CustomerDocument } from "../../customer/customer.model";
import { CreateTaskDialogViewModel } from "./CreateTaskDialog";

const createTaskFunction = firebase.app().functions("europe-west3").httpsCallable("Task-create2");

export const useCreateTaskAction = (customer: CustomerDocument | null | undefined) => {
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();
    
    const customerId = customer?.id;

    const action = useCallback((viewModel: CreateTaskDialogViewModel) => {
        block();
        Promise.resolve().then(() => {
            switch ( viewModel.type ) {

                case "adhoc":
                    return createTaskFunction({
                        type: "adhoc",
                        customerId,
                        whatHasHappened: viewModel.whatHasHappened,
                        whatShouldBeDone: viewModel.whatShouldBeDone,
                        performAfterDate: getPerformAfterDate(viewModel),
                    });

                default: throw new Error("Unexpected view model type");
            }
        })
            .then((response) => {
                if ( response.data === "has-active-gardener" ) return openSnackbar("warning", "En anden havemand er allerede aktivt tilknyttet denne kunde. Der er derfor ikke blevet oprettet en “Find havemand”-opgave");
                if ( response.data === "already-finding-gardener-automatically" ) return openSnackbar("info", "Vi er allerede i gang med at finde kunden en havemand automatisk.");

                openSnackbar("success", "Opgaven er oprettet");
            })
            .catch(() => {
                openSnackbar("error", "Der skete en fejl. Opgaven er ikke blevet oprettet.");
            })
            .finally(() => unblock());

    }, [block, unblock, openSnackbar, customerId]);

    return action;
};

function getPerformAfterDate(viewModel: Extract<CreateTaskDialogViewModel, { performAfterDateDate: string, performAfterDateTime: string }>): string {
    const date = moment(viewModel.performAfterDateDate).format("YYYY-MM-DD");

    return moment(`${date} ${viewModel.performAfterDateTime}`, "YYYY-MM-DD HH:mm").toISOString(true);
}
