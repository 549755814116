import { Tool } from "./gardener.model";

export const formatTools = (tools: Tool[]): string => {
    if ( tools.length === 0 ) return "Ingen redskaber";

    const toolsRepresentation = [];

    if ( tools.includes("lawn-mower") ) toolsRepresentation.push("plæneklipper");
    if ( tools.includes("grass-trimmer") ) toolsRepresentation.push("græstrimmer");
    if ( tools.includes("garden-tractor") ) toolsRepresentation.push("havetraktor");
    if ( tools.includes("verticutter") ) toolsRepresentation.push("plænelufter / vertikalskærer");
    if ( tools.includes("other-lawn-mowing-tools") ) toolsRepresentation.push("andre redskaber til græsslåning");
    if ( tools.includes("hand-tools") ) toolsRepresentation.push("håndredskaber");
    if ( tools.includes("weed-burner") ) toolsRepresentation.push("ukrudtsbrænder");
    if ( tools.includes("other-weed-control-tools") ) toolsRepresentation.push("andre redskaber til ukrudtsbekæmpelse");
    if ( tools.includes("hedge-trimmer") ) toolsRepresentation.push("hækklipper");
    if ( tools.includes("chainsaw") ) toolsRepresentation.push("motorsav");
    if ( tools.includes("other-hedge-trimming-tools") ) toolsRepresentation.push("andre redskaber til hækklipning");
    if ( tools.includes("pruning-shears") ) toolsRepresentation.push("beskæringssaks");
    if ( tools.includes("telescopic-pole") ) toolsRepresentation.push("teleskopstang");
    if ( tools.includes("scaffolding") ) toolsRepresentation.push("stige / stillads");
    if ( tools.includes("other-pruning-tools") ) toolsRepresentation.push("andre redskaber til beskæring");
    if ( tools.includes("regular-trailer") ) toolsRepresentation.push("almindelig trailer");
    if ( tools.includes("large-trailer") ) toolsRepresentation.push("stor trailer");
    if ( tools.includes("other-disposal-tools") ) toolsRepresentation.push("andre redskaber til bortkørsel");

    const representation = toolsRepresentation.join(", ");

    return representation.substring(0, 1).toUpperCase() + representation.substring(1);
}
