import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { ProcessProtectionActivationCommentDocument } from "../comment.model";

interface ProcessProtectionActivationCommentProps {
    comment: ProcessProtectionActivationCommentDocument;
}

export const ProcessProtectionActivationComment = (props: ProcessProtectionActivationCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Opgaver på havemandens oversigt">
            <CommentBody>
                {comment.enabled
                    ? "Havemanden er på pause, da opgaver ikke er besvaret til tiden. Havemanden er informeret og bliver aktiv igen, når det rettes."
                    : "Havemanden er aktiv igen, da ingen opgaver på havemandens oversigt er forfaldne længere."}
            </CommentBody>
        </Comment>
    );
};
