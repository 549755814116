import { Comment } from "../components/comment/Comment";
import { NewOrderCommentDocument } from "./comment.model";
import { CommentBody } from "../components/comment/CommentBody";

interface NewOrderCustomerCommentProps {
    comment: NewOrderCommentDocument;
}

export const NewOrderCustomerComment = (props: NewOrderCustomerCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Bestilling modtaget" author={comment.administratorName ?? undefined}>
            <CommentBody>
                Kunden har afgivet bestilling.
            </CommentBody>
        </Comment>
    );
};
