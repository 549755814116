import { useEffect, useState } from "react";

import firebase from "firebase/compat/app";

import { GardenerApplicationDocument } from "./gardener-application.model";

export const useGardenerApplication = (gardenerId: string) => {
    const [state, setState] = useState<GardenerApplicationDocument | null>();

    useEffect(() => {
        setState(undefined);

        return firebase.firestore().collection("gardenerApplications").where("gardenerId", "==", gardenerId).onSnapshot(snapshot => {
            if ( snapshot.docs.length === 0 ) return setState(null);
            
            const document = snapshot.docs[0];

            setState({ id: document.id, ...document.data() } as GardenerApplicationDocument);
        });
    }, [gardenerId]);

    return state;
};
