import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

import moment from "moment";

import { TaskDocument } from "../../task/task.model";
import { useComplaintTasks } from "../../workflow/follow-up/appointment/TaskProvider";
import { ContactGardenerTab } from "./ContactGardenerTab";

export const IntegratedContactGardenerTab = () => {
    const history = useHistory();
    const goToTask = useCallback((task: TaskDocument) => {
        history.push(`/complaint/contact-gardener/${task.id}`);
    }, [history]);

    const now = moment().toISOString(true);

    const tasks = useComplaintTasks();

    const relevantTasks = useMemo(() => tasks.filter(task => task.complaint.status === "contacting gardener"), [tasks]);
    const currentTasks = useMemo(() => relevantTasks.filter(task => task.performAfterDate <= now), [relevantTasks, now]);
    const futureTasks = useMemo(() => relevantTasks.filter(task => task.performAfterDate > now), [relevantTasks, now]);

    return (
        <ContactGardenerTab
            currentTasks={currentTasks}
            futureTasks={futureTasks}
            goToTask={goToTask}
        />
    );
};
