import { useCallback } from "react";

import firebase from "firebase/compat/app";

import { useBlockingProgress } from "../../components/blocking-progress";
import { useSnackbar } from "../../components/snackbar";
import { UpdateCustomerDialogProps } from "./UpdateCustomerDialog";

interface UpdateCustomerData {
    idempotencyKey: string;
    customerId: string;
    update: {
        name: string;
        email: string | null;
        phoneNumber: string;
        externalAddressId: string;
        billing: {
            type: "person",
            name: string;
            email: string | null;
            phoneNumber: string,
            address: {
                street: string,
                zipCode: string,
            },
        } | {
            type: "company",
            name: string;
            email: string | null;
            phoneNumber: string,
            address: {
                street: string,
                zipCode: string,
            },
            cvr: string;
            att: string | null;
            ean: string | null;
        }
    }
}

const updateCustomerFunction: (data: UpdateCustomerData) => Promise<firebase.functions.HttpsCallableResult>
    = firebase.app().functions("europe-west3").httpsCallable("Customer-update3");

export const useUpdateCustomerAction = (customerId: string) => {
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    const action: UpdateCustomerDialogProps["onConfirm"] = useCallback((viewModel, idempotencyKey) => {
        block();

        const data: UpdateCustomerData = {
            idempotencyKey,
            customerId,
            update: {
                name: viewModel.name,
                email: viewModel.email || null,
                phoneNumber: viewModel.phoneNumber,
                externalAddressId: viewModel.addressSearch.address!.externalId,
                billing: viewModel.billing.type === "person" ? {
                    type: "person",
                    name: viewModel.billing.name,
                    email: viewModel.billing.email || null,
                    phoneNumber: viewModel.billing.phoneNumber,
                    address: {
                        street: viewModel.billing.street,
                        zipCode: viewModel.billing.zipCode,
                    },
                } : {
                    type: "company",
                    name: viewModel.billing.name,
                    email: viewModel.billing.email || null,
                    phoneNumber: viewModel.billing.phoneNumber,
                    address: {
                        street: viewModel.billing.street,
                        zipCode: viewModel.billing.zipCode,
                    },
                    cvr: viewModel.billing.company.cvr,
                    att: viewModel.billing.company.att || null,
                    ean: viewModel.billing.company.ean || null,
                },
            },
        };

        updateCustomerFunction(data)
            .then(() => {
                openSnackbar("success", "Din handling er registreret");
                unblock();
            })
            .catch(() => {
                openSnackbar("error", "Der skete en fejl. Din handling er ikke blevet registreret.");
                unblock();
            });

    }, [block, unblock, openSnackbar, customerId]);

    return action;
};
