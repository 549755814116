import { useCallback, useEffect } from "react";

import { PositiveAction } from "../../../../../components/card/PositiveAction";
import { CancelInvoiceFormContext, cancelInvoiceFormHasChanged, scrollToFirstError, useCancelInvoiceForm, validateCancelInvoiceForm } from "./CancelInvoiceDialog";

interface SubmitButtonProps {
    onSubmit: (form: CancelInvoiceFormContext) => void;
}

export const SubmitButton = (props: SubmitButtonProps) => {
    const { onSubmit } = props;

    const { form, setForm } = useCancelInvoiceForm();    

    const hasChanged = cancelInvoiceFormHasChanged(form);

    const { validationAnimationDuration } = form;
    useEffect(() => {
        if ( validationAnimationDuration === 200 ) return;

        setForm((previousState): CancelInvoiceFormContext => ({
            ...previousState,
            validationAnimationDuration: 200,
        }))
    }, [setForm, validationAnimationDuration]);

    const skipValidationAnimation = useCallback(() => {
        setForm((previousState): CancelInvoiceFormContext => ({
            ...previousState,
            validationAnimationDuration: 0,
        }));
    }, [setForm]);

    const submit = useCallback(() => {
        const anyError = validateCancelInvoiceForm(form);
        if ( anyError ) {
            skipValidationAnimation();
            setForm((previousState): CancelInvoiceFormContext => ({
                ...previousState,
                reasonInput: {
                    ...previousState.reasonInput,
                    dirty: true,
                },
            }));
            return scrollToFirstError(form);
        }

        onSubmit(form);
    }, [setForm, skipValidationAnimation, onSubmit, form]);

    return (
        <PositiveAction onClick={submit} disabled={!hasChanged}>Ja, annuller faktura</PositiveAction>
    );
};
