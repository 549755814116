import { useEffect, useMemo, useRef, useState } from "react";

import { SearchResponse } from "@algolia/client-search";
import { debounce } from "@material-ui/core";
import { SearchClient } from "algoliasearch/lite";

import { CustomerDocument } from "../../customer.model";

export const useCustomerSearch = (searchClient: SearchClient | undefined, searchTerm: string) => {
    const terminated = useRef(true);
    const [state, setState] = useState<SearchResponse<CustomerDocument> | null>();

    const performCustomerSearch = useMemo(() => {
        return debounce(async (query: string) => {
            if ( terminated.current ) return;
            if ( !searchClient || !query ) return setState(null);

            const response = await searchClient.initIndex("customers").search<CustomerDocument>(query, { hitsPerPage: 10 })
            if ( terminated.current ) return;

            setState(response);
        }, 100);
    }, [searchClient]);
    
    useEffect(() => {
        setState(undefined);
        
        terminated.current = false;
        performCustomerSearch(searchTerm);

        return () => {
            terminated.current = true;
        };
    }, [performCustomerSearch, searchTerm, terminated]);

    return state;
};
