import { Link } from "react-router-dom";

import { Comment } from "../components/comment/Comment";
import { CommentBody } from "../components/comment/CommentBody";
import { InvoiceCreditCommentDocument } from "./comment.model";

interface InvoiceCreditCommentProps {
    context: "customer" | "gardener";
    comment: InvoiceCreditCommentDocument;
}

export const InvoiceCreditComment = (props: InvoiceCreditCommentProps) => {
    const { context, comment } = props;

    const invoice = <Link to={`/invoices/all/invoice/${comment.invoiceId}`}>Faktura {comment.invoiceNumber}</Link>;

    const contextDependentElement = (() => {
        switch ( context ) {
            case "customer": return <>udstedt af <Link to={`/gardeners/context/${comment.gardenerId}`}>{comment.gardenerName}</Link></>;
            case "gardener": return <>udstedt til <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link></>;
        }
    })();

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Faktura annulleret" author={comment.administratorName}>
            <CommentBody>
                {invoice} {contextDependentElement} er blevet annulleret.<br />
                <br />
                Årsag:<br />
                {comment.reason}
            </CommentBody>
        </Comment>
    );
};
