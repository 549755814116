import ChangeTimeIcon from "@material-ui/icons/UpdateOutlined";

import { MenuItem } from "../../components/menu/MenuItem";
import { useChangeTimeAction } from "./change-time-action.hook";
import { ChangeTimeDialogProps, useChangeTimeDialog } from "./ChangeTimeDialog";

interface ChangeTimeMenuItemProps {
    taskId: string;
    limitTime?: string;
    adapter?: ChangeTimeDialogProps["onConfirm"];
}

export const ChangeTimeMenuItem = (props: ChangeTimeMenuItemProps) => {
    const { taskId, limitTime, adapter, ...otherProps } = props;

    const onChangeTime = useChangeTimeAction(taskId);
    const openChangeTimeDialog = useChangeTimeDialog(adapter ?? onChangeTime, limitTime);

    return <MenuItem icon={<ChangeTimeIcon />} onClick={openChangeTimeDialog} {...otherProps}>Ændr tidspunkt</MenuItem>;
};
