import { useState } from "react";

import { GoogleMaps, GoogleMapsProps } from "../GoogleMaps";
import { ClusterMarker } from "./cluster-marker.model";
import { useClusterMarkers } from "./cluster-markers.hook";
import { useClustering } from "./clustering.hook";

interface ClusteredMapProps extends Omit<GoogleMapsProps, "setMap"> {
    markers: ClusterMarker[];
}

export const ClusteredMap = (props: ClusteredMapProps) => {
    const { markers, ...otherProps } = props;

    const [map, setMap] = useState<google.maps.Map>();
    const clusterMarkers = useClusterMarkers(map, markers);
    useClustering(map, clusterMarkers);

    return (
        <GoogleMaps setMap={setMap} {...otherProps} />
    );
};
