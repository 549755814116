import { useMemo } from "react";

import { PageFormItem, InfoFormItem, GroupFormItem, TextAreaFormItem, NoticeFormItem } from "../../../../../components/form-item/client/components";
import { InvoiceDocument } from "../../invoice.model";
import { DineroContactDocument } from "../../../pending-approval/dinero-contact.model";
import { hasCustomerInformationChanged, mapCustomer } from "./reissue-invoice.utility";

export interface ReissueInvoiceFormIds {
    TextAreaReason: string;
}

export type ReissueInvoiceForm = ConfirmationPage | CanReissueInvoiceBranch;

type CanReissueInvoiceBranch =
    GroupFormItem<[
        PageFormItem<
            GroupFormItem<[
                NoticeFormItem,
                NoticeFormItem,
                TextAreaFormItem,
            ]>
        >,
        ConfirmationPage,
    ]>;

type ConfirmationPage = PageFormItem<InfoFormItem>;

export const useReissueInvoiceForm = (invoice: InvoiceDocument, contact: DineroContactDocument, formIds: ReissueInvoiceFormIds): ReissueInvoiceForm => {
    return useMemo((): ReissueInvoiceForm => {

        const cannotReissueInvoiceConfirmationPage: ConfirmationPage = {
            type: "page",
            title: "Genudsted faktura med nye oplysninger",
            item: {
                type: "info",
                render: "default",
                text: "Fakturaen kan ikke genudstedes, da kundens faktureringsoplysninger ikke er blevet ændret.",
            },
        };

        const customerInformationHasChanged = hasCustomerInformationChanged(invoice, contact);
        if ( !customerInformationHasChanged ) return cannotReissueInvoiceConfirmationPage;

        const customerBefore = invoice.customer;
        const customerAfter = mapCustomer(contact);
    
        const canReissueInvoiceBranch: CanReissueInvoiceBranch = {
            type: "group",
            render: "all at once",
            items: [
                {
                    type: "page",
                    title: "Genudsted faktura med nye oplysninger",
                    item: {
                        type: "group",
                        render: "all at once",
                        items: [
                            {
                                type: "notice",
                                render: "info",
                                html: `
                                    <strong>Gammel faktura</strong><br />
                                    <br />
                                    ${formatCustomer(customerBefore)}
                                `,
                            },
                            {
                                type: "notice",
                                render: "info",
                                html: `
                                    <strong>Ny faktura</strong><br />
                                    <br />
                                    ${formatCustomer(customerAfter)}
                                `,
                            },
                            {
                                id: formIds.TextAreaReason,
                                type: "text-area",
                                label: "Årsag",
                                placeholder: "Angiv årsagen til at du vil genudstede fakturaen",
                                value: "",
                                dirty: false,
                                validation: "mandatory",
                            },
                        ],
                    }
                },
                {
                    type: "page",
                    title: "Genudsted faktura med nye oplysninger",
                    item: {
                        type: "info",
                        render: "default",
                        text: "Der udstedes en kreditnota for den oprindelige faktura og der udstedes herefter en ny faktura med de nye oplysninger.",
                    },
                },
            ],
        };

        return canReissueInvoiceBranch;
    }, [invoice, contact, formIds]);
};

function formatCustomer(customer: InvoiceDocument["customer"]): string {
    const lines: string[] = [];

    if ( customer.type === "person" ) {
        lines.push(customer.name);
        lines.push(`${customer.address.street}, ${customer.address.zipCode}`);
    } else {
        lines.push(customer.companyName);
        lines.push(`${customer.address.street}, ${customer.address.zipCode}`);
        lines.push(`CVR: ${customer.cvr}`);
        if ( customer.att ) lines.push(`ATT: ${customer.att}`);
    }

    return lines.join("<br />");
}
