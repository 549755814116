import { useMemo } from "react";

import { makeStyles } from "@material-ui/core";

import { CommentDocument } from "./comment.model";
import { ContextAwareComment } from "./ContextAwareComment";

const useStyles = makeStyles({
    root: {
        "& > *": {
            marginBottom: "24px",

            "&:last-child": {
                marginBottom: 0,
            },
        },
    },
});

interface GardenerCommentListProps {
    comments: CommentDocument[];
}

export const GardenerCommentList = (props: GardenerCommentListProps) => {
    const classes = useStyles();

    const comments = useMemo(() => {
        return props.comments.sort((a, b) => {
            if ( a.creationDate < b.creationDate ) return 1;
            if ( a.creationDate > b.creationDate ) return -1;
            return 0;
        });
    }, [props.comments]);

    return (
        <div className={classes.root}>
            {comments.map(comment => (
                <ContextAwareComment key={comment.id} context="gardener" comment={comment} />
            ))}
        </div>
    );
};
