import { useMemo } from "react";

import { PageFormItem, GroupFormItem, VerticalButtonsSingleChoiceFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { NoticeFormItem } from "../../components/form-item/client/form-item/NoticeFormItemComponent";

export interface SpecifyDurationFormIds {
    SingleChoiceDuration: string;
}

export type SpecifyDurationForm<NextPage extends FormItem> = SpecifyDurationPage<NextPage>;

type SpecifyDurationPage<NextPage extends FormItem> = PageFormItem<
    GroupFormItem<[
        NoticeFormItem,
        VerticalButtonsSingleChoiceFormItem<NextPage[]>,
    ]>
>;

export const useSpecifyDurationForm = <T extends FormItem>(nextPage: T, formIds: SpecifyDurationFormIds): SpecifyDurationForm<T> => {
    return useMemo(() => {

        const page: SpecifyDurationPage<T> = {
            type: "page",
            title: "Angiv varighed",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "notice",
                        render: "info",
                        html: "Det er ikke så afgørende, at estimatet er korrekt, men angiv blot det bedste bud.",
                    },
                    {
                        id: formIds.SingleChoiceDuration,
                        type: "single-choice",
                        mode: "switch page",
                        render: "vertical-buttons",
                        questionText: "Opgavens estimerede varighed",
                        answerTexts: [
                            "1-2 timer",
                            "3-5 timer",
                            "Mere end 6 timer",
                        ],
                        answerItems: [nextPage, nextPage, nextPage],
                        selectedChoiceIndex: null,
                        dirty: false,
                    },
                ],
            },
        };
        
        return page;
    }, [nextPage, formIds]);
}
