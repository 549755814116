import { makeStyles } from "@material-ui/core";

import { Card } from "../../../../components/card/Card";
import { LegacyCardContent } from "../../../../components/card/CardContent";
import { CardHeader } from "../../../../components/card/CardHeader";
import { Link } from "react-router-dom";
import { formatDateAccordingToHoursSkippingWeekend } from "../../../../date-utility";
import { UnorderedList } from "../../../../components/unordered-list/UnorderedList";
import { PaymentCollectionTaskDocument } from "../../../../task/task.model";
import { DineroContactDocument } from "../../pending-approval/dinero-contact.model";

const useStyles = makeStyles({
    group: {
        display: "flex",
        flexDirection: "column",
        lineHeight: "125%",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },

        "& > span": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
            whiteSpace: "pre-wrap",

            "& > a": {
                fontSize: "16px",
                fontWeight: 500,
                color: "#297A1D",
                textDecoration: "none",
        
                "&:hover": {
                    color: "#1C5B13",
                    textDecoration: "underline",
                },
            },
        },
    },
    order: {
        "& > *": {
            marginBottom: "16px",
        },

        "& > *:last-child": {
            marginBottom: 0,
        }
    },
});

interface CallBeforeInvoiceDebtCollectionDetailCardProps {
    task: PaymentCollectionTaskDocument;
    contact: DineroContactDocument;
}

export const CallBeforeInvoiceDebtCollectionDetailCard = (props: CallBeforeInvoiceDebtCollectionDetailCardProps) => {
    const classes = useStyles();

    const { task, contact } = props;

    const customer = <Link to={`/customers/context/${task.customerId}`}>{task.customerName}</Link>;
    const invoice = <Link to={`/invoices/all/invoice/${task.invoiceId}`}>faktura {task.invoiceNumber}</Link>;

    return (
        <Card>
            <CardHeader>{task.customerName}, faktura {task.invoiceNumber}</CardHeader>

            <LegacyCardContent className={classes.order}>

                <div className={classes.group}>
                    <label>Hvad er der hændt?</label>
                    <span>
                        {customer} har ikke betalt {invoice} efter at have fået tilsendt 3 rykkere.
                    </span>
                </div>

                <div className={classes.group}>
                    <label>Hvad skal der gøres?</label>
                    <span>
                        Ring kunden op på <Link to={`tel:${props.contact.phoneNumber}`}>{contact.phoneNumber}</Link>. Får du ikke fat i kunden, skal du ikke lægge besked.<br />
                        <br />
                        Stikord til samtalen/beskeden:
                    </span>
                    <UnorderedList>
                        <li>Vi har sendt dig 3 rykkere for din faktura, som er over forfald</li>
                        <li>Normalt ville vi sende fakturaen til inkasso på det her tidspunkt, men vi vil hellere ordne det uden at indblande inkassobureauet</li>
                        <li>De pålægger deres egne gebyrer, som kan være dyre, og når vi først har sendt den afsted, kan vi ikke trække den tilbage igen. Derfra er det en sag mellem dig og inkassobureauet og så hænger du på det fulde beløb inkl. gebyrer</li>
                        <li>Hvis du betaler med det samme, kan jeg sørge for, at du ikke bliver sendt til inkasso</li>
                    </UnorderedList>
                </div>

                <div className={classes.group}>
                    <label>Hvornår skal det udføres?</label>
                    <span>{formatDateAccordingToHoursSkippingWeekend(task.performAfterDate, 8, 20)}</span>
                </div>

            </LegacyCardContent>

        </Card>
    );
};
