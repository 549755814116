import { useAddCustomerComment } from "../../../comment/add-comment.hook";
import { useCustomerComments } from "../../../comment/comments.hook";
import { ComplaintTaskDocument } from "../../../task/task.model";
import { LinearProgress } from "../../../components/LinearProgress";
import { DetermineProposalDetailPage } from "./DetermineProposalDetailPage";
import { useAdministratorAction } from "../../../workflow/administrator-action.hook";
import { useInvoices } from "../../../finance/billing/invoices/invoices.hook";
import { ErrorPage } from "../../../ErrorPage";

interface IntegratedDetermineProposalDetailPageProps {
    task: ComplaintTaskDocument;
    goBack: () => void;
}

export const IntegratedDetermineProposalDetailPage = (props: IntegratedDetermineProposalDetailPageProps) => {
    const { task, goBack } = props;

    const invoices = useInvoices(task.complaint.invoiceIds);

    const administratorAction = useAdministratorAction(goBack);

    const comments = useCustomerComments(task?.customerId);
    const addComment = useAddCustomerComment(task?.customerId);

    if ( invoices === null ) return <ErrorPage message="Kunne ikke finde fakturaer" />
    if ( invoices === undefined || comments === undefined ) return <LinearProgress />;

    return (
        <DetermineProposalDetailPage
            task={task}
            invoices={invoices}
            administratorAction={administratorAction}
            comments={comments}
            addComment={addComment}
            goBack={goBack}
        />
    );
};
