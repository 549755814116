import { useMemo } from "react";

import moment from "moment";

export const useDate = (value: string): string | undefined => {
    return useMemo(() => {
        if ( !value ) return undefined;
        if ( !moment(value, true).isValid() ) return "Skal være på formen \"DD-MM-YYYY\"";

        return undefined;
    }, [value]);
}

export const useDateAfterCutoff = (value: string, cutoffDate: string): string | undefined => {
    return useMemo(() => {
        if ( !value ) return undefined;
        if ( !moment(value, true).isValid() ) return undefined;

        if ( moment(value).isBefore(cutoffDate, "day") ) return `Skal være efter ${moment(cutoffDate).format("DD-MM-YYYY")}`;

        return undefined;
    }, [value, cutoffDate]);
}
