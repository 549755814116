import { useEffect, useState } from "react";

export function useLastValue<T extends any>(value: T | undefined, initialValue?: T | undefined): T | undefined {
    const [state, setState] = useState(initialValue);

    useEffect(() => {
        if ( value !== undefined ) setState(value);
    }, [value]);

    return state;
}
