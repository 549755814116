import { useFormDialog, GroupFormItem, NoticeFormItem, SingleChoiceFormItem, PageFormItem } from "../../../../../components/form-item/client/components";
import { FormProps } from "../../../../../components/form-item/framework/react/FormProvider";
import { DineroContactDocument } from "../../../pending-approval/dinero-contact.model";
import { InvoiceDocument } from "../../invoice.model";
import { ReissueInvoiceForm, useReissueInvoiceForm } from "./ReissueInvoiceForm";
import { ResendInvoiceForm, useResendInvoiceForm } from "./ResendInvoiceForm";

export enum ReissueOrResendInvoiceFormIds {
    SingleChoiceAction = "SingleChoiceAction",
    TextAreaReissueInvoiceReason = "TextAreaReissueInvoiceReason",
    TextAreaResendInvoiceReason = "TextAreaResendInvoiceReason",
}

export type ReissueOrResendInvoiceForm =
    PageFormItem<
        GroupFormItem<[
            NoticeFormItem,
            SingleChoiceFormItem<[
                ReissueInvoiceForm,
                ResendInvoiceForm,
            ]>,
        ]>
    >;

export const useReissueOrResendInvoiceDialog = (invoice: InvoiceDocument, contact: DineroContactDocument, onConfirm: FormProps<ReissueOrResendInvoiceForm>["onSubmit"]) => {

    const reissueInvoiceForm = useReissueInvoiceForm(invoice, contact, {
        TextAreaReason: ReissueOrResendInvoiceFormIds.TextAreaReissueInvoiceReason,
    });

    const resendInvoiceForm = useResendInvoiceForm(invoice, contact, {
        TextAreaReason: ReissueOrResendInvoiceFormIds.TextAreaResendInvoiceReason,
    });

    const form: ReissueOrResendInvoiceForm = {
        type: "page",
        title: "Genudsted/gensend faktura",
        item: {
            type: "group",
            render: "all at once",
            items: [
                {
                    type: "notice",
                    render: "warning",
                    html: "Genudsted eller gensend kun de specifikke fakturaer, som kunden har bedt om.",
                },
                {
                    id: ReissueOrResendInvoiceFormIds.SingleChoiceAction,
                    type: "single-choice",
                    mode: "switch page",
                    render: "vertical-buttons",
                    questionText: "Hvilken handling vil du gerne foretage dig?",
                    answerTexts: [
                        "Genudsted faktura med nye oplysninger",
                        "Gensend faktura med gamle oplysninger",
                    ],
                    answerItems: [
                        reissueInvoiceForm,
                        resendInvoiceForm,
                    ],
                    selectedChoiceIndex: null,
                    dirty: false,
                }
            ],
        },
    };

    return useFormDialog(form, onConfirm);
};
