import { useMemo } from "react";

import { PageFormItem, GroupFormItem, InfoFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { NoticeFormItem } from "../../components/form-item/client/form-item/NoticeFormItemComponent";

export type ExplainConceptForm<NextPage extends FormItem> = ExplainConceptPage<NextPage>;

type ExplainConceptPage<NextPage extends FormItem> =
    PageFormItem<
        GroupFormItem<[
            InfoFormItem,
            NoticeFormItem,
            NextPage,
        ]>
    >

export const useExplainConceptForm = <T extends FormItem>(nextPage: T): ExplainConceptForm<T> => {
    return useMemo(() => {
    
        const page: ExplainConceptPage<T> = {
            type: "page",
            title: "Fortæl hvordan de hjælpes i gang",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: "Fortæl ansøgeren om hvordan de hjælpes i gang. Vælg “Næste”, når du er færdig.",
                    },
                    {
                        type: "notice",
                        render: "info",
                        html: `
                            <strong>Hvordan vi hjælper dig godt i gang</strong><br />
                            <br />
                            Når du starter som ny havemand, vil du nok have nogle spørgsmål til, hvordan det hele fungerer.
                            Men i stedet for at vi forsøger at tage det hele nu, så har vi lavet nogle guides til dig, som du vil se, efterhånden som du støder på noget nyt på platformen.
                            På den måde kan du komme rigtig godt fra start og lære konceptet at kende hen ad vejen.<br />
                            <br />
                            Vi har delt dem op i flere små guides, så de ikke tager ret lang tid hver især.
                            Så slipper du også for at få en masse information på én gang, når du ikke har brug for det endnu.<br />
                            <br />
                            Vores guides er lavet som små quizzer, hvor du får lidt information og så skal svare på et simpelt spørgsmål.<br />
                            <br />
                            Efter jeg har godkendt din ansøgning, vil den første guide være klar til dig inde på din side, og den vil fortælle dig lidt mere om hvordan det hele fungerer.
                            Den vil jeg anbefale dig at kigge på, når du har tid.
                        `,
                    },
                    nextPage,
                ],
            },
        };
        
        return page;
    }, [nextPage]);
}
