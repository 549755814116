import { IconButton, makeStyles } from "@material-ui/core";
import ProfileIcon from "@material-ui/icons/ArrowForwardOutlined";

import { CustomerDocument } from "../../customer.model";
import { CustomerCard } from "./CustomerCard";
import { useGoToCustomerProfile } from "../../go-to-customer-profile.hook";

const useStyles = makeStyles({
    icon: {
        fontSize: "24px",
        color: "#297A1D",
    },
})

export const ListItem = (props: { customer: CustomerDocument }) => {
    const classes = useStyles();

    const { customer } = props;

    const goToCustomerProfile = useGoToCustomerProfile("deleted", customer.id);

    const button = (
        <IconButton aria-label="go to customer profile" onClick={goToCustomerProfile}>
            <ProfileIcon className={classes.icon} />
        </IconButton>
    );

    return <CustomerCard customer={customer} button={button} />;
};
