import React, { useCallback } from "react";

import { Collapse } from "@material-ui/core";

import { Group } from "../../components/form/Group";
import { AlternativeHint } from "../../components/form/Hint";
import { TextArea } from "../../components/form/InputField";
import { PauseGardenerFormContext, usePauseGardenerForm } from "./PauseGardenerDialog";

interface ReasonInputProps {
    id?: string;
}

export const ReasonInput = (props: ReasonInputProps) => {
    const { id } = props;

    const { form, setForm } = usePauseGardenerForm();
    const { reasonInput } = form;

    const setReason = useCallback((reason: string) => {
        setForm((previousState): PauseGardenerFormContext => ({
            ...previousState,
            reasonInput: {
                ...previousState.reasonInput,
                reason,
            },
        }));
    }, [setForm]);

    const markDirty = useCallback(() => {
        setForm((previousState): PauseGardenerFormContext => ({
            ...previousState,
            reasonInput: {
                ...previousState.reasonInput,
                dirty: true,
            },
        }));
    }, [setForm]);

    const error = validateReasonInput(form);

    return (
        <Group error={Boolean(reasonInput.dirty && error)}>
            <TextArea id={id} value={reasonInput.reason} onChange={setReason} onBlur={markDirty} placeholder="Beskriv hvorfor havemanden skal sættes på pause" />

            <Collapse in={Boolean(reasonInput.dirty && error)} timeout={form.validationAnimationDuration}>
                <AlternativeHint message={error} />
            </Collapse>
        </Group>
    );
};

export interface ReasonInputContext {
    reason: string;
    dirty: boolean;
}

export const newReasonInputContext = (): ReasonInputContext => {
    return {
        reason: "",
        dirty: false,
    };
};

export const reasonInputHasChanged = (form: PauseGardenerFormContext) => {
    return form.reasonInput.reason !== "";
};

export const validateReasonInput = (form: PauseGardenerFormContext) => {
    if ( !form.reasonInput.reason ) return "Skal udfyldes";

    return undefined;
};
