import { makeStyles } from "@material-ui/core";
import moment from "moment";

import { Card } from "../../../../components/card/Card";
import { CardActions } from "../../../../components/card/CardActions";
import { CardContent } from "../../../../components/card/CardContent";
import { CardHeader } from "../../../../components/card/CardHeader";
import { NeutralAction } from "../../../../components/card/NeutralAction";
import { PositiveAction } from "../../../../components/card/PositiveAction";
import { MenuButton } from "../../../../components/menu/MenuButton";
import { ChangeTimeMenuItem } from "../../../../task/change-time/ChangeTimeMenuItem";
import { AdhocGardenerTaskDocument } from "../../../../task/task.model";
import { useCloseTask } from "../../close-task.hook";
import { usePlanGardenerFollowUp } from "../../plan-gardener-follow-up.hook";
import { FormGroup } from "../../../../finance/billing/invoices/invoice-detail/FormGroup";
import { GardenerDocument } from "../../../../gardener/gardener.model";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
    multiline: {
        whiteSpace: "pre-wrap",
    },
});

interface AdhocGardenerFollowUpDetailCardProps {
    task: AdhocGardenerTaskDocument;
    gardener: GardenerDocument;
    goBack: () => void;
}

export const AdhocGardenerFollowUpDetailCard = (props: AdhocGardenerFollowUpDetailCardProps) => {
    const classes = useStyles();

    const { task, gardener, goBack } = props;

    const closeTask = useCloseTask(task, goBack);
    const planFollowUp = usePlanGardenerFollowUp(task, goBack);

    const menuButton = !task.hasBeenPerformed ? (
        <MenuButton>
            <ChangeTimeMenuItem taskId={task.id} />
        </MenuButton>
    ) : undefined;

    return (
        <Card>
            <CardHeader button={menuButton}>{task.gardenerName}</CardHeader>

            <CardContent>

                {task.hasBeenPerformed ? (
                    <FormGroup>
                        <label>Status</label>
                        <span>Opgaven er allerede blevet udført</span>
                    </FormGroup>
                ) : null}

                <FormGroup>
                    <label>Havemand</label>
                    <span>
                        <Link to={`/gardeners/context/${gardener.id}`}>{gardener.name}</Link>
                    </span>
                </FormGroup>

                <FormGroup>
                    <label>Telefon</label>
                    <span>
                        <a href={`tel:${gardener.phoneNumber}`}>{gardener.phoneNumber}</a>
                    </span>
                </FormGroup>

                <FormGroup>
                    <label>Hvad er der hændt?</label>
                    <span className={classes.multiline}>{task.whatHasHappened}</span>
                </FormGroup>

                <FormGroup>
                    <label>Hvad skal der gøres?</label>
                    <span className={classes.multiline}>{task.whatShouldBeDone}</span>
                </FormGroup>

                <FormGroup>
                    <label>Hvornår skal det udføres?</label>
                    <span>{moment(task.performAfterDate).format("D MMMM HH:mm")}</span>
                </FormGroup>

            </CardContent>

            {!task.hasBeenPerformed ? (
                <CardActions>
                    <NeutralAction onClick={closeTask}>Afslut opgave</NeutralAction>
                    <PositiveAction onClick={planFollowUp}>Planlæg opfølgning</PositiveAction>
                </CardActions>
            ) : null}

        </Card>
    );
};
