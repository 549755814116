import { useEffect } from "react";

import firebase from "firebase/compat/app";

import { LinearProgress } from "../components/LinearProgress";

export const SignOut =  () => {
    useEffect(() => {
        firebase.auth().signOut();
    }, []);

    return <LinearProgress />;
};
