import { useCallback, useMemo, useState } from "react";

import { Collapse, makeStyles, MenuItem } from "@material-ui/core";

import { PositiveAction } from "../components/card/PositiveAction";
import { NeutralAction } from "../components/card/NeutralAction";
import { Dialog, DialogActions, DialogContent, DialogHeader, useDialog } from "../components/dialog";
import { Label } from "../components/form/Label";
import { Group } from "../components/form/Group";
import { AlternativeHint } from "../components/form/Hint";
import { useAnimation } from "../components/validation/animation.hook";
import { CustomerDocument } from "./customer.model";
import { Dropdown } from "../components/form/Dropdown";
import { FormGroup } from "../finance/billing/invoices/invoice-detail/FormGroup";
import { TextArea } from "../components/form/InputField";
import { DeleteCustomerReason } from "../workflow/administrator-action.hook";

const useStyles = makeStyles({
    text: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        marginTop: 0,
    },
});

export interface DeleteCustomerDialogProps {
    customer: CustomerDocument;
    onConfirm: (reason: DeleteCustomerReason, explanation: string) => void;
    onCancel: () => void;
}

export const DeleteCustomerDialog = (props: DeleteCustomerDialogProps) => {
    const classes = useStyles();

    const { customer, onConfirm, onCancel } = props;

    const [reason, setReason] = useState("");
    const [explanation, setExplanation] = useState("");
    const [dirty, setDirty] = useState({ reason: false, explanation: false });
    const markReasonDirty = useCallback(() => setDirty({ ...dirty, reason: true }), [dirty]);
    const markExplanationDirty = useCallback(() => setDirty({ ...dirty, explanation: true }), [dirty]);

    const [validationAnimationDuration, skipValidationAnimation] = useAnimation();

    const reasonError = useMemo(() => {
        if ( !reason ) return "Skal udfyldes";

        return undefined;
    }, [reason]);

    const explanationError = useMemo(() => {
        if ( !explanation ) return "Skal udfyldes";

        return undefined;
    }, [explanation]);

    const scrollToFirstError = useCallback(() => {
        const sectionId = (() => {
            if ( reasonError ) return "reason-section";
            if ( explanationError ) return "explanation-section";

            return undefined;
        })();

        if ( !sectionId ) return;

        const section = document.getElementById(sectionId);
        if ( !section ) return;

        section.scrollIntoView({ behavior: "smooth" });
    }, [reasonError, explanationError]);

    const confirm = useCallback(() => {
        if ( document.activeElement ) {
            (document.activeElement as HTMLInputElement).blur();
        }

        if ( reasonError || explanationError ) {
            skipValidationAnimation();
            setDirty({ reason: true, explanation: true });
            return setTimeout(scrollToFirstError, 100);
        }

        onConfirm(reason as DeleteCustomerReason, explanation);
    }, [skipValidationAnimation, scrollToFirstError, onConfirm, reasonError, reason, explanationError, explanation]);

    const renderPlaceholder = useCallback(() => <>Vælg årsag</>, []);

    return (
        <Dialog>
            <DialogHeader>Slet {customer.name.split(" ")[0]}?</DialogHeader>

            <DialogContent>
                <p className={classes.text}>Kundens oplysninger slettes fra systemet efter 30 dage. Tidsregistreringer foretaget på kunden forsvinder ikke, men vil herefter fremgå uden navn og adresse.</p>

                <div id="reason-section">
                    <Label style={{ marginBottom: "8px" }}>Årsag til sletning</Label>
                    <Group error={Boolean(dirty.reason && reasonError)}>
                        <Dropdown variant="outlined" renderValue={reason ? undefined : renderPlaceholder} value={reason} onChange={setReason} onBlur={markReasonDirty} displayEmpty>
                            <MenuItem value="customer-asked-to-be-deleted">Kunden har bedt om at blive slettet</MenuItem>
                            <MenuItem value="order-is-irrelevant">Bestillingen er irrelevant</MenuItem>
                        </Dropdown>

                        <Collapse in={Boolean(dirty.reason && reasonError)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={reasonError} />
                        </Collapse>
                    </Group>
                </div>

                <FormGroup id="explanation-section">
                    <Label htmlFor="explanation" style={{ marginBottom: "8px" }}>Uddybende forklaring</Label>
                    <Group error={Boolean(dirty.explanation && explanationError)}>
                        <TextArea id="explanation" value={explanation} onChange={setExplanation} onBlur={markExplanationDirty} placeholder="Uddyb hvorfor kunden skal slettes. Sendes ikke til kunden." />

                        <Collapse in={Boolean(dirty.explanation && explanationError)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={explanationError} />
                        </Collapse>
                    </Group>
                </FormGroup>

            </DialogContent>

            <DialogActions>
                <NeutralAction onClick={onCancel}>Annuller</NeutralAction>
                <PositiveAction onClick={confirm}>Ja, slet kunde</PositiveAction>
            </DialogActions>

        </Dialog>
    );
};


export const useDeleteCustomerDialog = (customer: CustomerDocument, onConfirm: DeleteCustomerDialogProps["onConfirm"]) => {
    const { openDialog, closeDialog } = useDialog();

    const confirm: DeleteCustomerDialogProps["onConfirm"] = useCallback((data, idempotencyKey) => {
        closeDialog();

        onConfirm(data, idempotencyKey);
    }, [closeDialog, onConfirm]);

    return useCallback(() => {
        openDialog(<DeleteCustomerDialog customer={customer} onConfirm={confirm} onCancel={closeDialog} />);
    }, [openDialog, closeDialog, confirm, customer]);
};
