import { useMemo } from "react";

import moment from "moment";

import { LinearProgress } from "../components/LinearProgress";
import { ResponsiveGrid } from "../components/responsive-grid/ResponsiveGrid";
import { ResponsiveGridItem } from "../components/responsive-grid/ResponsiveGridItem";
import { Section } from "../components/section/Section";
import { SectionHeaderAlternative } from "../components/section/SectionHeaderAlternative";
import { useEventsByTypeInTimePeriod } from "../event/event.hook";
import { EventDocument } from "../event/event.model";
import { MetricList } from "./metrics/MetricList";
import { MetricListItem } from "./metrics/MetricListItem";

interface Context {
    twoDaysAgo: string;
    yesterday: string;
    today: string;
    tomorrow: string;
}

export const GardenerMetricsSection = () => {
    const context = getContext();

    const applicationRejectedEvents = useEventsByTypeInTimePeriod("gardener-application-rejected", context.twoDaysAgo, context.tomorrow);
    const applicationsRejected = usePartitionedEvents(applicationRejectedEvents, context);

    const applicationReceivedEvents = useEventsByTypeInTimePeriod("gardener-application-received", context.twoDaysAgo, context.tomorrow);
    const applicationsBegun = usePartitionedEvents(applicationReceivedEvents, context);

    const applicationAcceptedEvents = useEventsByTypeInTimePeriod("gardener-application-accepted", context.twoDaysAgo, context.tomorrow);
    const applicationsFinished = usePartitionedEvents(applicationAcceptedEvents, context);

    const applicationApprovedEvents = useEventsByTypeInTimePeriod("gardener-application-approved", context.twoDaysAgo, context.tomorrow);
    const applicationsApproved = usePartitionedEvents(applicationApprovedEvents, context);

    if ( !applicationsBegun || !applicationsFinished || !applicationsRejected || !applicationsApproved ) return <LinearProgress />;

    return (
        <Section>
            <SectionHeaderAlternative>ansøgninger fra havemænd</SectionHeaderAlternative>

            <ResponsiveGrid>

                <ResponsiveGridItem units={1}>
                    <MetricList title="I dag">
                        <MetricListItem name="Afvist" value={applicationsRejected.today} color="red" />
                        <MetricListItem name="Påbegyndt" value={applicationsBegun.today} color="orange" />
                        <MetricListItem name="Færdiggjort" value={applicationsFinished.today} color="blue" />
                        <MetricListItem name="Onboardet" value={applicationsApproved.today} color="green" />
                    </MetricList>
                </ResponsiveGridItem>

                <ResponsiveGridItem units={1}>
                    <MetricList title="I går">
                        <MetricListItem name="Afvist" value={applicationsRejected.yesterday} color="red" />
                        <MetricListItem name="Påbegyndt" value={applicationsBegun.yesterday} color="orange" />
                        <MetricListItem name="Færdiggjort" value={applicationsFinished.yesterday} color="blue" />
                        <MetricListItem name="Onboardet" value={applicationsApproved.yesterday} color="green" />
                    </MetricList>
                </ResponsiveGridItem>

                <ResponsiveGridItem units={1}>
                    <MetricList title="I forgårs">
                        <MetricListItem name="Afvist" value={applicationsRejected.twoDaysAgo} color="red" />
                        <MetricListItem name="Påbegyndt" value={applicationsBegun.twoDaysAgo} color="orange" />
                        <MetricListItem name="Færdiggjort" value={applicationsFinished.twoDaysAgo} color="blue" />
                        <MetricListItem name="Onboardet" value={applicationsApproved.twoDaysAgo} color="green" />
                    </MetricList>
                </ResponsiveGridItem>

            </ResponsiveGrid>

        </Section>
    );
};

function getContext(): Context {
    const context = moment().toISOString(true);

    const twoDaysAgo = moment(context).subtract(2, "day").startOf("day").toISOString(true);
    const yesterday = moment(context).subtract(1, "day").startOf("day").toISOString(true);
    const today = moment(context).startOf("day").toISOString(true);
    const tomorrow = moment(context).add(1, "day").startOf("day").toISOString(true);

    return { twoDaysAgo, yesterday, today, tomorrow };
}

function usePartitionedEvents(events: EventDocument[] | undefined, context: Context) {
    return useMemo(() => {
        if ( !events ) return undefined;

        const twoDaysAgo = events.filter(event => event.creationDate >= context.twoDaysAgo && event.creationDate < context.yesterday).length;
        const yesterday = events.filter(event => event.creationDate >= context.yesterday && event.creationDate < context.today).length;
        const today = events.filter(event => event.creationDate >= context.today && event.creationDate < context.tomorrow).length;

        return { twoDaysAgo, yesterday, today };
    }, [events, context]);
}
