import { TextField } from "../../../../../components/form/InputField";
import { GardenerDocument } from "../../../../../gardener/gardener.model";
import { useInvoiceForm } from "./InvoiceForm";

export const GardenerInput = () => {
    const { form } = useInvoiceForm();
    const { gardenerInput } = form;

    return <TextField value={gardenerInput.gardenerName} disabled />;
}

export interface GardenerInputContext {
    gardenerName: string;
}

export const newGardenerInputContext = (gardener: GardenerDocument): GardenerInputContext => ({
    gardenerName: gardener.name,
});
