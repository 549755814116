import moment from "moment";

import { Comment } from "../../components/comment/Comment";
import { CustomerDeletedCommentDocument } from "../comment.model";
import { CommentBody } from "../../components/comment/CommentBody";

interface CustomerDeletedCommentProps {
    context: "customer";
    comment: CustomerDeletedCommentDocument;
}

export const CustomerDeletedComment = (props: CustomerDeletedCommentProps) => {
    const { comment } = props;

    const author = (() => {
        if ( comment.administratorName ) return comment.administratorName;

        return "Automatisk håndtering";
    })();

    const reason = (() => {
        switch ( comment.reason ) {
            case "unable-to-handle-order": return "Vi kunne ikke varetage opgaven";
            case "cannot-find-gardener": return "Vi kunne ikke finde en havemand";
            case "cannot-find-gardener-automatically": return "Vi kunne ikke finde en havemand";
            case "customer-has-cancelled-order": return "Kunden har fortrudt sin bestilling";
            case "customer-has-filed-complaint": return "Kunden har afgivet en klage";
            case "customer-asked-to-be-deleted": return "Kunden har bedt om at blive slettet";
            case "order-is-irrelevant": return "Bestillingen var irrelevant";
            case "customer-not-active": return "Kunden har ikke været aktiv i over et år";
            case "customer-unreachable": return "Vi kunne ikke få fat i kunden";
            default: return "Ukendt årsag";
        }
    })();

    const readyForDeletion = moment(comment.deletedDate).add(30, "days").toISOString(true);
    const timeOfDeletion = moment(readyForDeletion).add(1, "day").startOf("day").toISOString(true);

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Kunde sat til sletning" author={author}>
            <CommentBody>
                Årsag til sletning:<br />
                {reason}.<br />
                {comment.explanation ? (
                    <>
                        <br />
                        Uddybende forklaring:<br />
                        {comment.explanation}<br />
                    </>
                ) : null}
                <br />
                Dato for sletning:<br />
                Kunden slettes {moment(timeOfDeletion).format("D MMMM")} kl. 00:00.
            </CommentBody>
        </Comment>
    );
};
