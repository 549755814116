import { useCallback } from "react";

import firebase from "firebase/compat/app";
import moment from "moment";

import { useBlockingProgress } from "../../components/blocking-progress";
import { useDialog } from "../../components/dialog";
import { useSnackbar } from "../../components/snackbar";
import { TaskDocument } from "../../task/task.model";
import { CreateTaskDialogViewModel, useCreateTaskDialog } from "../../task/task-dialog/CreateTaskDialog";

const createTaskFunction = firebase.app().functions("europe-west3").httpsCallable("Task-create2");

const closeTaskFunction = async (taskId: string) => {
    await firebase.firestore().collection("tasks").doc(taskId).update({ hasBeenPerformed: true });
}

export const usePlanCustomerFollowUp = (task: Extract<TaskDocument, { customerId: string }>, goBack: () => void) => {
    const planFollowUp = usePlanCustomerFollowUpAction(task, goBack);

    return useCreateTaskDialog(planFollowUp);
};

export const usePlanCustomerFollowUpAction = (task: Extract<TaskDocument, { customerId: string }>, goBack: () => void) => {
    const { closeDialog } = useDialog();
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    const customerId = task.customerId;
    const taskId = task.id;

    return useCallback((viewModel: CreateTaskDialogViewModel) => {
        closeDialog();

        block();
        Promise.resolve().then(() => {
            switch ( viewModel.type ) {

                case "adhoc":
                    return createTaskFunction({
                        type: "adhoc",
                        customerId,
                        whatHasHappened: viewModel.whatHasHappened,
                        whatShouldBeDone: viewModel.whatShouldBeDone,
                        performAfterDate: getPerformAfterDate(viewModel),
                    });

                default: throw new Error("Unexpected view model type");
            }
        })
            .then(async (response) => {
                if ( response.data === "has-active-gardener" ) return openSnackbar("warning", "En anden havemand er allerede aktivt tilknyttet denne kunde. Der er derfor ikke blevet oprettet en “Find havemand”-opgave");
                
                await closeTaskFunction(taskId);

                if ( response.data === "already-finding-gardener-automatically" ) openSnackbar("info", "Vi er allerede i gang med at finde kunden en havemand automatisk.");
                
                openSnackbar("success", "Opfølgningen er planlagt");
                goBack();
            })
            .catch(() => openSnackbar("error", "Der skete en fejl. Opfølgningen er ikke blevet planlagt."))
            .finally(() => unblock());

    }, [closeDialog, block, unblock, openSnackbar, goBack, customerId, taskId]);
};

function getPerformAfterDate(viewModel: Extract<CreateTaskDialogViewModel, { performAfterDateDate: string, performAfterDateTime: string }>): string {
    return moment(`${viewModel.performAfterDateDate} ${viewModel.performAfterDateTime}`, "YYYY-MM-DD HH:mm").toISOString(true);
}
