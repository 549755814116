import { makeStyles } from "@material-ui/core";
import moment from "moment";

import { Card } from "../../components/card/Card";
import { CardContent } from "../../components/card/CardContent";
import { CardHeader } from "../../components/card/CardHeader";
import { EvaluateGardenerTaskDocument } from "../../task/task.model";

const useStyles = makeStyles({
    content: {
        "& > *": {
            marginBottom: "16px",
        },

        "& > *:last-child": {
            marginBottom: 0,
        }
    },
    group: {
        display: "flex",
        flexDirection: "column",
        lineHeight: "125%",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },

        "& > span": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
            lineHeight: "125%",
            whiteSpace: "pre-wrap",
        },
    },
});

interface EvaluateGardenerDetailCardProps {
    task: EvaluateGardenerTaskDocument;
    goBack: () => void;
}

export const EvaluateGardenerDetailCard = (props: EvaluateGardenerDetailCardProps) => {
    const classes = useStyles();

    const { task } = props;

    return (
        <Card>
            <CardHeader>{task.gardenerName}</CardHeader>

            <CardContent className={classes.content}>

                <div className={classes.group}>
                    <label>Hvad er der hændt?</label>
                    <span>Havemanden er løbet tør for opgaver, fordi for få af opgaverne, som havemanden har sagt ja tak til er blevet løst.</span>
                </div>

                <div className={classes.group}>
                    <label>Hvad skal der gøres?</label>
                    <span>Tag stilling til hvad der skal ske med samarbejdet ved at besvare opgaven.</span>
                </div>

                <div className={classes.group}>
                    <label>Hvornår skal det udføres?</label>
                    <span>{moment(task.performAfterDate).format("D MMMM YYYY HH:mm")}</span>
                </div>

            </CardContent>

        </Card>
    );
};
