import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { TaskDocument } from "../../task/task.model";
import { useOnboardingTasks } from "../../workflow/follow-up/appointment/TaskProvider";
import { ApplicationTab } from "./ApplicationTab";

export const IntegratedApplicationTab = () => {
    const history = useHistory();
    const goToTask = useCallback((task: TaskDocument) => {
        history.push(`/onboarding/application/${task.id}`);
    }, [history]);

    const tasks = useOnboardingTasks();
    const relevantTasks = useMemo(() => tasks.filter(task => task.application.status === "accepted"), [tasks]);

    return (
        <ApplicationTab
            tasks={relevantTasks}
            goToTask={goToTask}
        />
    );
};
