import { Link } from "react-router-dom";

import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { CloseTaskCommentDocument } from "../comment.model";
import { formatTask } from "../../shared/utility";

interface CloseTaskCommentProps {
    context: "customer" | "gardener";
    comment: CloseTaskCommentDocument;
}

export const CloseTaskComment = (props: CloseTaskCommentProps) => {
    const { comment } = props;

    const task = <Link to={`/tasks/${comment.taskId}`}>{formatTask(comment.taskType)}</Link>;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Opgave afsluttet" author={comment.administratorName}>
            <CommentBody>
                {task} er afsluttet.<br />
                <br />
                Årsag:<br />
                {comment.reason}
            </CommentBody>
        </Comment>
    );
};
