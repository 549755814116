import { useMemo } from "react";

import { PageFormItem, HorizontalButtonsSingleChoiceFormItem, InfoFormItem, GroupFormItem, NoticeFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { ComplaintDocument } from "../complaint.model";
import { ListFormatter } from "../../formatting/list.formatter";
import { DineroContactDocument } from "../../finance/billing/pending-approval/dinero-contact.model";

export interface CustomerReachedFormIds {
    SingleChoiceCustomerReached: string;
}

export type CustomerReachedForm<NextPage extends FormItem> = 
    PageFormItem<
        GroupFormItem<[
            NoticeFormItem,
            HorizontalButtonsSingleChoiceFormItem<[
                ConfirmationPage,
                NextPage,
            ]>,
        ]>
    >;

type ConfirmationPage = PageFormItem<InfoFormItem>;

export const useCustomerReachedForm = <T extends FormItem>(complaint: ComplaintDocument, contact: DineroContactDocument, nextPage: T, formIds: CustomerReachedFormIds): CustomerReachedForm<T> => {
    return useMemo(() => {
        const listFormatter = new ListFormatter();
        const invoicesListed = listFormatter.formatList(complaint.invoices.map(x => `faktura ${x.invoiceNumber}`));

        const calledCustomerbefore = complaint.history.some(x => x.event === "called customer");
        const confirmationPage: ConfirmationPage = !calledCustomerbefore ? {
            type: "page",
            title: "Udskyd opgave",
            item: {
                type: "info",
                render: "default",
                text: `
                    Opgaven udskydes til i morgen.<br />
                    <br />
                    Kunden modtager en notifikation om, at vi har forsøgt at komme i kontakt med dem ift. deres reklamation, og at vi vil kontakte dem igen.
                `,
            },
        } : {
            type: "page",
            title: "Reklamationen overdrages til havemanden",
            item: {
                type: "info",
                render: "default",
                text: `
                    Opgaven afsluttes.<br />
                    <br />
                    Kunden modtager en notifikation om, at vi har forsøgt at komme i kontakt med dem, men at det ikke er lykkedes og at havemanden er blevet informeret om reklamationen.<br />
                    <br />
                    Havemanden modtager en notifikation om, at der er modtaget en reklamation på ${invoicesListed} og at de nu må finde en løsning sammen med kunden.
                `,
            },
        }

        return {
            type: "page",
            title: "Kontakt kunden",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "notice",
                        render: "info",
                        html: `
                            Ring til <strong>${contact.name}</strong> på <strong>${contact.phoneNumber}</strong> for at diskutere reklamationen.<br />
                            <br />
                            Vi ønsker, at de skal føle sig hørt og vi har brug for, at vide hvad problemet er, og hvilken løsning kunden ønsker sig.
                        `,
                    },
                    {
                        id: formIds.SingleChoiceCustomerReached,
                        type: "single-choice",
                        mode: "switch page",
                        render: "horizontal-buttons",
                        questionText: "Fik du fat i kunden?",
                        answerTexts: ["Nej", "Ja"],
                        answerItems: [
                            confirmationPage,
                            nextPage,
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                    },
                ],
            },
        };
    }, [complaint, contact, nextPage, formIds]);
}
