import { createContext, Dispatch, SetStateAction, useCallback, useContext, useMemo, useState } from "react";

import { NeutralAction } from "../../../../../components/card/NeutralAction";
import { Dialog, DialogActions, DialogContent, DialogHeader, useDialog } from "../../../../../components/dialog";
import { Label } from "../../../../../components/form/Label";
import { FormGroup } from "../FormGroup";
import { newReasonInputContext, ReasonInput, ReasonInputContext, reasonInputHasChanged, validateReasonInput } from "./ReasonInput";
import { SubmitButton } from "./SubmitButton";

const Context = createContext<{ form: CancelInvoiceFormContext, setForm: Dispatch<SetStateAction<CancelInvoiceFormContext>> }>(undefined as any);

interface CancelInvoiceDialogProps {
    onConfirm: (form: CancelInvoiceFormContext) => void;
    onCancel: () => void;
}

export const CancelInvoiceDialog = (props: CancelInvoiceDialogProps) => {
    const { onConfirm, onCancel } = props;

    const [form, setForm] = useState(newFormContext());
    const value = useMemo(() => ({ form, setForm }), [form, setForm]);

    return (
        <Context.Provider value={value}>
            <Dialog>
                <DialogHeader>Annuller faktura?</DialogHeader>

                <DialogContent>
                    <div>Kunden vil modtage en kreditnota på fakturaen.</div>

                    <FormGroup id="reason-section">
                        <Label htmlFor="reason" style={{ marginBottom: "8px" }}>Årsag til annullering</Label>
                        <ReasonInput id="reason" />
                    </FormGroup>

                </DialogContent>

                <DialogActions>
                    <NeutralAction onClick={onCancel}>Annuller</NeutralAction>
                    <SubmitButton onSubmit={onConfirm}/>
                </DialogActions>

            </Dialog>
        </Context.Provider>
    );
};

export const useCancelInvoiceForm = () => {
    return useContext(Context);
};

export interface CancelInvoiceFormContext {
    validationAnimationDuration: number;
    reasonInput: ReasonInputContext;
}

export const newFormContext = (): CancelInvoiceFormContext => {
    return {
        validationAnimationDuration: 200,
        reasonInput: newReasonInputContext(),
    };
};

export const cancelInvoiceFormHasChanged = (form: CancelInvoiceFormContext) => {
    return  reasonInputHasChanged(form);
};

export const validateCancelInvoiceForm = (form: CancelInvoiceFormContext) => {
    return validateReasonInput(form);
};

export const scrollToFirstError = (form: CancelInvoiceFormContext) => {
    const sectionId = (() => {
        if ( validateReasonInput(form) ) return "reason-section"

        return undefined;
    })();

    if ( !sectionId ) return;

    const section = document.getElementById(sectionId);
    if ( !section ) return;

    const header = document.getElementsByTagName("header").length === 1 ? document.getElementsByTagName("header").item(0) : null;
    const headerOffset = header?.getBoundingClientRect().height ?? 0;

    const offsetPosition = window.scrollY + section.getBoundingClientRect().top - headerOffset;

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
}

export const useCancelInvoiceDialog = (onConfirm: CancelInvoiceDialogProps["onConfirm"]) => {
    const { openDialog, closeDialog } = useDialog();

    const confirm: CancelInvoiceDialogProps["onConfirm"] = useCallback(form => {
        closeDialog();

        onConfirm(form);
    }, [closeDialog, onConfirm]);

    return useCallback(() => {
        openDialog(<CancelInvoiceDialog onConfirm={confirm} onCancel={closeDialog} />);
    }, [openDialog, closeDialog, confirm]);
};
