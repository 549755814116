import { useParams } from "react-router";

import { Container } from "@material-ui/core";

import { ContentSection } from "../../../../layout/ContentSection";
import { TitleHeader } from "../../../../components/TitleHeader";
import { LinearProgress } from "../../../../components/LinearProgress";
import { BackButton } from "../../../../components/BackButton";
import { useCustomerComments } from "../../../../comment/comments.hook";
import { ActivitySection } from "../../../../comment/ActivitySection";
import { CustomerCommentList } from "../../../../comment/CustomerCommentList";
import { useAddCustomerComment } from "../../../../comment/add-comment.hook";
import { useTask } from "../../../../task/tasks.hook";
import { NotFoundPage } from "../../../../NotFoundPage";
import { ErrorPage } from "../../../../ErrorPage";
import { PaymentCollectionTaskDocument } from "../../../../task/task.model";
import { PaymentCollectionTaskContext } from "./PaymentCollectionTaskContext";

type RelevantTask = PaymentCollectionTaskDocument;

interface PaymentCollectionDetailPageProps {
    goBack: () => void;
}

export const PaymentCollectionDetailPage = (props: PaymentCollectionDetailPageProps) => {
    const { goBack } = props;

    const { taskId } = useParams<{ taskId: string }>();
    const task = useTask(taskId) as RelevantTask | null | undefined;

    const comments = useCustomerComments(task?.customerId);
    const addComment = useAddCustomerComment(task?.customerId);

    if ( task === null ) return <NotFoundPage />;
    if ( task === undefined ) return <LinearProgress />;

    if ( task.type !== "payment-collection" ) return <ErrorPage message="Opgavens type genkendes ikke" />;
    if ( task.status !== "schedule call before invoice reminder fee 1" && task.status !== "send invoice reminder 1" && task.status !== "send invoice reminder 2" && task.status !== "send invoice reminder fee 1" && task.status !== "send invoice reminder fee 2" && task.status !== "send invoice reminder fee 3" && task.status !== "schedule call before debt collection" ) return <ErrorPage message="Opgavens status er uforventet" />;

    if ( comments === undefined ) return <LinearProgress />;

    const backButton = <BackButton onClick={goBack} />;

    return (
        <>
            <TitleHeader button={backButton}>Automatisk håndtering</TitleHeader>
            
            <ContentSection>
                <Container maxWidth="sm" disableGutters>

                    <PaymentCollectionTaskContext task={task} />

                    <ActivitySection onSave={addComment}>
                        <CustomerCommentList comments={comments} />
                    </ActivitySection>

                </Container>
            </ContentSection>
        </>
    );
};
