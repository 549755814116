import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { LegacyGardenerUnpausedCommentDocument } from "../comment.model";

interface LegacyGardenerUnpausedCommentProps {
    comment: LegacyGardenerUnpausedCommentDocument;
}

export const LegacyGardenerUnpausedComment = (props: LegacyGardenerUnpausedCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Havemand er ikke længere på administrativ pause" author={comment.administratorName}>
            <CommentBody>
                Årsag:<br />
                {comment.reason}
            </CommentBody>
        </Comment>
    );
};
