import moment from "moment";

import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { formatDate } from "../../date-utility";
import { GardenerTerminatedCommentDocument } from "../comment.model";

interface GardenerTerminatedCommentProps {
    comment: GardenerTerminatedCommentDocument;
}

export const GardenerTerminatedComment = (props: GardenerTerminatedCommentProps) => {
    const { comment } = props;

    const deletionDate = determineDeletionDate(props.comment.terminationDate);

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Samarbejde ophørt" author={comment.administratorName ?? undefined}>
            <CommentBody>
                Årsag til ophør:<br />
                {formatIssue(comment.issue)}<br />
                <br />
                {comment.explanation ? (
                    <>
                        Uddybende forklaring:<br />
                        {comment.explanation}<br />
                        <br />
                    </>
                ) : null}
                Dato for sletning:<br />
                Havemanden slettes d. {formatDate(deletionDate)}.
            </CommentBody>
        </Comment>
    );
};


function determineDeletionDate(terminationDate: string): string {
    return moment(terminationDate).add(3, "years").startOf("year").add(2, "months").toISOString(true);
}

function formatIssue(issue: GardenerTerminatedCommentDocument["issue"]): string {
    switch ( issue ) {
        case "bad-partnership": return "Samarbejdet fungerer ikke.";
        case "gardener-behavior": return "Havemandens opførsel.";
        case "gardener-wants-to-stop": return "Havemanden ønsker at stoppe samarbejdet.";
        case "gardener-inactive": return "Havemanden har ikke været aktiv de sidste 12 måneder.";
    }
}
