import { Comment } from "../components/comment/Comment";
import { CommentBody } from "../components/comment/CommentBody";
import { GardenerApplicationReceivedCommentDocument } from "./comment.model";

interface GardenerApplicationReceivedCommentProps {
    comment: GardenerApplicationReceivedCommentDocument;
}

export const GardenerApplicationReceivedComment = (props: GardenerApplicationReceivedCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Ansøgning modtaget">
            <CommentBody>Havemanden har sendt en ansøgning som {comment.mode === "person" ? "privatperson" : "virksomhed"}.</CommentBody>
        </Comment>
    );
};
