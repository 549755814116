import { useMemo } from "react";

import { PageFormItem, GroupFormItem, InfoFormItem, SingleChoiceFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { NoticeFormItem } from "../../components/form-item/client/form-item/NoticeFormItemComponent";
import { GuardrailsProtectionDocument } from "../shared/guardrails-protection.model";
import { GardenerDocument } from "../../gardener/gardener.model";

export interface ContactGardenerFormIds {
    SingleChoiceReachedGardener: string;
}

export type ContactGardenerForm<NextPage extends FormItem> = ContactGardenerPage<NextPage>;

type ContactGardenerPage<NextPage extends FormItem> =
    PageFormItem<
        GroupFormItem<[
            InfoFormItem,
            NoticeFormItem,
            SingleChoiceFormItem<[
                ConfirmationPage,
                NextPage
            ]>,
        ]>
    >;

type ConfirmationPage = PageFormItem<InfoFormItem>

export const useContactGardenerForm = <T extends FormItem>(gardener: GardenerDocument, protection: GuardrailsProtectionDocument, nextPage: T, formIds: ContactGardenerFormIds): ContactGardenerForm<T> => {
    return useMemo(() => {
        const numberOfCalls = protection.action.status === "call" ? protection.action.numberOfCalls + 1 : 1;

        const confirmationPage = ((): ConfirmationPage => {
            if ( numberOfCalls === 1 ) {
                return {
                    type: "page",
                    title: "Udskyd opgave efter første kontaktforsøg",
                    item: {
                        type: "info",
                        render: "default",
                        text: `
                            Opgaven udskydes til i morgen.<br />
                            <br />
                            Havemanden informeres om, at vi har forsøgt at komme i kontakt med dem ift. vores samarbejde.
                        `,
                    },
                };
            }

            if ( numberOfCalls === 2 ) {
                return {
                    type: "page",
                    title: "Udskyd opgave efter andet kontaktforsøg",
                    item: {
                        type: "info",
                        render: "default",
                        text: `
                            Opgaven udskydes til i morgen.<br />
                            <br />
                            Havemanden informeres ikke.
                        `,
                    },
                };
            }

            return {
                type: "page",
                title: "Ophør samarbejde",
                item: {
                    type: "info",
                    render: "default",
                    text: `
                        Samarbejdet ophøres med havemanden med begrundelsen “samarbejdet fungerer ikke”.<br />
                        <br />
                        Havemanden informeres om, at samarbejdet er ophørt.<br />
                        <br />
                        Hvis havemanden stadig har nogen opgaver på sin liste, vil systemet automatisk lede efter en anden havemand til dem.
                    `,
                },
            };
        })();
    
        const page: ContactGardenerPage<T> = {
            type: "page",
            title: "Kontakt havemand",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `Ring til havemanden på <a href="tel:${gardener.phoneNumber}">${gardener.phoneNumber}</a> med henblik på at have en samtale omkring samarbejdet.`
                    },
                    {
                        type: "notice",
                        render: "warning",
                        html: "Får du ikke fat i havemanden, skal du ikke lægge en besked.",
                    },
                    {
                        id: formIds.SingleChoiceReachedGardener,
                        type: "single-choice",
                        mode: "switch page",
                        render: "horizontal-buttons",
                        questionText: "Fik du fat i havemanden?",
                        answerTexts: [
                            "Nej",
                            "Ja"
                        ],
                        answerItems: [
                            confirmationPage,
                            nextPage,
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                    }
                ],
            },
        };
        
        return page;
    }, [gardener, protection, nextPage, formIds]);
}
