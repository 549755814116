import { PropsWithChildren } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        lineHeight: "125%",
        whiteSpace: "pre-wrap",
        margin: 0,

        "& > a": {
            fontSize: "16px",
            fontWeight: 500,
            color: "#297A1D",
            textDecoration: "none",
    
            "&:hover": {
                color: "#1C5B13",
                textDecoration: "underline",
            },
        },
    },
});

export const CommentBody = (props: PropsWithChildren<{}>) => {
    const classes = useStyles();

    const { children } = props;

    return (
        <div className={classes.root}>{children}</div>
    );
};
