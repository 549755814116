import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { AppBar, ClickAwayListener, Collapse, makeStyles, MenuItem, Toolbar, useMediaQuery } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

import { HamburgerButton } from "../components/HamburgerButton";
import { UserMenuButton } from "../components/UserMenuButton";
import { useLinks } from "../LinkProvider";
import { useUser } from "../user/UserProvider";
import { sidebarBreakpoint } from "./Sidebar";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#1C5B13",
    },
    container: {
        display: "flex",
        justifyContent: "space-between",
    },
    logoLink: {
        display: "flex",
        alignItems: "center",
        transition: "opacity 0.15s linear",
        padding: "0 16px",

        "&:hover": {
            opacity: 0.7,
        },

        [theme.breakpoints.down(sidebarBreakpoint)]: {
            padding: "0 10px",
        },
    },
    logo: {
        height: "30px",

        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            height: "24px",
        },
    },
    menu: {
        display: "flex",
        alignItems: "center",

        [theme.breakpoints.down(sidebarBreakpoint)]: {
            display: "none",
        },

        "& > button": {
            fontSize: "14px",
            fontWeight: 600,
            letterSpacing: "0.75px",
            color: "rgba(255, 255, 255, 0.7)",
            textDecoration: "none",
            textTransform: "none",
            transition: "color 0.2s linear",
            padding: "16px",

            "&:hover": {
                backgroundColor: "transparent",
                color: "#ffffff",
            },

            "&.active": {
                color: "#ffffff",
            },
        },

        "& ul": {
            minWidth: "192px",
            padding: "8px 0",

            "& > li": {
                padding: 0,
            },

            "& > li > a": {
                padding: "8px 16px",
                width: "100%",
                fontSize: "14px",
                fontWeight: 500,
                color: "#4A4A4A",
                textDecoration: "none",
            },
        },
    },
    hamburger: {
        [theme.breakpoints.up(sidebarBreakpoint)]: {
            display: "none",
        },
    },
    drawer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#297A1D",
        padding: "8px",

        "& > a": {
            fontSize: "14px",
            fontWeight: 600,
            letterSpacing: "0.75px",
            color: "rgba(255, 255, 255, 0.7)",
            textDecoration: "none",
            textTransform: "uppercase",
            transition: "color 0.2s linear",
            padding: "8px",

            "&.active, &:hover": {
                color: "#ffffff",
            },
        },
    },
    verticalDivider: {
        marginBottom: "32px",
    },
    separator: theme.mixins.toolbar,
}));

// TODO: Separate navigations and topbar
// TODO: Create a components folder and let it include topbar and sidebar
// TODO: Create a layout folder and let it include layout, sidenavigation and topnavigation
export const Topbar = () => {
    const classes = useStyles();

    const [active, setActive] = useState(false);

    const toggleActive = useCallback(() => {
        setActive(!active);
    }, [active]);

    const closeDrawer = useCallback(() => {
        setActive(false);
    }, []);

    const location = useLocation();
    useEffect(() => {
        setActive(false);
    }, [location]);

    const disableHamburger = useMediaQuery(`(min-width: ${sidebarBreakpoint}px)`);
    useEffect(() => {
        if ( !disableHamburger ) return;

        setActive(false);
    }, [disableHamburger]);

    const user = useUser();
    const userFirstName = user.name.split(" ")[0];

    const { insightLinks, workflowLinks, taskCount, logOutLink } = useLinks();

    return (
        <>
            <ClickAwayListener onClickAway={closeDrawer}>

                <AppBar position="fixed" component="nav" className={classes.root}>
                    <Toolbar className={classes.container}>

                        <Link to="/" className={classes.logoLink}>
                            <img src="/logo.png" alt="Go Go Garden" className={classes.logo} />
                        </Link>

                        <div className={classes.menu}>
                            <UserMenuButton
                                id="user-menu"
                                text={taskCount !== "0" ? `${userFirstName} (${taskCount})` : userFirstName}
                                hide={!disableHamburger}
                                disableRipple
                                startIcon={<AccountCircle />}
                                endIcon={<ArrowDropDown />}
                            >
                                {closeMenu => [
                                    <MenuItem key="logout" onClick={closeMenu}>
                                        {logOutLink}
                                    </MenuItem>,
                                ]}
                            </UserMenuButton>
                        </div>

                        <HamburgerButton active={active} toggle={toggleActive} className={classes.hamburger} />

                    </Toolbar>

                    <Collapse in={active} timeout={400}>
                        <div className={classes.drawer}>

                            {insightLinks}

                            <div className={classes.verticalDivider} />

                            {workflowLinks}

                            <div className={classes.verticalDivider} />

                            {logOutLink}

                        </div>
                    </Collapse>

                </AppBar>

            </ClickAwayListener>

            <div className={classes.separator} />
        </>
    );
};
