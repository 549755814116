import { Container } from "@material-ui/core";

import { ComplaintTaskDocument } from "../../../task/task.model";
import { CommentDocument } from "../../../comment/comment.model";
import { Message } from "../../../Message";
import { ActivitySection } from "../../../comment/ActivitySection";
import { CustomerCommentList } from "../../../comment/CustomerCommentList";
import { BackButton } from "../../../components/BackButton";
import { Section } from "../../../components/section";
import { TitleHeader } from "../../../components/TitleHeader";
import { ContentSection } from "../../../layout/ContentSection";
import { GreenButton, VerticalButtons } from "../../../components/new-button";
import { RegistrationDetailCard } from "../../../finance/billing/pending-approval/RegistrationDetailCard";
import { ComplaintDetailCard } from "../../ComplaintDetailCard";
import { InvoiceDocument } from "../../../finance/billing/invoices/invoice.model";
import { AdministratorActionData } from "../../../workflow/administrator-action.hook";
import { useCallback } from "react";
import { FormProps } from "../../../components/form-item/framework/react/FormProvider";
import { FormItem } from "../../../components/form-item/framework/core/form-item.model";
import { ContactCustomerFormIds, useContactCustomerDialog } from "./ContactCustomerDialog";
import { SingleChoiceFormItem, TextAreaFormItem } from "../../../components/form-item/client/components";
import { DineroContactDocument } from "../../../finance/billing/pending-approval/dinero-contact.model";

export interface ContactCustomerDetailPageProps {
    task: ComplaintTaskDocument;
    contact: DineroContactDocument,
    invoices: InvoiceDocument[];
    administratorAction: (data: AdministratorActionData | string) => void;
    comments: CommentDocument[];
    addComment: (comment: string) => void;
    goBack: () => void;
}

export const ContactCustomerDetailPage = (props: ContactCustomerDetailPageProps) => {
    const { task, contact, invoices, administratorAction, comments, addComment } = props;

    const backButton = <BackButton onClick={props.goBack} />;

    const responseAdapter = useCallback<FormProps<FormItem>["onSubmit"]>((controller, item) => {
        const complaintStillRelevant = (controller.getItem(ContactCustomerFormIds.SingleChoiceStillRelevant, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 1;
        if ( !complaintStillRelevant ) {
            const reason = (controller.getItem(ContactCustomerFormIds.TextAreaCloseComplaintBecauseIrrelevantReason, item) as TextAreaFormItem).value;

            return administratorAction({
                action: "close complaint",
                complaintId: task.complaint.id,
                reason,
            })
        }

        const customerReached = (controller.getItem(ContactCustomerFormIds.SingleChoiceCustomerReached, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 1;
        if ( !customerReached ) {
            return administratorAction({
                action: "register did not reach customer for complaint",
                taskId: task.id,
            });
        }

        const hasMisunderstanding = (controller.getItem(ContactCustomerFormIds.SingleChoiceHasMisunderstanding, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 1;
        if ( hasMisunderstanding ) {
            const reason = (controller.getItem(ContactCustomerFormIds.TextAreaCloseComplaintDueToMisunderstandingReason, item) as TextAreaFormItem).value;

            return administratorAction({
                action: "close complaint",
                complaintId: task.complaint.id,
                reason,
            });
        }

        const customerComment = (controller.getItem(ContactCustomerFormIds.TextAreaCustomerComment, item) as TextAreaFormItem).value;

        const hasReasonableProposal = (controller.getItem(ContactCustomerFormIds.SingleChoiceCustomerProposal, item) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 0;
        if ( !hasReasonableProposal ) {
            return administratorAction({
                action: "register customer did not have proposal for complaint",
                taskId: task.id,
                customerComment,
            });
        }

        const customerProposal = (controller.getItem(ContactCustomerFormIds.TextAreaCustomerProposal, item) as TextAreaFormItem).value;
        return administratorAction({
            action: "register customer did have proposal for complaint",
            taskId: task.id,
            customerComment,
            customerProposal,
        });
    }, [administratorAction, task]);
    const openResponseDialog = useContactCustomerDialog(task, contact, responseAdapter);

    return (
        <>
            <TitleHeader button={backButton}>Kontakt kunde</TitleHeader>
            
            <ContentSection>
                <Container maxWidth="sm" disableGutters>

                    <Section>
                        {task.hasBeenPerformed ? <Message>Opgaven er allerede blevet udført</Message> : null}

                        <ComplaintDetailCard complaint={task.complaint} administratorAction={administratorAction} />

                        {invoices.map(invoice => (
                            <RegistrationDetailCard key={invoice.id} invoice={invoice} gardenerName={task.gardenerName} />
                        ))}

                        {!task.hasBeenPerformed ? (
                            <VerticalButtons>
                                <GreenButton onClick={openResponseDialog}>Besvar opgave</GreenButton>
                            </VerticalButtons>
                        ) : null}

                    </Section>

                    <ActivitySection onSave={addComment}>
                        <CustomerCommentList comments={comments} />
                    </ActivitySection>

                </Container>
            </ContentSection>
        </>
    );
};
