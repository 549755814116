import { useMemo } from "react";

import { PageFormItem, HorizontalButtonsSingleChoiceFormItem, InfoFormItem, NoticeFormItem, GroupFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { ComplaintDocument } from "../complaint.model";
import { GardenerDocument } from "../../gardener/gardener.model";

export interface GardenerReachedFormIds {
    SingleChoiceGardenerReached: string;
}

export type GardenerReachedForm<NextPage extends FormItem> = 
    PageFormItem<
        GroupFormItem<[
            NoticeFormItem,
            HorizontalButtonsSingleChoiceFormItem<[
                ConfirmationPage,
                NextPage,
            ]>,
        ]>
    >;

type ConfirmationPage = PageFormItem<InfoFormItem>;

export const useGardenerReachedForm = <T extends FormItem>(complaint: ComplaintDocument, gardener: GardenerDocument, nextPage: T, formIds: GardenerReachedFormIds): GardenerReachedForm<T> => {
    return useMemo(() => {
        const calledGardenerBefore = complaint.history.some(x => x.event === "called gardener");
        const confirmationPage: ConfirmationPage = !calledGardenerBefore ? {
            type: "page",
            title: "Udskyd opgave",
            item: {
                type: "info",
                render: "default",
                text: `
                    Opgaven udskydes til i morgen.<br />
                    <br />
                    Havemanden modtager en notifikation om, at vi har forsøgt at komme i kontakt med dem ift. deres reklamation, og at vi vil kontakte dem igen.
                `,
            },
        } : {
            type: "page",
            title: "Reklamationen overdrages til havemanden",
            item: {
                type: "info",
                render: "default",
                text: `
                    Opgaven afsluttes.<br />
                    <br />
                    Havemanden modtager en notifikation om, at vi har forsøgt at komme i kontakt med dem, men at det ikke er lykkedes og at de nu må finde en løsning sammen med kunden.<br />
                    <br />
                    Kunden modtager en notifikation om, at vi ikke kunne komme i kontakt med havemanden, men at vi har informeret havemanden skriftligt om reklamationen.
                `,
            },
        }

        return {
            type: "page",
            title: "Kontakt havemanden",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "notice",
                        render: "info",
                        html: `
                            Ring til <strong>${gardener.name}</strong> på <strong>${gardener.phoneNumber}</strong> for at diskutere reklamationen.<br />
                            <br />
                            Vi har brug for at vide, om havemanden vil acceptere kundens løsning eller om de vil tilbyde en anden løsning.
                        `,
                    },
                    {
                        id: formIds.SingleChoiceGardenerReached,
                        type: "single-choice",
                        mode: "switch page",
                        render: "horizontal-buttons",
                        questionText: "Fik du fat i havemanden?",
                        answerTexts: ["Nej", "Ja"],
                        answerItems: [
                            confirmationPage,
                            nextPage,
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                    },
                ],
            },
        };
    }, [complaint, gardener, nextPage, formIds]);
}
