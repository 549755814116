import { Redirect, useParams } from "react-router-dom";

import { LinearProgress } from "../components/LinearProgress";
import { ErrorPage } from "../ErrorPage";
import { Layout } from "../layout/Layout";
import { useTask } from "./tasks.hook";

export const RedirectToTask = () => {
    const { taskId } = useParams<{ taskId: string }>();

    const task = useTask(taskId);

    const url = (() => {
        switch ( task?.type ) {
            case "evaluate-gardener": return `/evaluate-gardener/gardeners/${taskId}`;
            case "adhoc": return `/workflow/adhoc/customers/${taskId}`;
            case "adhoc-gardener": return `/workflow/adhoc/gardeners/${taskId}`;
            case "complaint":
                if ( task.complaint.status === "contacting customer" ) return `/complaint/contact-customer/${taskId}`;
                if ( task.complaint.status === "contacting gardener" ) return `/complaint/contact-gardener/${taskId}`;
                if ( task.complaint.status === "determining proposal" ) return `/complaint/determine-proposal/${taskId}`;

                return undefined;
            case "onboarding":
                if ( task.application.status === "accepted" ) return `/onboarding/applicant/${task.id}`;
                if ( task.application.status === "checked" ) return `/onboarding/interview/${task.id}`;
        }
    })();

    if ( task === undefined ) return <LinearProgress />;
    if ( task === null ) return <Layout><ErrorPage message="Opgaven findes ikke" /></Layout>;
    if ( !url ) return <Layout><ErrorPage message="Opgavens type genkendes ikke" /></Layout>;

    return <Redirect to={url} />;
};
