import { makeStyles } from "@material-ui/core";
import moment from "moment";

import { Card } from "../../../components/card/Card";
import { CardHeader } from "../../../components/card/CardHeader";
import { CardContent } from "../../../components/card";
import { OnboardingTaskDocument } from "../../../task/task.model";
import { MenuButton } from "../../../components/menu/MenuButton";
import { ChangeTimeMenuItem } from "../../../task/change-time/ChangeTimeMenuItem";
import { AdministratorActionData } from "../../../workflow/administrator-action.hook";
import { useCallback } from "react";
import { ChangeTimeDialogProps } from "../../../task/change-time/ChangeTimeDialog";

const useStyles = makeStyles({
    group: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },

        "& > span": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
            lineHeight: "125%",
            whiteSpace: "pre-wrap",
        },
    },
    order: {
        "& > *": {
            marginBottom: "16px",
        },

        "& > *:last-child": {
            marginBottom: 0,
        }
    },
});

interface InterviewTaskDetailCardProps {
    task: OnboardingTaskDocument;
    administratorAction: (data: AdministratorActionData | string) => void;
}

export const InterviewTaskDetailCard = (props: InterviewTaskDetailCardProps) => {
    const classes = useStyles();
    const { task, administratorAction } = props;

    const changeTimeAdapter: ChangeTimeDialogProps["onConfirm"] = useCallback((data) => {
        administratorAction({
            action: "reschedule call about application",
            taskId: task.id,
            newDate: data.dueDate,
            reason: data.reason,
        });
    }, [administratorAction, task.id]);

    const menuButton = !task.hasBeenPerformed ? (
        <MenuButton>
            <ChangeTimeMenuItem taskId={task.id} adapter={changeTimeAdapter} />
        </MenuButton>
    ) : undefined;

    return (
        <Card>
            <CardHeader button={menuButton}>Ansøgning fra {task.gardenerName}</CardHeader>

            <CardContent className={classes.order}>

                <div className={classes.group}>
                    <label>Hvad er der hændt?</label>
                    <span>{task.gardenerName} har ansøgt om at blive havemand ved Go Go Garden.</span>
                </div>

                <div className={classes.group}>
                    <label>Hvad skal der gøres?</label>
                    <span>Besvar opgaven for at ringe ansøgeren op.</span>
                </div>

                <div className={classes.group}>
                    <label>Hvornår skal det udføres?</label>
                    <span>{moment(task.performAfterDate).format("D MMMM YYYY HH:mm")}</span>
                </div>

            </CardContent>

        </Card>
    );
};
