import { PropsWithChildren } from "react";

import { makeStyles, Paper } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    root: {
        padding: "24px",

        [theme.breakpoints.down(500)]: {
            padding: "16px",
        },
    },
    header: {
        marginBottom: "20px", // hack: 24px, but 4 is added from content

        [theme.breakpoints.down(500)]: {
            marginBottom: "12px", // hack: 16px, but 4 is added from content
        },
    },
    title: {
        display: "inline-block",
        fontSize: "16px",
        fontWeight: 600,
        color: "#4A4A4A",
        marginRight: "12px",
        marginBottom: "4px",
    },
    date: {
        display: "inline-block",
        fontSize: "14px",
        fontWeight: 500,
        color: "#818181",
        marginBottom: "4px",
    },
    author: {
        display: "block",
        fontSize: "14px",
        fontWeight: 400,
        color: "#818181",
        marginBottom: "4px",
    },
}));

interface CommentProps {
    date: string;
    title: string;
    author?: string;
}

export const Comment = (props: PropsWithChildren<CommentProps>) => {
    const classes = useStyles();

    const { date, title, author, children } = props;

    return (
        <Paper className={classes.root}>

            <div className={classes.header}>
                <span className={classes.title}>{title}</span>
                <span className={classes.date}>{formatDate(date)}</span>
                {author ? <span className={classes.author}>{author}</span> : null}
            </div>

            {children}
        </Paper>
    );
};

function formatDate(date: string): string {
    return moment(date).format("D MMMM YYYY HH:mm");
}
