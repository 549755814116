import { Dispatch, SetStateAction, useCallback } from "react";

import { Collapse } from "@material-ui/core";

import { Label } from "../../components/form/Label";
import { Group } from "../../components/form/Group";
import { AlternativeHint } from "../../components/form/Hint";
import { TextField } from "../../components/form/InputField";
import { UpdateCustomerDialogErrorModel, UpdateCustomerDialogViewModel } from "./UpdateCustomerDialog";

export interface CompanySubSectionProps {
    viewModel: UpdateCustomerDialogViewModel;
    setViewModel: Dispatch<SetStateAction<UpdateCustomerDialogViewModel>>;
    errorModel: UpdateCustomerDialogErrorModel;
    validationAnimationDuration: number;
}

export const CompanySubSection = (props: CompanySubSectionProps) => {
    const { viewModel, setViewModel, errorModel, validationAnimationDuration } = props;

    const changeCvr = useCallback((value: string) => {
        setViewModel(viewModel => ({
            ...viewModel,
            billing: {
                ...viewModel.billing,
                company: {
                    ...viewModel.billing.company,
                    cvr: value,
                },
            }
        }));
    }, [setViewModel])

    const markCvrDirty = useCallback(() => {
        setViewModel(viewModel => ({
            ...viewModel,
            billing: {
                ...viewModel.billing,
                company: {
                    ...viewModel.billing.company,
                    cvrDirty: true,
                },
            }
        }));
    }, [setViewModel]);

    const changeAtt = useCallback((value: string) => {
        setViewModel(viewModel => ({
            ...viewModel,
            billing: {
                ...viewModel.billing,
                company: {
                    ...viewModel.billing.company,
                    att: value,
                },
            }
        }));
    }, [setViewModel])

    const markAttDirty = useCallback(() => {
        setViewModel(viewModel => ({
            ...viewModel,
            billing: {
                ...viewModel.billing,
                company: {
                    ...viewModel.billing.company,
                    attDirty: true,
                },
            }
        }));
    }, [setViewModel]);

    const changeEan = useCallback((value: string) => {
        setViewModel(viewModel => ({
            ...viewModel,
            billing: {
                ...viewModel.billing,
                company: {
                    ...viewModel.billing.company,
                    ean: value,
                },
            }
        }));
    }, [setViewModel])

    const markEanDirty = useCallback(() => {
        setViewModel(viewModel => ({
            ...viewModel,
            billing: {
                ...viewModel.billing,
                company: {
                    ...viewModel.billing.company,
                    eanDirty: true,
                },
            }
        }));
    }, [setViewModel]);

    return (
        <>
            <div id="billing-company-cvr-section">
                <Label htmlFor="billing-company-cvr" style={{ marginBottom: "8px" }}>CVR-nummer</Label>
                <Group error={Boolean(viewModel.billing.company.cvrDirty && errorModel.billingCompanyCvr)}>
                    <TextField id="billing-company-cvr" value={viewModel.billing.company.cvr} onChange={changeCvr} onBlur={markCvrDirty} />

                    <Collapse in={Boolean(viewModel.billing.company.cvrDirty && errorModel.billingCompanyCvr)} timeout={validationAnimationDuration}>
                        <AlternativeHint message={errorModel.billingCompanyCvr} />
                    </Collapse>
                </Group>
            </div>

            <div id="billing-company-att-section">
                <Label htmlFor="billing-company-att" style={{ marginBottom: "8px" }}>ATT</Label>
                <Group error={Boolean(viewModel.billing.company.attDirty && errorModel.billingCompanyAtt?.severity === "error")}>
                    <TextField id="billing-company-att" value={viewModel.billing.company.att} onChange={changeAtt} onBlur={markAttDirty} />

                    <Collapse in={Boolean((viewModel.billing.company.attDirty && errorModel.billingCompanyAtt) || errorModel.billingCompanyAtt?.severity === "warning")} timeout={validationAnimationDuration}>
                        <AlternativeHint severity={errorModel.billingCompanyAtt?.severity} message={errorModel.billingCompanyAtt?.message} />
                    </Collapse>
                </Group>
            </div>

            <div id="billing-company-ean-section">
                <Label htmlFor="billing-company-ean" style={{ marginBottom: "8px" }}>EAN</Label>
                <Group error={Boolean(viewModel.billing.company.eanDirty && errorModel.billingCompanyEan)}>
                    <TextField id="billing-company-ean" value={viewModel.billing.company.ean} onChange={changeEan} onBlur={markEanDirty} />

                    <Collapse in={Boolean(viewModel.billing.company.eanDirty && errorModel.billingCompanyEan)} timeout={validationAnimationDuration}>
                        <AlternativeHint message={errorModel.billingCompanyEan} />
                    </Collapse>
                </Group>
            </div>
        </>
    );
};
