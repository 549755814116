import { useEffect, useState } from "react";

import firebase from "firebase/compat/app";

import { CustomerPriceDocument } from "./customer-price.model";

export const useCustomerPrice = (customerId: string | null | undefined, date: string | null | undefined) => {
    const [state, setState] = useState<CustomerPriceDocument | null>();

    useEffect(() => {
        if ( customerId === null || date === null ) return setState(null);
        if ( customerId === undefined || date === undefined ) return setState(undefined);

        setState(undefined);

        return firebase.firestore()
            .collection("customerPrices")
                .where("customerId", "==", customerId)
                .where("effectiveDate", "<=", date)
            .orderBy("effectiveDate", "desc")
            .limit(1)
            .onSnapshot(snapshot => {
                if ( snapshot.empty ) return setState(null);

                const document = snapshot.docs[0];

                setState({ id: document.id, ...document.data() } as CustomerPriceDocument);
            });
    }, [customerId, date]);

    return state;
};
