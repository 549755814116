import { makeStyles } from "@material-ui/core";

import { Comment } from "../components/comment/Comment";
import { CommentBody } from "../components/comment/CommentBody";
import { LinkButton } from "../components/LinkButton";
import { CustomerTrelloCommentDocument, TrelloCommentDocument } from "./comment.model";
import { useDeleteComment } from "./delete-comment.hook";

const useStyles = makeStyles(theme => ({
    actions: {
        padding: "0px 12px",

        [theme.breakpoints.down(500)]: {
            padding: "0px 4px",
        },
    },
}));

interface TrelloCommentProps {
    comment: TrelloCommentDocument | CustomerTrelloCommentDocument;
}

export const TrelloComment = (props: TrelloCommentProps) => {
    const classes = useStyles();

    const { comment } = props;

    const deleteComment = useDeleteComment(comment.id);

    return (
        <div>
            <Comment date={comment.creationDate.toDate().toISOString()} title="Kommentar importeret fra Trello">
                <CommentBody>{comment.text}</CommentBody>
            </Comment>

            <div className={classes.actions}>
                <LinkButton onClick={deleteComment}>Slet</LinkButton>
            </div>
        </div>
    );
};
