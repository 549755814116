import { Grid, makeStyles } from "@material-ui/core";
import { PropsWithChildren, useMemo } from "react";

const useStyles = makeStyles({
    root: {
        boxSizing: "content-box",
    },
});

export interface ResponsiveGridItemProps {
    units: number;
}

export const ResponsiveGridItem = (props: PropsWithChildren<ResponsiveGridItemProps>) => {
    const classes = useStyles();

    const { units, children } = props;

    const styles = useMemo(() => {
        return { flexGrow: units, flexBasis: `${288 * units}px` };
    }, [units]);

    return (
        <Grid item className={classes.root} style={styles}>
            {children}
        </Grid>
    );
};
