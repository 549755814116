import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

import moment from "moment";

import { TaskDocument } from "../../task/task.model";
import { useEvaluateGardenerTasks } from "../../workflow/follow-up/appointment/TaskProvider";
import { GardenersTab } from "./GardenersTab";
import { useIsSuperAdministrator } from "../../LinkProvider";

export const IntegratedGardeners = () => {
    const history = useHistory();
    const goToTask = useCallback((task: TaskDocument) => {
        history.push(`/evaluate-gardener/gardeners/${task.id}`);
    }, [history]);

    const now = moment().toISOString(true);

    const isSuperAdministrator = useIsSuperAdministrator();

    const tasks = useEvaluateGardenerTasks();
    const currentTasks = useMemo(() => tasks.filter(task => !task.specialPartnership || isSuperAdministrator).filter(task => task.performAfterDate <= now), [tasks, now, isSuperAdministrator]);
    const futureTasks = useMemo(() => tasks.filter(task => !task.specialPartnership || isSuperAdministrator).filter(task => task.performAfterDate > now), [tasks, now, isSuperAdministrator]);

    return (
        <GardenersTab
            currentTasks={currentTasks}
            futureTasks={futureTasks}
            goToTask={goToTask}
        />
    );
};
