import { Section } from "../section/Section";
import { SectionHeader } from "../section/SectionHeader";
import { ListSectionItem, ListSectionItemProps } from "./ListSectionItem";
import { Message } from "../../Message";
import { List } from "../list/List";

export interface ListSectionProps {
    title: string;
    items: Omit<ListSectionItemProps, "index" | "onClick">[];
    placeholder: string;
    onClick: (index: number) => void;
}

export const ListSection = (props: ListSectionProps) => {
    const { title, items, placeholder, onClick } = props;

    return (
        <Section>
            <SectionHeader>{title}</SectionHeader>

            {items.length > 0 ? (
                <List>
                    {items.map((item, index) => (
                        <ListSectionItem key={index} heading={item.heading} subheading={item.subheading} index={index} onClick={onClick} />
                    ))}
                </List>
            ) : (
                <Message>{placeholder}</Message>
            )}

        </Section>
    );
};
