import { Link } from "react-router-dom";

import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { UnorderedList } from "../../components/unordered-list/UnorderedList";
import { GardeningTaskUpdatedCommentDocument } from "../comment.model";

interface GardeningTaskUpdatedCommentProps {
    comment: GardeningTaskUpdatedCommentDocument;
}

export const GardeningTaskUpdatedComment = (props: GardeningTaskUpdatedCommentProps) => {
    const { comment } = props;

    const task = <Link to={`/customers/context/${comment.customerId}/gardening-tasks/${comment.gardeningTaskId}`}>Denne opgave</Link>
    const customer = <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link>;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Opgave ændret" author={comment.administratorName}>
            <CommentBody>
                {task} fra kunden {customer} er blevet ændret:<br />
                <br />

                <UnorderedList>
                    {comment.changes.map((change, index) => (
                        <li key={index}>{change}</li>
                    ))}
                </UnorderedList>

            </CommentBody>
        </Comment>
    );
};
