import { useCallback, useMemo } from "react";
import { Redirect, useParams } from "react-router-dom";

import { CustomerDetailPage } from "./CustomerDetailPage";
import { useAdministratorAction } from "../workflow/administrator-action.hook";
import { useCustomer } from "./customers.hook";
import { LinearProgress } from "../components/LinearProgress";
import { NotFoundPage } from "../NotFoundPage";
import { useDineroContact } from "../finance/billing/pending-approval/dinero-contacts.hook";
import { useCustomerComments } from "../comment/comments.hook";
import { useReactivateCustomerAction } from "./reactivate-customer/reactivate-customer-action.hook";
import { useCreateTaskAction } from "../task/task-dialog/create-task.hook";
import { useUpdateCustomerAction } from "./update-customer/update-customer-action.hook";
import { useAddCustomerComment } from "../comment/add-comment.hook";
import { useGardeningTasksByCustomerId } from "../gardening-task/gardening-task.hook";
import { CustomerTab, useNavigation } from "../navigation.hook";
import { GardeningTaskDocument } from "../gardening-task/gardening-task.model";
import { useGardenersById } from "../gardener/gardeners.hook";

export const IntegratedCustomerDetailPage = () => {
    const { tab, customerId } = useParams<{ tab: CustomerTab, customerId: string }>();

    const { goToCustomerList, goToCustomerGardeningTask } = useNavigation();

    const goBack = useCallback(() => {
        goToCustomerList(tab);
    }, [goToCustomerList, tab]);

    const administratorAction = useAdministratorAction();
    const customer = useCustomer(customerId);
    const contact = useDineroContact(customerId);
    const gardeningTasks = useGardeningTasksByCustomerId(customerId);
    const comments = useCustomerComments(customerId);

    const gardenerIds = useMemo(() => {
        if ( !gardeningTasks ) return undefined;

        const possiblyDuplicatedGardenerIds = gardeningTasks.reduce((total, gardeningTask) => {
            if ( !gardeningTask.matchmaking.selectedGardenerId ) return total;
    
            return [...total, gardeningTask.matchmaking.selectedGardenerId];
        }, [] as string[]);

        return Array.from(new Set(possiblyDuplicatedGardenerIds));
    }, [gardeningTasks])
    const gardeners = useGardenersById(gardenerIds);

    const addComment = useAddCustomerComment(customerId);
    const createTaskAction = useCreateTaskAction(customer);
    const reactivateCustomerAction = useReactivateCustomerAction(customerId);
    const updateCustomerAction = useUpdateCustomerAction(customerId);
    
    const onSelectGardeningTask = useCallback((gardeningTask: GardeningTaskDocument) => {
        goToCustomerGardeningTask(tab, customerId, gardeningTask.id);
    }, [goToCustomerGardeningTask, tab, customerId]);

    if ( tab !== "all" && tab !== "deleted" ) return <Redirect to="/customers/all" />;

    if ( customer === null ) return <NotFoundPage />;
    if ( customer === undefined || contact === undefined || gardeningTasks === undefined || gardeners === undefined || comments === undefined ) return <LinearProgress />;

    return (
        <CustomerDetailPage
            customer={customer}
            contact={contact}
            gardeningTasks={gardeningTasks}
            gardeners={gardeners}
            comments={comments}
            addComment={addComment}
            administratorAction={administratorAction}
            createTaskAction={createTaskAction}
            reactivateCustomerAction={reactivateCustomerAction}
            updateCustomerAction={updateCustomerAction}
            goBack={goBack}
            onSelectGardeningTask={onSelectGardeningTask}
        />
    );
};
