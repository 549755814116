import { useCallback } from "react";
import { useHistory, useRouteMatch } from "react-router";

import { ListItem, ListItemText, makeStyles } from "@material-ui/core";
import moment from "moment";

import { TaskDocument } from "../../task/task.model";

const useStyles = makeStyles({
    listItem: {
        padding: "12px 16px",

        "&:hover": {
            backgroundColor: "#EBF3EA",
        },

        "& > .MuiTouchRipple-root": {
            color: "#1C5B13",
        },
    },
    listItemText: {
        margin: 0,

        "& > span": {
            fontSize: "16px",
            fontWeight: 500,
            color: "#4A4A4A",
        },

        "& > p": {
            fontSize: "14px",
            fontWeight: 400,
            color: "#818181",
        },
    },
});

interface FollowUpListItemProps<T extends TaskDocument> {
    task: T;
    formatPrimaryText: (task: T) => string;
}

export const FollowUpListItem = <T extends TaskDocument>(props: FollowUpListItemProps<T>) => {
    const classes = useStyles();

    const { task, formatPrimaryText } = props;

    const history = useHistory();
    const { path } = useRouteMatch();

    const goToTaskPage = useCallback(() => {
        history.push(`${path}/${task.id}`);
    }, [history, path, task]);

    const primaryText = formatPrimaryText(task);
    const secondary = `Skal udføres ${moment(task.performAfterDate).format("D MMMM HH:mm")}`;

    return (
        <ListItem button className={classes.listItem} onClick={goToTaskPage}>
            <ListItemText primary={primaryText} secondary={secondary} className={classes.listItemText} />
        </ListItem>
    );
};
