import { FormGroup } from "@material-ui/core";
import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from "react";

import { NeutralAction } from "../../components/card/NeutralAction";
import { Dialog, DialogActions, DialogContent, DialogHeader, useDialog } from "../../components/dialog";
import { Label } from "../../components/form/Label";
import { newReasonInputContext, ReasonInput, ReasonInputContext, reasonInputHasChanged, validateReasonInput } from "./ReasonInput";
import { SubmitButton } from "./SubmitButton";

const Context = createContext<{ form: UnpauseGardenerFormContext, setForm: Dispatch<SetStateAction<UnpauseGardenerFormContext>> }>(undefined as any);

interface UnpauseGardenerDialogProps {
    onConfirm: (form: UnpauseGardenerFormContext) => void;
}

export const UnpauseGardenerDialog = (props: UnpauseGardenerDialogProps) => {
    const { onConfirm } = props;
    const { closeDialog } = useDialog();

    const [form, setForm] = useState(newFormContext());
    const value = useMemo(() => ({ form, setForm }), [form, setForm]);

    return (
        <Context.Provider value={value}>
            <Dialog>
                <DialogHeader>Genaktiver havemanden?</DialogHeader>

                <DialogContent>

                    <div>Havemanden vil igen blive tilbudt opgaver.</div>

                    <FormGroup id="reason-section">
                        <Label htmlFor="reason" style={{ marginBottom: "8px" }}>Årsag til genaktivering</Label>
                        <ReasonInput id="reason" />
                    </FormGroup>

                </DialogContent>

                <DialogActions>
                    <NeutralAction onClick={closeDialog}>Annuller</NeutralAction>
                    <SubmitButton onSubmit={onConfirm} />
                </DialogActions>

            </Dialog>
        </Context.Provider>
    );
};

export const useUnpauseGardenerForm = () => {
    return useContext(Context);
};

export interface UnpauseGardenerFormContext {
    validationAnimationDuration: number;
    reasonInput: ReasonInputContext;
}

export const newFormContext = (): UnpauseGardenerFormContext => {
    return {
        validationAnimationDuration: 200,
        reasonInput: newReasonInputContext(),
    };
};

export const unpauseGardenerFormHasChanged = (form: UnpauseGardenerFormContext) => {
    return  reasonInputHasChanged(form);
};

export const validateUnpauseGardenerForm = (form: UnpauseGardenerFormContext) => {
    return validateReasonInput(form);
};

export const scrollToFirstError = (form: UnpauseGardenerFormContext) => {
    const sectionId = (() => {
        if ( validateReasonInput(form) ) return "reason-section"

        return undefined;
    })();

    if ( !sectionId ) return;

    const section = document.getElementById(sectionId);
    if ( !section ) return;

    const header = document.getElementsByTagName("header").length === 1 ? document.getElementsByTagName("header").item(0) : null;
    const headerOffset = header?.getBoundingClientRect().height ?? 0;

    const offsetPosition = window.scrollY + section.getBoundingClientRect().top - headerOffset;

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
}
