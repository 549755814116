import moment from "moment";

import { Comment } from "../components/comment/Comment";
import { CommentBody } from "../components/comment/CommentBody";
import { FollowUpCustomerCommentDocument, FollowUpGardenerCommentDocument } from "./comment.model";

interface FollowUpCommentProps {
    comment: FollowUpCustomerCommentDocument | FollowUpGardenerCommentDocument;
}

export const FollowUpComment = (props: FollowUpCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Opfølgning planlagt" author={comment.administratorName}>
            <CommentBody>
                Hvad er der hændt?<br />
                {comment.whatHasHappened}<br /><br />

                Hvad skal der gøres?<br />
                {comment.whatShouldBeDone}<br /><br />
                
                Hvornår skal det udføres?<br />
                {moment(comment.performAfterDate).format("D MMMM HH:mm")}
            </CommentBody>
        </Comment>
    );
};
