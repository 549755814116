import { InfoFormItem, PageFormItem, useFormDialog } from "../../../components/form-item/client/components";
import { FormProps } from "../../../components/form-item/framework/react/FormProvider";
import { OnboardingTaskDocument } from "../../../task/task.model";
import { GardenerDocument } from "../../../gardener/gardener.model";
import { ContactApplicantForm, useContactApplicantForm } from "../../shared/ContactApplicantForm";
import { CanInterviewForm, useCanInterviewForm } from "../../shared/CanInterviewForm";
import { GetCprForm, useGetCprForm } from "../../shared/GetCprForm";
import { ExplainConceptForm, useExplainConceptForm } from "../../shared/ExplainConceptForm";
import { CheckNameForm, useCheckNameForm } from "../../shared/CheckNameForm";
import { useAskApplicantForm } from "../../shared/AskApplicantForm";

export enum InterviewResponseFormIds {
    SingleChoiceReachedApplicant = "SingleChoiceReachedApplicant",
    SingleChoiceStillInterested = "SingleChoiceStillInterested",
    SingleChoiceCanTalkNow = "SingleChoiceCanTalkNow",
    SingleChoiceFluentInDanish = "SingleChoiceFluentInDanish",
    SingleChoiceWillOnboardApplicant = "SingleChoiceWillOnboardApplicant",

    TextAreaName = "TextAreaName",

    SingleChoiceWillGiveCpr = "SingleChoiceWillGiveCpr",
    TextAreaCpr = "TextAreaCpr",
}

export type InterviewResponseForm =
    ContactApplicantForm<
        CanInterviewForm<
            ExplainConceptForm<
                CheckNameForm<
                    GetCprForm<
                        ConfirmationPage
                    >
                >
            >
        >
    >

type ConfirmationPage = PageFormItem<InfoFormItem>

export const useInterviewResponseDialog = (gardener: GardenerDocument, task: OnboardingTaskDocument, onConfirm: FormProps<InterviewResponseForm>["onSubmit"]) => {
    
    const confirmationPage: ConfirmationPage = {
        type: "page",
        title: "Godkend ansøgning",
        item: {
            type: "info",
            render: "default",
            text: `
                Ansøgningen bliver godkendt.<br />
                <br />
                Ansøgeren modtager en velkomstmail og vil herefter kunne tilbydes opgaver.
            `,
        },
    };

    const getCprForm = useGetCprForm(gardener, confirmationPage, {
        SingleChoiceWillGiveCpr: InterviewResponseFormIds.SingleChoiceWillGiveCpr,
        TextAreaCpr: InterviewResponseFormIds.TextAreaCpr,
    });

    const checkNameForm = useCheckNameForm(gardener, getCprForm, {
        TextAreaName: InterviewResponseFormIds.TextAreaName,
    });

    const explainConceptForm = useExplainConceptForm(checkNameForm);

    const askApplicantForm = useAskApplicantForm(explainConceptForm, {
        SingleChoiceWillOnboardApplicant: InterviewResponseFormIds.SingleChoiceWillOnboardApplicant,
    });

    const canInterviewForm = useCanInterviewForm(askApplicantForm, {
        SingleChoiceStillInterested: InterviewResponseFormIds.SingleChoiceStillInterested,
        SingleChoiceCanTalkNow: InterviewResponseFormIds.SingleChoiceCanTalkNow,
        SingleChoiceFluentInDanish: InterviewResponseFormIds.SingleChoiceFluentInDanish,
    });

    const contactApplicantForm = useContactApplicantForm(gardener, task, canInterviewForm, {
        SingleChoiceReachedApplicant: InterviewResponseFormIds.SingleChoiceReachedApplicant,
    });

    return useFormDialog(contactApplicantForm, onConfirm);
};
