import { makeStyles } from "@material-ui/core";
import { PropsWithChildren } from "react";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#D0E6CC",
    },
    title: {
        flexGrow: 1,
        padding: "16px",
        fontSize: "16px",
        fontWeight: 600,
        letterSpacing: "0.75px",
        color: "#4A4A4A",
        margin: 0,

        textAlign: "center",

        [theme.breakpoints.down(500)]: {
            textAlign: "left",
        },
    },
    dummySlot: {
        width: "48px",
        height: "48px",
        visibility: "hidden",

        [theme.breakpoints.down(500)]: {
            display: "none",
        },
    },
    buttonSlot: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "48px",
        height: "48px",
    },
}));

interface CardHeaderProps {
    button?: JSX.Element;
}

export const CardHeader = (props: PropsWithChildren<CardHeaderProps>) => {
    const classes = useStyles();

    const { button, children } = props;

    return (
        <div className={classes.root}>
            {button ? <div className={classes.dummySlot} /> : null}

            <h3 className={classes.title}>
                {children}
            </h3>

            {button ? <div className={classes.buttonSlot}>{button}</div> : null}
        </div>
    );
};
