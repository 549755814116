import { useCallback, useMemo, useState } from "react";

import { SearchResponse } from "@algolia/client-search";
import { Container, makeStyles } from "@material-ui/core";

import { ContentSection } from "../../../layout/ContentSection";
import { CustomerDocument } from "../../customer.model";
import { Section } from "../../../components/section";
import { SectionHeader } from "../../../components/section/SectionHeader";
import { Message } from "../../../Message";
import { SearchField } from "../../../components/form/InputField";
import { useCustomerSearch } from "./customer-search.hook";
import { useSearchClient } from "../../../search-client.hook";
import { ListItem } from "./ListItem";
import { useLastValue } from "../../../last-value.hook";
import { FloatingButton } from "../../../components/floating-button/FloatingButton";
import { GreenButton, VerticalButtons } from "../../../components/new-button";
import { CreateCustomerDialogProps, useCreateCustomerDialog } from "../../create-customer/CreateCustomerDialog";
import { AdministratorActionData, useAdministratorAction } from "../../../workflow/administrator-action.hook";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
    list: {
        "& > *": {
            marginBottom: "32px",
        },

        "& > *:last-child": {
            marginBottom: 0,
        },
    },
});

export const CustomersPageAllTab = () => {
    const classes = useStyles();

    const searchClient = useSearchClient();
    const [searchTerm, setSearchTerm] = useState("");

    const searchResults = useCustomerSearch(searchClient, searchTerm);
    const lastSearchResults = useLastValue(searchResults);

    const resultSections = useSortAndSplit(lastSearchResults);

    const history = useHistory();
    const goToCustomer = useCallback((action: AdministratorActionData["action"], result: string) => {
        if ( action === "create customer" ) return history.push(`/customers/all/${result}`);
    }, [history]);
    const administratorAction = useAdministratorAction(goToCustomer);

    const createCustomerAdapter = useCallback<CreateCustomerDialogProps["onConfirm"]>((viewModel) => {
        administratorAction({
            action: "create customer",
            name: viewModel.name,
            email: viewModel.email,
            phoneNumber: viewModel.phoneNumber,
            externalAddressId: viewModel.addressSearch.address!.externalId,
        });
    }, [administratorAction]);

    const openCreateCustomerDialog = useCreateCustomerDialog({
        name: "",
        nameDirty: false,
        email: "",
        emailDirty: false,
        phoneNumber: "",
        phoneNumberDirty: false,
        addressSearch: {
            searchTerm: "",
            address: undefined,
        },
        addressSearchDirty: false,
    }, createCustomerAdapter);

    return (
        <ContentSection>
            <Container maxWidth="sm" disableGutters className={classes.list}>

                <SearchField fullWidth value={searchTerm} onChange={setSearchTerm} placeholder="Søg" autoFocus />

                {lastSearchResults?.hits.length === 0 ? (
                    <Message>Ingen kunder matchede din søgning</Message>
                ) : null}

                {resultSections.map(section => {
                    if ( section.results.length === 0 ) return null;

                    return (
                        <Section key={section.title}>
                            <SectionHeader>{section.title}</SectionHeader>

                            {section.results.map(customer => (
                                <ListItem key={customer.id} customer={customer} />
                            ))}
                        </Section>
                    );
                })}

                <FloatingButton>
                    <VerticalButtons>
                        <GreenButton onClick={openCreateCustomerDialog}>Opret kunde</GreenButton>
                    </VerticalButtons>
                </FloatingButton>

            </Container>
        </ContentSection>
    );
};

function useSortAndSplit(searchResults: SearchResponse<CustomerDocument> | null | undefined) {
    return useMemo(() => {
        const customers = searchResults?.hits || [];
        if ( customers.length === 0 ) return [];

        const activeCustomerSection = {
            title: "Aktive kunder",
            results: customers.filter(customer => customer.status !== "deleted"),
        };

        const deletedCustomerSection = {
            title: "Slettede kunder",
            results: customers.filter(customer => customer.status === "deleted"),
        };

        return customers[0].status !== "deleted" ? [activeCustomerSection, deletedCustomerSection] : [deletedCustomerSection, activeCustomerSection];
    }, [searchResults]);
}
