import { Link } from "react-router-dom";

import moment from "moment";

import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { ChangeTimeOfTaskCommentDocument } from "../comment.model";
import { formatTask } from "../../shared/utility";

interface ChangeTimeOfTaskCommentProps {
    comment: ChangeTimeOfTaskCommentDocument;
}

export const ChangeTimeOfTaskComment = (props: ChangeTimeOfTaskCommentProps) => {
    const { comment } = props;

    const line = <>Tidspunktet for udførsel af <Link to={`/tasks/${comment.taskId}`}>{formatTask(comment.taskType)}</Link> er ændret fra {formatDate(comment.oldDate)} til {formatDate(comment.newDate)}.</>;

    if ( !comment.reason ) {
        return (
            <Comment date={comment.creationDate.toDate().toISOString()} title="Tidspunkt for opgave ændret" author={comment.administratorName ?? undefined}>
                <CommentBody>
                    {line}
                </CommentBody>
            </Comment>
        );
    }

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Tidspunkt for opgave ændret" author={comment.administratorName ?? undefined}>
            <CommentBody>
                {line}<br />
                <br />
                Årsag til udskydelse:<br />
                {comment.reason}
            </CommentBody>
        </Comment>
    );
};

const formatDate = (date: string): string => {
    const dayOfWeek = moment(date).format("dddd");
    const dayAndMonth = moment(date).format("D MMMM");
    const time = moment(date).format("HH:mm");

    return `${dayOfWeek} d. ${dayAndMonth} kl. ${time}`;
}
