import { makeStyles } from "@material-ui/core";

import { useLinks } from "../LinkProvider";
import { Sidebar } from "./Sidebar";

const useStyles = makeStyles({
    heading: {
        padding: "32px 24px 12px",
        fontSize: "16px",
        fontWeight: 400,
        fontVariant: "small-caps",
        color: "#1C5B13",
    },
    navigation: {
        "& > a": {
            display: "block",
            padding: "16px 24px",
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
            textDecoration: "none",

            "&:hover": {
                backgroundColor: "#D0E6CC",
            },

            "&.active": {
                backgroundColor: "#D0E6CC",
                fontWeight: 500,
            },
        },
    },
});

export const SideNavigation = () => {
    const classes = useStyles();

    const { insightLinks, workflowLinks } = useLinks();

    return (
        <Sidebar>
            <nav className={classes.navigation}>

                <div className={classes.heading}>indblik</div>
                {insightLinks}

                <div className={classes.heading}>workflows</div>
                {workflowLinks}

            </nav>
        </Sidebar>
    );
};
