import { useCallback, useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch, useHistory } from "react-router-dom";

import { Layout } from "./layout/Layout";
import { LinearProgress } from "./components/LinearProgress";
import { NotFoundPage } from "./NotFoundPage";
import { SignOut } from "./user/SignOut";
import { RedirectToLogin } from "./user/RedirectToLogin";
import { UserDocument } from "./user/user.model";
import { SignIn } from "./user/SignIn";
import { CustomerListPage } from "./customer/list/CustomerListPage";
import { GardenerListPage } from "./gardener/list/GardenerListPage";
import { useUser } from "./user/UserProvider";
import { LinkProvider } from "./LinkProvider";
import { AdhocFollowUpOverviewPage } from "./workflow/follow-up/adhoc/AdhocFollowUpOverviewPage";
import { AdhocCustomerFollowUpDetailPage } from "./workflow/follow-up/adhoc/customer/AdhocCustomerFollowUpDetailPage";
import { TaskProvider } from "./workflow/follow-up/appointment/TaskProvider";
import { IntegratedComplaintOverviewPage } from "./complaint/overview/IntegratedComplaintOverviewPage";
import { BillingTaskProvider } from "./finance/billing/BillingTaskProvider";
import { RedirectToCustomerWithAppropriateContext } from "./customer/RedirectToCustomerWithAppropriateContext";
import { DashboardPage } from "./dashboard/DashboardPage";
import { AdhocGardenerFollowUpDetailPage } from "./workflow/follow-up/adhoc/gardener/AdhocGardenerFollowUpDetailPage";
import { RedirectToTask } from "./task/RedirectToTask";
import { PaymentCollectionOverviewPage } from "./finance/billing/payment-collection/PaymentCollectionOverviewPage";
import { CallBeforeInvoiceReminderFee1DetailPage } from "./finance/billing/payment-collection/call-before-invoice-reminder-fee-1/CallBeforeInvoiceReminderFee1DetailPage";
import { CallBeforeInvoiceDebtCollectionDetailPage } from "./finance/billing/payment-collection/call-before-invoice-debt-collection/CallBeforeInvoiceDebtCollectionDetailPage";
import { IntegratedInvoiceOverviewPage } from "./invoice/overview/IntegratedInvoiceOverviewPage";
import { IntegratedInvoiceDetailPage } from "./invoice/detail/IntegratedInvoiceDetailPage";
import { IntegratedCreditNoteDetailPage } from "./invoice/detail/IntegratedCreditNoteDetailPage";
import { IntegratedEvaluateGardenerOverviewPage } from "./evaluate-gardener/overview/IntegratedEvaluateGardenerOverviewPage";
import { IntegratedEvaluateGardenerDetailPage } from "./evaluate-gardener/detail/IntegratedEvaluateGardenerDetailPage";
import { IntegratedComplaintDetailPage } from "./complaint/detail/IntegratedComplaintDetailPage";
import { TakePaymentDetailPage } from "./finance/billing/payment-collection/take-payment/TakePaymentDetailPage";
import { IntegratedGardenerPage } from "./gardener/IntegratedGardenerPage";
import { RedirectToGardenerWithAppropriateContext } from "./customer/RedirectToGardenerWithAppropriateContext";
import { PaymentCollectionDetailPage } from "./finance/billing/payment-collection/detail/PaymentCollectionDetailPage";
import { IntegratedOnboardingOverviewPage } from "./onboarding/overview/IntegratedOnboardingOverviewPage";
import { IntegratedOnboardingDetailPage } from "./onboarding/detail/IntegratedOnboardingDetailPage";
import { IntegratedCustomerDetailPage } from "./customer/IntegratedCustomerDetailPage";
import { IntegratedCustomerGardeningTaskDetailPage } from "./gardening-task/detail/IntegratedCustomerGardeningTaskDetailPage";
import { IntegratedGardenerGardeningTaskDetailPage } from "./gardening-task/detail/IntegratedGardenerGardeningTaskDetailPage";
import { IntegratedEvaluateGardenerGardeningTaskDetailPage } from "./gardening-task/detail/IntegratedEvaluateGardenerGardeningTaskDetailPage";

export const Application = () => {
    const user = useUser() as UserDocument | null | undefined;

    if ( user === undefined ) return <LinearProgress />

    return (
        <BrowserRouter>

            {user && user.type === "administrator" ? (
                <TaskProvider>
                    <BillingTaskProvider>

                        <LinkProvider>
                            <ProtectedRoutes user={user} />
                        </LinkProvider>

                    </BillingTaskProvider>
                </TaskProvider>
            ) : (
                <UnprotectedRoutes />
            )}

        </BrowserRouter>
    );
};

const ProtectedRoutes = (props: { user: UserDocument }) => {
    const { user } = props;

    const history = useHistory();
    useEffect(() => {
        if ( user.type === "administrator" ) return;

        history.replace("/logout");
    }, [history, user]);

    const goBackToAdhocCustomersTab = useCallback(() => {
        history.push("/workflow/adhoc/customers");
    }, [history]);

    const goBackToAdhocGardenersTab = useCallback(() => {
        history.push("/workflow/adhoc/gardeners");
    }, [history]);

    const goBackToPaymentCollectionReminderFeeWarningTab = useCallback(() => {
        history.push("/finance/payment-collection/reminder-fee-warning");
    }, [history]);

    const goBackToPaymentCollectionDebtCollectionWarningTab = useCallback(() => {
        history.push("/finance/payment-collection/debt-collection-warning");
    }, [history]);

    const goBackToPaymentCollectionAutomaticTab = useCallback(() => {
        history.push("/finance/payment-collection/automatic");
    }, [history]);

    return (
        <Switch>

            <Route exact path="/">
                <Redirect to="/dashboard" />
            </Route>

            <Route exact path="/dashboard">
                <Redirect to="/dashboard/overview" />
            </Route>

            <Route exact path="/dashboard/:tab">
                <Layout>
                    <DashboardPage />
                </Layout>
            </Route>

            <Route exact path="/customers/context/:customerId">
                <RedirectToCustomerWithAppropriateContext />
            </Route>

            <Route exact path={["/customers", "/customers/:tab"]}>
                <Layout>
                    <CustomerListPage />
                </Layout>
            </Route>

            <Route exact path="/customers/:tab/:customerId">
                <Layout>
                    <IntegratedCustomerDetailPage />
                </Layout>
            </Route>

            <Route exact path="/customers/:tab/:customerId/gardening-tasks/:gardeningTaskId">
                <Layout>
                    <IntegratedCustomerGardeningTaskDetailPage />
                </Layout>
            </Route>

            <Route exact path="/gardeners/context/:gardenerId">
                <RedirectToGardenerWithAppropriateContext />
            </Route>

            <Route exact path={["/gardeners", "/gardeners/:tab"]}>
                <Layout>
                    <GardenerListPage />
                </Layout>
            </Route>

            <Route exact path="/gardeners/:tab/:gardenerId">
                <Layout>
                    <IntegratedGardenerPage />
                </Layout>
            </Route>

            <Route exact path="/gardeners/:tab/:gardenerId/gardening-tasks/:gardeningTaskId">
                <Layout>
                    <IntegratedGardenerGardeningTaskDetailPage />
                </Layout>
            </Route>

            <Route exact path={["/invoices", "/invoices/:tab"]}>
                <Layout>
                    <IntegratedInvoiceOverviewPage />
                </Layout>
            </Route>

            <Route exact path="/invoices/:tab/invoice/:invoiceId">
                <Layout>
                    <IntegratedInvoiceDetailPage />
                </Layout>
            </Route>

            <Route exact path="/invoices/:tab/credit-note/:creditNoteId">
                <Layout>
                    <IntegratedCreditNoteDetailPage />
                </Layout>
            </Route>

            <Route exact path="/tasks/:taskId">
                <RedirectToTask />
            </Route>

            <Route exact path={["/onboarding", "/onboarding/:tab"]}>
                <Layout>
                    <IntegratedOnboardingOverviewPage />
                </Layout>
            </Route>

            <Route exact path="/onboarding/:tab/:taskId">
                <Layout>
                    <IntegratedOnboardingDetailPage />
                </Layout>
            </Route>

            <Route exact path="/workflow/find-gardener">
                <Redirect to="/workflow/find-gardener/manual" />
            </Route>

            <Route exact path={["/evaluate-gardener", "/evaluate-gardener/:tab"]}>
                <Layout>
                    <IntegratedEvaluateGardenerOverviewPage />
                </Layout>
            </Route>

            <Route exact path="/evaluate-gardener/:tab/:taskId">
                <Layout>
                    <IntegratedEvaluateGardenerDetailPage />
                </Layout>
            </Route>

            <Route exact path="/evaluate-gardener/:tab/:taskId/gardening-tasks/:gardeningTaskId">
                <Layout>
                    <IntegratedEvaluateGardenerGardeningTaskDetailPage />
                </Layout>
            </Route>

            <Route exact path="/workflow/adhoc">
                <Redirect to="/workflow/adhoc/customers" />
            </Route>

            <Route exact path="/workflow/adhoc/:tab">
                <Layout>
                    <AdhocFollowUpOverviewPage />
                </Layout>
            </Route>

            <Route exact path="/workflow/adhoc/customers/:taskId">
                <Layout>
                    <AdhocCustomerFollowUpDetailPage goBack={goBackToAdhocCustomersTab} />
                </Layout>
            </Route>

            <Route exact path="/workflow/adhoc/gardeners/:taskId">
                <Layout>
                    <AdhocGardenerFollowUpDetailPage goBack={goBackToAdhocGardenersTab} />
                </Layout>
            </Route>

            <Route exact path={["/complaint", "/complaint/:tab"]}>
                <Layout>
                    <IntegratedComplaintOverviewPage />
                </Layout>
            </Route>

            <Route exact path="/complaint/:tab/:taskId">
                <Layout>
                    <IntegratedComplaintDetailPage />
                </Layout>
            </Route>

            <Route exact path="/finance/payment-collection">
                <Redirect to="/finance/payment-collection/reminder-fee-warning" />
            </Route>

            <Route exact path="/finance/payment-collection/:tab">
                <Layout>
                    <PaymentCollectionOverviewPage />
                </Layout>
            </Route>

            <Route exact path="/finance/payment-collection/reminder-fee-warning/:taskId">
                <Layout>
                    <CallBeforeInvoiceReminderFee1DetailPage goBack={goBackToPaymentCollectionReminderFeeWarningTab} />
                </Layout>
            </Route>

            <Route exact path="/finance/payment-collection/debt-collection-warning/:taskId">
                <Layout>
                    <CallBeforeInvoiceDebtCollectionDetailPage goBack={goBackToPaymentCollectionDebtCollectionWarningTab} />
                </Layout>
            </Route>

            <Route exact path="/finance/payment-collection/automatic/take-payment/:taskId">
                <Layout>
                    <TakePaymentDetailPage goBack={goBackToPaymentCollectionAutomaticTab} />
                </Layout>
            </Route>

            <Route exact path="/finance/payment-collection/automatic/payment-collection/:taskId">
                <Layout>
                    <PaymentCollectionDetailPage goBack={goBackToPaymentCollectionAutomaticTab} />
                </Layout>
            </Route>

            <Route exact path="/login">
                <SignIn />
            </Route>

            <Route exact path="/logout">
                <SignOut />
            </Route>

            <Route path="/">
                <Layout>
                    <NotFoundPage />
                </Layout>
            </Route>

        </Switch>
    );
};

const UnprotectedRoutes = () => {
    return (
        <Switch>

            <Route exact path="/login">
                <SignIn />
            </Route>

            <Route exact path="/logout">
                <RedirectToLogin fresh />
            </Route>

            <Route path="/">
                <RedirectToLogin />
            </Route>

        </Switch>
    );
};
