import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";

import { useTask } from "../../task/tasks.hook";
import { EvaluateGardenerTaskDocument } from "../../task/task.model";
import { useGardenerComments } from "../../comment/comments.hook";
import { useAddGardenerComment } from "../../comment/add-comment.hook";
import { NotFoundPage } from "../../NotFoundPage";
import { LinearProgress } from "../../components/LinearProgress";
import { ErrorPage } from "../../ErrorPage";
import { EvaluateGardenerDetailPage } from "./EvaluateGardenerDetailPage";
import { EvaluateGardenerTab, useNavigation } from "../../navigation.hook";
import { GardeningTaskDocument } from "../../gardening-task/gardening-task.model";
import { useGardeningTasksByGardenerIdAndStatus, useGardeningTasksByGardenerIdWorkingStatus } from "../../gardening-task/gardening-task.hook";
import { useOptionalCustomersById } from "../../customer/customers.hook";
import moment from "moment";
import { useGardener } from "../../gardener/gardeners.hook";
import { useAdministratorAction } from "../../workflow/administrator-action.hook";
import { useCurrentGardeningTasks } from "../../gardener/IntegratedGardenerPage";

export const IntegratedEvaluateGardenerDetailPage = () => {
    const { tab, taskId } = useParams<{ tab: EvaluateGardenerTab, taskId: string }>();

    const { goToEvaluateGardenerList, goToEvaluateGardenerTaskGardeningTask } = useNavigation();
    const goBack = useCallback(() => {
        goToEvaluateGardenerList(tab);
    }, [goToEvaluateGardenerList, tab]);

    const task = useTask(taskId) as EvaluateGardenerTaskDocument;
    const gardener = useGardener(task && task.gardenerId);

    const currentGardeningTasks = useCurrentGardeningTasks(task && task.gardenerId);
    const finishedGardeningTasks = useFinishedGardeningTasks(task && task.gardenerId);

    const customerIds = useMemo(() => {
        if ( !currentGardeningTasks || !finishedGardeningTasks ) return undefined;

        const customerIdSet = new Set<string>();
        currentGardeningTasks.forEach(x => customerIdSet.add(x.customerId));
        finishedGardeningTasks.forEach(x => customerIdSet.add(x.customerId));

        return Array.from(customerIdSet);
    }, [currentGardeningTasks, finishedGardeningTasks])
    const customers = useOptionalCustomersById(customerIds);

    const comments = useGardenerComments(task?.gardenerId);
    const addComment = useAddGardenerComment(task?.gardenerId);

    const onSelectGardeningTask = useCallback((gardeningTask: GardeningTaskDocument) => {
        goToEvaluateGardenerTaskGardeningTask(tab, taskId, gardeningTask.id);
    }, [goToEvaluateGardenerTaskGardeningTask, tab, taskId]);

    const administratorAction = useAdministratorAction(goBack);

    const now = moment().toISOString(true);

    if ( task === null ) return <NotFoundPage />;
    if ( gardener === null ) return <ErrorPage message="Kunne ikke finde havemanden" />
    if ( currentGardeningTasks === null || finishedGardeningTasks === null ) return <ErrorPage message="Kunne ikke finde opgaver" />;
    if ( task === undefined || gardener === undefined || currentGardeningTasks === undefined || finishedGardeningTasks === undefined || customers === undefined || comments === undefined ) return <LinearProgress />;
    if ( task.type !== "evaluate-gardener" ) return <ErrorPage message="Opgavens type genkendes ikke" />;

    return (
        <EvaluateGardenerDetailPage
            task={task}
            gardener={gardener}
            currentGardeningTasks={currentGardeningTasks}
            finishedGardeningTasks={finishedGardeningTasks}
            customers={customers}
            comments={comments}
            addComment={addComment}
            goBack={goBack}
            onSelectGardeningTask={onSelectGardeningTask}
            administratorAction={administratorAction}
            now={now}
        />
    );
};

const useFinishedGardeningTasks = (gardenerId: string | null | undefined) => {
    const gardenerStopped = useGardeningTasksByGardenerIdAndStatus(gardenerId, "gardener stopped");
    const finishedWorking = useGardeningTasksByGardenerIdWorkingStatus(gardenerId, "has finished working");

    return useMemo(() => {
        if ( gardenerStopped === null || finishedWorking === null ) return null;
        if ( gardenerStopped === undefined || finishedWorking === undefined ) return undefined;

        return [...gardenerStopped, ...finishedWorking].sort((a, b) => {
            const aCompletionDate = a.history.find(x => x.event === "gardener stopped" || x.event === "performed work")?.date;
            const bCompletionDate = b.history.find(x => x.event === "gardener stopped" || x.event === "performed work")?.date;

            if ( !aCompletionDate && bCompletionDate ) return -1;
            if ( aCompletionDate && !bCompletionDate ) return 1;

            if ( aCompletionDate && bCompletionDate ) {
                if ( aCompletionDate < bCompletionDate ) return -1;
                if ( aCompletionDate > bCompletionDate ) return 1;
            }

            return 0;
        }).reverse().slice(0, 25);
    }, [gardenerStopped, finishedWorking]);
};
