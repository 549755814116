import { useCallback } from "react";

import firebase from "firebase/compat/app";

import { useBlockingProgress } from "../components/blocking-progress";
import { useSnackbar } from "../components/snackbar";
import { TerminateGardenerDialogProps, TerminateGardenerDialogViewModel } from "./TerminateGardenerDialog";

interface TerminateGardenerData {
    gardenerId: string;
    terminationReason: TerminationReason;
}

type TerminationReason = BadPartnershipTerminationReason | GardenerBehaviorTerminationReason | GardenerWantsToStopTerminationReason

interface BadPartnershipTerminationReason {
    issue: "bad-partnership";
    explanation: string;
};

interface GardenerBehaviorTerminationReason {
    issue: "gardener-behavior";
    explanation: string;
};

interface GardenerWantsToStopTerminationReason {
    issue: "gardener-wants-to-stop";
    explanation: null;
};

const terminateGardenerFunction: (data: TerminateGardenerData) => Promise<firebase.functions.HttpsCallableResult>
    = firebase.app().functions("europe-west3").httpsCallable("Gardener-terminate2");

export const useTerminateGardenerAction = (gardenerId: string | null | undefined) => {
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    const action: TerminateGardenerDialogProps["onConfirm"] = useCallback((viewModel: TerminateGardenerDialogViewModel) => {
        if ( !gardenerId ) return;

        const terminationReason = {
            issue: viewModel.reason,
            explanation: viewModel.reason !== "gardener-wants-to-stop" ? viewModel.explanation : null,
        } as TerminationReason;

        block();
        terminateGardenerFunction({ gardenerId, terminationReason })
            .then(() => {
                openSnackbar("success", "Samarbejdet med havemanden ophøres");
                unblock();
            })
            .catch(() => {
                openSnackbar("error", "Der skete en fejl. Samarbejdet med havemanden er ikke blevet ophørt.");
                unblock();
            });

    }, [block, unblock, openSnackbar, gardenerId]);

    return action;
};
