import { useCallback } from "react";

import firebase from "firebase/compat/app";

import { useBlockingProgress } from "../components/blocking-progress";
import { useDialog } from "../components/dialog";
import { useSnackbar } from "../components/snackbar";
import { DeleteCommentDialog } from "./DeleteCommentDialog";

export const useDeleteComment = (commentId: string) => {
    const { openDialog, closeDialog } = useDialog();
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    const deleteComment = useCallback(() => {
        closeDialog();

        block();
        firebase.firestore().collection("comments").doc(commentId).delete()
            .then(() => {
                openSnackbar("success", "Kommentaren er slettet");
                unblock();
            })
            .catch(() => {
                openSnackbar("error", "Der skete en fejl. Kommentaren er ikke blevet slettet.");
                unblock();
            });
    }, [closeDialog, block, unblock, openSnackbar, commentId]);

    return useCallback(() => {
        openDialog(<DeleteCommentDialog onConfirm={deleteComment} />);
    }, [openDialog, deleteComment]);
};
