import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from "react";

import { makeStyles } from "@material-ui/core";
import moment from "moment";

import { Card, CardActions, LegacyCardContent, CardHeader, NeutralAction } from "../../../../../components/card";
import { Label } from "../../../../../components/form/Label";
import { InvoiceDocument } from "../../invoice.model";
import { CustomerDocument } from "../../../../../customer/customer.model";
import { GardenerDocument } from "../../../../../gardener/gardener.model";
import { DashedBorderSeparator } from "../../DashedBorderSeparator";
import { SubmitButton } from "./SubmitButton";
import { GardenerInput, GardenerInputContext, newGardenerInputContext } from "./GardenerInput";
import { CustomerInput, CustomerInputContext, newCustomerInputContext } from "./CustomerInput";
import { InvoiceDateInput, InvoiceDateInputContext, newInvoiceDateInputContext } from "./InvoiceDateInput";
import { InvoiceDueDateInput, InvoiceDueDateInputContext, newInvoiceDueDateInputContext } from "./InvoiceDueDateInput";
import { DescriptionInput, validateDescriptionInput } from "./service/DescriptionInput";
import { DurationInput, validateDurationInput } from "./service/DurationInput";
import { ToolsInput, validateToolsInput } from "./service/ToolsInput";
import { FormGroup } from "../FormGroup";
import { calculateGardenerCommissionIncludingVat, calculateInvoiceTotal, calculateServiceCommissionIncludingVat, formatPrice } from "../utility";
import { DineroContactDocument } from "../../../pending-approval/dinero-contact.model";
import { newServiceInputContext, ServiceInputContext, serviceInputHasChanged, validateServiceInput } from "./service";
import { DiscountInput, DiscountInputContext, discountInputHasChanged, newDiscountInputContext, validateDiscountInput } from "./DiscountInput";
import { GardenerPriceDocument } from "../../../registration/gardener-price.model";
import { CustomerPriceDocument } from "../../../registration/customer-price.model";
import { determineServices } from "./utility";
import { GardenerDiscountInput, GardenerDiscountInputContext, gardenerDiscountInputHasChanged, newGardenerDiscountInputContext, validateGardenerDiscountInput } from "./GardenerDiscountInput";
import { newServiceDateInputContext, ServiceDateInput, ServiceDateInputContext, serviceDateInputHasChanged, validateServiceDateInput } from "./ServiceDateInput";
import { AddServiceButton } from "./service/AddServiceButton";
import { ServiceHeader } from "./service/ServiceHeader";
import { NoticeFormItemComponent } from "../../../../../components/form-item/client/components";

const Context = createContext<{ form: InvoiceFormContext, setForm: Dispatch<SetStateAction<InvoiceFormContext>> }>(undefined as any);

const useStyles = makeStyles(theme => ({
    notice: {
        marginBottom: "24px",
    },
    topGrid: {
        display: "grid",
        gridAutoColumns: "minmax(0, 1fr)",
        gridAutoFlow: "column",
        gridTemplateAreas: `
            "gardener invoice-date"
            "customer invoice-due-date"
        `,
        columnGap: "24px",
        marginBottom: "24px",

        "& > *:nth-child(1)": {
            gridArea: "gardener",
        },
        "& > *:nth-child(2)": {
            gridArea: "customer",
            marginBottom: 0,
        },
        "& > *:nth-child(3)": {
            gridArea: "invoice-date",
        },
        "& > *:nth-child(4)": {
            gridArea: "invoice-due-date",
        },

        "& > *:nth-child(3), & > *:nth-child(4)": {
            textAlign: "right",
        },

        [theme.breakpoints.down(500)]: {
            gridTemplateAreas: `
                "gardener"
                "customer"
                "invoice-date"
                "invoice-due-date"
            `,

            "& > *:nth-child(1), & > *:nth-child(2)": {
                marginBottom: "24px",
            },
            "& > *:nth-child(3), & > *:nth-child(4)": {
                textAlign: "left",
            }
        },
    },
    alignLeft: {
        textAlign: "left",
    },
    service: {
        marginBottom: "48px",

        "&:last-child": {
            marginBottom: 0,
        },
    },
    summary: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 16px",

        "& > label": {
            fontSize: "16px",
            fontWeight: 500,
            color: "#4A4A4A",
        },

        "& > span": {
            fontSize: "16px",
            fontWeight: 600,
            color: "#4A4A4A",
        },
    },
}));

interface InvoiceFormProps {
    invoice: InvoiceDocument;
    gardener: GardenerDocument;
    gardenerPrice: GardenerPriceDocument;
    customer: CustomerDocument;
    customerPrice: CustomerPriceDocument;
    contact: DineroContactDocument;
    isSuperAdministrator: boolean;
    onCancel: () => void;
    onSubmit: (form: InvoiceFormContext) => void;
}

export const InvoiceForm = (props: InvoiceFormProps) => {
    const classes = useStyles();

    const { invoice, gardener, gardenerPrice, customer, customerPrice, contact, isSuperAdministrator, onCancel, onSubmit } = props;

    const [form, setForm] = useState(newInvoiceFormContext(invoice, gardener, gardenerPrice, customer, customerPrice, contact));
    const value = useMemo(() => ({ form, setForm }), [form, setForm]);

    const { serviceCommission, gardenerCommission, invoiceTotal } = useMemo(() => {
        const services = determineServices(form);
        const serviceCommission = calculateServiceCommissionIncludingVat(services, form.discountInput.discount, form.gardenerDiscountInput.gardenerDiscount);
        const gardenerCommission = calculateGardenerCommissionIncludingVat(services);
        const invoiceTotal = calculateInvoiceTotal(services, form.discountInput.discount, form.gardenerDiscountInput.gardenerDiscount);

        return { serviceCommission, gardenerCommission, invoiceTotal };
    }, [form])

    return (
        <Context.Provider value={value}>
            <Card>

                <CardHeader>
                    Ret faktura {invoice.invoiceNumber}
                </CardHeader>

                <LegacyCardContent>

                    <div className={classes.notice}>
                        <NoticeFormItemComponent
                            item={{
                                type: "notice",
                                render: "warning",
                                html: `
                                    <strong>Bemærk</strong><br />
                                    <br />
                                    Du må kun rette fakturaen efter aftale med havemanden.
                                `,
                            }}
                        />
                    </div>

                    <div className={classes.topGrid}>

                        <FormGroup>
                            <Label>Havemand</Label>
                            <GardenerInput />
                        </FormGroup>

                        <FormGroup>
                            <Label>Kunde</Label>
                            <CustomerInput />
                        </FormGroup>

                        <FormGroup>
                            <Label className={classes.alignLeft}>Fakturadato</Label>
                            <InvoiceDateInput />
                        </FormGroup>

                        <FormGroup>
                            <Label className={classes.alignLeft}>Forfaldsdato</Label>
                            <InvoiceDueDateInput />
                        </FormGroup>

                    </div>

                    <div>
                        <FormGroup id="service-date-section">
                            <Label htmlFor="service-date">Dato for besøg</Label>
                            <ServiceDateInput id="service-date" />
                        </FormGroup>
                    </div>

                    <DashedBorderSeparator />

                    <div>
                        {form.serviceInputs.map((_, index) => (
                            <div key={index} className={classes.service}>
                                <ServiceHeader index={index} />

                                <FormGroup id={`description-${index}-section`}>
                                    <Label htmlFor={`description-${index}`}>Beskrivelse</Label>
                                    <DescriptionInput index={index} id={`description-${index}`} />
                                </FormGroup>

                                <FormGroup id={`duration-${index}-section`}>
                                    <Label htmlFor={`duration-${index}`}>Varighed</Label>
                                    <DurationInput index={index} id={`duration-${index}`} />
                                </FormGroup>

                                <FormGroup id={`tools-${index}-section`}>
                                    <Label htmlFor={`tools-${index}`}>Redskaber</Label>
                                    <ToolsInput index={index} id={`tools-${index}`} />
                                </FormGroup>

                            </div>
                        ))}

                        <AddServiceButton />
                    </div>
                    
                    <DashedBorderSeparator />

                    <div>

                        {isSuperAdministrator ? (
                            <FormGroup id="gardener-discount-section">
                                <Label htmlFor="gardener-discount">Rabat fra havemand (max {formatPrice(gardenerCommission)})</Label>
                                <GardenerDiscountInput id="gardener-discount" />
                            </FormGroup>
                        ) : null}

                        {isSuperAdministrator ? (
                            <FormGroup id="discount-section">
                                <Label htmlFor="discount">Rabat fra Go Go Garden (max {formatPrice(serviceCommission)})</Label>
                                <DiscountInput id="discount" />
                            </FormGroup>
                        ) : null}

                        <div className={classes.summary}>
                            <label>Total</label>
                            <span>{formatPrice(invoiceTotal)}</span>
                        </div>

                    </div>

                </LegacyCardContent>

                <CardActions>
                    <NeutralAction onClick={onCancel}>Annuller</NeutralAction>
                    <SubmitButton onSubmit={onSubmit} />
                </CardActions>

            </Card>
        </Context.Provider>
    );
};

export const useInvoiceForm = () => {
    return useContext(Context);
};

export interface InvoiceFormContext {
    validationAnimationDuration: number;
    dateContext: string;
    invoice: InvoiceDocument;
    gardenerPrice: GardenerPriceDocument;
    customerPrice: CustomerPriceDocument;
    gardenerInput: GardenerInputContext;
    customerInput: CustomerInputContext;
    invoiceDateInput: InvoiceDateInputContext;
    invoiceDueDateInput: InvoiceDueDateInputContext;
    serviceDateInput: ServiceDateInputContext;
    serviceInputs: ServiceInputContext[];
    gardenerDiscountInput: GardenerDiscountInputContext;
    discountInput: DiscountInputContext;
}

export const newInvoiceFormContext = (invoice: InvoiceDocument, gardener: GardenerDocument, gardenerPrice: GardenerPriceDocument, customer: CustomerDocument, customerPrice: CustomerPriceDocument, contact: DineroContactDocument): InvoiceFormContext => {
    const today = moment().toISOString(true);

    return {
        validationAnimationDuration: 200,
        dateContext: today,
        invoice,
        gardenerPrice,
        customerPrice,
        gardenerInput: newGardenerInputContext(gardener),
        customerInput: newCustomerInputContext(customer),
        invoiceDateInput: newInvoiceDateInputContext(today),
        invoiceDueDateInput: newInvoiceDueDateInputContext(today, contact),
        serviceDateInput: newServiceDateInputContext(invoice.serviceDate),
        serviceInputs: invoice.services.map(service => newServiceInputContext(service)),
        gardenerDiscountInput: newGardenerDiscountInputContext(invoice.gardenerDiscount),
        discountInput: newDiscountInputContext(invoice.discount),
    };
};

export const invoiceFormHasChanged = (form: InvoiceFormContext) => {
    return serviceDateInputHasChanged(form) || form.serviceInputs.some((_, index) => serviceInputHasChanged(form, index)) || gardenerDiscountInputHasChanged(form) || discountInputHasChanged(form);
};

export const validateInvoiceForm = (form: InvoiceFormContext) => {
    return validateServiceDateInput(form) || form.serviceInputs.some((_, index) => validateServiceInput(form, index)) || validateGardenerDiscountInput(form) || validateDiscountInput(form);
};

export const scrollToFirstError = (form: InvoiceFormContext) => {
    const sectionId = (() => {
        if ( validateServiceDateInput(form) ) return "service-date-section";
        
        for (let index = 0; index < form.serviceInputs.length; index++) {
            if ( validateDescriptionInput(form, index) ) return `description-${index}-section`;
            if ( validateDurationInput(form, index) ) return `duration-${index}-section`;
            if ( validateToolsInput(form, index) ) return `tools-${index}-section`;
        }

        if ( validateGardenerDiscountInput(form) ) return "gardener-discount-section";
        if ( validateDiscountInput(form) ) return "discount-section";

        return undefined;
    })();

    if ( !sectionId ) return;

    const section = document.getElementById(sectionId);
    if ( !section ) return;

    const header = document.getElementsByTagName("header").length === 1 ? document.getElementsByTagName("header").item(0) : null;
    const headerOffset = header?.getBoundingClientRect().height ?? 0;

    const offsetPosition = window.scrollY + section.getBoundingClientRect().top - headerOffset;

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
}
