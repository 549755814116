import { createContext, PropsWithChildren, useContext, useMemo } from "react";

import moment from "moment";

import { LinearProgress } from "../../../components/LinearProgress";
import { EvaluateGardenerTaskDocument, AdhocCustomerTaskDocument, AdhocGardenerTaskDocument, ComplaintTaskDocument, OnboardingTaskDocument } from "../../../task/task.model";
import { useOpenTasksByType } from "../../../task/tasks.hook";
import { useAdminConfiguration } from "../../../application/application.hook";
import { AdminConfigurationDocument } from "../../../application/configuration.model";
import { useEventsByTypeInTimePeriod } from "../../../event/event.hook";
import { GardenerApplicationApprovedEventDocument } from "../../../event/event.model";

const Context = createContext<{
    configuration: AdminConfigurationDocument | null,
    gardenerApplicationApprovedEventsToday: GardenerApplicationApprovedEventDocument[],
    onboardingTasks: OnboardingTaskDocument[],
    evaluateGardenerTasks: EvaluateGardenerTaskDocument[],
    adhocCustomerTasks: AdhocCustomerTaskDocument[],
    adhocGardenerTasks: AdhocGardenerTaskDocument[],
    complaintTasks: ComplaintTaskDocument[],
} | undefined>(undefined);

export const TaskProvider = (props: PropsWithChildren<{}>) => {
    const configuration = useAdminConfiguration();

    const now = moment().toISOString(true);

    const fromDate = moment(now).startOf("day").toISOString(true);
    const toDate = moment(now).add(1, "day").startOf("day").toISOString(true);
    const gardenerApplicationApprovedEventsToday = useEventsByTypeInTimePeriod("gardener-application-approved", fromDate, toDate) as GardenerApplicationApprovedEventDocument[];

    const onboardingTasks = useOpenTasksByType("onboarding");
    const evaluateGardenerTasks = useOpenTasksByType("evaluate-gardener");
    const adhocCustomerTasks = useOpenTasksByType("adhoc");
    const adhocGardenerTasks = useOpenTasksByType("adhoc-gardener");
    const complaintTasks = useOpenTasksByType("complaint");

    const taskMap = useMemo(() => {
        if ( configuration === undefined ) return undefined;

        if ( gardenerApplicationApprovedEventsToday === undefined ) return undefined;
        if ( onboardingTasks === undefined ) return undefined;

        if ( evaluateGardenerTasks === undefined ) return undefined;
        if ( adhocCustomerTasks === undefined ) return undefined;
        if ( adhocGardenerTasks === undefined ) return undefined;
        if ( complaintTasks === undefined ) return undefined;

        return { configuration, gardenerApplicationApprovedEventsToday, onboardingTasks, evaluateGardenerTasks, adhocCustomerTasks, adhocGardenerTasks, complaintTasks };
    }, [configuration, gardenerApplicationApprovedEventsToday, onboardingTasks, evaluateGardenerTasks, adhocCustomerTasks, adhocGardenerTasks, complaintTasks]);

    if ( taskMap === undefined ) return <LinearProgress />;

    return (
        <Context.Provider value={taskMap}>
            {props.children}
        </Context.Provider>
    );
};

export const useConfiguration = () => {
    return useContext(Context)?.configuration as AdminConfigurationDocument;
};

export const useOnboardingTasks = () => {
    return useContext(Context)?.onboardingTasks as OnboardingTaskDocument[];
};

export const useEvaluateGardenerTasks = () => {
    return useContext(Context)?.evaluateGardenerTasks as EvaluateGardenerTaskDocument[];
};

export const useAdhocCustomerTasks = () => {
    return useContext(Context)?.adhocCustomerTasks as AdhocCustomerTaskDocument[];
};

export const useAdhocGardenerTasks = () => {
    return useContext(Context)?.adhocGardenerTasks as AdhocGardenerTaskDocument[];
};

export const useComplaintTasks = () => {
    return useContext(Context)?.complaintTasks as ComplaintTaskDocument[];
};
