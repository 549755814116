import { Comment } from "../components/comment/Comment";
import { CommentBody } from "../components/comment/CommentBody";
import { CustomerBlocklistedCommentDocument } from "./comment.model";

interface CustomerBlocklistedCommentProps {
    comment: CustomerBlocklistedCommentDocument;
}

export const CustomerBlocklistedComment = (props: CustomerBlocklistedCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Vi ønsker ikke at servicere denne kunde" author={comment.administratorName}>
            <CommentBody>
                Vi ønsker ikke at servicere denne kunde.<br />
                <br />
                Årsag:<br />
                {comment.reason}
            </CommentBody>
        </Comment>
    );
};
