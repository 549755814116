import { Link } from "react-router-dom";

import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { AppointmentCancelledCommentDocument } from "../comment.model";
import { formatDateIncludingWeekday } from "../../date-utility";

interface AppointmentCancelledCommentProps {
    context: "customer" | "gardener";
    comment: AppointmentCancelledCommentDocument;
}

export const AppointmentCancelledComment = (props: AppointmentCancelledCommentProps) => {
    const { context, comment } = props;

    const gardener = (() => {
        switch ( context ) {
            case "customer": return <Link to={`/gardeners/context/${comment.gardenerId}`}>{comment.gardenerName}</Link>;
            case "gardener": return <>Havemanden</>;
        }
    })();

    const customer = (() => {
        switch ( context ) {
            case "customer": return <>kunden</>;
            case "gardener": return <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link>;
        }
    })();

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Aftale aflyst">
            <CommentBody>
                {gardener} har aflyst sin aftale med {customer}.<br />
                <br />

                {comment.scheduledDate ? (
                    <>
                        Hvornår:<br />
                        {formatDateIncludingWeekday(comment.scheduledDate)}<br />
                        <br />
                    </>
                ) : null}

                {comment.description ? (
                    <>
                        Hvad:<br />
                        {comment.description}<br />
                        <br />
                    </>
                ) : null}

                Årsag:<br />
                {comment.reasonForCancelling}
            </CommentBody>
        </Comment>
    );
};
