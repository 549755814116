import { GardenerDocument } from "../../gardener.model";
import { useGoToGardenerProfile } from "../../go-to-gardener-profile.hook";
import { GardenerCard } from "./GardenerCard";

export const ListItem = (props: { gardener: GardenerDocument }) => {
    const { gardener } = props;

    const goToGardenerProfile = useGoToGardenerProfile("terminated", gardener.id);

    return <GardenerCard gardener={gardener} goToProfile={goToGardenerProfile} />;
};
