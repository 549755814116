import { useCallback } from "react";

import { Hit } from "@algolia/client-search";
import { makeStyles, TableCell, TableRow } from "@material-ui/core";
import sanitizeHtml from "sanitize-html";

import { InvoiceSearchRecord } from "../invoice-search-record.model";

const useStyles = makeStyles({
    root: {
        "& em": {
            backgroundColor: "#A259FF",
            color: "#FFFFFF",
            fontStyle: "normal",
            lineHeight: "125%",
        },
        "& td": {
            padding: "12px 24px",
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
            whiteSpace: "nowrap",
        },
        "&:hover td": {
            backgroundColor: "#EBF3EA",
            cursor: "pointer",

            "& > .MuiTouchRipple-root": {
                color: "#1C5B13",
            },
        },
    },
    price: {
        fontWeight: 500,
        color: "#297A1D",
    },
    discount: {
        fontWeight: 500,
        color: "#F44336",
    },
    blank: {
        textAlign: "center",
    },
});

interface InvoiceTableRowProps {
    invoice: Hit<InvoiceSearchRecord>;
    onSelectInvoice: (invoice: Hit<InvoiceSearchRecord>) => void;
}

export const InvoiceTableRow = (props: InvoiceTableRowProps) => {
    const classes = useStyles();

    const { invoice, onSelectInvoice } = props;

    const selectInvoice = useCallback(() => {
        onSelectInvoice(invoice);
    }, [onSelectInvoice, invoice]);

    return (
        <TableRow className={classes.root} onClick={selectInvoice}>

            <TableCell>
                <span dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(invoice._highlightResult?.invoiceNumber?.value ?? invoice.invoiceNumber)
                }} />
            </TableCell>

            <TableCell>
                <span dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(invoice._highlightResult?.invoiceDate?.value ?? invoice.invoiceDate)
                }} />
            </TableCell>

            {invoice.invoiceDueDate ? (
                <TableCell>
                    <span dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(invoice._highlightResult?.invoiceDueDate?.value ?? invoice.invoiceDueDate)
                    }} />
                </TableCell>
            ) : (
                <TableCell className={classes.blank}>--</TableCell>
            )}

            <TableCell>
                <span dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(invoice._highlightResult?.customerName?.value ?? invoice.customerName)
                }} />
            </TableCell>

            <TableCell>
                <span dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(invoice._highlightResult?.gardenerName?.value ?? invoice.gardenerName)
                }} />
            </TableCell>

            <TableCell>
                <span className={invoice.type === "invoice" ? classes.price : classes.discount} dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(invoice._highlightResult?.invoiceTotal?.value ?? invoice.invoiceTotal)
                }} />
            </TableCell>

        </TableRow>
    );
};
