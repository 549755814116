import { useCallback, useEffect } from "react";

import { makeStyles } from "@material-ui/core";
import { useWindowVariable } from "../../window-variable.hook";

const globalBootstrapMapVariableName = "bootstrapMap";

const useStyles = makeStyles({
    root: {
        height: "100%",
    },
});

export interface GoogleMapsProps extends google.maps.MapOptions {
    apiKey: string;
    setMap?: (map: google.maps.Map) => void;
}

export const GoogleMaps = (props: GoogleMapsProps) => {
    const classes = useStyles();

    const { apiKey, setMap, ...mapOptions } = props;

    const bootstrapMap = useCallback(() => {
        const container = document.getElementById("map");
        if ( !container ) return;
    
        const map = new google.maps.Map(container, mapOptions);
    
        if ( setMap ) setMap(map);
    }, [setMap, mapOptions]);

    useWindowVariable(globalBootstrapMapVariableName, bootstrapMap);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=${globalBootstrapMapVariableName}&v=weekly`;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            delete (window as any).google.maps;

            document.querySelectorAll("script").forEach((script) => {
                const identifiers = ["googleapis.com/maps", "maps.gstatic.com", "earthbuilder.googleapis.com"];
                const scriptMatchesIdentifier = identifiers.some(identifier => script.src.includes(identifier));
                if ( !scriptMatchesIdentifier ) return;

                script.remove()
            });
        };
    }, [apiKey]);

    return (
        <div id="map" className={classes.root} />
    );
};
