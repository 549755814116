import { useMemo } from "react";

import { PageFormItem, GroupFormItem, InfoFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { NoticeFormItem } from "../../components/form-item/client/form-item/NoticeFormItemComponent";

export type InformGardenerOfTermsForm<NextPage extends FormItem> = InformGardenerOfTermsPage<NextPage>;

type InformGardenerOfTermsPage<NextPage extends FormItem> =
    PageFormItem<
        GroupFormItem<[
            InfoFormItem,
            NoticeFormItem,
            NextPage
        ]>
    >;

export const useInformGardenerOfTermsForm = <T extends FormItem>(nextPage: T): InformGardenerOfTermsForm<T> => {
    return useMemo(() => {
        const page: InformGardenerOfTermsPage<T> = {
            type: "page",
            title: "Fortæl om vilkårene fremadrettet",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: "Fortæl havemanden om vilkårene fremadrettet ud fra nedenstående punkter. Vælg “Næste”, når du er færdig.",
                    },
                    {
                        type: "notice",
                        render: "info",
                        html: `
                            <strong>Du får en chance til</strong><br />
                            <br />
                            Jeg giver dig mulighed for at sige ja tak til 3 opgaver mere. Hvis du gør det godt, så vil du fremadrettet få tilbudt flere og flere opgaver.<br />
                            <br />
                            Hvis du omvendt løber tør for opgaver igen, så bliver vi nødt til at tage samarbejdet op til genovervejelse.<br />
                            <br />
                            Vær opmærksom på, at vi kun tæller de opgaver, du siger ja tak til. Du skal derfor ikke være nervøs for at sige nej tak til nogen af de opgaver, du får tilbudt efter vores snak. Du kan fortsat nøjes med at sige ja tak til de opgaver, som er interessante for dig.
                        `,
                    },
                    nextPage,
                ],
            },
        };
        
        return page;
    }, [nextPage]);
}
