import { useMemo } from "react";

import { PageFormItem, GroupFormItem, InfoFormItem, HorizontalButtonsSingleChoiceFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { NoticeFormItem } from "../../components/form-item/client/form-item/NoticeFormItemComponent";

export interface CanInterviewFormIds {
    SingleChoiceStillInterested: string;
    SingleChoiceCanTalkNow: string;
    SingleChoiceFluentInDanish: string;
}

export type CanInterviewForm<NextPage extends FormItem> = CanInterviewPage<NextPage>;

type CanInterviewPage<NextPage extends FormItem> = PageFormItem<
    GroupFormItem<[
        InfoFormItem,
        HorizontalButtonsSingleChoiceFormItem<[
            ConfirmationPage,
            HorizontalButtonsSingleChoiceFormItem<[
                ConfirmationPage,
                GroupFormItem<[
                    NoticeFormItem,
                    HorizontalButtonsSingleChoiceFormItem<[
                        ConfirmationPage,
                        NextPage,
                    ]>,
                ]>,
            ]>,
        ]>,
    ]>
>;

type ConfirmationPage = PageFormItem<InfoFormItem>

export const useCanInterviewForm = <T extends FormItem>(nextPage: T, formIds: CanInterviewFormIds): CanInterviewForm<T> => {
    return useMemo(() => {

        const notInterestedConfirmationPage: ConfirmationPage = {
            type: "page",
            title: "Afvis ansøgning",
            item: {
                type: "info",
                render: "default",
                text: `
                    Ansøgningen bliver afvist.<br />
                    <br />
                    Ansøgeren informeres om, at ansøgningen blev afvist fordi de ikke var interesserede længere.
                `,
            },
        };

        const cannotTalkRightNowConfirmationPage: ConfirmationPage = {
            type: "page",
            title: "Udskyd opgave",
            item: {
                type: "info",
                render: "default",
                text: `
                    Opgaven udskydes til i morgen.<br />
                    <br />
                    Ansøgeren informeres ikke.
                `,
            },
        };

        const notFluentInDanishConfirmationPage: ConfirmationPage = {
            type: "page",
            title: "Afvis ansøgning",
            item: {
                type: "info",
                render: "default",
                text: `
                    Ansøgningen bliver afvist.<br />
                    <br />
                    Ansøgeren informeres om, at ansøgningen blev afvist fordi de ikke talte flydende dansk.
                `,
            },
        };
    
        const page: CanInterviewPage<T> = {
            type: "page",
            title: "Kan I påbegynde onboarding-samtalen?",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: "Find ud af om I kan påbegynde onboarding-samtalen.",
                    },
                    {
                        id: formIds.SingleChoiceStillInterested,
                        type: "single-choice",
                        mode: "switch page",
                        switchPageIndexes: [0],
                        render: "horizontal-buttons",
                        questionText: "Er ansøgeren stadig interesseret?",
                        answerTexts: ["Nej", "Ja"],
                        answerItems: [
                            notInterestedConfirmationPage,
                            {
                                id: formIds.SingleChoiceCanTalkNow,
                                type: "single-choice",
                                mode: "switch page",
                                switchPageIndexes: [0],
                                render: "horizontal-buttons",
                                questionText: "Har ansøgeren tid til at snakke?",
                                answerTexts: ["Nej", "Ja"],
                                answerItems: [
                                    cannotTalkRightNowConfirmationPage,
                                    {
                                        type: "group",
                                        render: "all at once",
                                        items: [
                                            {
                                                type: "notice",
                                                render: "warning",
                                                html: `
                                                    Hvis du har svært ved at forstå ansøgeren eller de har svært ved at forstå dig på dansk, så vil vi ikke onboarde dem.
                                                `,
                                            },
                                            {
                                                id: formIds.SingleChoiceFluentInDanish,
                                                type: "single-choice",
                                                mode: "switch page",
                                                render: "horizontal-buttons",
                                                questionText: "Taler ansøgeren flydende dansk?",
                                                answerTexts: ["Nej", "Ja"],
                                                answerItems: [
                                                    notFluentInDanishConfirmationPage,
                                                    nextPage,
                                                ],
                                                selectedChoiceIndex: null,
                                                dirty: false,
                                            }
                                        ],
                                    },
                                ],
                                selectedChoiceIndex: null,
                                dirty: false,
                            },
                        ],
                        selectedChoiceIndex: null,
                        dirty: false,
                    }
                ],
            },
        };
        
        return page;
    }, [nextPage, formIds]);
}
