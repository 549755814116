import { Dispatch, SetStateAction, useCallback, useMemo } from "react";

import { Collapse, makeStyles } from "@material-ui/core";

import { Label } from "../../components/form/Label";
import { Group } from "../../components/form/Group";
import { AlternativeHint } from "../../components/form/Hint";
import { TwoOptionSwitch } from "../../components/switch/TwoOptionSwitch";
import { EmailField, TextField } from "../../components/form/InputField";
import { UpdateCustomerDialogViewModel, UpdateCustomerDialogErrorModel } from "./UpdateCustomerDialog";
import { CompanySubSection } from "./CompanySubSection";

const useStyles = makeStyles(theme => ({
    doubleGroup: {
        display: "flex",

        "& > *": {
            flexBasis: "50%",
            marginRight: "24px",
        },

        "& > *:last-child": {
            marginRight: 0,
        },

        [theme.breakpoints.down(675)]: {
            flexDirection: "column",

            "& > *": {
                flexBasis: "100%",
                marginRight: 0,
                marginBottom: "24px",
            },
    
            "& > *:last-child": {
                marginBottom: 0,
            },
        },
    },
}));

export interface BillingSectionProps {
    viewModel: UpdateCustomerDialogViewModel;
    setViewModel: Dispatch<SetStateAction<UpdateCustomerDialogViewModel>>;
    errorModel: UpdateCustomerDialogErrorModel;
    validationAnimationDuration: number;
}

export const BillingSection = (props: BillingSectionProps) => {
    const classes = useStyles();
    const { viewModel, setViewModel, errorModel, validationAnimationDuration } = props;

    const changeName = useCallback((value: string) => {
        setViewModel(viewModel => ({
            ...viewModel,
            billing: {
                ...viewModel.billing,
                name: value,
            },
        }));
    }, [setViewModel])

    const markNameDirty = useCallback(() => {
        setViewModel(viewModel => ({
            ...viewModel,
            billing: {
                ...viewModel.billing,
                nameDirty: true,
            },
        }));
    }, [setViewModel]);

    const changeEmail = useCallback((value: string) => {
        setViewModel(viewModel => ({
            ...viewModel,
            billing: {
                ...viewModel.billing,
                email: value,
            },
        }));
    }, [setViewModel])

    const markEmailDirty = useCallback(() => {
        setViewModel(viewModel => ({
            ...viewModel,
            billing: {
                ...viewModel.billing,
                emailDirty: true,
            },
        }));
    }, [setViewModel]);

    const changePhoneNumber = useCallback((value: string) => {
        setViewModel(viewModel => ({
            ...viewModel,
            billing: {
                ...viewModel.billing,
                phoneNumber: value,
            },
        }));
    }, [setViewModel])

    const markPhoneNumberDirty = useCallback(() => {
        setViewModel(viewModel => ({
            ...viewModel,
            billing: {
                ...viewModel.billing,
                phoneNumberDirty: true,
            },
        }));
    }, [setViewModel]);

    const changeStreet = useCallback((value: string) => {
        setViewModel(viewModel => ({
            ...viewModel,
            billing: {
                ...viewModel.billing,
                street: value,
            },
        }));
    }, [setViewModel])

    const markStreetDirty = useCallback(() => {
        setViewModel(viewModel => ({
            ...viewModel,
            billing: {
                ...viewModel.billing,
                streetDirty: true,
            },
        }));
    }, [setViewModel]);

    const changeZipCode = useCallback((value: string) => {
        setViewModel(viewModel => ({
            ...viewModel,
            billing: {
                ...viewModel.billing,
                zipCode: value,
            },
        }));
    }, [setViewModel])

    const markZipCodeDirty = useCallback(() => {
        setViewModel(viewModel => ({
            ...viewModel,
            billing: {
                ...viewModel.billing,
                zipCodeDirty: true,
            },
        }));
    }, [setViewModel]);

    const typeOptions = useMemo(() => [
        { label: "Privatperson", value: "person" as UpdateCustomerDialogViewModel["billing"]["type"] },
        { label: "Virksomhed", value: "company" as UpdateCustomerDialogViewModel["billing"]["type"] },
    ], []);
    const changeType = useCallback((type: "person" | "company") => {
        setViewModel(viewModel => ({
            ...viewModel,
            billing: {
                ...viewModel.billing,
                type,
                typeDirty: true 
            },
        }));
    }, [setViewModel]);

    return (
        <>
            <div id="billing-name-section">
                <Label htmlFor="billing-name" style={{ marginBottom: "8px" }}>Fakturerings-navn</Label>
                <Group error={Boolean(viewModel.billing.nameDirty && errorModel.billingName)}>
                    <TextField id="billing-name" value={viewModel.billing.name} onChange={changeName} onBlur={markNameDirty} />

                    <Collapse in={Boolean(viewModel.billing.nameDirty && errorModel.billingName)} timeout={validationAnimationDuration}>
                        <AlternativeHint message={errorModel.billingName} />
                    </Collapse>
                </Group>
            </div>

            <div id="billing-email-section">
                <Label htmlFor="billing-email" style={{ marginBottom: "8px" }}>Fakturerings-email</Label>
                <Group error={Boolean(viewModel.billing.emailDirty && errorModel.billingEmail)}>
                    <EmailField id="billing-email" value={viewModel.billing.email} onChange={changeEmail} onBlur={markEmailDirty} />

                    <Collapse in={Boolean(viewModel.billing.emailDirty && errorModel.billingEmail)} timeout={validationAnimationDuration}>
                        <AlternativeHint message={errorModel.billingEmail} />
                    </Collapse>
                </Group>
            </div>

            <div id="billing-phone-number-section">
                <Label htmlFor="billing-phone-number" style={{ marginBottom: "8px" }}>Fakturerings-telefon</Label>
                <Group error={Boolean(viewModel.billing.phoneNumberDirty && errorModel.billingPhoneNumber)}>
                    <TextField id="billing-phone-number" value={viewModel.billing.phoneNumber} onChange={changePhoneNumber} onBlur={markPhoneNumberDirty} />

                    <Collapse in={Boolean(viewModel.billing.phoneNumberDirty && errorModel.billingPhoneNumber)} timeout={validationAnimationDuration}>
                        <AlternativeHint message={errorModel.billingPhoneNumber} />
                    </Collapse>
                </Group>
            </div>

            <div className={classes.doubleGroup}>

                <div id="billing-street-section">
                    <Label htmlFor="billing-street" style={{ marginBottom: "8px" }}>Fakturerings-adresse</Label>
                    <Group error={Boolean(viewModel.billing.streetDirty && errorModel.billingStreet)}>
                        <TextField id="billing-street" value={viewModel.billing.street} onChange={changeStreet} onBlur={markStreetDirty} />

                        <Collapse in={Boolean(viewModel.billing.streetDirty && errorModel.billingStreet)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.billingStreet} />
                        </Collapse>
                    </Group>
                </div>

                <div id="billing-zip-code-section">
                    <Label htmlFor="billing-zip-code" style={{ marginBottom: "8px" }}>Fakturerings-postnummer</Label>
                    <Group error={Boolean(viewModel.billing.zipCodeDirty && errorModel.billingZipCode)}>
                        <TextField id="billing-zip-code" value={viewModel.billing.zipCode} onChange={changeZipCode} onBlur={markZipCodeDirty} />

                        <Collapse in={Boolean(viewModel.billing.zipCodeDirty && errorModel.billingZipCode)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.billingZipCode} />
                        </Collapse>
                    </Group>
                </div>

            </div>

            <div id="billing-type-section">
                <Label style={{ marginBottom: "8px" }}>Skal kunden faktureres som privatperson eller virksomhed?</Label>
                <Group error={Boolean(viewModel.billing.typeDirty && errorModel.billingType)}>

                    <TwoOptionSwitch options={typeOptions} value={viewModel.billing.type} onChange={changeType} />

                    <Collapse in={Boolean(viewModel.billing.typeDirty && errorModel.billingType)} timeout={validationAnimationDuration}>
                        <AlternativeHint message={errorModel.billingType} />
                    </Collapse>
                </Group>
            </div>

            {viewModel.billing.type === "company" ? (
                <CompanySubSection
                    viewModel={viewModel}
                    setViewModel={setViewModel}
                    errorModel={errorModel}
                    validationAnimationDuration={validationAnimationDuration}
                />
            ) : null}

        </>
    );
};
