import { PropsWithChildren } from "react";

import { Grid } from "@material-ui/core";

export const ResponsiveGrid = (props: PropsWithChildren<{}>) => {
    const { children } = props;

    return (
        <Grid container spacing={4}>
            {children}
        </Grid>
    );
};
