import { useMemo } from "react";

import { PageFormItem, GroupFormItem, TextAreaFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { NoticeFormItem } from "../../components/form-item/client/form-item/NoticeFormItemComponent";

export interface SpecifyDescriptionFormIds {
    TextAreaDescription: string;
}

export type SpecifyDescriptionForm<NextPage extends FormItem> = SpecifyDescriptionPage<NextPage>;

type SpecifyDescriptionPage<NextPage extends FormItem> =
    PageFormItem<
        GroupFormItem<[
            NoticeFormItem,
            TextAreaFormItem,
            NextPage,
        ]>
    >

export const useSpecifyDescriptionForm = <T extends FormItem>(nextPage: T, formIds: SpecifyDescriptionFormIds): SpecifyDescriptionForm<T> => {
    return useMemo(() => {

        const page: SpecifyDescriptionPage<T> = {
            type: "page",
            title: "Angiv beskrivelse",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "notice",
                        render: "warning",
                        html: "Vær opmærksom på, at der <strong>ikke tilbydes træfældning</strong> gennem Go Go Garden.",
                    },
                    {
                        id: formIds.TextAreaDescription,
                        type: "text-area",
                        render: "text-area",
                        label: "Beskrivelse af opgaven",
                        placeholder: "Kunden ønsker hjælp til ...",
                        value: "",
                        dirty: false,
                    },
                    nextPage,
                ],
            },
        };
        
        return page;
    }, [nextPage, formIds]);
}
