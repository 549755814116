import { useEffect, useState } from "react";

import firebase from "firebase/compat/app";

import { AdminConfigurationDocument } from "./configuration.model";

export const useAdminConfiguration = () => {
    const [state, setState] = useState<AdminConfigurationDocument | null>();

    useEffect(() => {
        setState(undefined);

        return firebase.firestore().collection("application").doc("configuration").collection("admin").doc("configuration").onSnapshot(document => {
            if ( !document.exists ) return null;

            setState({ id: document.id, ...document.data() } as AdminConfigurationDocument);
        });
    }, []);

    return state;
};
