import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { GardeningTaskClosedCommentDocument } from "../comment.model";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { formatDuration, formatFrequency, formatRequiredSkills, formatTools } from "../../gardening-task/utility";

const useStyles = makeStyles({
    label: {
        fontWeight: 600,
    },
});

interface GardeningTaskClosedCommentProps {
    comment: GardeningTaskClosedCommentDocument;
}

export const GardeningTaskClosedComment = (props: GardeningTaskClosedCommentProps) => {
    const classes = useStyles();
    const { comment } = props;

    const customer = <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link>;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Opgave lukket">
            <CommentBody>
                Følgende opgave fra kunden {customer} er blevet lukket.<br />
                <br />

                <span className={classes.label}>Adresse:</span> {comment.data.address.street}, {comment.data.address.zipCode}<br />
                <span className={classes.label}>Opgaver:</span> {formatRequiredSkills(comment.data.requiredSkills)}<br />
                <span className={classes.label}>Varighed:</span> {formatDuration(comment.data.hourInterval)}<br />
                <span className={classes.label}>Redskaber:</span> {formatTools(comment.data.tools)}<br />
                <span className={classes.label}>{comment.data.frequency === "once" ? "Engangsopgave:" : "Fast opgave:"}</span> {formatFrequency(comment.data.frequency)}<br />
                <br />

                <span className={classes.label}>Beskrivelse af opgaven:</span><br />
                “{comment.data.description}”
            </CommentBody>
        </Comment>
    );
};
