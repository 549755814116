import { GardenerDocument } from "../../gardener/gardener.model";
import { validateMandatory, Validation } from "../../components/validation/validation";

export const validateGardenerId = (gardenerId: string, gardener: GardenerDocument | null | undefined): Validation => {
    const mandatoryError = validateMandatory(gardenerId);
    if ( mandatoryError ) return { severity: "error", message: mandatoryError };

    if ( gardener === undefined ) return undefined;
    if ( gardener === null ) return { severity: "error", message: "Der findes ikke en havemand med dette id" };

    return { severity: "warning", message: "Du må kun gøre dette efter aftale med havemanden" };
};
