import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import firebase from "firebase/compat/app";

import { LinearProgress } from "../components/LinearProgress";

const useQuery = () => {
    const location = useLocation();

    return new URLSearchParams(location.search);
};

export const SignIn = () => {
    const query = useQuery();
    const token = query.get("token");
    const returnUrl = query.get("returnUrl") || "https://admin.gogogarden.dk";

    const history = useHistory();

    useEffect(() => {
        (async () => {
            await firebase.auth().signOut();

            if ( !token ) throw new Error("Needs a token to sign in");
            await firebase.auth().signInWithCustomToken(token);

            const returnPath = new URL(returnUrl).pathname;
            if ( returnPath.startsWith("/gb") ) return window.open(returnPath, "_self");
            if ( returnPath.startsWith("/au") ) return window.open(returnPath, "_self");

            history.replace(returnPath);
        })()
            .catch(() => history.replace("/logout"));

    }, [history, token, returnUrl]);

    return <LinearProgress />;
};
