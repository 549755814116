import { useParams } from "react-router-dom";
import { useGardeningTask } from "../gardening-task.hook";
import { GardeningTaskDetailPage } from "./GardeningTaskDetailPage";
import { useCustomer } from "../../customer/customers.hook";
import { useGardener } from "../../gardener/gardeners.hook";
import { LinearProgress } from "../../components/LinearProgress";
import { ErrorPage } from "../../ErrorPage";
import { useCallback } from "react";
import { EvaluateGardenerTab, useNavigation } from "../../navigation.hook";
import { useAdministratorAction } from "../../workflow/administrator-action.hook";

export const IntegratedEvaluateGardenerGardeningTaskDetailPage = () => {
    const { tab, taskId, gardeningTaskId } = useParams<{ tab: EvaluateGardenerTab, taskId: string, gardeningTaskId: string }>();

    const { goToEvaluateGardenerTask } = useNavigation();

    const goBack = useCallback(() => {
        goToEvaluateGardenerTask(tab, taskId)
    }, [goToEvaluateGardenerTask, tab, taskId]);

    const gardeningTask = useGardeningTask(gardeningTaskId);
    const customer = useCustomer(gardeningTask && gardeningTask.customerId);
    const gardener = useGardener(gardeningTask && gardeningTask.matchmaking.selectedGardenerId);
    const administratorAction = useAdministratorAction();

    if ( gardeningTask === null ) return <ErrorPage message="Opgaven kunne ikke findes" />;
    if ( customer === null ) return <ErrorPage message="Kunden kunne ikke findes" />;
    if ( gardeningTask?.matchmaking.selectedGardenerId && gardener === null ) return <ErrorPage message="Havemanden kunne ikke findes" />;

    if ( gardeningTask === undefined || customer === undefined || gardener === undefined ) return <LinearProgress />;

    return (
        <GardeningTaskDetailPage
            gardeningTask={gardeningTask}
            customer={customer}
            gardener={gardener}
            goBack={goBack}
            administratorAction={administratorAction}
        />
    );
};