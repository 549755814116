import { Link } from "react-router-dom";

import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { MessageSentCommentDocument } from "../comment.model";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    comment: {
        fontStyle: "italic",
    },
})

interface MessageSentCustomerCommentProps {
    comment: MessageSentCommentDocument;
}

export const MessageSentCustomerComment = (props: MessageSentCustomerCommentProps) => {
    const classes = useStyles();

    const { comment } = props;

    const gardenerLink = <Link to={`/gardeners/context/${comment.gardenerId}`}>{comment.gardenerName}</Link>;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Besked til Go Go Garden">
            <CommentBody>
                {gardenerLink} har sendt en besked angående kunden.<br />
                <br />
                Besked fra havemanden:<br />
                <span className={classes.comment}>“{comment.message}”</span>
            </CommentBody>
        </Comment>
    );
};
