export class ListFormatter {

    public formatList(list: string[]): string {
        if ( list.length === 0 ) throw new Error("Expected list to consist of elements but was empty");
        if ( list.length === 1 ) return list[0];

        const listedParts = list.slice(0, list.length - 1).join(", ");
        const lastPart = list[list.length - 1];

        return `${listedParts} og ${lastPart}`;
    }

}
