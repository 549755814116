import { Comment } from "../components/comment/Comment";
import { CommentBody } from "../components/comment/CommentBody";
import { GardenerApplicationApprovedCommentDocument } from "./comment.model";

interface GardenerApplicationApprovedCommentProps {
    comment: GardenerApplicationApprovedCommentDocument;
}

export const GardenerApplicationApprovedComment = (props: GardenerApplicationApprovedCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Ansøgning godkendt" author={comment.administratorName ?? undefined}>
            <CommentBody>Havemanden er onboardet og klar til at modtage opgaver.</CommentBody>
        </Comment>
    );
};
