import { useCallback } from "react";

import firebase from "firebase/compat/app";

import { useBlockingProgress } from "../../components/blocking-progress";
import { useSnackbar } from "../../components/snackbar";

export interface CheckInvoicePaymentData {
    invoiceId: string;
}

export interface CheckInvoicePaymentResult {
    principal: number;
    fees: number;
    transactions: PaymentTransaction[],
    externalDebtCollectionDate: string | null;
}

export interface PaymentTransaction {
    type: "payment" | "credit-note" | "voucher" | "write off",
    date: string;
    amount: number;
}

const checkInvoicePayment: (data: CheckInvoicePaymentData) => Promise<firebase.functions.HttpsCallableResult>
    = firebase.app().functions("europe-west3").httpsCallable("Billing-checkInvoicePayment");

export type CheckInvoicePaymentFunction = (invoiceId: string, callback: (result: CheckInvoicePaymentResult) => void) => void;

export const useCheckInvoicePaymentFunction = (): CheckInvoicePaymentFunction => {
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    return useCallback<CheckInvoicePaymentFunction>((invoiceId, callback) => {
        block();

        return checkInvoicePayment({ invoiceId })
            .then((result) => {
                unblock();

                callback(result.data);
            })
            .catch(() => {
                openSnackbar("error", "Der skete en fejl. Kunne ikke tjekke betaling.");
                unblock();
            });

    }, [block, unblock, openSnackbar]);
};
