import { Link } from "react-router-dom";

import { Comment } from "../components/comment/Comment";
import { OfferExpiredCommentDocument } from "./comment.model";
import { CommentBody } from "../components/comment/CommentBody";

interface OfferExpiredGardenerCommentProps {
    comment: OfferExpiredCommentDocument;
}

export const OfferExpiredGardenerComment = (props: OfferExpiredGardenerCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Nej tak (intet svar)">
            <CommentBody>
                Havemanden besvarede ikke tilbuddet inden for 24 timer.<br /><br />

                Det er registreret som nej tak til <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link>.
            </CommentBody>
        </Comment>
    );
};
