import { useEffect, useState } from "react";

import firebase from "firebase/compat/app";

import { TaskDocument } from "./task.model";

export const useOpenTasksByTypes = <
    Type extends TaskDocument["type"],
    Task extends Extract<TaskDocument, { type: Type }>,
>(types: Type[]) => {
    const [state, setState] = useState<Task[]>();

    useEffect(() => {
        setState(undefined);

        return firebase.firestore()
            .collection("tasks")
                .where("type", "in", types)
                .where("hasBeenPerformed", "==", false)
            .onSnapshot(collection => {
                const documents = collection.docs.map(document => ({ id: document.id, ...document.data() } as Task));

                setState(documents);
            });
    }, [types]);

    return state;
}

export const useOpenTasksByType = <
    Type extends TaskDocument["type"],
    Task extends Extract<TaskDocument, { type: Type }>,
>(type: Type) => {
    const [state, setState] = useState<Task[]>();

    useEffect(() => {
        setState(undefined);

        return firebase.firestore()
            .collection("tasks")
                .where("type", "==", type)
                .where("hasBeenPerformed", "==", false)
            .onSnapshot(collection => {
                const documents = collection.docs.map(document => ({ id: document.id, ...document.data() } as Task));

                setState(documents);
            });
    }, [type]);

    return state;
}

export const useTask = (taskId: string) => {
    const [state, setState] = useState<TaskDocument | null>();

    useEffect(() => {
        setState(undefined);

        return firebase.firestore().collection("tasks").doc(taskId).onSnapshot(document => {
            if ( !document.exists ) return setState(null);

            const task = { id: document.id, ...document.data() } as TaskDocument;

            setState(task);
        });
    }, [taskId]);

    return state;
};
