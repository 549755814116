import { Link } from "react-router-dom";

import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { MatchPostponementCommentDocument } from "../comment.model";

interface MatchPostponementCommentProps {
    comment: MatchPostponementCommentDocument;
}

export const MatchPostponementComment = (props: MatchPostponementCommentProps) => {
    const { comment } = props;

    const gardener = <Link to={`/gardeners/context/${comment.gardenerId}`}>{comment.gardenerName}</Link>;
    const customer = <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link>;

    const attempt = (() => {
        switch ( comment.numberOfPostponements ) {
            case 1: return "første forsøg";
            case 2: return "andet forsøg";
            case 3: return "tredje forsøg";
            default: return "forsøg";
        }
    })();

    const submessage = (() => {
        if ( comment.numberOfPostponements === 3 ) return "Vi er derfor i tvivl om kunden stadig ønsker hjælp.";
        if ( comment.gaveUp ) return "Havemanden vil ikke kontakte kunden igen.";

        return "Havemanden forventes at kontakte kunden igen.";
    })();

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Havemanden fik ikke fat i kunden">
            <CommentBody>

                Det lykkedes ikke {gardener} at få fat i {customer} i {attempt}. {submessage}

                {comment.numberOfPostponements === 3 || comment.gaveUp ? (
                    <>
                        <br />
                        <br />
                        Kunden fjernes fra havemandens liste. Der sendes automatisk en email og sms til kunden.
                    </>
                ) : null}
            </CommentBody>
        </Comment>
    );
};
