import { makeStyles } from "@material-ui/core";
import moment from "moment";

import { Card } from "../../../components/card/Card";
import { CardContent } from "../../../components/card/CardContent";
import { CardHeader } from "../../../components/card/CardHeader";
import { InvoiceDocument, Service } from "../invoices/invoice.model";

const useStyles = makeStyles({
    content: {
        "& > *": {
            marginBottom: "16px",
        },

        "& > *:last-child": {
            marginBottom: 0,
        }
    },
    heading: {
        fontSize: "16px",
        fontWeight: 500,
        color: "#4A4A4A",
        marginTop: 0,
    },
    task: {
        "& > h4": {
            fontSize: "16px",
            fontWeight: 500,
            color: "#4A4A4A",
            marginTop: 0,
            marginBottom: "4px",
        },

        "& > p": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
            margin: 0,
        },
    },
});

interface RegistrationDetailCardProps {
    invoice: InvoiceDocument;
    gardenerName: string;
    alert?: JSX.Element;
}

export const RegistrationDetailCard = (props: RegistrationDetailCardProps) => {
    const classes = useStyles();

    const { invoice, gardenerName, alert } = props;

    const formattedDate = moment(invoice.serviceDate, "YYYY-MM-DD").format("D. MMMM YYYY");

    return (
        <Card>
            <CardHeader>Tidsregistrering for faktura {invoice.invoiceNumber}</CardHeader>

            {alert}

            <CardContent className={classes.content}>

                <p className={classes.heading}>Udført af {gardenerName} d. {formattedDate}</p>

                {invoice.services.map((service, index) => (
                    <div key={index} className={classes.task}>
                        <h4>{service.description}</h4>
                        <p>{formatServiceScope(service)}</p>
                    </div>
                ))}

            </CardContent>

        </Card>
    );
};

const formatServiceScope = (service: Service) => {
    const unit = service.hours === 1 ? "time" : "timer";

    const tools = (() => {
        switch ( service.tools ) {
            case "customer-tools": return "kundens redskaber";
            case "gardener-tools": return "havemandens redskaber";
            case "gardener-large-tools": return "havemandens store redskaber";
        }
    })();

    return `${service.hours.toString().replace(/\./g, ",")} ${unit} med ${tools}`;
}
