import { Link } from "react-router-dom";

import { Comment } from "../../components/comment/Comment";
import { OfferDeclinedCommentDocument } from "../comment.model";
import { CommentBody } from "../../components/comment/CommentBody";
import { makeStyles } from "@material-ui/core";
import { DeclineMatchReason } from "../../event/event.model";

const useStyles = makeStyles({
    label: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        lineHeight: "125%",
        marginTop: 0,
        marginBottom: "4px !important",
    },
    comment: {
        fontStyle: "italic",
    },
    list: {
        listStyle: "none",
        paddingLeft: "8px",
        margin: 0,
    },
    listItem: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        lineHeight: "125%",

        "&::before": {
            content: '"\\2022"',
            fontWeight: 600,
            marginRight: "8px",
        },
    },
})

interface OfferDeclinedGardenerCommentProps {
    comment: OfferDeclinedCommentDocument;
}

export const OfferDeclinedGardenerComment = (props: OfferDeclinedGardenerCommentProps) => {
    const classes = useStyles();

    const { comment } = props;

    const reasons = comment.reasons?.sort((a, b) => {
        const sortOrder: DeclineMatchReason[] = ["do-not-understand", "do-not-have-time", "cannot-perform", "too-far-away-or-too-small", "other"];
        return sortOrder.indexOf(a) - sortOrder.indexOf(b);
    }) ?? [];

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Nej tak">
            <CommentBody>

                Havemanden har sagt nej tak til <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link>.
                
                {reasons.length > 0 ? (
                    <>
                        <br /><br />
                        <p className={classes.label}>Årsag:</p>
                        <ul className={classes.list}>
                            {reasons.map(reason => (
                                <li key={reason} className={classes.listItem}>
                                    {formatReason(reason)}
                                </li>
                            ))}
                        </ul>
                    </>
                ) : null}

                {comment.comment ? (
                    <>
                        <br />
                        Kommentar fra havemanden:<br />
                        <span className={classes.comment}>“{comment.comment}”</span>
                    </>
                ): null}

            </CommentBody>
        </Comment>
    );
};

function formatReason(reason: DeclineMatchReason): string {
    switch ( reason ) {
        case "do-not-understand": return "Jeg forstår ikke opgaven";
        case "do-not-have-time": return "Jeg har ikke tid til opgaven";
        case "cannot-perform": return "Jeg kan ikke løse opgaven";
        case "too-far-away-or-too-small": return "Opgaven er for langt væk / for lille";
        case "other": return "Andet";
    }
}
