import { useMemo } from "react";

import { PageFormItem, GroupFormItem, HorizontalButtonsSingleChoiceFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { NoticeFormItem } from "../../components/form-item/client/form-item/NoticeFormItemComponent";

export interface SpecifyToolsFormIds {
    SingleChoiceTools: string;
}

export type SpecifyToolsForm<NextPage extends FormItem> = SpecifyToolsPage<NextPage>;

type SpecifyToolsPage<NextPage extends FormItem> = PageFormItem<
    GroupFormItem<[
        NoticeFormItem,
        HorizontalButtonsSingleChoiceFormItem<[NextPage, NextPage]>,
    ]>
>;

export const useSpecifyToolsForm = <T extends FormItem>(nextPage: T, formIds: SpecifyToolsFormIds): SpecifyToolsForm<T> => {
    return useMemo(() => {

        const page: SpecifyToolsPage<T> = {
            type: "page",
            title: "Angiv redskaber",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "notice",
                        render: "warning",
                        html: "Det koster <strong>10% ekstra</strong>, hvis havemanden bruger egne redskaber.",
                    },
                    {
                        id: formIds.SingleChoiceTools,
                        type: "single-choice",
                        mode: "switch page",
                        render: "horizontal-buttons",
                        questionText: "Hvem stiller redskaber til rådighed?",
                        answerTexts: [
                            "Kunden",
                            "Havemanden",
                        ],
                        answerItems: [nextPage, nextPage],
                        selectedChoiceIndex: null,
                        dirty: false,
                    },
                ],
            },
        };
        
        return page;
    }, [nextPage, formIds]);
}
