import { makeStyles } from "@material-ui/core";

import { Card } from "../../../components/card/Card";
import { LegacyCardContent } from "../../../components/card/CardContent";
import { CustomerDocument } from "../../customer.model";
import { Address } from "../../../address.model";
import { CardHeader } from "../../../components/card/CardHeader";

const useStyles = makeStyles({
    group: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },

        "& > span": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",

            "& > a": {
                fontSize: "16px",
                fontWeight: 500,
                color: "#297A1D",
                textDecoration: "none",
        
                "&:hover": {
                    color: "#1C5B13",
                    textDecoration: "underline",
                },
            },
        },
    },
});

interface CustomerCardProps {
    customer: CustomerDocument;
    button?: JSX.Element;
}

export const CustomerCard = (props: CustomerCardProps) => {
    const classes = useStyles();

    const { customer, button } = props;

    return (
        <Card>
            <CardHeader button={button}>{customer.name}</CardHeader>

            <LegacyCardContent>

                <div className={classes.group}>
                    <label>Adresse</label>
                    <span>
                        <a href={gpsHref(customer.address)} target="_blank" rel="noreferrer">
                            {customer.address.street}<br />
                            {customer.address.zipCode}
                        </a>
                    </span>
                </div>

                <div className={classes.group}>
                    <label>Email</label>
                    <span>
                        <a href={`mailto:${customer.email}`}>{customer.email}</a>
                    </span>
                </div>

                <div className={classes.group}>
                    <label>Telefon</label>
                    <span>
                        <a href={`tel:${customer.phoneNumber}`}>{customer.phoneNumber}</a>
                    </span>
                </div>

            </LegacyCardContent>
        </Card>
    );
};

function gpsHref(address: Address): string {
    const query = `${address.street}, ${address.zipCode}`;

    return encodeURI(`https://maps.google.com/?q=${query}`);
}
