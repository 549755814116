import { DatePickerFormItemComponent } from "../form-item/DatePickerFormItemComponent";
import { DropdownFormItemComponent } from "../form-item/DropdownFormItemComponent";
import { FormItem } from "../../framework/core/form-item.model";
import { GroupFormItemComponent } from "../form-item/GroupFormItemComponent";
import { InfoFormItemComponent } from "../form-item/InfoFormItemComponent";
import { MultiChoiceFormItemComponent } from "../form-item/MultiChoiceFormItemComponent";
import { SingleChoiceFormItemComponent } from "../form-item/SingleChoiceFormItemComponent";
import { TextAreaFormItemComponent } from "../form-item/TextAreaFormItemComponent";
import { HorizontalButtonsFormItemComponent } from "../form-item/HorizontalButtonsFormItemComponent";
import { NoticeFormItemComponent } from "../form-item/NoticeFormItemComponent";
import { AddressPickerFormItemComponent } from "../form-item/AddressPickerFormItemComponent";
import { GardenerPickerFormItemComponent } from "../form-item/GardenerPickerFormItemComponent";

interface FormItemComponentProps {
    item: FormItem;
}

/**
 * TODO: This should probably live in FormController's render method.
 * It should work by delegating to FormItemSupports' render method.
*/
export const FormItemComponent = (props: FormItemComponentProps) => {
    const { item } = props;

    switch (item.type) {
        case "page": return null;
        case "group": return <GroupFormItemComponent item={item} />;
        case "single-choice": return <SingleChoiceFormItemComponent item={item} />;
        case "multi-choice": return <MultiChoiceFormItemComponent item={item} />;
        case "info": return <InfoFormItemComponent item={item} />;
        case "notice": return <NoticeFormItemComponent item={item} />;
        case "dropdown": return <DropdownFormItemComponent item={item} />;
        case "horizontal-buttons": return <HorizontalButtonsFormItemComponent item={item} />;
        case "date-picker": return <DatePickerFormItemComponent item={item} />;
        case "address-picker": return <AddressPickerFormItemComponent item={item} />;
        case "gardener-picker": return <GardenerPickerFormItemComponent item={item} />;
        case "text-area": return <TextAreaFormItemComponent item={item} />;
    }
};
