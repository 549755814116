import { useMemo } from "react";

import { PageFormItem, GroupFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { CustomerDocument } from "../customer.model";
import { AddressPickerFormItem } from "../../components/form-item/client/form-item/AddressPickerFormItemComponent";

export interface SpecifyAddressFormIds {
    AddressPickerAddress: string;
}

export type SpecifyAddressForm<NextPage extends FormItem> = SpecifyAddressPage<NextPage>;

type SpecifyAddressPage<NextPage extends FormItem> = PageFormItem<
    GroupFormItem<[
        AddressPickerFormItem,
        NextPage
    ]>
>;

export const useSpecifyAddressForm = <T extends FormItem>(customer: CustomerDocument, nextPage: T, formIds: SpecifyAddressFormIds): SpecifyAddressForm<T> => {
    return useMemo(() => {    
        const page: SpecifyAddressPage<T> = {
            type: "page",
            title: "Angiv adresse",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        id: formIds.AddressPickerAddress,
                        type: "address-picker",
                        label: "Adresse, hvor opgaven skal udføres",
                        searchTerm: `${customer.address.street}, ${customer.address.zipCode}`,
                        value: null,
                        dirty: false,
                        validation: "mandatory",
                    },
                    nextPage,
                ],
            },
        };
        
        return page;
    }, [customer, nextPage, formIds]);
}
