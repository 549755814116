import { useEffect, useMemo, useRef, useState } from "react";

import firebase from "firebase/compat/app";
import moment from "moment";

import { LinearProgress } from "../components/LinearProgress";
import { ResponsiveGrid } from "../components/responsive-grid/ResponsiveGrid";
import { ResponsiveGridItem } from "../components/responsive-grid/ResponsiveGridItem";
import { Section } from "../components/section/Section";
import { SectionHeaderAlternative } from "../components/section/SectionHeaderAlternative";
import { useEventsByTypeInTimePeriod } from "../event/event.hook";
import { EventDocument } from "../event/event.model";
import { MetricList } from "./metrics/MetricList";
import { MetricListItem } from "./metrics/MetricListItem";
import { ResponsiveGridBreak } from "../components/responsive-grid/ResponsiveGridBreak";

interface GetStatusOfTasksFunctionResult {
    mightBeRejected: number,
    lookingForGardener: number,
    schedulingVisit: number,
    performingVisit: number,
}

const getStatusOfTasksFunction: () => Promise<firebase.functions.HttpsCallableResult>
    = firebase.app().functions("europe-west3").httpsCallable("Dashboard-getStatusOfTasks");

export const CustomerMetricsSection = () => {
    const customersLastThreeDays = useCustomersLastThreeDays();
    const customersLastTwentyOneDays = useCustomersLastTwentyOneDays();
    const customersByStatus = useCustomersByStatus();

    if ( !customersLastThreeDays || !customersLastTwentyOneDays || !customersByStatus ) return <LinearProgress />;

    return (
        <Section>
            <SectionHeaderAlternative>kunder</SectionHeaderAlternative>

            <ResponsiveGrid>

                <ResponsiveGridItem units={1}>
                    <MetricList title="I dag">
                        <MetricListItem name="Nye" value={customersLastThreeDays.newCustomers.today} color="green" />
                        <MetricListItem name="Bestilt igen" value={customersLastThreeDays.reorderedCustomers.today} color="blue" />
                        <MetricListItem name="Stalled" value={customersLastThreeDays.stalledCustomers.today} color="orange" />
                        <MetricListItem name="Mistede" value={customersLastThreeDays.lostCustomers.today} color="red" />
                    </MetricList>
                </ResponsiveGridItem>

                <ResponsiveGridItem units={1}>
                    <MetricList title="I går">
                        <MetricListItem name="Nye" value={customersLastThreeDays.newCustomers.yesterday} color="green" />
                        <MetricListItem name="Bestilt igen" value={customersLastThreeDays.reorderedCustomers.yesterday} color="blue" />
                        <MetricListItem name="Stalled" value={customersLastThreeDays.stalledCustomers.yesterday} color="orange" />
                        <MetricListItem name="Mistede" value={customersLastThreeDays.lostCustomers.yesterday} color="red" />
                    </MetricList>
                </ResponsiveGridItem>

                <ResponsiveGridItem units={1}>
                    <MetricList title="I forgårs">
                        <MetricListItem name="Nye" value={customersLastThreeDays.newCustomers.twoDaysAgo} color="green" />
                        <MetricListItem name="Bestilt igen" value={customersLastThreeDays.reorderedCustomers.twoDaysAgo} color="blue" />
                        <MetricListItem name="Stalled" value={customersLastThreeDays.stalledCustomers.twoDaysAgo} color="orange" />
                        <MetricListItem name="Mistede" value={customersLastThreeDays.lostCustomers.twoDaysAgo} color="red" />
                    </MetricList>
                </ResponsiveGridItem>

                <ResponsiveGridBreak />

                <ResponsiveGridItem units={1}>
                    <MetricList title="Seneste 7 dage">
                        <MetricListItem name="Nye" value={customersLastTwentyOneDays.newCustomers.lastSevenDays} color="green" />
                        <MetricListItem name="Bestilt igen" value={customersLastTwentyOneDays.reorderedCustomers.lastSevenDays} color="blue" />
                        <MetricListItem name="Stalled" value={customersLastTwentyOneDays.stalledCustomers.lastSevenDays} color="orange" />
                        <MetricListItem name="Mistede" value={customersLastTwentyOneDays.lostCustomers.lastSevenDays} color="red" />
                    </MetricList>
                </ResponsiveGridItem>

                <ResponsiveGridItem units={1}>
                    <MetricList title="Seneste 7-14 dage">
                        <MetricListItem name="Nye" value={customersLastTwentyOneDays.newCustomers.lastSevenToFourteenDays} color="green" />
                        <MetricListItem name="Bestilt igen" value={customersLastTwentyOneDays.reorderedCustomers.lastSevenToFourteenDays} color="blue" />
                        <MetricListItem name="Stalled" value={customersLastTwentyOneDays.stalledCustomers.lastSevenToFourteenDays} color="orange" />
                        <MetricListItem name="Mistede" value={customersLastTwentyOneDays.lostCustomers.lastSevenToFourteenDays} color="red" />
                    </MetricList>
                </ResponsiveGridItem>

                <ResponsiveGridItem units={1}>
                    <MetricList title="Seneste 14-21 dage">
                        <MetricListItem name="Nye" value={customersLastTwentyOneDays.newCustomers.lastFourteenToTwentyOneDays} color="green" />
                        <MetricListItem name="Bestilt igen" value={customersLastTwentyOneDays.reorderedCustomers.lastFourteenToTwentyOneDays} color="blue" />
                        <MetricListItem name="Stalled" value={customersLastTwentyOneDays.stalledCustomers.lastFourteenToTwentyOneDays} color="orange" />
                        <MetricListItem name="Mistede" value={customersLastTwentyOneDays.lostCustomers.lastFourteenToTwentyOneDays} color="red" />
                    </MetricList>
                </ResponsiveGridItem>

                <ResponsiveGridBreak />

                <ResponsiveGridItem units={1}>
                    <MetricList title="Status på aktuelle opgaver">
                        <MetricListItem name="Opgaver som måske afvises" value={customersByStatus.mightBeRejected} color="orange" />
                        <MetricListItem name="Leder efter havemand" value={customersByStatus.lookingForGardener} color="blue" />
                        <MetricListItem name="Besøg endnu ikke planlagt" value={customersByStatus.schedulingVisit} color="blue" />
                        <MetricListItem name="Besøg planlagt" value={customersByStatus.performingVisit} color="blue" />
                    </MetricList>
                </ResponsiveGridItem>

            </ResponsiveGrid>

        </Section>
    );
};

function useCustomersLastThreeDays() {
    const twoDaysAgo = moment().subtract(2, "days").startOf("day").toISOString(true);
    const tomorrow = moment().add(1, "day").startOf("day").toISOString(true);
    
    const customerAcquiredEvents = useEventsByTypeInTimePeriod("customer-acquired", twoDaysAgo, tomorrow);
    const newCustomers = usePartitionedEventsLastThreeDays(customerAcquiredEvents);

    const reorderedEvents = useEventsByTypeInTimePeriod("repeat-order", twoDaysAgo, tomorrow);
    const reorderedCustomers = usePartitionedEventsLastThreeDays(reorderedEvents);

    const orderInProgressEvents = useEventsByTypeInTimePeriod("customer-order-in-progress", twoDaysAgo, tomorrow);
    const stalledCustomers = usePartitionedEventsLastThreeDays(orderInProgressEvents);

    const customerLostEventsPastTwoDays = useEventsByTypeInTimePeriod("customer-lost", twoDaysAgo, tomorrow);
    const lostCustomers = usePartitionedEventsLastThreeDays(customerLostEventsPastTwoDays);

    return useMemo(() => {
        if ( !newCustomers || !reorderedCustomers || !stalledCustomers || !lostCustomers ) return undefined;

        return { newCustomers, reorderedCustomers, stalledCustomers, lostCustomers };
    }, [newCustomers, reorderedCustomers, stalledCustomers, lostCustomers]);
}

function usePartitionedEventsLastThreeDays(events: EventDocument[] | undefined) {
    const twoDaysAgo = moment().subtract(2, "days").startOf("day").toISOString(true);
    const yesterday = moment().subtract(1, "day").startOf("day").toISOString(true);
    const today = moment().startOf("day").toISOString(true);
    const tomorrow = moment().add(1, "day").startOf("day").toISOString(true);

    return useMemo(() => {
        if ( !events ) return undefined;

        return {
            twoDaysAgo: events.filter(event => event.creationDate >= twoDaysAgo && event.creationDate < yesterday).length,
            yesterday: events.filter(event => event.creationDate >= yesterday && event.creationDate < today).length,
            today: events.filter(event => event.creationDate >= today && event.creationDate < tomorrow).length,
        };
    }, [events, twoDaysAgo, yesterday, today, tomorrow]);
}

function useCustomersLastTwentyOneDays() {
    const twentyOne = moment().subtract(21, "days").toISOString(true);
    const fourteen = moment().subtract(14, "day").toISOString(true);
    const seven = moment().subtract(7, "day").toISOString(true);
    const zero = moment().toISOString(true);
    const daysAgo = useRef({ zero, seven, fourteen, twentyOne }).current;
    
    const customerAcquiredEvents = useEventsByTypeInTimePeriod("customer-acquired", daysAgo.twentyOne, daysAgo.zero);
    const newCustomers = usePartitionedEventsLastTwentyOneDaysAgo(customerAcquiredEvents, daysAgo);

    const reorderedEvents = useEventsByTypeInTimePeriod("repeat-order", daysAgo.twentyOne, daysAgo.zero);
    const reorderedCustomers = usePartitionedEventsLastTwentyOneDaysAgo(reorderedEvents, daysAgo);

    const orderInProgressEvents = useEventsByTypeInTimePeriod("customer-order-in-progress", daysAgo.twentyOne, daysAgo.zero);
    const stalledCustomers = usePartitionedEventsLastTwentyOneDaysAgo(orderInProgressEvents, daysAgo);

    const customerLostEventsPastTwoDays = useEventsByTypeInTimePeriod("customer-lost", daysAgo.twentyOne, daysAgo.zero);
    const lostCustomers = usePartitionedEventsLastTwentyOneDaysAgo(customerLostEventsPastTwoDays, daysAgo);

    return useMemo(() => {
        if ( !newCustomers || !reorderedCustomers || !stalledCustomers || !lostCustomers ) return undefined;

        return { newCustomers, reorderedCustomers, stalledCustomers, lostCustomers };
    }, [newCustomers, reorderedCustomers, stalledCustomers, lostCustomers]);
}

function usePartitionedEventsLastTwentyOneDaysAgo(
    events: EventDocument[] | undefined,
    daysAgo: { zero: string, seven: string, fourteen: string, twentyOne: string },
) {
    return useMemo(() => {
        if ( !events ) return undefined;

        return {
            lastFourteenToTwentyOneDays: events.filter(event => event.creationDate >= daysAgo.twentyOne && event.creationDate < daysAgo.fourteen).length,
            lastSevenToFourteenDays: events.filter(event => event.creationDate >= daysAgo.fourteen && event.creationDate < daysAgo.seven).length,
            lastSevenDays: events.filter(event => event.creationDate >= daysAgo.seven && event.creationDate < daysAgo.zero).length,
        };
    }, [events, daysAgo]);
}

function useCustomersByStatus() {
    const [state, setState] = useState<GetStatusOfTasksFunctionResult>();

    const hasTerminated = useRef(false);
    useEffect(() => {
        hasTerminated.current = false;

        getStatusOfTasksFunction().then(result => {
            if ( !hasTerminated.current ) setState(result.data);
        });

        return () => {
            hasTerminated.current = true;
        };
    }, []);

    return state;
}
