import { Container, makeStyles } from "@material-ui/core";
import moment from "moment";

import { ContentSection } from "../../../layout/ContentSection";
import { LinearProgress } from "../../../components/LinearProgress";
import { SectionHeader } from "../../../components/section/SectionHeader";
import { Section } from "../../../components/section";
import { ListItem } from "./ListItem";
import { useDeletedCustomers } from "../../customers.hook";
import { useMemo } from "react";
import { DeletedCustomerDocument } from "../../customer.model";

const useStyles = makeStyles({
    list: {
        "& > *": {
            marginBottom: "32px",
        },

        "& > *:last-child": {
            marginBottom: 0,
        },
    },
});

export const CustomersPageDeletedTab = () => {
    const classes = useStyles();

    const customersByDeletionDate = useDeletedCustomersGroupedByDay();

    if ( customersByDeletionDate === undefined ) return <LinearProgress />;

    return (
        <ContentSection>
            <Container maxWidth="sm" disableGutters className={classes.list}>

                {customersByDeletionDate.map(({ day, customers }) => (
                    <Section key={day}>
                        <SectionHeader>{formatDate(day)}</SectionHeader>

                        {customers.map(customer => (
                            <ListItem key={customer.id} customer={customer} />
                        ))}
                    </Section>
                ))}

            </Container>
        </ContentSection>
    );
};

function formatDate(date: string): string {
    const text = moment(date).format("dddd D. MMMM YYYY");

    return text.substring(0, 1).toUpperCase() + text.substring(1);
}

function useDeletedCustomersGroupedByDay() {
    const customers = useDeletedCustomers();

    return useMemo(() => {
        if ( !customers ) return undefined;

        const dayMap: { [day: string]: DeletedCustomerDocument[] } = {};

        customers.forEach(customer => {
            const day = moment(customer.deletedDate).startOf("day").toISOString();

            const customersForDay = dayMap[day] || [];
            customersForDay.push(customer);

            dayMap[day] = customersForDay;
        })

        return Object.keys(dayMap).sort().reverse().map(day => {
            const customersForDay = dayMap[day].sort((a, b) => {
                if ( a.deletedDate < b.deletedDate ) return -1;
                if ( a.deletedDate > b.deletedDate ) return 1;
                return 0;
            }).reverse();

            return { day, customers: customersForDay };
        });

    }, [customers])
};
