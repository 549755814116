import { useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";

import Big from "big.js";

import { InvoiceDetailPage } from "./InvoiceDetailPage";
import { useInvoice } from "../../finance/billing/invoices/invoices.hook";
import { useGardener } from "../../gardener/gardeners.hook";
import { useRegistration } from "../../finance/billing/registrations.hook";
import { useGardenerPrice } from "../../finance/billing/registration/gardener-price.hooks";
import { useCustomer } from "../../customer/customers.hook";
import { useCustomerPrice } from "../../finance/billing/registration/customer-price.hooks";
import { useDineroContact } from "../../finance/billing/pending-approval/dinero-contacts.hook";
import { useUpdateInvoiceAction } from "../../finance/billing/invoices/invoice-detail/update-invoice/update-invoice.hook";
import { NotFoundPage } from "../../NotFoundPage";
import { LinearProgress } from "../../components/LinearProgress";
import { useCancelInvoiceAction } from "../../finance/billing/invoices/invoice-detail/cancel-invoice/cancel-invoice-action.hook";
import { useComplaintWithInvoiceId } from "../../complaint/complaints.hook";
import { useAdministratorAction } from "../../workflow/administrator-action.hook";
import { useCheckInvoicePaymentFunction } from "./check-invoice-payment.function";
import { useIsSuperAdministrator } from "../../LinkProvider";
import { useReissueInvoiceFunction } from "../../finance/billing/invoices/invoice-detail/reissue-invoice/reissue-invoice.function";
import { useResendInvoiceFunction } from "../../finance/billing/invoices/invoice-detail/reissue-invoice/resend-invoice.function";
import { useGardeningTask } from "../../gardening-task/gardening-task.hook";
import { ErrorPage } from "../../ErrorPage";
import { GardenerPriceDocument } from "../../finance/billing/registration/gardener-price.model";

export const IntegratedInvoiceDetailPage = () => {
    const history = useHistory();
    const goBack = useCallback(() => {
        history.push("/invoices/all");
    }, [history]);

    const { invoiceId } = useParams<{ invoiceId: string }>();
    const invoice = useInvoice(invoiceId);
    const complaint = useComplaintWithInvoiceId(invoice && invoice.id);

    const gardener = useGardener(invoice && invoice.gardener.id);

    const gardeningTask = useGardeningTask(invoice && invoice.gardeningTaskId);
    const registration = useRegistration(invoice && invoice.registrationId);
    const gardenerPrice = useGardenerPrice(gardener && gardener.id, registration && registration.creationDate);
    const actualGardenerPrice = useMemo((): GardenerPriceDocument | null | undefined => {
        if ( gardeningTask === null || gardenerPrice === null ) return null;
        if ( gardeningTask === undefined || gardenerPrice === undefined ) return undefined;

        if ( !gardeningTask.working.backstopper ) return gardenerPrice;

        return {
            ...gardenerPrice,
            customerTools: Big(gardenerPrice.customerTools).times(1.25).round(2).toNumber(),
            gardenerTools: Big(gardenerPrice.gardenerTools).times(1.25).round(2).toNumber(),
            gardenerLargeTools: Big(gardenerPrice.gardenerLargeTools).times(1.25).round(2).toNumber(),
        };
    }, [gardeningTask, gardenerPrice]);

    const customer = useCustomer(invoice?.customer.id);
    const customerPrice = useCustomerPrice(customer && customer.id, registration && registration.creationDate);
    const contact = useDineroContact(customer && customer.id);

    const openInvoiceInNewTab = useCallback(() => {
        if ( !invoice ) return;

        return window.open(`https://gogogarden.dk/billing/invoices/invoice?id=${invoice.id}`, "_blank");
    }, [invoice]);

    const updateInvoiceAction = useUpdateInvoiceAction();
    const cancelInvoiceAction = useCancelInvoiceAction(invoice && invoice.id);
    const administratorAction = useAdministratorAction();
    const checkInvoicePaymentFunction = useCheckInvoicePaymentFunction();
    const reissueInvoiceFunction = useReissueInvoiceFunction();
    const resendInvoiceFunction = useResendInvoiceFunction();
    const isSuperAdministrator = useIsSuperAdministrator();

    if ( invoice === null ) return <NotFoundPage />;
    if ( gardeningTask === null ) return <ErrorPage message="Kunne ikke finde opgaven" />;
    if ( invoice === undefined || complaint === undefined || gardener === undefined || gardeningTask === undefined || actualGardenerPrice === undefined || customer === undefined || customerPrice === undefined || contact === undefined ) return <LinearProgress />;

    return (
        <InvoiceDetailPage
            invoice={invoice}
            complaint={complaint}
            gardener={gardener}
            gardenerPrice={actualGardenerPrice}
            customer={customer}
            customerPrice={customerPrice}
            contact={contact}
            updateInvoiceAction={updateInvoiceAction}
            cancelInvoiceAction={cancelInvoiceAction}
            administratorAction={administratorAction}
            checkInvoicePaymentFunction={checkInvoicePaymentFunction}
            reissueInvoiceFunction={reissueInvoiceFunction}
            resendInvoiceFunction={resendInvoiceFunction}
            isSuperAdministrator={isSuperAdministrator}
            openInvoiceInNewTab={openInvoiceInNewTab}
            goBack={goBack}
        />
    );
};
