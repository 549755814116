import { useEffect } from "react";

export const useWindowVariable = (name: string, value: any) => {
    useEffect(() => {
        (window as any)[name] = value;

        return () => {
            delete (window as any)[name];
        };
    }, [name, value]);
};
