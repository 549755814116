import { useFormDialog } from "../../../components/form-item/client/components";
import { FormProps } from "../../../components/form-item/framework/react/FormProvider";
import { useCloseComplaintForm } from "../../shared/CloseComplaintForm";
import { ComplaintTaskDocument } from "../../../task/task.model";
import { ComplaintStillRelevantForm, useComplaintStillRelevantForm } from "../../shared/ComplaintStillRelevantForm";
import { CanOfferProposalForm, useCanOfferProposalForm } from "../../shared/CanOfferProposalForm";
import { OfferProposalForm, useOfferProposalForm } from "../../shared/OfferProposalForm";

export enum DetermineProposalFormIds {
    SingleChoiceStillRelevant = "SingleChoiceStillRelevant",
    TextAreaCloseComplaintBecauseIrrelevantReason = "TextAreaCloseComplaintBecauseIrrelevantReason",

    SingleChoiceCanOfferProposal = "SingleChoiceGardenerReached",
    TextAreaHandOverComplaintReason = "TextAreaHandOverComplaintReason",

    TextAreaProblem = "TextAreaCustomerComment",
    TextAreaProposal = "TextAreaCustomerProposal",
}

export type DetermineProposalForm =
    ComplaintStillRelevantForm<
        CanOfferProposalForm<
            OfferProposalForm
        >
    >;

export const useDetermineProposalDialog = (task: ComplaintTaskDocument, onConfirm: FormProps<DetermineProposalForm>["onSubmit"]) => {
    const offerProposalForm = useOfferProposalForm(task.complaint, {
        TextAreaProblem: DetermineProposalFormIds.TextAreaProblem,
        TextAreaProposal: DetermineProposalFormIds.TextAreaProposal,
    });

    const canOfferProposalForm = useCanOfferProposalForm(offerProposalForm, {
        SingleChoiceCanOfferProposal: DetermineProposalFormIds.SingleChoiceCanOfferProposal,
        TextAreaHandOverComplaintReason: DetermineProposalFormIds.TextAreaHandOverComplaintReason,
    });

    const closeComplaintBecauseIrrelevantForm = useCloseComplaintForm(task.complaint, {
        TextAreaReason: DetermineProposalFormIds.TextAreaCloseComplaintBecauseIrrelevantReason,
    });
    
    const complaintStillRelevantForm = useComplaintStillRelevantForm(closeComplaintBecauseIrrelevantForm, canOfferProposalForm, {
        SingleChoiceStillRelevant: DetermineProposalFormIds.SingleChoiceStillRelevant,
    });

    return useFormDialog(complaintStillRelevantForm, onConfirm);
};
