import { Link } from "react-router-dom";

import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { InvoiceUpdateCommentDocument } from "../comment.model";

interface GardenerInvoiceUpdateCommentProps {
    comment: InvoiceUpdateCommentDocument;
}

export const GardenerInvoiceUpdateComment = (props: GardenerInvoiceUpdateCommentProps) => {
    const { comment } = props;

    const invoice = <Link to={`/invoices/all/invoice/${comment.invoiceId}`}>Faktura {comment.invoiceNumber}</Link>;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Faktura rettet" author={comment.administratorName}>
            <CommentBody>
                {invoice} udstedt til <Link to={`/customers/context/${comment.customerId}`}>{comment.customerName}</Link> er blevet rettet.<br />
                <br />
                Årsag:<br />
                {comment.reason}
            </CommentBody>
        </Comment>
    );
};
