import { createContext, PropsWithChildren, useContext } from "react";

import { useCurrentUser } from "./current-user.hook";
import { useUser as useUserHook } from "./user.hook";
import { UserDocument } from "./user.model";

const UserContext = createContext<UserDocument | null | undefined>(undefined);

export const UserProvider = (props: PropsWithChildren<{}>) => {
    const userId = useCurrentUser();
    const user = useUserHook(userId);

    return (
        <UserContext.Provider value={user}>
            {props.children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    return useContext(UserContext) as UserDocument;
};
