import { Button, makeStyles } from "@material-ui/core";
import { PropsWithChildren } from "react";

const useStyles = makeStyles({
    root: {
        minWidth: "auto",
        padding: "12px",
        fontSize: "14px",
        fontWeight: 400,
        color: "#818181",
        lineHeight: "125%",
        textDecoration: "underline",
        textTransform: "none",

        "&:hover": {
            background: "none",
            color: "#4A4A4A",
            textDecoration: "underline",
        },
    },
});

interface LinkButtonProps {
    onClick: () => void;
}

export const LinkButton = (props: PropsWithChildren<LinkButtonProps>) => {
    const classes = useStyles();

    return (
        <Button className={classes.root} onClick={props.onClick} disableRipple>
            {props.children}
        </Button>
    );
};
