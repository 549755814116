import { Link } from "react-router-dom";

import { Comment } from "../components/comment/Comment";
import { CommentBody } from "../components/comment/CommentBody";
import { InvoiceReissueCommentDocument } from "./comment.model";

interface InvoiceReissueCommentProps {
    comment: InvoiceReissueCommentDocument;
}

export const InvoiceReissueComment = (props: InvoiceReissueCommentProps) => {
    const { comment } = props;

    const invoice = <Link to={`/invoices/all/invoice/${comment.invoiceId}`}>Faktura {comment.invoiceNumber}</Link>;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Faktura genudstedt" author={comment.administratorName}>
            <CommentBody>
                {invoice} udstedt af <Link to={`/gardeners/context/${comment.gardenerId}`}>{comment.gardenerName}</Link> er blevet genudstedt.<br />
                <br />
                Årsag:<br />
                {comment.reason}
            </CommentBody>
        </Comment>
    );
};
