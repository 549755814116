import { useEffect, useState } from "react";

import firebase from "firebase/compat/app";

import { GardenerPriceDocument } from "./gardener-price.model";

export const useGardenerPrice = (gardenerId: string | null | undefined, date: string | null | undefined) => {
    const [state, setState] = useState<GardenerPriceDocument | null>();

    useEffect(() => {
        if ( gardenerId === null || date === null ) return setState(null);
        if ( gardenerId === undefined || date === undefined ) return setState(undefined);

        setState(undefined);

        return firebase.firestore()
            .collection("gardenerPrices")
                .where("gardenerId", "==", gardenerId)
                .where("effectiveDate", "<=", date)
            .orderBy("effectiveDate", "desc")
            .limit(1)
            .onSnapshot(snapshot => {
                if ( snapshot.empty ) return setState(null);

                const document = snapshot.docs[0];

                setState({ id: document.id, ...document.data() } as GardenerPriceDocument);
            });
    }, [gardenerId, date]);

    return state;
};
