import { useCallback } from "react";

import firebase from "firebase/compat/app";

import { useBlockingProgress } from "../../../../../components/blocking-progress";
import { useSnackbar } from "../../../../../components/snackbar";

export interface ResendInvoiceData {
    invoiceId: string;
    reason: string;
}

const reissueInvoice: (data: ResendInvoiceData) => Promise<firebase.functions.HttpsCallableResult>
    = firebase.app().functions("europe-west3").httpsCallable("Invoice-resend");

export const useResendInvoiceFunction = () => {
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    return useCallback((data: ResendInvoiceData) => {
        block();

        reissueInvoice(data)
            .then(() => {
                openSnackbar("success", "Din handling er registreret");
                unblock();
            })
            .catch(() => {
                openSnackbar("error", "Der skete en fejl. Din handling er ikke blevet registreret.");
                unblock();
            });

    }, [block, unblock, openSnackbar]);
};
